import { useSyncExternalStore } from 'react';
import appEventStore from 'store/appEventStore';

const useAppEventStore = () => {
  const { resumeKey } = useSyncExternalStore(appEventStore.subscribe, appEventStore.getState);

  return {
    resumeKey,
  };
};

export default useAppEventStore;
