import Header from 'component/Header';
import { API_PATH } from 'constant/Api';
import { DEFAULT_WGS84_LAT_NUM, DEFAULT_WGS84_LON_NUM } from 'constant/Map';
import { Paths } from 'constant/RoutePath';
import { useCallback, useRef, useState } from 'react';
import { ECompanyType, EServiceUserOS } from 'types/App';
import { parseApiPath } from 'utils/apis';
import fetcher from 'utils/fetcher';
import { parseAppScheme } from 'utils/tmapInApp';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const buttonStyle = {
  padding: 10,
  background: '#efefef',
};

type TResult = {
  status: string;
  code: string;
};

const ProtoAppSchemePage = () => {
  const [cameraResult, setCameraResult] = useState<string>();
  const [qrResult, setQrResult] = useState<TResult>();

  const [tripId, setTripId] = useState();

  const refInput = useRef<HTMLInputElement>(null);

  // callback 테스트 항목
  // 1. QR 뒤로가기 시
  // 2. 코드번호직접입력 클릭 시
  const handleClickQR = useCallback(async () => {
    const result = await tmapWrapper.startQrCodeScanActivity('티맵킥보드 QR 테스트');
    tmapWrapper.makeToast(`[startQrCodeScanActivity] status: ${result[0]}, code: ${result[1]}`);
    setQrResult({
      status: result[0],
      code: result[1],
    });

    tmapWrapper.makeToast('버튼 클릭');
  }, []);

  // callback 테스트 항목
  // 1. 카메라 뒤로가기
  // 2. 카메라 찍은 후 return 으로 오는 값
  const handleClickCamera = useCallback(() => {
    // @ts-ignore
    window.cameraTest = (status, image) => {
      // const result = `status: ${status}, code: ${image}`;

      tmapWrapper.makeToast('[cameraTest] ' + status);
      setCameraResult(image);
    };

    setCameraResult('');

    // @ts-ignore
    // https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=375617480#InAppWebView%EC%97%B0%EB%8F%99%EA%B7%9C%EA%B2%A9-startCameraActivity
    tmapWrapper.startCameraActivity([
      {
        key: 'title',
        value: '티맵킥보드 카메라 테스트',
      },
      {
        key: 'captureMessage',
        value: '카메라 화면 메시지',
      },
      {
        key: 'callbackJS',
        value: 'cameraTest',
      },
    ]);
  }, []);

  const handleOpenPromotion = useCallback(() => {
    const jsonData = { pageid: 'mp_alphabet' };

    // @ts-ignore
    tmapWrapper.openServiceByName('promotion', { jsonData: JSON.stringify(jsonData) });
  }, []);

  const handleClickOpenPage = useCallback(() => {
    // @ts-ignore
    window.openService = () => {
      tmapWrapper.makeToast('openServiceByUrl');
    };

    tmapWrapper.openServiceByUrl(
      window.location.origin + Paths.ProtoAppSchemeTest,
      'Test',
      'openService'
    );
  }, []);

  return (
    <div style={{ marginTop: '30px' }}>
      <Header title="앱스킴테스트" />

      <br />
      <br />
      <button
        style={buttonStyle}
        onClick={handleClickQR}
      >
        QR 앱스킴 실행
      </button>
      <div>
        QR 결과{' '}
        {qrResult && (
          <div>
            status: {qrResult.status}, code: {qrResult.code}
          </div>
        )}
      </div>
      {qrResult?.code && (
        <button
          style={buttonStyle}
          onClick={() => {
            fetcher
              .post(API_PATH.POST_TRIP_START, {
                company: ECompanyType.GCOOTER,
                lat: DEFAULT_WGS84_LAT_NUM,
                lng: DEFAULT_WGS84_LON_NUM,
                userOs: ua.isAndroid ? EServiceUserOS.ANDROID : EServiceUserOS.IOS,
                // urlFromQR: 'https://open.gbike.io/?b=08201202490',
                urlFromQR: qrResult.code,
              })
              .then((res) => {
                const id = res.data?.data?.tripId;

                if (id) {
                  tmapWrapper.makeToast(`성공 ${res.data.data.tripId}`);
                  setTripId(id);
                } else {
                  throw new Error();
                }
              })
              .catch(() => {
                tmapWrapper.makeToast('실패');
              });
          }}
        >
          라이딩 시작 {tripId ? `됨 (${tripId})` : ''}
        </button>
      )}
      <br />
      {tripId && (
        <button
          style={buttonStyle}
          onClick={() => {
            fetcher
              .post(parseApiPath(API_PATH.POST_TRIP_END, { tripId }), {
                lat: DEFAULT_WGS84_LAT_NUM,
                lng: DEFAULT_WGS84_LON_NUM,
              })
              .then(() => {
                setTripId(undefined);
                tmapWrapper.makeToast('라이딩 종료 성공');
              })
              .catch((v) => {
                tmapWrapper.makeToast('라이딩 종료 실패');
              });
          }}
        >
          라이딩 종료
        </button>
      )}
      <br />
      <br />
      <button
        style={buttonStyle}
        onClick={handleClickCamera}
      >
        카메라 앱스킴 실행
      </button>
      <div style={{ lineBreak: 'anywhere', overflow: 'scroll' }}>
        카메라 결과{' '}
        <img
          src={`data:image/jpeg;base64,${cameraResult}`}
          width="200"
        />
        <div
          onClick={() => {
            tmapWrapper.copyClipboard('', JSON.stringify(cameraResult));
            tmapWrapper.makeToast('클립보드에 복사되었습니다');
          }}
        >
          {`data:image/jpeg;base64,${cameraResult}`}
        </div>
      </div>
      <br />
      <br />

      <button
        style={buttonStyle}
        onClick={handleOpenPromotion}
      >
        promotion테스트(망플)
      </button>

      <br />
      <br />

      <input
        ref={refInput}
        defaultValue={'tmap://communication?pageid=event'}
      />
      <button
        style={buttonStyle}
        onClick={() => {
          const p = parseAppScheme(refInput.current?.value || '');

          tmapWrapper.makeToast(JSON.stringify(p));

          p && tmapWrapper.openServicePage(p as any);
        }}
      >
        openServicePage 테스트
      </button>

      <br />
      <br />
      <button
        style={buttonStyle}
        onClick={handleClickOpenPage}
      >
        새창에서 스킴 실행 테스트
      </button>
    </div>
  );
};

export default ProtoAppSchemePage;
