import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { ReactComponent as IcoMenu } from 'resource/images/ico_menu.svg';
import s from 'styles/components/SideMenuButton.module.scss';

export const SideMenuButton = ({
  className = '',
  ...buttonProps
}: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={classNames(s.side_menu_button, className)}
      {...buttonProps}
    >
      <IcoMenu
        width={28}
        height={28}
      />
    </button>
  );
};

export default SideMenuButton;
