import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { APP_ENV, EProjectEnv, isLocalEnv, isProdEnv } from 'constant/Env';

import pkg from '../../package.json';

const SITE_NAME = 'datadoghq.com';
const SERVICE_NAME = 'tmap-bike-web';

// https://tmobi.datadoghq.com/logs/pipelines/indexes
// https://tmobi.datadoghq.com/logs/pipelines/indexes/517393/exclusionFilters/2
// https://github.com/TMOBI-LCC/personal-mobility-web/pull/88
// prod에서는 로그 파이프 라인으로 info 로그의 샘플링은 90% 가 걸러져서 10%만 남게 됨
// 꼭 남겨야 하는 로그는 error로 남겨야 함.
const DATA_DOG_ENV = {
  [EProjectEnv.PROD]: 'prd',
  [EProjectEnv.RTG]: 'rtg',
  [EProjectEnv.STAGE]: 'stg',
  [EProjectEnv.DEV]: 'dev',
}[APP_ENV];

// https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
if (!isLocalEnv) {
  datadogRum.init({
    applicationId: 'b2748814-d606-42d4-8f3a-befae034bde7',
    clientToken: 'pub5f9ad0fcaccb344820e4aaab2157067d',
    site: SITE_NAME,
    service: SERVICE_NAME,
    env: DATA_DOG_ENV,
    version: pkg.version,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    clientToken: 'pubfa5ec3b8e379929b870b3f53c95c018e',
    site: SITE_NAME,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: SERVICE_NAME,
    env: DATA_DOG_ENV,
    version: pkg.version,
  });
}

if (isProdEnv) {
  datadogRum.startSessionReplayRecording();
}

export const setUserKey = (userKey: string) => {
  datadogLogs.setUser({ id: userKey });
};
