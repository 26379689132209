import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';
import s from 'styles/components/tmds/TmdsButton.module.scss';

type TProps = PropsWithChildren<
  HTMLAttributes<HTMLButtonElement> & {
    color: 'primary' | 'secondary' | 'blue-stroke' | 'gray-stroke' | 'blue-naked' | 'negative';
    size: 'large' | 'medium' | 'small';
    disabled?: boolean;
  }
>;

const TmdsButton = ({ color, size, ...props }: TProps) => {
  return (
    <button
      {...props}
      className={classNames(s.button, s[color], s[size], props.className)}
    />
  );
};

export default TmdsButton;
