import distance from '@turf/distance';
import { QueryResultRenderedFeatureCollection } from '@vsm/vsm';
import DevMapInfo from 'component/DevMapInfo';
import VSMMap from 'component/VSMMap';
import VSMMarker from 'component/VSMMarker';
import PmPriceMarker from 'component/pmMain/PmPriceMarker';
import { isProdEnv } from 'constant/Env';
import { POLYGON_VISIBLE_LEVEL } from 'constant/Map';
import {
  MAP_HEIGHT,
  NOTICE_BANNER_HEIGHT,
  PM_CARD_HEIGHT,
  getNextZoomLevel,
} from 'constant/Scooter';
import useCurrentPosition from 'hooks/useCurrentPosition';
import useInterval from 'hooks/useInterval';
import useMap from 'hooks/useMap';
import useOnce from 'hooks/useOnce';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmMarkerLayer from 'hooks/usePmMarkerLayer';
import usePmNotice from 'hooks/usePmNotice';
import usePmPageLaunch from 'hooks/usePmPageLaunch';
import usePmPolygon, { STACK_ID_LIST } from 'hooks/usePmPolygon';
import usePmStore from 'hooks/usePmStore';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import useUserInfo from 'hooks/useUserInfo';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import vehicleRepository from 'repository/vehicleRepository';
import { ReactComponent as IconCurrent } from 'resource/images/icon-current.svg';
import pmStore from 'store/pmStore';
import s from 'styles/components/pmMain/PmMap.module.scss';
import { EAreas, ECompanyType, EMarkerType, TPointInfo } from 'types/App';
import { TLonLat } from 'types/Map';
import { TVehicles } from 'types/api/Vehicle';
import { sendAFMapView, sendAFSelectDevice } from 'utils/appsFlyer';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import PmClusterMarker from './PmClusterMarker';
import PmMarkerList from './PmMarkerList';
import PmSelectVehicleMarker from './PmSelectVehicleMarker';

const DEBOUNCE_DELAY = 500;

type TDevInfo = {
  maxLat?: number;
  maxLon?: number;
  minLat?: number;
  minLon?: number;
  level?: number;
};

type TPriceMarker = TLonLat & TPointInfo;

const NO_PARKING_TEXT = '이곳은 반납 금지 구역입니다.\n반납가능한 곳까지 이동하셔서 반납해주세요.';
const NO_SERVICE = '이곳은 서비스 지역이 아닙니다.';

const GUIDE_TEXT: Record<ECompanyType, any> = {
  [ECompanyType.XINGXING]: {
    [EAreas.NO_PARKING]: NO_PARKING_TEXT,
    [EAreas.NO_SERVICE]: NO_SERVICE,
  },
  [ECompanyType.GCOOTER]: {
    // NO_SERVICE polygon 영역 클릭 시 CHARGE_ADDITIONAL로 내려옴
    [EAreas.CHARGE_ADDITIONAL]: NO_SERVICE,
    [EAreas.NO_PARKING]: NO_PARKING_TEXT,
    [EAreas.NO_SERVICE]: NO_SERVICE,
  },
  // 씽씽과 동일
  [ECompanyType.TMAP]: {
    [EAreas.NO_PARKING]: NO_PARKING_TEXT,
    [EAreas.NO_SERVICE]: NO_SERVICE,
  },
};

const PRICE_MARKER_STATUS: Record<ECompanyType, any> = {
  [ECompanyType.XINGXING]: {
    [EAreas.CHARGE_ADDITIONAL]: true,
    [EAreas.NO_PARKING]: false,
    [EAreas.NO_SERVICE]: true,
  },
  [ECompanyType.GCOOTER]: {
    // NO_SERVICE polygon 영역 클릭 시 CHARGE_ADDITIONAL로 내려옴
    [EAreas.CHARGE_ADDITIONAL]: true,
    [EAreas.NO_PARKING]: false,
    [EAreas.NO_SERVICE]: true,
  },
  // 씽씽과 동일
  [ECompanyType.TMAP]: {
    [EAreas.CHARGE_ADDITIONAL]: true,
    [EAreas.NO_PARKING]: false,
    [EAreas.NO_SERVICE]: true,
  },
};

const CURRENT_LOCATION_DELAY = 2000;

type TProps = {
  onTouchMap?: () => void;
};

const PmMap = ({ onTouchMap }: TProps) => {
  const mainLogger = usePmLogger(LogPageId.Main);
  const ridingLogger = usePmLogger(LogPageId.Riding);
  const mainPoiPopLogger = usePmLogger(LogPageId.MainPoiPop);

  const { vehicleList, activeItem, activeCompany, getVehicleList, getParams } = usePmStore();

  const { pmStatus } = useUserInfo();
  const ridingCompany = useMemo(() => pmStatus.tripInfo?.company, [pmStatus]);

  const { isRiding } = usePmUsingStatus();
  const { isInitialized, getFeatureCollection, getFixedZoomLevel, moveToCenter } = useMap();
  const { loadedLaunchStatus } = usePmPageLaunch();
  const { currentPosition, getPosition } = useCurrentPosition();
  const {
    removeAllLayer: removeAllPolygon,
    renderVehiclePolygon,
    isLayerRendered,
    isVectorTileRendered,
    getLayerIdMap,
    initVehicleVectorTile,
    toggleVectorTile,
    hideAllVectorTile,
  } = usePmPolygon();
  const { findVehicleByCollection } = usePmMarkerLayer();
  const { visibleBannerList } = usePmNotice();

  const refStartZoom = useRef();
  const refCurrentZoomLevel = useRef<number>(0);
  const [isConfigLoaded, setConfigLoaded] = useState(false);
  const position = useMemo(() => currentPosition, [currentPosition]);

  const interval = useInterval(() => getPosition({ move: isRiding }), CURRENT_LOCATION_DELAY);

  const [devInfo, setDevInfo] = useState<TDevInfo>();
  const [priceMarkerInfo, setPriceMarkerInfo] = useState<TPriceMarker>();

  const alwaysShowPolygon = useMemo(() => isRiding || !!activeCompany, [isRiding, activeCompany]);
  const nowCompany = useMemo(
    () => activeItem?.company || ridingCompany || activeCompany,
    [activeItem, ridingCompany, activeCompany]
  );

  const hideAllPolygonAndTile = useCallback(
    ({ force } = { force: true }) => {
      if (alwaysShowPolygon && !force) {
        return;
      }

      removeAllPolygon();
      hideAllVectorTile();
    },
    [removeAllPolygon, alwaysShowPolygon, hideAllVectorTile]
  );

  const refDebounce = useRef<NodeJS.Timeout>();

  const getVehicleListDebounce = useCallback(() => {
    if (refDebounce.current) {
      clearTimeout(refDebounce.current);
    }

    refDebounce.current = setTimeout(() => {
      if (isRiding) {
        return;
      }

      const param = getVehicleList();

      !isProdEnv && setDevInfo(param);
    }, DEBOUNCE_DELAY);
  }, [getVehicleList, isRiding]);

  const getPolygonLayer = useCallback(
    ({ features }: QueryResultRenderedFeatureCollection) => {
      const layerIdMap = getLayerIdMap();
      const matchedLayer = features.find((f) => layerIdMap[f.properties.combinedInfo.layerId]);

      if (matchedLayer) {
        return matchedLayer;
      }

      return features.find((f) => STACK_ID_LIST.includes(f.properties.combinedInfo.stackId));
    },
    [getLayerIdMap]
  );

  const handleMoveEndMap = useCallback(() => {
    const nextLevel = getFixedZoomLevel();
    const prevLevel = refCurrentZoomLevel.current;

    if (!activeItem) {
      getVehicleListDebounce();
    }

    const devInfoParams = getParams();
    if (devInfoParams && !isProdEnv) {
      setDevInfo(devInfoParams);
    }

    if (nextLevel < POLYGON_VISIBLE_LEVEL) {
      pmStore.setActiveItem(null);
      getVehicleListDebounce();
      setPriceMarkerInfo(undefined);

      if (!alwaysShowPolygon) {
        hideAllPolygonAndTile();
      }
    } else {
      renderVehiclePolygon(nowCompany);
      // 기기 클릭과 동시에 줌이 일어나서 이벤트가 겹쳐서 생기는 버그를 방지하기 위한 코드
      if (prevLevel < POLYGON_VISIBLE_LEVEL && prevLevel !== nextLevel) {
        getVehicleListDebounce();
      }
    }

    refCurrentZoomLevel.current = nextLevel;
  }, [
    nowCompany,
    getVehicleListDebounce,
    activeItem,
    getFixedZoomLevel,
    alwaysShowPolygon,
    renderVehiclePolygon,
    hideAllPolygonAndTile,
    vehicleList.data?.items,
  ]);

  const activeVehicle = useCallback(
    (vehicle: TVehicles) => {
      /**
       * 줌 했을 때, 비율 계산식 및 근거
       * active 되었을 때 3:2의 비율로 카메라를 옮겨야함.
       * 기준은 핀의 하단 꼭지점을 기준으로 해야하기 때문에 마지막에 보정값 추가 필요
       * (원래 지도 높이 / 2) - (현재 보이는 뷰포트 지도 높이 * 0.6) 만큼 올려야됨
       */
      const nowZoomLevel = getFixedZoomLevel();
      const zoom = getNextZoomLevel(nowZoomLevel);
      const activeBaseMapHeight = MAP_HEIGHT - PM_CARD_HEIGHT;
      const viewMapHeight = visibleBannerList
        ? activeBaseMapHeight - NOTICE_BANNER_HEIGHT
        : activeBaseMapHeight;
      const halfMapHeight = MAP_HEIGHT / 2;
      const focusMapPoint = viewMapHeight * 0.6;

      moveToCenter({ ...vehicle, zoom }, { offsetY: -(halfMapHeight - focusMapPoint) });
      pmStore.setActiveItem(vehicle);
      mainLogger.sendClickLog('tap.map_device');
    },
    [getFixedZoomLevel, mainLogger, moveToCenter, visibleBannerList?.length]
  );

  const handleClickMap = useCallback(
    (e) => {
      onTouchMap?.();
      mainLogger.sendClickLog('tap.map');
      const screenPoint = e.data.screenPoint;
      const collection = getFeatureCollection(screenPoint);

      if (collection) {
        const vehicle = findVehicleByCollection(collection) as Nullable<TVehicles>;

        if (vehicle) {
          activeVehicle(vehicle);
          return;
        }
      }

      if (collection && (activeItem || alwaysShowPolygon)) {
        const hasPolygonLayer = !!getPolygonLayer(collection);

        if (hasPolygonLayer) {
          const { lng: lon, lat } = e.data.lngLat;

          if (!nowCompany) {
            return;
          }

          vehicleRepository
            .getCompanyAreaInfo({
              company: nowCompany,
              lon,
              lat,
            })
            .then((res) => {
              const type = res?.pointInfo.type;
              const guideText = GUIDE_TEXT[nowCompany]?.[type];
              const showPriceMarker = PRICE_MARKER_STATUS[nowCompany]?.[type];

              if (guideText) {
                tmapWrapper.makeToast(guideText);
              }

              if (
                showPriceMarker &&
                res.pointInfo &&
                typeof res.pointInfo.additionalFee === 'number'
              ) {
                setPriceMarkerInfo({
                  lon,
                  lat,
                  ...res.pointInfo,
                });
              } else {
                setPriceMarkerInfo(undefined);
              }

              if (type !== EAreas.SERVICE) {
                if (activeItem) {
                  mainPoiPopLogger.sendApp('tap.backscreen');
                } else {
                  isRiding
                    ? ridingLogger.sendApp('tap.map_restricedarea')
                    : mainLogger.sendApp('tap.map_restricedarea');
                }
              }
            })
            .catch(() => {
              setPriceMarkerInfo(undefined);
            });

          return;
        }
      }

      if (activeItem) {
        getVehicleListDebounce();
      }

      if (!alwaysShowPolygon) {
        hideAllPolygonAndTile();
      }

      pmStore.setActiveItem(null);
      setPriceMarkerInfo(undefined);
    },
    [
      onTouchMap,
      mainLogger,
      getFeatureCollection,
      activeItem,
      alwaysShowPolygon,
      findVehicleByCollection,
      activeVehicle,
      getPolygonLayer,
      nowCompany,
      mainPoiPopLogger,
      isRiding,
      ridingLogger,
      getVehicleListDebounce,
      hideAllPolygonAndTile,
    ]
  );

  const handleDragStart = useCallback(() => {
    onTouchMap?.();
    if (isRiding) {
      ridingLogger.sendClickLog('panning.map');
    } else {
      mainLogger.sendClickLog('panning.map');
    }
  }, [mainLogger, onTouchMap, isRiding, ridingLogger]);
  const handlePinchStart = useCallback(
    (e) => (refStartZoom.current = e.target.getCamera().getZoom()),
    []
  );
  const handlePinchEnd = useCallback(
    (e) => {
      const sendLog = isRiding ? ridingLogger.sendClickLog : mainLogger.sendClickLog;

      if (Number(refStartZoom.current) < e.target.getCamera().getZoom()) {
        sendLog('pinchin.map');
      } else {
        sendLog('pinchout.map');
      }
    },
    [isRiding, mainLogger.sendClickLog, ridingLogger.sendClickLog]
  );

  const handleClickMarker = useCallback(
    (item: TVehicles) => {
      onTouchMap?.();

      currentPosition &&
        sendAFSelectDevice({
          deviceBrandName: item.company,
          deviceBatteryRemain: item.battery,
          deviceLocation: {
            lat: item.lat,
            lon: item.lon,
          },
          deviceDistance: distance(
            [item.lat, item.lon],
            [currentPosition.lat, currentPosition.lon]
          ),
        });
    },
    [currentPosition, onTouchMap]
  );

  // state 업데이트를 해주지 않으면, 주행 이후 탭 변경 시 vector tile 이 보이지 않음
  // TODO : 추후 변경 가능한 지 테스트
  const handleConfigLoad = useCallback(() => setConfigLoaded(true), []);

  useOnce(!!isConfigLoaded, () => {
    initVehicleVectorTile();
  });

  useOnce(isInitialized && !activeItem && loadedLaunchStatus, () => {
    getVehicleList();
  });

  useEffect(() => {
    return () => {
      pmStore.setActiveItem(null);
    };
  }, []);

  // company 탭, 마커클릭, 주행 시 폴리곤노출
  useEffect(() => {
    if (nowCompany && isVectorTileRendered) {
      toggleVectorTile(nowCompany);
      renderVehiclePolygon(nowCompany, true);
    }

    return () => {
      hideAllPolygonAndTile({ force: true });
    };
  }, [nowCompany, isVectorTileRendered]);

  useEffect(() => {
    if (alwaysShowPolygon) {
      return;
    }
    if (isLayerRendered && !activeItem) {
      requestAnimationFrame(() => removeAllPolygon());
    }
  }, [alwaysShowPolygon, isLayerRendered, activeItem, removeAllPolygon]);

  useEffect(() => {
    setPriceMarkerInfo(undefined);
  }, [activeItem, activeCompany]);

  useEffect(() => {
    if (!activeItem && !activeCompany) {
      hideAllPolygonAndTile();
    }
  }, [activeItem, activeCompany, hideAllPolygonAndTile]);

  useEffect(() => {
    interval.start();

    return () => {
      interval.stop();
    };
  }, [isRiding]);

  // 로그
  useOnce(vehicleList.loaded, () => {
    vehicleList.data &&
      currentPosition &&
      sendAFMapView({
        prevPageId: '',
        deviceCount: (vehicleList.data.items || []).length,
        userLocation: currentPosition,
      });
  });

  return (
    <>
      <div className={s.map_wrap}>
        <VSMMap
          onMoveEnd={handleMoveEndMap}
          onClick={handleClickMap}
          onConfigLoad={handleConfigLoad}
          onDragStart={handleDragStart}
          onPinchStart={handlePinchStart}
          onPinchEnd={handlePinchEnd}
        />
        {!isRiding &&
          (vehicleList.data?.items || [])
            .filter((it) => it.viewType === EMarkerType.CLUSTER)
            .map((v, i) => (
              <PmClusterMarker
                key={v.vehicleId || i}
                item={v}
                onClick={() => handleClickMarker(v)}
              />
            ))}

        <PmMarkerList
          items={vehicleList.data?.items || []}
          isDrawMarker={!isRiding}
        />
        {position && (
          <VSMMarker {...position}>
            <IconCurrent />
          </VSMMarker>
        )}
        {priceMarkerInfo && (
          <VSMMarker
            {...priceMarkerInfo}
            anchor="bottom"
          >
            <PmPriceMarker additionalPrice={priceMarkerInfo.additionalFee} />
          </VSMMarker>
        )}
        <PmSelectVehicleMarker />
      </div>
      <DevMapInfo
        {...devInfo}
        activeItem={activeItem}
      />
    </>
  );
};

export default memo(PmMap);
