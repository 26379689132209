import { Paths } from 'constant/RoutePath';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import styles from 'styles/pages/ProtoPage.module.scss';

const ProtoPage = () => {
  const history = useHistory();

  return (
    <div className={styles.link_wrap}>
      {routes.map((c, i) => (
        <div key={i}>
          <h3>{c.title || 'Service'}</h3>

          {(c?.path as string[]).map((n) =>
            ![Paths.Pm, Paths.Proto].includes(n as any) ? (
              <div
                key={n}
                className={styles.link_box}
                onClick={() => {
                  history.push(n);
                }}
              >
                {n}
              </div>
            ) : null
          )}
        </div>
      ))}
    </div>
  );
};

export default ProtoPage;
