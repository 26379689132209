import axios from 'axios';
import classNames from 'classnames';
import { APP_ENV, isLocalEnv, isProdEnv } from 'constant/Env';
import { WEB_HOST, WEB_HOST_MAP } from 'constant/Path';
import { Paths } from 'constant/RoutePath';
import { StorageKey } from 'constant/Storage';
import useLayoutStore from 'hooks/useLayoutStore';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { localStorageStore } from 'store/storageStore';
import s from 'styles/components/BuildInfo.module.scss';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import packageInfo from '../../package.json';

enum EDataType {
  VALUE,
  ARRAY,
  KEY_VALUE,
  INPUT,
}

const webHost = isLocalEnv ? window.location.origin : WEB_HOST;
const appServerName = isLocalEnv ? 'LOCAL' : APP_ENV;

const BuildInfo = () => {
  const storageValue = useSyncExternalStore(
    localStorageStore.subscribe,
    localStorageStore.getState
  );

  const rdUserInfo = useUserInfo();
  const rdLayout = useLayoutStore();
  const [isView, setView] = useState<boolean>(false);
  const refUrl = useRef<HTMLInputElement>(null);
  const initStorage = useMemo(() => JSON.stringify(window.localStorage), []);
  const [, setUpdateView] = useState(Date.now());

  const devInfos = useMemo(() => {
    if (!isView) {
      return [];
    }

    const appInfo: any[] = [];
    for (let key in ua) {
      if (typeof ua[key] === 'object') {
        for (let subKey in ua[key]) {
          appInfo.push({ key: `${key}.${subKey}`, value: ua[key][subKey] });
        }
      } else {
        appInfo.push({ key, value: ua[key] });
      }
    }

    const cookieInfo: any[] = [];
    document.cookie.split(';').forEach((n) => {
      if (n) {
        const [key, ...values] = n.split('=');
        cookieInfo.push({ key, value: values.join('=') });
      }
    });

    const vsmInfo: any[] = [
      {
        key: 'config uri',
        value: (window as any).___map?.getConfig()?.uri,
      },
    ];

    const devLinks: any[] = [
      {
        key: 'Dev',
        pushLink: `${WEB_HOST_MAP.DEV}${Paths.PmMain}`,
      },
      {
        key: 'Stage',
        pushLink: `${WEB_HOST_MAP.STAGE}${Paths.PmMain}`,
      },
      {
        key: 'Rtg',
        pushLink: `${WEB_HOST_MAP.RTG}${Paths.PmMain}`,
      },
      {
        key: 'Rachel Local',
        pushLink: `http://192.168.45.78:3000${Paths.PmMain}`,
      },
      {
        key: 'proto 앱스킴 테스트',
        value: 'stage',
        pushLink: `${webHost}${Paths.Proto}`,
      },
      {
        key: 'QR 앱스킴 테스트',
        value: 'stage',
        pushLink: `${webHost}/proto/app-scheme`,
      },
      {
        key: '면허등록해제/약관동의해제',
        pushLink: `${webHost}/proto/user-info`,
      },
    ];

    const deviceInfo = [
      {
        key: 'rdLayout',
        value: JSON.stringify(rdLayout),
      },
    ];

    return [
      { key: 'Package Version', value: packageInfo.version, type: EDataType.VALUE },
      { key: 'accessKey', value: rdUserInfo.accessKey, type: EDataType.VALUE },
      { key: 'pmUserKey', value: rdUserInfo.pmUserKey, type: EDataType.VALUE },
      { key: 'Server Environment', value: appServerName, type: EDataType.VALUE },
      { key: 'url', value: window.location.href, type: EDataType.VALUE },
      { key: 'init storage', value: initStorage, type: EDataType.VALUE },
      { key: 'cookies', value: cookieInfo, type: EDataType.KEY_VALUE },
      { key: 'App Info', value: appInfo, type: EDataType.KEY_VALUE },
      { key: 'DeviceInfo', value: deviceInfo, type: EDataType.KEY_VALUE },
      { key: 'VSM Info', value: vsmInfo, type: EDataType.KEY_VALUE },
      { key: 'Url Test', value: '', type: EDataType.INPUT },
      { key: 'DEV Links', value: devLinks, type: EDataType.KEY_VALUE },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView]);

  const copyClipboard = useCallback((e, key, value) => {
    e.stopPropagation();

    if (value || typeof value === 'boolean') {
      if (ua.isInApp) {
        tmapWrapper.copyClipboard(key || '', value);
        tmapWrapper.makeToast(`${key ? key + ' 정보가 ' : ''}클립보드에 복사되었습니다`);
      } else {
        navigator.clipboard.writeText(value);
      }
    }
  }, []);

  return !isProdEnv ? (
    <div
      className={classNames(s.build_info_wrap, {
        [s.view_detail]: isView,
      })}
      onClick={() => setView(false)}
    >
      <div
        className={s.info_wrap}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {devInfos.map((n, listIndex) => (
          <li
            key={listIndex}
            className={classNames(s.list_item, {
              [s.table_data]: n.type === EDataType.KEY_VALUE,
              [s.array_data]: n.type === EDataType.ARRAY,
            })}
          >
            <h3>{n.key}</h3>
            <div
              className={s.data_wrap}
              onClick={(e) => {
                copyClipboard(
                  e,
                  n.key,
                  n.type === EDataType.VALUE ? n.value : (n.value || []).length > 0 ? n.value : ''
                );
              }}
            >
              {n.type === EDataType.ARRAY &&
                ((n.value || []).length > 0
                  ? (n.value as any[]).map((v, i) => (
                      <div
                        key={i}
                        className={s.array_data}
                        onClick={(e) => {
                          copyClipboard(e, '', v);
                        }}
                      >
                        {`${v ?? ''}`}
                      </div>
                    ))
                  : '정보 없음')}

              {n.type === EDataType.KEY_VALUE &&
                ((n.value || []).length > 0
                  ? (n.value as any[]).map((v, i) => (
                      <div
                        key={i}
                        className={classNames(s.array_data, s.key_value_data)}
                        onClick={(e) => {
                          if (v.ignoreCopy) {
                            e.stopPropagation();
                            return;
                          }
                          copyClipboard(e, v.key, v.value);
                        }}
                      >
                        <div className={s.table_header}>{v.key}</div>
                        {v.value && <div className={s.table_value}>{`${v.value ?? ''}`} </div>}
                        {v.link && (
                          <a
                            href={v.link}
                            onClick={(e) => {
                              if (ua.isInApp) {
                                e.preventDefault();
                                tmapWrapper.openServiceByUrl(
                                  e.currentTarget.href,
                                  '',
                                  'console.log',
                                  true
                                );
                              }
                            }}
                          >
                            {v.link}
                          </a>
                        )}
                        {v.pushLink && (
                          <a
                            href={v.pushLink}
                            onClick={(e) => {
                              if (ua.isInApp) {
                                e.preventDefault();
                                window.location.href = e.currentTarget.href;
                              }
                            }}
                          >
                            {v.pushLink}
                          </a>
                        )}
                        {v.openLink && (
                          <button
                            onClick={() => {
                              tmapWrapper.openServiceByUrl(v.openLink, '', 'console.log', true);
                            }}
                          >
                            {v.openLink}
                          </button>
                        )}
                      </div>
                    ))
                  : '정보 없음')}

              {n.type === EDataType.INPUT && (
                <li className={s.list_item}>
                  <div className={s.input_wrap}>
                    <input
                      type="text"
                      ref={refUrl}
                    />
                    <button
                      className={s.button}
                      onClick={() => {
                        if (refUrl.current) {
                          window.location.href = refUrl.current.value;
                        }
                      }}
                    >
                      open
                    </button>
                    <button
                      className={s.button}
                      onClick={() => {
                        if (refUrl.current) {
                          tmapWrapper.openServiceByUrl(
                            refUrl.current.value,
                            '',
                            'console.log',
                            true
                          );
                        }
                      }}
                    >
                      새창에서 열기
                    </button>
                    <button
                      className={s.button}
                      onClick={async () => {
                        if (refUrl.current) {
                          axios.post(
                            'https://surl.tmobiapi.com/openapi/v1/urlshortener/shortener',
                            {
                              apiKey: 'event-4b8f5f00-6fd6-4f97-9618-d658198ed52f',
                              routeCd: 'TMAP_EVENT',
                              orgUrl: refUrl.current.value,
                            }
                          );
                        }
                      }}
                    >
                      short url 제공
                    </button>
                  </div>
                </li>
              )}

              {n.type === EDataType.VALUE && (`${n.value ?? ''}` || '정보 없음')}
            </div>
          </li>
        ))}

        <ul className={s.storage_wrap}>
          {[
            ['이벤트 팝업', StorageKey.PM_EVENT_POPUP_MAP],
            ['바이크 레드닷', StorageKey.BIKE_RED_DOT],
            ['메인 Popover', StorageKey.MAIN_POPOVER],
            ['포인트 Popover', StorageKey.POINT_POPOVER],
            ['포인트 Popover Count', `${StorageKey.POINT_POPOVER}_count`],
            ['메인 이벤트 Popup', StorageKey.MAIN_EVENT_POPUP],
          ].map((target) => {
            const [displayName, key] = target;

            return (
              <li
                className={s.keys}
                key={displayName}
              >
                <p>
                  {displayName}
                  <button
                    onClick={() => {
                      localStorageStore.remove(key);

                      tmapWrapper.makeToast(`${displayName} 항목 초기화 되었습니다.`);
                      setUpdateView(Date.now());
                    }}
                  >
                    초기화
                  </button>
                </p>

                <p>
                  [{key}] {`${storageValue[key]}`}
                </p>
              </li>
            );
          })}
        </ul>

        <li
          className={s.end_item}
          onClick={() => window.location.reload()}
        >
          리스트의 마지막입니다
        </li>
      </div>

      <button
        className={s.btn_env}
        onClick={(e) => {
          e.stopPropagation();
          setView((prevState) => !prevState);
        }}
      >
        {`${appServerName}-${rdLayout.appSize.isLandscape ? 'L' : 'P'}`}
      </button>
    </div>
  ) : null;
};

export default BuildInfo;
