import { EDateFormat } from 'constant/Date';
import { localStorageStore } from 'store/storageStore';
import { EAFEventKey } from 'types/Log';
import { TLonLat } from 'types/Map';
import { TUserTripStatus } from 'types/api/User';
import ua from 'utils/uaParser';

import { getSafeDateFormat } from './date';
import { devLog } from './dev';
import tmapWrapper from './wrappers/TMapWrapper';

const USER_START_POS_FOR_AF = 'USER_START_POS_FOR_AF';
const USER_END_POS_FOR_AF = 'USER_END_POS_FOR_AF';
const USER_TRIP_FOR_AF = 'USER_TRIP_FOR_AF';

export const saveAFUserInfo = (
  tripId,
  { start, end, trip }: { start?: TLonLat; end?: TLonLat; trip?: Partial<TUserTripStatus> }
) => {
  const time = getSafeDateFormat(Date.now(), EDateFormat.yyyyMMddTHHmmss);

  start && localStorageStore.setValue(`${USER_START_POS_FOR_AF}_${tripId}`, { ...start, time });
  end && localStorageStore.setValue(`${USER_END_POS_FOR_AF}_${tripId}`, { ...end, time });
  trip && localStorageStore.setValue(`${USER_TRIP_FOR_AF}_${tripId}`, trip);
};

const getUserStartPos = (tripId): TLonLat & { time: string } =>
  localStorageStore.getState()[`${USER_START_POS_FOR_AF}_${tripId}`];
const getUserEndPos = (tripId): TLonLat & { time: string } =>
  localStorageStore.getState()[`${USER_END_POS_FOR_AF}_${tripId}`];
const getUserTrip = (tripId): Partial<TUserTripStatus> =>
  localStorageStore.getState()[`${USER_TRIP_FOR_AF}_${tripId}`];

export const clearAFUserInfo = (tripId) => {
  localStorageStore.setValue(`${USER_START_POS_FOR_AF}_${tripId}`, null);
  localStorageStore.setValue(`${USER_END_POS_FOR_AF}_${tripId}`, null);
  localStorageStore.setValue(`${USER_TRIP_FOR_AF}_${tripId}`, null);
};

export const sendAppsFlyerByApp = (name: EAFEventKey, data?: { [key: string]: string }) => {
  devLog(`[AF origin] ${name}`, data);

  if (ua.isInApp) {
    tmapWrapper.recordEvent(name, data || {});
  }
};

export const sendAFInitiateTutorial = () => {
  sendAppsFlyerByApp(EAFEventKey.INITIATE_TUTORIAL);
};

export const sendAFTutorialCompletion = () => {
  sendAppsFlyerByApp(EAFEventKey.TUTORIAL_COMPLETION);
};

export const sendAFLicenseRegistration = (prevPageId: number | string) => {
  sendAppsFlyerByApp(EAFEventKey.LICENSE_REGISTRATION, {
    af_prev: `${prevPageId || ''}`,
  });
};

export const sendAFLicenseRegistrationCompletion = () => {
  sendAppsFlyerByApp(EAFEventKey.LICENSE_REGISTRATION_COMPLETION);
};

export const sendAFMapView = (values: {
  prevPageId: number | string;
  deviceCount: number | string;
  userLocation: {
    lat: number | string;
    lon: number | string;
  };
}) => {
  sendAppsFlyerByApp(EAFEventKey.MAP_VIEW, {
    af_prev: `${values.prevPageId}`,
    af_device: `${values.deviceCount}`,
    af_lat: `${values.userLocation.lat}`,
    af_lon: `${values.userLocation.lon}`,
  });
};

export const sendAFSelectBrand = (brandName: string) => {
  sendAppsFlyerByApp(EAFEventKey.SELECT_BRAND, {
    af_brand: brandName,
  });
};

export const sendAFSelectDevice = (values: {
  deviceBrandName: string;
  deviceBatteryRemain: number | string;
  deviceLocation: {
    lat: number | string;
    lon: number | string;
  };
  deviceDistance: number | string;
}) => {
  sendAppsFlyerByApp(EAFEventKey.SELECT_DEVICE, {
    af_device_brand: values.deviceBrandName,
    af_device_battery: `${values.deviceBatteryRemain}`,
    af_device_location_lat: `${values.deviceLocation.lat}`,
    af_device_location_lon: `${values.deviceLocation.lon}`,
    af_distance: `${values.deviceDistance}`,
  });
};

export const sendAFStartRide = (values: {
  riderId: number | string;
  userStartLocation: {
    lat: number | string;
    lon: number | string;
  };
}) => {
  sendAppsFlyerByApp(EAFEventKey.START_RIDE, {
    rider_id: `${values.riderId}`,
    af_user_start_location_lat: `${values.userStartLocation.lat}`,
    af_user_start_location_lon: `${values.userStartLocation.lon}`,
  });
};

export const sendAFCheckRidingGuide = () => {
  sendAppsFlyerByApp(EAFEventKey.CHECK_RIDING_GUIDE);
};

export const sendAFScanDevice = (deviceBrandName: string) => {
  sendAppsFlyerByApp(EAFEventKey.SCAN_DEVICE, {
    af_device_brand: deviceBrandName,
  });
};

export const sendAFTermsCompletion = (brandName: string) => {
  sendAppsFlyerByApp(EAFEventKey.TERMS_COMPLETION, {
    af_brand: brandName,
  });
};

export const sendAFEndRide = () => {
  sendAppsFlyerByApp(EAFEventKey.END_RIDE);
};

export const sendAFCompleteRide = (
  values: {
    paymentMoney: number | string;
    deviceBrandName: string;
    riderId: number | string;
    userStartLocation?: {
      lat: number | string;
      lon: number | string;
    };
    userEndLocation?: {
      lat: number | string;
      lon: number | string;
    };
    moveDistance?: number | string;
    startTime?: number | string;
    endTime?: number | string;
    rideTime?: number | string;
  },
  { tripId }
) => {
  const userStartLocation = values.userStartLocation || getUserStartPos(tripId);
  const userEndLocation = values.userEndLocation || getUserEndPos(tripId);
  const userStartTime = values.startTime || getUserStartPos(tripId)?.time;
  const userEndTime = values.endTime || getUserEndPos(tripId)?.time;
  const userTripValue = getUserTrip(tripId);

  if (userStartLocation && userEndLocation && userTripValue) {
    sendAppsFlyerByApp(EAFEventKey.COMPLETE_RIDE, {
      af_revenue: `${values.paymentMoney}`,
      af_device_brand: values.deviceBrandName,
      rider_id: `${values.riderId}`,
      af_user_start_location_lat: `${userStartLocation.lat}`,
      af_user_start_location_lon: `${userStartLocation.lon}`,
      af_user_end_location_lat: `${userEndLocation.lat}`,
      af_user_end_location_lon: `${userEndLocation.lon}`,
      af_distance: `${values.moveDistance || userTripValue.distance}`,
      af_start_time: `${userStartTime}`,
      af_end_time: `${userEndTime}`,
      af_ride_time: `${values.rideTime || userTripValue.duration}`,
    });
    clearAFUserInfo(tripId);
  }
};

export const sendAFShotParking = () => {
  sendAppsFlyerByApp(EAFEventKey.SHOT_PARKING);
};

export const sendAFPayFailure = (
  values: {
    paymentMoney: number | string;
    deviceBrandName: string;
    riderId: number | string;
    userStartLocation?: {
      lat: number | string;
      lon: number | string;
    };
    userEndLocation?: {
      lat: number | string;
      lon: number | string;
    };
    moveDistance?: number | string;
    startTime?: number | string;
    endTime?: number | string;
    rideTime?: number | string;
  },
  { tripId }
) => {
  const userStartLocation = values.userStartLocation || getUserStartPos(tripId);
  const userEndLocation = values.userEndLocation || getUserEndPos(tripId);
  const userStartTime = values.startTime || getUserStartPos(tripId)?.time;
  const userEndTime = values.endTime || getUserEndPos(tripId)?.time;
  const userTripValue = getUserTrip(tripId);

  if (userStartLocation && userEndLocation && userTripValue) {
    sendAppsFlyerByApp(EAFEventKey.PAY_FAILURE, {
      af_revenue: `${values.paymentMoney}`,
      af_device_brand: values.deviceBrandName,
      rider_id: `${values.riderId}`,
      af_user_start_location_lat: `${userStartLocation.lat}`,
      af_user_start_location_lon: `${userStartLocation.lon}`,
      af_user_end_location_lat: `${userEndLocation.lat}`,
      af_user_end_location_lon: `${userEndLocation.lon}`,
      af_distance: `${values.moveDistance || userTripValue.distance}`,
      af_start_time: `${userStartTime}`,
      af_end_time: `${userEndTime}`,
      af_ride_time: `${values.rideTime || userTripValue.duration}`,
    });
    clearAFUserInfo(tripId);
  }
};

export const sendAFEventBannerView = (eventId: number | string) => {
  sendAppsFlyerByApp(EAFEventKey.EVENT_BANNER_VIEW, {
    af_event_id: `${eventId}`,
  });
};

export const sendAFEventBannerClick = (eventId: number | string) => {
  sendAppsFlyerByApp(EAFEventKey.EVENT_BANNER_CLICK, {
    af_event_id: `${eventId}`,
  });
};

export const sendAFEventPopupView = (eventId: number | string) => {
  sendAppsFlyerByApp(EAFEventKey.EVENT_POPUP_VIEW, {
    af_event_id: `${eventId}`,
  });
};

export const sendAFEventPopupClick = (eventId: number | string) => {
  sendAppsFlyerByApp(EAFEventKey.EVENT_POPUP_CLICK, {
    af_event_id: `${eventId}`,
  });
};

export const sendAFOpenedFromPushNotification = (pushId: number | string) => {
  sendAppsFlyerByApp(EAFEventKey.OPENED_FROM_PUSH_NOTIFICATION, {
    af_push_id: `${pushId}`,
  });
};

// 오픈스펙에서 위젯 제외
export const sendAFAfOpenedFromWidget = () => {
  sendAppsFlyerByApp(EAFEventKey.AF_OPENED_FROM_WIDGET);
};

export const sendAFOpenedFromMkt = (marketChannel: number | string, userKey?: string) => {
  sendAppsFlyerByApp(EAFEventKey.OPENED_FROM_MKT, {
    af_mkt_channel: `${marketChannel}`,
    userKey: userKey || '',
  });
};
