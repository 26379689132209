import { StorageKey } from 'constant/Storage';
import { differenceInCalendarDays } from 'date-fns';
import { useCallback, useMemo } from 'react';

import useStorageStore from './useStorageStore';

const usePopover = (key: StorageKey, expiredDay: number = 1) => {
  const [lastClickedTimeStamp, setLastClickedTimeStamp] = useStorageStore<string>(key, '');
  const [visibleCount, setVisibleCount] = useStorageStore<number>(`${key}_count`, 0);

  const isVisible = useMemo(() => {
    // 초기값 세팅시 null -> "" 인 부분 처리
    if (lastClickedTimeStamp === null) {
      return false;
    }
    if (lastClickedTimeStamp) {
      return differenceInCalendarDays(new Date(), new Date(lastClickedTimeStamp)) >= expiredDay;
    }
    return true;
  }, [expiredDay, lastClickedTimeStamp]);

  const setNowTimestamp = useCallback(() => {
    if (isVisible && visibleCount !== null) {
      setVisibleCount(visibleCount + 1);
    }
    setLastClickedTimeStamp(new Date().toISOString());
  }, [isVisible, setLastClickedTimeStamp, setVisibleCount, visibleCount]);

  return {
    lastClickedTimeStamp,
    setNowTimestamp,
    isVisible,
    visibleCount: visibleCount || 0,
  };
};

export default usePopover;
