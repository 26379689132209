import ModalPopup from 'component/ModalPopup';
import PmRejectRentPopup from 'component/PmRejectRentPopup';
import PmRentStatusLayer from 'component/PmRentStatusLayer';
import SideBar from 'component/SideBar';
// import UsingGuideText from 'component/UsingGuideText';
import PmBottom from 'component/pmMain/PmBottom';
import PmMainHeader from 'component/pmMain/PmMainHeader';
import PmPopupController from 'component/pmMain/PmPopupController';
import { PmRidingGuide } from 'component/pmMain/PmRidingGuide';
import { PopupLayerId } from 'constant/App';
import { Paths } from 'constant/RoutePath';
import { MODAL_TEXT } from 'constant/Scooter';
import { useLayerContextConsumer } from 'context/LayerContext';
import useHandleBackKey from 'hooks/useHandleBackKey';
import { useInitLogger } from 'hooks/useInitLogger';
import useMovePage from 'hooks/useMovePage';
import useOnce from 'hooks/useOnce';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmPageLaunch from 'hooks/usePmPageLaunch';
import usePmTripStart from 'hooks/usePmTripStart';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import usePopupLayer from 'hooks/usePopupLayer';
import useSendLocation from 'hooks/useSendLocation';
import useSideBarToggle from 'hooks/useSideBarToggle';
import useTmapLinkRedirect from 'hooks/useTmapLinkRedirect';
import { useCallback, useEffect, useMemo } from 'react';
import s from 'styles/pages/PmMainPage.module.scss';
import { ELottieType } from 'types/App';
import { sendAFCheckRidingGuide } from 'utils/appsFlyer';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const BackTMApMainDetail = () => (
  <>
    기기 이용은 종료되지 않아요.
    <br />
    라이딩을 마치시면 앱으로 돌아와
    <br />꼭 <mark>반납하기 버튼</mark>을 눌러주세요.
  </>
);

const PmMainPage = () => {
  const backTMapMainDimmed = usePopupLayer(PopupLayerId.BACK_TMAP_MAIN);
  const pmRidingGuide = usePopupLayer(PopupLayerId.PM_RIDING_GUIDE);

  const mainLogger = usePmLogger(LogPageId.Main);
  const ridingLogger = usePmLogger(LogPageId.Riding);
  const ridingPopupLogger = usePmLogger(LogPageId.RidingPopup, backTMapMainDimmed.visible);

  const { scanVehicle } = usePmTripStart();

  const lockUserPopup = useLayerContextConsumer();

  const { movePage } = useMovePage();
  const { startInitAction, accessKey } = usePmPageLaunch();

  const initLogger = useInitLogger(startInitAction ? accessKey : '');

  const { isUnpaid, isLock, isTryTrip, isRiding, isWaitReturn } = usePmUsingStatus();

  const sideBar = useSideBarToggle();

  useSendLocation();
  useTmapLinkRedirect(startInitAction);

  const sendLogger = useMemo(() => (isRiding ? ridingLogger : mainLogger), [isRiding]);

  /**
   * 제재당한 유저이거나, 라이딩상태에서 나갔다 오는 경우 보이지 않도록 함.
   * PmRentStatusLayer가 노출 되는 경우 보이지 않도록 함.
   */
  const visibleEventPopup = useMemo(
    () => startInitAction && !isLock && !isRiding && !isTryTrip && !isWaitReturn,
    [isLock, isRiding, isTryTrip, isWaitReturn, startInitAction]
  );

  const handleShowSideBar = useCallback(() => {
    sideBar.show();

    sendLogger.sendClickLog('tap.menu');
  }, [sideBar]);

  const handleClickBack = useCallback(() => {
    if (isRiding) {
      backTMapMainDimmed.show();
    } else {
      tmapWrapper.closeWebview();
    }
    sendLogger.sendClickLog('tap.back');
  }, [backTMapMainDimmed, isRiding, sendLogger]);

  const handleClickRidingGuideConfirm = useCallback(() => {
    sendAFCheckRidingGuide();
    scanVehicle();
    pmRidingGuide.hide();
    tmapWrapper.makeToast('이용요금은 반납완료 시 결제됩니다.');
  }, [pmRidingGuide, scanVehicle]);

  const handleClickRidingGuideOverlay = useCallback(() => {
    pmRidingGuide.hide();
  }, [pmRidingGuide]);

  useOnce(isUnpaid, () => {
    movePage(Paths.PmReturnPage);
  });

  useOnce(isLock, () => {
    lockUserPopup.pushPopup({ key: 'USER_LOCK', level: 10 });
  });

  useOnce(initLogger, () => {
    sendLogger.sendPageView();
  });

  useEffect(() => {
    return () => {
      backTMapMainDimmed.visible && ridingPopupLogger.sendClickLog('popup_tap.endservice_cancel');
    };
  }, [backTMapMainDimmed.visible]);

  useHandleBackKey(ua.isAndroid && ua.isInApp && isRiding, () => {
    if (sideBar.isSidebarVisible) {
      sideBar.hide();
      return;
    }
    if (backTMapMainDimmed.visible) {
      backTMapMainDimmed.hide();
    } else {
      backTMapMainDimmed.show();
    }
  });

  return (
    <>
      <PmRentStatusLayer
        loading={isTryTrip}
        type={ELottieType.UNLOCK}
      />
      <PmRentStatusLayer
        loading={isWaitReturn}
        type={ELottieType.PAYMENT}
      />
      {isLock && (
        <ModalPopup
          title={MODAL_TEXT.LOCK_USER.title}
          detail={MODAL_TEXT.LOCK_USER.detail}
          onConfirm={() => tmapWrapper.closeWebview()}
        />
      )}
      <div className={s.main_page_wrap}>
        <PmMainHeader
          onClickBack={handleClickBack}
          onClickSide={handleShowSideBar}
        />

        <PmPopupController visible={visibleEventPopup} />

        {pmRidingGuide.visible && (
          <PmRidingGuide
            onClickConfirm={handleClickRidingGuideConfirm}
            onClickOverlay={handleClickRidingGuideOverlay}
          />
        )}

        <PmBottom />

        <PmRejectRentPopup />

        {backTMapMainDimmed.visible && (
          <ModalPopup
            title={'해당 페이지를 종료 하시겠어요?'}
            detailComponent={<BackTMApMainDetail />}
            onConfirm={() => {
              ridingPopupLogger.sendClickLog('popup_tap.endservice_confirm');
              tmapWrapper.closeWebview();
            }}
            onCancel={() => {
              backTMapMainDimmed.hide();
            }}
          />
        )}
      </div>
      <SideBar {...sideBar} />
    </>
  );
};

export default PmMainPage;
