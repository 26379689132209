import ModalPopup from 'component/ModalPopup';
import { useLayerContextConsumer } from 'context/LayerContext';
import { useEffect } from 'react';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

export const NotFoundPopup = () => {
  const { pushPopup } = useLayerContextConsumer();

  useEffect(() => {
    pushPopup({ key: '404notFound', level: 1 });
  }, []);

  return (
    <ModalPopup
      title="종료된 이벤트입니다."
      onConfirm={() => {
        tmapWrapper.closeWebview();
      }}
      confirmLabel="TMAP BIKE로 이동"
    />
  );
};
