import { PaymentCardTitle } from 'component/PaymentCardTitle';
import { useCallback } from 'react';
import s from 'styles/components/pmReturn/PmReturnInfo.module.scss';
import { TPaymentInfo } from 'types/App';
import { setComma } from 'utils/formatter';

type TProps = Pick<
  TPaymentInfo,
  | 'companyName'
  | 'vehicleId'
  | 'useInfo'
  | 'feeInfo'
  | 'discountInfo'
  | 'purchasePayMeanInfo'
  | 'amount'
>;

export const PmReturnInfo = ({
  companyName,
  vehicleId,
  useInfo,
  feeInfo,
  discountInfo,
  purchasePayMeanInfo,
  amount,
}: TProps) => {
  const getDisCountValue = useCallback((value: number) => {
    if (value > 0) {
      return value * -1;
    }

    return value;
  }, []);

  return (
    <div className={s.info}>
      <div className={s.item}>
        <span className={s.item_name}>이용정보</span>
        <span className={s.item_value}>
          [{companyName}]{vehicleId}
        </span>
      </div>
      {(useInfo || []).map((item) => (
        <div
          className={s.item}
          key={item.name}
        >
          <span className={s.item_name}>{item.name}</span>
          <span className={s.item_value}>{item.value}</span>
        </div>
      ))}
      <div className={s.divider} />
      {(feeInfo || []).map((item) => (
        <div
          className={s.item}
          key={item.name}
        >
          <span className={s.item_name}>{item.name}</span>
          <span className={s.item_value}>{setComma(item.value)}원</span>
        </div>
      ))}
      {(discountInfo || []).map((item) => (
        <div
          className={s.item}
          key={item.name}
        >
          <span className={s.item_name}>{item.name}</span>
          <span className={s.item_value}>{setComma(getDisCountValue(item.value))}원</span>
        </div>
      ))}
      <div className={s.divider} />
      {purchasePayMeanInfo && (
        <>
          <div className={s.item}>
            <span className={s.item_name}>결제수단</span>
            <PaymentCardTitle {...purchasePayMeanInfo} />
          </div>
          <div className={s.divider} />
        </>
      )}
      <div className={s.item}>
        <span className={s.total_name}>최종 결제 금액</span>
        <span className={s.total_money}>{setComma(amount)}원</span>
      </div>
    </div>
  );
};

export default PmReturnInfo;
