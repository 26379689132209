import classNames from 'classnames';
import { ReactNode } from 'react';
import s from 'styles/components/DescriptionList.module.scss';

type TProps = {
  list: ReactNode[];
  itemClassName?: string;
  roundDot?: boolean;
};

const DescriptionList = ({ roundDot, list, itemClassName }: TProps) => {
  return (
    <ul className={s.list_wrap}>
      {list.map((v, i) => (
        <li
          key={`desc-${i}`}
          className={classNames(s.item, itemClassName)}
        >
          <div
            className={classNames(s.dot, {
              [s.is_round]: roundDot,
            })}
          />
          <span className={s.text}>{v}</span>
        </li>
      ))}
    </ul>
  );
};

export default DescriptionList;
