import {
  ECompanyName,
  ECompanyType,
  EServiceAccessStatus,
  ETripStatus,
  EVehicleType,
} from 'types/App';
import { EBannerType, EPopupViewOption, TPmLaunchData } from 'types/api/User';

const defaultPmLaunchStatus: TPmLaunchData = {
  userKey: 'userKey',
  companies: [
    {
      code: ECompanyType.XINGXING,
      name: ECompanyName.XINGXING,
    },
    {
      code: ECompanyType.GCOOTER,
      name: ECompanyName.GCOOTER,
    },
    {
      code: ECompanyType.TMAP,
      name: ECompanyName.TMAP,
    },
  ],
  userStatus: {
    code: EServiceAccessStatus.READY,
    desc: '서비스 이용 가능',
  },
  tripStatus: null,
  unpaidOrder: null,
  needCompanyTerm: {
    [ECompanyType.TMAP]: false,
    [ECompanyType.XINGXING]: false,
    [ECompanyType.GCOOTER]: false,
  },
  bannerList: [
    {
      bannerId: 4,
      title: '공지 테스트',
      landingUrl: '랜딩Url',
      bannerType: EBannerType.NOTICE,
      startDateTime: new Date('2023-08-22').toISOString(),
      endDateTime: new Date('2030-11-30').toISOString(),
      use: true,
      promotionId: null,
    },
    {
      bannerId: 5,
      title: '공지 테스트1',
      landingUrl: '',
      bannerType: EBannerType.NOTICE,
      startDateTime: new Date('2023-08-22').toISOString(),
      endDateTime: new Date('2030-11-30').toISOString(),
      use: true,
      promotionId: null,
    },
    {
      bannerId: 6,
      title: '공지 테스트2',
      landingUrl: '랜딩Url',
      bannerType: EBannerType.NOTICE,
      startDateTime: new Date('2023-08-22').toISOString(),
      endDateTime: new Date('2030-11-30').toISOString(),
      use: true,
      promotionId: null,
    },
    {
      bannerId: 7,
      title: '공지 테스트3',
      landingUrl: '',
      bannerType: EBannerType.NOTICE,
      startDateTime: new Date('2023-08-22').toISOString(),
      endDateTime: new Date('2030-11-30').toISOString(),
      use: true,
      promotionId: null,
    },
  ],
  popupList: [
    {
      popupId: 5,
      imgUrl: 'https://pm-cdn.tmobiweb.com/operation/popup/6982d6b824ab2916fca5acb3bb882565',
      landingUrl: null,
      viewOption: EPopupViewOption.NEVER,
      startDateTime: new Date('2023-08-22').toISOString(),
      endDateTime: new Date('2030-11-30').toISOString(),
      use: true,
      promotionId: null,
    },
    {
      popupId: 6,
      imgUrl: 'https://pm-cdn.tmobiweb.com/operation/popup/6982d6b824ab2916fca5acb3bb882565',
      landingUrl: null,
      viewOption: EPopupViewOption.NEVER,
      startDateTime: new Date('2023-08-22').toISOString(),
      endDateTime: new Date('2030-11-30').toISOString(),
      use: true,
      promotionId: null,
    },
  ],
};

export const mockTripStatus = {
  tripId: 12345,
  tripOrderId: 998,
  status: ETripStatus.START,
  company: ECompanyType.TMAP,
  duration: 0,
  battery: 80,
  vehicleId: 'PLQ7UT',
  distance: 10,
  vehicleType: EVehicleType.BIKE,
  lastAlertPushMessage: {
    msg: 'test',
    msgType: 'test',
  },
};

export const mockNeedLicenseUserStatus = {
  code: EServiceAccessStatus.REQUIRE_PM_LICENSE,
  desc: '서비스 이용 가능',
};

export const mockAllNeedTerms = {
  [ECompanyType.TMAP]: true,
  [ECompanyType.XINGXING]: true,
  [ECompanyType.GCOOTER]: true,
};

type TFillMockDataKey = keyof typeof defaultPmLaunchStatus;

export class MockInitApi {
  loading = false;
  loaded = true;
  data = defaultPmLaunchStatus;

  constructor(params: Partial<Record<TFillMockDataKey, any>> = {}) {
    const keys = Object.keys(params);
    const defaultData = keys.reduce((acc, key) => {
      acc[key] = params[key];
      return acc;
    }, defaultPmLaunchStatus);

    this.data = defaultData;
  }
}
