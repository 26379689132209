import classNames from 'classnames';
import { useAppLocation } from 'hooks/useAppLocation';
import { useCallback } from 'react';
import { ReactComponent as IcoArrowLeft } from 'resource/images/ico_arrow_left.svg';
import s from 'styles/components/BackButton.module.scss';

type TProps = {
  className?: string;
  onClick?: (e) => void;
};

export const BackButton = ({ className = '', onClick }: TProps) => {
  const { goBack } = useAppLocation();

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      } else {
        goBack();
      }
    },
    [goBack, onClick]
  );

  return (
    <button
      className={classNames(s.back_button, className)}
      onClick={handleClick}
      aria-label="뒤로가기"
    >
      <IcoArrowLeft />
    </button>
  );
};

export default BackButton;
