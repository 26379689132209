import { isProdEnv } from 'constant/Env';

export const devLog = (...args) => {
  if (!isProdEnv) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export const devAlert = (...args) => {
  if (!isProdEnv) {
    window.alert(...args);
  }
};
