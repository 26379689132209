import classNames from 'classnames';
import PmMap from 'component/pmMain/PmMap';
import { Paths } from 'constant/RoutePath';
import useMainHeaderPopover from 'hooks/useMainHeaderPopover';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import s from 'styles/components/map/MapLayout.module.scss';

const visibleMapPathnameList = [Paths.PmMain];

const MapLayout = ({ children }: PropsWithChildren) => {
  const { hide } = useMainHeaderPopover();
  const { pathname } = useLocation();

  return (
    <>
      <div
        className={classNames(s.wrapper, {
          [s.hide]: !visibleMapPathnameList.includes(pathname as any),
        })}
      >
        <PmMap onTouchMap={hide} />
      </div>

      {children}
    </>
  );
};

export default MapLayout;
