import {
  FocusEventHandler,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as InputDeleteImage } from 'resource/images/btn-input-delete.svg';
import s from 'styles/components/ExtensionInput.module.scss';

export type InputInstance = {
  input: Nullable<HTMLInputElement>;
  clear: VoidFunction;
  focus: VoidFunction;
};

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  onAfterInit?: (e: InputInstance) => void;
  onChange?: (e: { value: string }) => void;
  onBlur?: (e: FocusEventHandler<HTMLInputElement>) => void;
  onFocus?: VoidFunction;
  forceUppercase?: boolean;
  matchText?: RegExp;
  hideDeleteButton?: boolean;
};

export const ExtensionInput = ({
  onAfterInit,
  onChange,
  onBlur,
  matchText,
  forceUppercase,
  value,
  defaultValue,
  hideDeleteButton = false,
  onFocus,
  ...inputProps
}: TProps) => {
  const refWrap = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refDeleteButton = useRef<HTMLButtonElement>(null);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue || value || '');
  const numberKeyProps = useMemo(() => {
    return inputProps.type === 'number'
      ? {
          pattern: '\\d*',
        }
      : {};
  }, [inputProps]);

  const validTextInput = useCallback(
    (text) => {
      let newValue = text;

      if ((inputProps?.maxLength || 0) > 0 && inputProps?.type === 'number') {
        const newNumber = text.slice(0, inputProps?.maxLength);

        newValue = isNaN(parseInt(newNumber, 10)) ? '' : newNumber;
      }

      if (matchText) {
        newValue = (newValue.match(matchText) || []).join('');
      }

      if (forceUppercase) {
        newValue = newValue.toUpperCase();
      }

      return newValue;
    },
    [inputProps?.maxLength, inputProps?.type, matchText, forceUppercase]
  );

  const handleWindowClick = useCallback((e) => {
    if (!refWrap.current?.contains(e.target)) {
      setDeleteVisible(false);
    }
  }, []);

  const handleDeleteClick = useCallback(() => {
    setCurrentValue('');
    setDeleteVisible(false);
    onChange?.({ value: '' });
  }, [onChange]);

  const handleInputChange = useCallback(
    (e) => {
      const newValue = validTextInput(e.target.value);

      setCurrentValue(newValue);
      setDeleteVisible(newValue.length > 0);
      onChange?.({ value: newValue });
    },
    [onChange, validTextInput]
  );

  const handleInputFocus = useCallback((e) => {
    setDeleteVisible(e.target.value.length > 0);
    onFocus?.();
  }, []);

  const handleInputBlur = useCallback(
    (e) => {
      if (e.relatedTarget && e.relatedTarget !== refDeleteButton.current) {
        setDeleteVisible(false);
      }
      onBlur?.(e);
    },
    [onBlur]
  );

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  useEffect(() => {
    onAfterInit?.({
      input: refInput.current,
      focus: () => refInput.current?.focus(),
      clear: () => {
        setCurrentValue('');
        setDeleteVisible(false);
        onChange?.({ value: '' });
      },
    });
  }, []);

  return (
    <span
      className={s.wrap}
      ref={refWrap}
    >
      <input
        {...inputProps}
        {...numberKeyProps}
        ref={refInput}
        value={currentValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      {deleteVisible && !hideDeleteButton && (
        <button
          className={s.wrap}
          onClick={handleDeleteClick}
          ref={refDeleteButton}
        >
          <InputDeleteImage />
        </button>
      )}
    </span>
  );
};

export default ExtensionInput;
