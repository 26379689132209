import { EDateFormat } from 'constant/Date';
import { format, parseISO } from 'date-fns';
import ko from 'date-fns/locale/ko';

export const parseDate = (date: string | number | Date) => {
  return typeof date === 'string'
    ? parseISO(date)
    : typeof date === 'number'
      ? new Date(date)
      : date;
};

export const getSafeDateFormat = (date: string | number | Date, formatString: EDateFormat) => {
  const parsedDate = parseDate(date);

  try {
    return format(parsedDate, formatString, {
      locale: ko,
    });
  } catch (err) {
    return date.toString() || '';
  }
};

export const floorToNearestMinutes = (date, options?) => {
  if (!date) {
    return '';
  }

  const baseMinute = options?.baseMinute || 5;
  const parsedDate = parseDate(date);
  const minute = Math.floor(parsedDate.getMinutes() / baseMinute) * baseMinute;

  return parsedDate.setMinutes(minute);
};

export const isInTime = (startDate: string, endDate: string) => {
  const startTime = parseDate(startDate).getTime();
  const endTime = parseDate(endDate).getTime();
  const now = Date.now();
  const inTime = startTime < now && now < endTime;

  return !!inTime;
};

export { format };
