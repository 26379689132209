import { EApiResponseCode } from 'types/Api';
import { EAreas, ECompanyType, EMarkerType, EVehicleType } from 'types/App';
import { TVehicles } from 'types/api/Vehicle';

const vehicleMockItem: TVehicles = {
  isAllowBeep: true,
  allowBeep: true,
  allowPause: true,
  battery: 80,
  availableTime: 500,
  availableDistance: 700,
  company: ECompanyType.TMAP,
  lat: 37.57413133627048,
  lon: 127.00607914943804,
  vehicleId: 'PLQ7UT',
  viewType: EMarkerType.DEVICE,
  count: 1,
  unlockFee: 500,
  chargeTime: 2,
  chargeFee: 160,
  vehicleType: EVehicleType.BIKE,
  event: false, // 다트 이벤트 킥보드
  parkingImage: 'https://pm-cdn.tmobiweb.com/park/202311/09/437660db10fdf9d6d062a1b366458aaa',
  area: {
    type: EAreas.SERVICE,
    additionalFee: 5000,
  },
  isAllowPause: null,
};

export class MockVehicleItem {
  code = EApiResponseCode.SUCCESS;
  data = vehicleMockItem;

  constructor(params: Partial<{ battery: number; vehicleType: EVehicleType }> = {}) {
    this.data = { ...vehicleMockItem, ...params };
  }
}
