import { useSyncExternalStore } from 'react';
import promotionStore from 'store/promotionStore';

const usePromotion = () => {
  const { currentPromotion } = useSyncExternalStore(
    promotionStore.subscribe,
    promotionStore.getState
  );

  return { currentPromotion };
};

export default usePromotion;
