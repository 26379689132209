import { API_HOST } from 'constant/Path';
import { HttpResponse, http } from 'msw';
import { TPageMockCase } from 'tmsw/types/types';
import { EApiResponseCode } from 'types/Api';
import { EPaymentStatus, ETripStatus } from 'types/App';

import { MockVehicleItem } from '../api/company/vehicle';
import { MockInitApi, mockTripStatus } from '../api/init';
import { MockDefaultPayMeanInfoApi } from '../api/user/defaultPayMeanInfo';
import { MockVehicleListApi } from '../api/vehicle/getList';

const mainMockCases: TPageMockCase = {
  paymentAndNoRiding: {
    description: '카드 O 라이딩 X',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi());
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem());
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(new MockDefaultPayMeanInfoApi({ isSuccess: true }));
      }),
    ],
  },

  noPaymentAndNoRiding: {
    description: '카드 X 라이딩 X',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi());
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem());
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(undefined);
      }),
    ],
  },
  mainRiding: {
    description: '라이딩 상태 세팅',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(
          new MockInitApi({
            tripStatus: mockTripStatus,
          })
        );
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(new MockDefaultPayMeanInfoApi({ isSuccess: true }));
      }),
      http.get(`${API_HOST}/api/trip/*/status`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: new MockInitApi({ tripStatus: mockTripStatus }).data.tripStatus,
        });
      }),
      http.post(`${API_HOST}/api/trip/*/pause`, () => {
        return HttpResponse.json({});
      }),
      http.post(`${API_HOST}/api/trip/*/resume`, () => {
        return HttpResponse.json({});
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem());
      }),
      http.post(`${API_HOST}/api/trip/*/end`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: {
            tripOrderId: 12345,
          },
        });
      }),
    ],
  },
  mainRidingPause: {
    description: '라이딩 상태 일시잠금',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(
          new MockInitApi({
            tripStatus: mockTripStatus,
          })
        );
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(new MockDefaultPayMeanInfoApi({ isSuccess: true }));
      }),
      http.get(`${API_HOST}/api/trip/*/status`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: {
            ...new MockInitApi({ tripStatus: mockTripStatus }).data.tripStatus,
            status: ETripStatus.PAUSE,
          },
        });
      }),
      http.post(`${API_HOST}/api/trip/*/pause`, () => {
        return HttpResponse.json({});
      }),
      http.post(`${API_HOST}/api/trip/*/resume`, () => {
        return HttpResponse.json({});
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem());
      }),
      http.post(`${API_HOST}/api/trip/*/end`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: {
            tripOrderId: 12345,
          },
        });
      }),
    ],
  },
  cardMainTenance: {
    description: '카드사 점검 중',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi());
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(
          new MockDefaultPayMeanInfoApi(
            { isSuccess: false },
            {
              status: EPaymentStatus.CARD_MAINTENANCE,
              statusName: '카드 점검 중',
              payMeansDisplayName: undefined,
              payMeansDisplayNumber: undefined,
              paymentGroup: undefined,
              payMeansImageInfo: undefined,
            }
          )
        );
      }),
    ],
  },
  vehicleTest: {
    description: '바이크 리스트 테스트',
    mocks: [
      http.get(`${API_HOST}/api/vehicles`, () => {
        return HttpResponse.json(new MockVehicleListApi());
      }),
    ],
  },
  baeminSuccessTest: {
    description: '배민 성공 테스트',
    mocks: [
      http.get(`${API_HOST}/api/baemin/check`, () => {
        return HttpResponse.json({ data: true, code: EApiResponseCode.SUCCESS });
      }),
    ],
  },
  baeminFailTest: {
    description: '배민 실패 테스트',
    mocks: [
      http.get(`${API_HOST}/api/baemin/check`, () => {
        return HttpResponse.json({ data: false, code: EApiResponseCode.SUCCESS });
      }),
    ],
  },
};

export default mainMockCases;
