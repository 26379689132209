import classNames from 'classnames';
import BottomButton from 'component/BottomButton';
import CloseButton from 'component/CloseButton';
import Header from 'component/Header';
import PmRejectRentPopup from 'component/PmRejectRentPopup';
import PmRentStatusLayer from 'component/PmRentStatusLayer';
import { WEB_HOST } from 'constant/Path';
import { Paths } from 'constant/RoutePath';
import { useAppLocation } from 'hooks/useAppLocation';
import useMovePage from 'hooks/useMovePage';
import useOnce from 'hooks/useOnce';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmTripStart from 'hooks/usePmTripStart';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import useTerms from 'hooks/useTerms';
import useUserInfo from 'hooks/useUserInfo';
import qs from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { ReactComponent as CheckBoxOff } from 'resource/images/component-check-box-off.svg';
import { ReactComponent as CheckBoxOn } from 'resource/images/component-check-box-on.svg';
import { ReactComponent as SubCheckBoxOff } from 'resource/images/component-checkbox-sub-off.svg';
import { ReactComponent as SubCheckBoxOn } from 'resource/images/component-checkbox-sub-on.svg';
import { ReactComponent as ArrowIcon } from 'resource/images/icon-arrow-right-gray-12.svg';
import { ReactComponent as ErmsImage } from 'resource/images/img-img-erms.svg';
import userInfoStore from 'store/userInfoStore';
import s from 'styles/pages/PmTermsAgreePage.module.scss';
import { ELottieType } from 'types/App';
import { EUserStep } from 'types/api/User';
import { sendAFTermsCompletion } from 'utils/appsFlyer';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

export const PmTermsAgreePage = () => {
  const { isTryTrip } = usePmUsingStatus();
  const { enterCode } = usePmTripStart();
  const { moveMainPage } = useMovePage();
  const { termList, termVersion, setTermList, getCompanyTerms, postCompanyTermAgree } = useTerms();
  const termsLogger = usePmLogger(LogPageId.Terms, true);
  const { goBack } = useAppLocation();

  const userInfo = useUserInfo();

  const { company, vehicleId, termAgree } = useMemo(() => {
    const ri = userInfo.pmStatus.rentInfo || {};

    return {
      company: ri.company,
      vehicleId: ri.vehicleId,
      termAgree: !!(ri.company && userInfo.pmTermsAgree?.[ri.company]),
    };
  }, [userInfo]);
  const { allRequiredAgree, allAgree } = useMemo(
    () => ({
      allAgree: !!termList?.every((term) => term.agree),
      allRequiredAgree: !!termList?.filter((term) => term.essential)?.every((term) => term.agree),
    }),
    [termList]
  );

  const handleClickAgree = useCallback(() => {
    if (company && termList) {
      postCompanyTermAgree(company, termList, termVersion)
        .then(() => {
          if (company) {
            userInfoStore.setTermAgree(company);
            sendAFTermsCompletion(company);
          }
        })
        .catch((e) => {
          tmapWrapper.makeToast('약관동의에 실패하였습니다.');
        });
      termsLogger.sendClickLog('tap.next');
    }
  }, [company, termList, postCompanyTermAgree, termVersion, termsLogger]);

  useOnce(!!(company && vehicleId && termAgree), () => {
    if (vehicleId && company) {
      userInfoStore.setPmStatus({
        step: EUserStep.WAIT_RIDING,
        rentInfo: { company, vehicleId },
      });

      moveMainPage();
      enterCode({ company, vehicleId });
    }
  });

  useOnce(!!company, () => {
    if (company) {
      getCompanyTerms(company);
    }
  });

  const handleChangeTermAgree = useCallback((e) => {
    setTermList((list) => {
      return list?.map((term) => {
        return term.code === e.target.id
          ? {
              ...term,
              agree: e.target.checked,
            }
          : term;
      });
    });
    if (e.target.checked) {
      termsLogger.sendClickLog('tap.check');
    }
  }, []);

  const handleClickClose = useCallback(() => {
    termsLogger.sendClickLog('tap.xbutton');
    userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
    goBack();
  }, [goBack, termsLogger]);

  useEffect(() => {
    return () => {
      userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
    };
  }, []);

  return (
    <div className={s.wrap}>
      <PmRejectRentPopup />
      <PmRentStatusLayer
        loading={isTryTrip && termAgree}
        type={ELottieType.UNLOCK}
      />
      <Header
        rightButton={<CloseButton onClick={handleClickClose} />}
        leftButton={null}
      />
      <h1>
        <p>
          서비스 이용을 위해
          <br />
          <b>약관에 동의</b>해주세요.
        </p>
        <span>
          <ErmsImage />
        </span>
      </h1>
      <div className={classNames(s.all, s.checkbox_area)}>
        {allAgree ? <CheckBoxOn /> : <CheckBoxOff />}
        <input
          type="checkbox"
          id="all_agree"
          onChange={(e) => {
            const agree = e.currentTarget.checked;
            setTermList((list) => list?.map((term) => ({ ...term, agree })));
            termsLogger.sendClickLog('tap.checkall');
          }}
        />
        <label htmlFor="all_agree">약관 전체동의</label>
      </div>
      <h3>서비스 이용을 위한 약관</h3>
      <ul>
        {termList?.map(({ code, essential, description, agree }) => (
          <li
            className={s.checkbox_area}
            key={code}
          >
            {agree ? <SubCheckBoxOn /> : <SubCheckBoxOff />}
            <input
              type="checkbox"
              id={code}
              onChange={handleChangeTermAgree}
            />
            <label
              htmlFor={code}
              data-cy={code}
            >
              ({essential ? '필수' : '선택'}) {description}
            </label>
            <button
              onClick={() => {
                tmapWrapper.openServiceByUrl(
                  qs.stringifyUrl({
                    url: `${WEB_HOST}${Paths.PmTerms}`,
                    query: { code },
                  }),
                  '',
                  'console.log',
                  true
                );
                termsLogger.sendClickLog('impression.terms');
              }}
            >
              <ArrowIcon />
            </button>
          </li>
        ))}
      </ul>
      <div className={s.bottom}>
        {(termList?.length || 0) > 0 && (
          <BottomButton
            disabled={!allRequiredAgree}
            onClick={handleClickAgree}
            data-cy="다음"
          >
            다음
          </BottomButton>
        )}
      </div>
    </div>
  );
};

export default PmTermsAgreePage;
