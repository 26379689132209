import { PageId } from 'constant/PmLog';
import useHandleBackKey from 'hooks/useHandleBackKey';
import { usePmLogger } from 'hooks/usePmLogger';
import { PropsWithChildren } from 'react';
import { createRoot } from 'react-dom/client';
import s from 'styles/components/BottomSheet.module.scss';

import BottomSheet from './BottomSheet';
import PrePurchaseErrorAlert from './PrePurchaseErrorAlert';

const contents = {
  PRE_PURCHASE_ERROR: PrePurchaseErrorAlert,
} as const;

const logId: Record<string, string> = {
  PRE_PURCHASE_ERROR: PageId.PreAuth,
};

type TProps = {
  type?: keyof typeof contents;
  props?: any;
};

export const openBottomSheet = async ({ type, props = {} }: TProps) => {
  const rootEl = document.createElement('div');
  document.body.appendChild(rootEl);
  const root = createRoot(rootEl);
  const Component = contents[type || ''];

  if (!Component) {
    return;
  }

  return new Promise((resolve) => {
    const handleClose = (param: any) => {
      root.unmount();
      rootEl.remove();
      resolve(param);
    };
    root.render(
      <BottomSheetModal
        onClose={handleClose}
        logId={type ? logId[type] : ''}
      >
        <Component
          onClose={handleClose}
          {...props}
        />
      </BottomSheetModal>
    );
  });
};

type TModalProps = PropsWithChildren<{
  onClose: (param: any) => void;
  logId?: string;
}>;

function BottomSheetModal({ onClose, children, logId }: TModalProps) {
  const { sendClickLog } = usePmLogger(logId);

  useHandleBackKey(true, () => {
    onClose(false);
  });

  return (
    <div className={s.overlay}>
      <div
        className={s.dimmed}
        onClick={() => {
          onClose(false);
          if (logId) {
            sendClickLog('tap.backscreen');
          }
        }}
      />
      <BottomSheet>{children}</BottomSheet>
    </div>
  );
}
