import { ECompanyType } from './App';

export enum EStatusBarColor {
  WHITE = 'white',
  BLACK = 'black',
}

export enum EOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum EKeyCode {
  ENTER = 13,
}

export type TQrScanResult = {
  company: ECompanyType;
  vehicleId: string;
};
