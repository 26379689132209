import BottomButton from 'component/BottomButton';
import PmReturnInfo from 'component/pmReturn/PmReturnInfo';
import PmReturnTitle from 'component/pmReturn/PmReturnTitle';
import s from 'styles/components/PmReturn.module.scss';
import { TPaymentInfo } from 'types/App';

type TProps = {
  paymentInfo: TPaymentInfo;
  onConfirm?: () => void;
};

const PmReturnSuccess = ({ paymentInfo, onConfirm }: TProps) => {
  return (
    <>
      <div className={s.title_wrap}>
        <PmReturnTitle type={paymentInfo.status} />
      </div>
      {paymentInfo && (
        <PmReturnInfo
          companyName={paymentInfo.companyName}
          vehicleId={paymentInfo.vehicleId}
          useInfo={paymentInfo.useInfo}
          feeInfo={paymentInfo.feeInfo}
          discountInfo={paymentInfo.discountInfo}
          purchasePayMeanInfo={paymentInfo.purchasePayMeanInfo}
          amount={paymentInfo.amount}
        />
      )}

      <div className={s.bottom}>
        <BottomButton onClick={onConfirm}>확인</BottomButton>
      </div>
    </>
  );
};

export default PmReturnSuccess;
