import Vsm, {
  LngLatBoundsObjectCompatible,
  QueryRenderedFeaturesOptions,
  QueryResultRenderedFeatureCollection,
  ScreenBoundsCompatible,
  ScreenPointCompatible,
  SetBoundsOptions,
} from '@vsm/vsm';
import VSMMarker from 'component/VSMMarker';
import { DEFAULT_ZOOM } from 'constant/Map';
import { useCallback, useMemo } from 'react';
import mapStore from 'store/mapStore';
import { TLonLat } from 'types/Map';
import { devLog } from 'utils/dev';

import useMapStore from './useMapStore';

type TCenterProps = TLonLat & {
  zoom?: number;
};

type TOffset = {
  offsetX?: number;
  offsetY?: number;
};

export type TMapOption = {
  speed?: number;
  animate?: boolean;
  offsetX?: number;
  offsetY?: number;
  move?: boolean;
};

const DEFAULT_FEATURES_BUFFER = 3;

const useMap = () => {
  const { camera, map, isRenderCompleted, transform, currentPosition } = useMapStore();

  const isInitialized = useMemo(() => !!map, [map]);

  const getBounds = useCallback(() => {
    if (camera) {
      const { northEast, southWest } = camera.getBounds();

      return {
        west: southWest.lng,
        south: southWest.lat,
        east: northEast.lng,
        north: northEast.lat,
      } as LngLatBoundsObjectCompatible;
    }
  }, [camera]);

  const setBounds = useCallback(
    (bounds: LngLatBoundsObjectCompatible, options?: SetBoundsOptions) => {
      if (camera) {
        camera.setBounds(bounds, options);
      }
    },
    [camera]
  );

  const panBy = useCallback(
    ({ offsetX = 0, offsetY = 0 }: TOffset) => {
      camera?.panBy({
        x: offsetX,
        y: offsetY,
      });
    },
    [camera]
  );

  const moveToCenterWithOffset = useCallback(
    ({ lon, lat }: TLonLat, { offsetX = 0, offsetY = 0 }: TOffset) => {
      const center = { lat, lng: lon };

      if (transform) {
        const { x, y } = transform.lngLatToScreenPoint(center);
        const mapHeight = transform.getHeight();
        const mapWidth = transform.getWidth();

        const centerX = mapWidth / 2 - x;
        const centerY = mapHeight / 2 - y;

        panBy({
          offsetX: -(centerX + offsetX),
          offsetY: -(centerY + offsetY),
        });
      }
    },
    [panBy, transform]
  );

  const getMinMaxBounds = useCallback(() => {
    const bounds = getBounds();

    if (!bounds) {
      return;
    }

    const { east, west, south, north } = bounds;

    return {
      maxLat: north,
      minLat: south,
      maxLon: east,
      minLon: west,
    };
  }, [getBounds]);

  const getFixedZoomLevel = useCallback(() => {
    if (!camera) {
      return DEFAULT_ZOOM;
    }

    return Math.floor(camera.getZoom());
  }, [camera]);

  const moveToCenter = useCallback(
    (
      { lat, lon, zoom }: TCenterProps,
      option: TMapOption = { speed: 0.5, animate: true, offsetX: 0, offsetY: 0 }
    ) => {
      camera?.flyTo(
        {
          center: { lat, lng: lon },
          ...(zoom && { zoom }),
        },
        {
          speed: option.speed,
          // animate = false 는 camera.jumpTo와 동일
          animate: option.animate,
          offset: {
            x: option.offsetX || 0,
            y: option.offsetY || 0,
          },
        }
      );
    },
    [camera]
  );

  const removeLayer = useCallback(
    (layerId) => {
      const iterator = (count = 0) => {
        if (count > 5) {
          return;
        }

        requestAnimationFrame(() => {
          try {
            map?.removeLayer(Number(layerId));
            return;
          } catch (e) {
            devLog(e);
            iterator(count + 1);
          }
        });
      };

      iterator();
    },
    [map]
  );

  const renderDebugMarker = useCallback(
    (coords) =>
      coords.map(([lon, lat], i) => (
        <VSMMarker
          lon={lon}
          lat={lat}
          key={i}
        >
          <div style={{ width: 3, height: 3, backgroundColor: 'red' }} />
        </VSMMarker>
      )),
    []
  );

  const getFeatureCollection = useCallback(
    (
      screenPoint: ScreenPointCompatible | ScreenBoundsCompatible,
      option?: QueryRenderedFeaturesOptions
    ): QueryResultRenderedFeatureCollection | undefined => {
      return map?.queryRenderedFeatures(screenPoint, {
        buffer: DEFAULT_FEATURES_BUFFER,
        ...option,
      });
    },
    [map]
  );

  const isMapMovedByUser = useCallback(() => {
    return new Promise((resolve) => {
      const handleMoveStart = (e) => {
        if (e.data.domEvent) {
          resolve(true);
          map?.off(Vsm.Map.EventNames.MoveStart, handleMoveStart);
        }
      };
      map?.on(Vsm.Map.EventNames.MoveStart, handleMoveStart);
    });
  }, [map]);

  const isMapMoved = useCallback(() => {
    return new Promise((resolve) => {
      const handleMoveStart = () => resolve(true);
      map?.once(Vsm.Map.EventNames.MoveStart, handleMoveStart);
    });
  }, [map]);

  const setCurrentPosition = useCallback((position: TLonLat) => {
    mapStore.setCurrentPosition(position);
  }, []);

  return {
    isRenderCompleted,
    transform,
    currentPosition,
    isInitialized,
    moveToCenter,
    panBy,
    removeLayer,
    getBounds,
    setBounds,
    getMinMaxBounds,
    getFixedZoomLevel,
    moveToCenterWithOffset,
    renderDebugMarker,
    getFeatureCollection,
    isMapMovedByUser,
    isMapMoved,
    setCurrentPosition,
  };
};

export default useMap;
