import qs from 'query-string';
import { TApiStatusType } from 'types/Api';
import { ECompanyType, EVehicleType } from 'types/App';
import { TPmState, TPmStoreState, TVehicles } from 'types/api/Vehicle';
import { getDefaultApiStatus } from 'utils/apis';

import Store from './base';

const { pageid } = qs.parse(window.location.search);
const pageId = (pageid as string)?.toUpperCase();

const initialState: TPmStoreState = {
  vehicleList: getDefaultApiStatus<TPmState>({
    items: [],
  }),
  activeItem: null,
  activeType: EVehicleType[pageId] || EVehicleType.ALL,
  activeCompany: ECompanyType[pageId] || undefined,
};

class PmStore extends Store<TPmStoreState> {
  constructor() {
    super(initialState);
  }

  setActiveItem = (activeItem: Nullable<TVehicles>) => {
    this.updateState({
      activeItem,
    });
  };

  setActiveType = (activeType: EVehicleType) => {
    this.updateState({
      activeType,
    });
  };

  setActiveCompany = (activeCompany?: ECompanyType) => {
    this.updateState({
      activeCompany,
    });
  };

  resetList = () => {
    this.updateState({
      vehicleList: initialState.vehicleList,
    });
  };

  setApiVehicleList = (data?: TPmState, status: TApiStatusType = 'pending') => {
    let nextData = data || initialState.vehicleList.data;
    if (status === 'pending') {
      nextData = this.state.vehicleList.data;
    }

    this.setApiStatusState('vehicleList', nextData, status);
  };

  reset = () => {
    this.setState(initialState);
  };
}

const pmStore = new PmStore();

export default pmStore;
