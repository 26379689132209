export enum EMapStyle {
  DAY = 'DAY',
  NIGHT = 'NIGHT',
  SATELLITE = 'SATELLITE',
}

export enum EMapType {
  VECTOR = 'VECTOR',
  RASTER = 'RASTER',
}

export type TLonLat = {
  lon: number;
  lat: number;
};

export enum EMapGeometry {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
  MULTI_POLYGON = 'MultiPolygon',
}
