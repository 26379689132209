import { isLocalEnv, isProdEnv, isStageEnv } from 'constant/Env';
import { Paths } from 'constant/RoutePath';
import useAdditionalLocationSearch from 'hooks/useAdditionalLocationSearch';
import useMapStore from 'hooks/useMapStore';
import { useEffect } from 'react';
import s from 'styles/components/DevMapInfo.module.scss';
import { ECompanyType } from 'types/App';
import { TVehicles } from 'types/api/Vehicle';
import fetcher from 'utils/fetcher';

type TProps = {
  maxLat?: number;
  maxLon?: number;
  minLat?: number;
  minLon?: number;
  level?: number;
  activeItem?: TVehicles | null;
};

const STG_AUTH =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImF1dGhJZCI6InRtYXAiLCJ1c2VySWQiOiJ0bWFwIiwicm9sZXMiOlsiYXBweGluZ3hpbmciXX0sImlhdCI6MTU1NTMzNDcyMywiZXhwIjo4NjU1NTUzMzQ3MjN9.kGyVj-4aP8dRmDM5LerLt9yXhl2bHELay0XsvDnVaqQ';
const DEV_AUTH =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImF1dGhJZCI6InRtYXAiLCJ1c2VySWQiOiJ0bWFwIiwicm9sZXMiOlsiYXBweGluZ3hpbmciXX0sImlhdCI6MTU1NTMzNDcyMywiZXhwIjo4NjU1NTUzMzQ3MjN9.gGIf8pErHmZCiNWpffTdw4G1wAh2zyisQWSA6z1jO70';

const labels = ['maxLat', 'maxLon', 'minLat', 'minLon', 'level'];

const useMoveMarkerTest = (activeItem) => {
  const { camera, map } = useMapStore();
  const isDevMove = activeItem && activeItem.company === ECompanyType.XINGXING && isLocalEnv;

  const { currentQuery } = useAdditionalLocationSearch();

  useEffect(() => {
    const onContextMenu = async (e) => {
      const lat = e.data.lngLat.lat;
      const lng = e.data.lngLat.lng;

      const move = window.confirm(
        `${activeItem?.company} ${activeItem?.vehicleId} ${lat} ${lng} 로 이동?`
      );

      if (move) {
        await fetcher.put(
          // stage 만 가능?
          `https://tmap-${
            isStageEnv ? 'stg' : 'dev'
          }.xingxingmobility.com/api-xingxing/tmap/v1/location/devices/${
            activeItem.vehicleId
          }/manually`,
          {
            option: 1,
            battery: 0,
            lat,
            lng,
          },
          {
            headers: {
              authorization: isStageEnv ? STG_AUTH : DEV_AUTH,
            },
          }
        );

        window.location.href = `${Paths.PmMain}?lat=${lat}&lng=${lng}`;
      }
    };

    isDevMove && map?.on('ContextMenu', onContextMenu);

    return () => {
      isDevMove && map?.off('ContextMenu', onContextMenu);
    };
  }, [activeItem]);

  return isDevMove && currentQuery.lat && currentQuery.lng ? (
    <button
      onClick={() => {
        camera?.flyTo(
          {
            center: { lat: Number(currentQuery.lat) as any, lng: Number(currentQuery.lng) as any },
          },
          {
            animate: false,
          }
        );
      }}
    >
      {currentQuery.lat} {currentQuery.lng} 로 이동
    </button>
  ) : null;
};

const DevMapInfo = ({ activeItem, ...props }: TProps) => {
  const MoveButton = useMoveMarkerTest(activeItem);

  if (isProdEnv) {
    return null;
  }

  return (
    <div className={s.dev_info_wrap}>
      <ul>
        {Object.keys(props || {})
          .filter((v) => labels.includes(v))
          .map((key) => (
            <li key={key}>
              {key} : {props?.[key]}
            </li>
          ))}
      </ul>
      {MoveButton}
    </div>
  );
};

export default DevMapInfo;
