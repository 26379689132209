import { LOTTIE_NAME } from 'constant/App';
import lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import animationData from 'resource/lottie/bike_area_guide.json';

const PmGuideAnimation = () => {
  const refWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refWrapper.current) {
      lottie.loadAnimation({
        container: refWrapper.current,
        loop: true,
        animationData,
        name: LOTTIE_NAME.GUIDE,
      });
    }

    return () => {
      lottie.destroy(LOTTIE_NAME.GUIDE);
    };
  }, []);

  return (
    <div
      style={{ width: 'inherit', height: 'inherit', background: 'white' }}
      ref={refWrapper}
    />
  );
};

export default PmGuideAnimation;
