import classNames from 'classnames';
import ModalPopup from 'component/ModalPopup';
import PmRejectReturnPopup from 'component/PmRejectReturnPopup';
import TmdsButton from 'component/tmds/TmdsButton';
import { PopupLayerId } from 'constant/App';
import { COMPANY_NAME_MAP } from 'constant/Scooter';
import useAppEventStore from 'hooks/useAppEventStore';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmTrip from 'hooks/usePmTrip';
import usePopupLayer from 'hooks/usePopupLayer';
import { useUpdateNumber } from 'hooks/useUpdateNumber';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ReactComponent as IconLock } from 'resource/images/icon-lineicon-lock.svg';
import { ReactComponent as IconUnlock } from 'resource/images/icon-lineicon-unlock.svg';
import s from 'styles/components/pmMain/PmTripStatus.module.scss';
import { ECompanyType, ETripStatus } from 'types/App';
import { getDistance, parseRentDuration } from 'utils/formatter';

const KM_DIGIT = 1;

export const PmTripStatus = () => {
  const { resumeKey } = useAppEventStore();
  const { pmStatus } = useUserInfo();
  const tripInfo = useMemo(() => pmStatus.tripInfo || {}, [pmStatus.tripInfo]);

  const { endTrip, resumeTrip, pauseTrip, getTripStatus, moveToReturnPage } = usePmTrip();
  const refInterval = useRef<NodeJS.Timeout>();
  const ridingLogger = usePmLogger(LogPageId.Riding);
  const refSendedEndRideLog = useRef(false);

  const {
    tripId,
    vehicleId,
    company,
    status,
    distance,
    battery,
    lastAlertPushMessage,
    vehicleType,
    tripOrderId,
    ...others
  } = tripInfo;
  const { number: duration, resetNumber: resetDuration } = useUpdateNumber(others.duration);
  const pauseGuidePopup = usePopupLayer(PopupLayerId.PAUSE_GUIDE_POPUP);
  const pushMessagePopup = usePopupLayer(PopupLayerId.PUSH_MESSAGE_POPUP);
  const refLastMessageType = useRef(lastAlertPushMessage?.msgType);

  const lock = useMemo(() => {
    const support = company && [ECompanyType.XINGXING, ECompanyType.TMAP].includes(company);

    return {
      support,
      pause: status === ETripStatus.PAUSE,
    };
  }, [status, company]);

  const handleToggleLock = useCallback(async () => {
    if (tripId === undefined) {
      return;
    }

    const toggleTrip = lock.pause ? resumeTrip : pauseTrip;

    if (!lock.pause) {
      pauseGuidePopup.show();
      ridingLogger.sendClickLog('tap.pause');
    }

    try {
      await toggleTrip(tripId);
    } finally {
      getTripStatus(tripId);
    }
  }, [getTripStatus, lock.pause, pauseGuidePopup, pauseTrip, resumeTrip, ridingLogger, tripId]);

  useEffect(() => {
    if (lastAlertPushMessage?.msgType !== refLastMessageType.current) {
      !pushMessagePopup.visible && pushMessagePopup.show();

      refLastMessageType.current = lastAlertPushMessage?.msgType || '';
    }
  }, [lastAlertPushMessage, pushMessagePopup]);

  useEffect(() => {
    if (tripId !== undefined) {
      refInterval.current = setInterval(() => getTripStatus(tripId), 5000);
      getTripStatus(tripId).then((e) => {
        resetDuration(e.duration);
      });
    }
    return () => {
      refInterval.current && clearInterval(refInterval.current);
    };
  }, [getTripStatus, tripId, resetDuration]);

  useEffect(() => {
    if (status === ETripStatus.END && tripOrderId !== undefined) {
      moveToReturnPage(tripOrderId);
    }
  }, [moveToReturnPage, status, tripOrderId]);

  useEffect(() => {
    resumeKey > 0 &&
      tripId !== undefined &&
      getTripStatus(tripId).then((e) => {
        resetDuration(e.duration);
      });
  }, [resumeKey, getTripStatus, resetDuration, tripId]);

  return (
    <>
      <PmRejectReturnPopup />
      {pushMessagePopup.visible && (
        <ModalPopup
          title={lastAlertPushMessage?.msg || ''}
          onConfirm={() => pushMessagePopup.hide()}
        />
      )}
      {pauseGuidePopup.visible && (
        <ModalPopup
          title={'일시 잠금 되었습니다.'}
          detailComponent={
            <>
              일시잠금 중에도
              <br />
              <mark>동일한 주행요금이 부과</mark>되며
              <br />
              30분 경과 시 자동 반납처리되니
              <br />
              유의하세요.
            </>
          }
          onConfirm={() => pauseGuidePopup.hide()}
        />
      )}
      <div className={s.wrapper}>
        <div className={s.header}>
          <p className={s.scooter_info}>
            <span className={s.vehicle}>
              {vehicleId && company ? `[${COMPANY_NAME_MAP[company || '']}]${vehicleId}` : ' '}
            </span>
            <span className={s.status}>{lock.pause ? '일시잠금중' : '주행중'}</span>
          </p>
          <p className={s.battery_info}>
            <span className={s.battery_label}>배터리</span>
            <span className={s.battery_percent}>{isNaN(Number(battery)) ? '' : `${battery}%`}</span>
          </p>
        </div>
        <ul className={classNames(s.trip_info)}>
          <li>
            <span className={s.value}>{isNaN(duration) ? '' : parseRentDuration(duration)}</span>
            <span className={s.label}>이용시간</span>
          </li>
          {typeof distance === 'number' && (
            <li>
              <span className={s.value}>{getDistance(distance, KM_DIGIT)}</span>
              <span className={s.label}>이동거리</span>
            </li>
          )}
        </ul>
        <div className={s.action}>
          {lock.support && (
            <TmdsButton
              color="gray-stroke"
              size="large"
              onClick={handleToggleLock}
              data-cy={lock.pause ? '잠금해제' : '일시잠금'}
            >
              <i className={s.button_icon}>{lock.pause ? <IconUnlock /> : <IconLock />}</i>
              <span>{lock.pause ? '잠금해제' : '일시잠금'}</span>
            </TmdsButton>
          )}
          <TmdsButton
            color="primary"
            size="large"
            onClick={() => {
              if (!refSendedEndRideLog.current) {
                refSendedEndRideLog.current = true;
                ridingLogger.sendClickLog('tap.endride', {
                  index: company?.toLowerCase(),
                  unit: vehicleType,
                });
              }

              endTrip({ company, vehicleId }, { checkArea: true });
              return;
            }}
            data-cy="반납하기"
          >
            반납하기
          </TmdsButton>
        </div>
      </div>
    </>
  );
};

export default PmTripStatus;
