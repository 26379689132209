import { LayerContextProvider } from 'context/LayerContext';
import useLayoutStore from 'hooks/useLayoutStore';
import usePmPageLaunch from 'hooks/usePmPageLaunch';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { EOrientation } from 'types/Device';
import { TRouter } from 'types/Router';
import renderNestedRouter from 'utils/renderNestedRouter';
import { firstCharToUppercase } from 'utils/string';

// import {NetworkDetectModal} from './NetworkDetectModal';

type TProps = PropsWithChildren<{
  className?: string;
  routes?: TRouter[];
}>;

const TITLE_PREFIX = 'T map Search';

const getPageTitle = (routes: TRouter[] = [], pathname) => {
  const matched = routes.find((r) => pathname === r.path);
  const pathnameToTitle = (p = '') =>
    p
      .split('/')
      .filter((v) => !!v)
      .map((v) => firstCharToUppercase(v))
      .join(' ');

  return matched?.title ?? pathnameToTitle(pathname);
};

const CommonLayout = ({ className, children, routes = [] }: TProps) => {
  const { pathname } = useLocation();
  const title = useMemo(() => getPageTitle(routes, pathname), [routes, pathname]);
  const {
    appSize: { isLandscape },
  } = useLayoutStore();

  usePmPageLaunch(true);

  useEffect(() => {
    document.body.setAttribute(
      'data-orientation',
      isLandscape ? EOrientation.LANDSCAPE : EOrientation.PORTRAIT
    );
  }, [isLandscape]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {TITLE_PREFIX}
          {title ? ` - ${title}` : ''}
        </title>
      </Helmet>

      <LayerContextProvider>
        <div className={className}>
          {children}
          {renderNestedRouter(routes)}
        </div>
        {/* <NetworkDetectModal /> */}
      </LayerContextProvider>
    </HelmetProvider>
  );
};

export default CommonLayout;
