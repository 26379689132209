import classNames from 'classnames';
import { ReactNode } from 'react';
import s from 'styles/components/Header.module.scss';

import BackButton from './BackButton';

type TProps = {
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  title?: ReactNode;
  gradient?: boolean;
  className?: string;
};

export const HEADER_HEIGHT = 56;

export const Header = ({
  leftButton = <BackButton />,
  rightButton = null,
  title = '',
  gradient = false,
  className,
}: TProps) => {
  return (
    <div
      className={classNames(s.header_wrap, className, {
        [s.gradient]: gradient,
      })}
    >
      {leftButton && <div className={classNames(s.side, s.left)}> {leftButton} </div>}
      {title && (
        <h1>
          <span className={s.title}>{title}</span>
        </h1>
      )}
      {rightButton && <div className={classNames(s.side, s.right)}> {rightButton} </div>}
    </div>
  );
};

export default Header;
