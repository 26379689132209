import { PageId } from 'constant/PmLog';
import {
  PRE_PURCHASE_ERROR_CODE,
  PRE_PURCHASE_ERROR_MESSAGE,
  getPrePurchaseErrorType,
} from 'constant/Vehicle';
import { usePmLogger } from 'hooks/usePmLogger';
import { useMemo } from 'react';
import { ReactComponent as IconCaution } from 'resource/images/sign_caution_2.svg';
import s from 'styles/components/bottomSheet/PrePurchaseErrorAlert.module.scss';

import TmdsButton from '../TmdsButton';

type TProps = {
  onClose: (moveTo: any) => void;
  code: string;
  message?: string;
};

const PrePurchaseErrorAlert = ({ onClose, code, message }: TProps) => {
  const { type, msg } = useMemo(() => getPrePurchaseErrorType(code), [code]);
  const { sendClickLog } = usePmLogger(PageId.PreAuth, true);

  return (
    <div data-cy="pre-purchase-error">
      <div className={s.header}>
        <IconCaution />
      </div>
      <div className={s.body}>
        <section className={s.title}>
          {msg.split('\n').map((it) => (
            <div key={it}>{it}</div>
          ))}
        </section>
        <section className={s.message}>
          {PRE_PURCHASE_ERROR_MESSAGE[code] ||
            message ||
            PRE_PURCHASE_ERROR_MESSAGE[PRE_PURCHASE_ERROR_CODE.ETC_ERROR]}
        </section>
      </div>
      <div className={s.footer}>
        {type === 'invalidError' ? (
          <TmdsButton
            data-cy="닫기"
            className={s.close_button}
            color="secondary"
            size="large"
            onClick={() => {
              onClose(false);
              sendClickLog('tap.close_button');
            }}
          >
            닫기
          </TmdsButton>
        ) : (
          <TmdsButton
            className={s.retry_button}
            color="secondary"
            size="large"
            onClick={() => {
              onClose('retry');
              sendClickLog('tap.retry_button');
            }}
          >
            다시 시도하기
          </TmdsButton>
        )}

        <TmdsButton
          className={s.change_button}
          color="primary"
          size="large"
          onClick={() => {
            onClose('purchase');
            sendClickLog('tap.cardchange_button');
          }}
        >
          결제수단 변경
        </TmdsButton>
      </div>
    </div>
  );
};

export default PrePurchaseErrorAlert;
