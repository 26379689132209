import Vsm from '@vsm/vsm';
import ProtoQueryViewer from 'component/ProtoQueryViewer';
import VSMMap from 'component/VSMMap';
import { Paths } from 'constant/RoutePath';
import useMap from 'hooks/useMap';
import useMapPolygon from 'hooks/useMapPolygon';
import useMapStore from 'hooks/useMapStore';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';

const MOCK_LOCATION: [number, [number, number]][] = [
  [4, [37.56287942202016, 126.98667129999882]],
  [12, [37.565271663751616, 126.98143134915853]],
  [8, [37.46429981538927, 128.3486142930792]],
  [9, [37.554477192930634, 126.73098112962293]],
];

const pointCoord = [126.9834782, 37.5680947];
const polyLineCoord = [
  [126.9615047, 37.5650229],
  [126.9603088, 37.5605019],
  [126.96868, 37.5644396],
  [126.9651843, 37.5571474],
  [126.9720377, 37.5592987],
];

const polygonCoord = [
  [
    [126.9760225, 37.5602266],
    [126.9769524, 37.5561158],
    [126.9791181, 37.556154],
    [126.9829383, 37.554431],
    [126.9890636, 37.5613231],
    [126.9874829, 37.5661263],
    [126.9829383, 37.5660219],
    [126.9813575, 37.5615319],
    [126.9760225, 37.5602266], // 닫아주어야 함
  ],
];

const holesInPolygonCoord = [
  [
    // ccw
    [126.9604443, 37.5721359],
    [126.9661493, 37.565786],
    [126.9716115, 37.5701636],
    [126.9769524, 37.5701636],
    [126.9876948, 37.5701155],
    [126.9976482, 37.5705926],
    [126.9974054, 37.5757396],
    [126.9920645, 37.5776155],
    [126.9862989, 37.5769902],
    [126.9799869, 37.5802129],
    [126.9801083, 37.5831468],
    [126.9700942, 37.5839163],
    [126.9708225, 37.5815115],
    [126.9714901, 37.5793952],
    [126.9726433, 37.5760763],
    [126.9666955, 37.5743931],
    [126.9603229, 37.5726133],
    [126.9604443, 37.5721359],
  ],
  [
    // ccw
    [126.9792586, 37.5743005],
    [126.9799262, 37.5724245],
    [126.9829001, 37.5718472],
    [126.9830215, 37.5752625],
    [126.9812008, 37.5757435],
    [126.9792586, 37.5743005],
  ],
  [
    //cw
    [126.9743675, 37.5756948],
    [126.9759154, 37.5756309],
    [126.9763669, 37.5751581],
    [126.9765281, 37.5747364],
    [126.9744159, 37.5740846],
    [126.9743675, 37.5756948],
  ],
];

const lineStyle = {
  stroke: '#2780e3',
  'stroke-width': '4',
  'stroke-opacity': '1',
};

const ProtoMove = () => {
  const { getFixedZoomLevel, getMinMaxBounds } = useMap();

  const getQuery = useCallback(() => {
    const bounds = getMinMaxBounds();

    if (!bounds) {
      return;
    }

    return {
      ...bounds,
      level: getFixedZoomLevel(),
    };
  }, [getMinMaxBounds, getFixedZoomLevel]);

  return (
    <button
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10,
        background: '#fff',
        border: '3px solid #000',
      }}
      onClick={() => {
        window.open(
          queryString.stringifyUrl({
            url: Paths.ProtoPmRent,
            query: getQuery(),
          })
        );
      }}
    >
      현재 지도 기준으로 <br />
      리스트 프로토 페이지 이동
    </button>
  );
};

const CurrentMapStatus = () => {
  const { map, camera } = useMapStore();
  const { getFixedZoomLevel, getMinMaxBounds } = useMap();

  const [pos, setPos] = useState<any>(null);

  const getQuery = useCallback(() => {
    const bounds = getMinMaxBounds();

    if (!bounds) {
      return;
    }

    return {
      ...bounds,
      level: getFixedZoomLevel(),
    };
  }, [getMinMaxBounds, getFixedZoomLevel]);

  useEffect(() => {
    const moveEnd = () => setPos(getQuery());

    map?.on(Vsm.Map.EventNames.MoveEnd, moveEnd);

    moveEnd();

    return () => {
      map?.off(Vsm.Map.EventNames.MoveEnd, moveEnd);
    };
  }, [map, camera]);

  return pos ? (
    <ProtoQueryViewer
      style={{
        position: 'fixed',
        zIndex: 10,
        top: 50,
        left: 0,
      }}
      item={pos}
    />
  ) : null;
};

const ProtoActionItem = ({ focus, onClickItem }) => {
  const { camera } = useMapStore();

  return (
    <ul
      style={{
        position: 'fixed',
        zIndex: 10,
        bottom: 10,
        left: 0,
        right: 0,
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
      }}
    >
      {MOCK_LOCATION.map((item, idx) => (
        <li
          style={{
            margin: 10,
            display: 'inline-block',
            background: focus === idx ? 'yellow' : '#fff',
          }}
          key={item[0]}
        >
          <button
            onClick={() => {
              const [lat, lng] = item[1];

              camera?.setCenter({ lat, lng });
              camera?.setZoom(item[0]);

              onClickItem(idx);
            }}
          >
            zoom: {item[0]}
            <br />
            lon: {item[1][0]}
            <br />
            lat: {item[1][1]}
          </button>
        </li>
      ))}
    </ul>
  );
};

const ProtoPolygon = () => {
  const { isRenderCompleted, addPoint, addLineString, addPolygon } = useMapPolygon();
  const { renderDebugMarker } = useMap();

  useEffect(() => {
    if (isRenderCompleted) {
      addPoint(pointCoord);

      addLineString(polyLineCoord, lineStyle);

      addPolygon(polygonCoord, {
        fill: '#2780e3',
        'fill-opacity': 0.3,
      });
      addLineString([...polygonCoord[0]], lineStyle);

      addPolygon(holesInPolygonCoord, {
        fill: '#111111',
        'fill-opacity': 0.3,
      });
      addLineString([...holesInPolygonCoord[0]], lineStyle);
    }
  }, [isRenderCompleted]);

  return (
    <>
      {renderDebugMarker([pointCoord])}
      {renderDebugMarker(polyLineCoord)}
      {renderDebugMarker(polygonCoord.flat())}
      {renderDebugMarker(holesInPolygonCoord.flat())}
    </>
  );
};

export const ProtoVSMMapPage = () => {
  const [idx, setIdx] = useState(0);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <CurrentMapStatus />
      <VSMMap />
      <ProtoPolygon />
      <ProtoActionItem
        focus={idx}
        onClickItem={(itemIndex) => setIdx(itemIndex)}
      />
      <ProtoMove />
    </div>
  );
};

export default ProtoVSMMapPage;
