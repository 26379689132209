import { VEHICLE_TYPE_MAP } from 'constant/Vehicle';
import { useCallback, useMemo } from 'react';
import { ECompanyType, EServiceAccessStatus, EVehicleType } from 'types/App';
import { EUserStep } from 'types/api/User';

import usePmStore from './usePmStore';
import usePmVehicle from './usePmVehicle';
import useUserInfo from './useUserInfo';

export const usePmUsingStatus = () => {
  const { pmStatus, pmUserStatus, pmUserKey } = useUserInfo();

  const { isTryTrip, isRiding, isUnpaid, isWaitReturn, isLock, hasInitStatus, accessCode } =
    useMemo(() => {
      const userAccessCode = pmUserStatus?.code;
      const step = pmStatus.step;

      return {
        isLock: userAccessCode === EServiceAccessStatus.LOCK,
        isTryTrip: step === EUserStep.WAIT_RIDING,
        isRiding: step === EUserStep.RIDING,
        isUnpaid: step === EUserStep.UNPAID,
        isWaitReturn: step === EUserStep.WAIT_RETURN,
        hasInitStatus: !!pmUserKey,
        accessCode: userAccessCode,
      };
    }, [pmStatus.step, pmUserKey, pmUserStatus?.code]);
  const { activeCompany, activeItem, activeType } = usePmStore();

  const isRequireLicense = useMemo(() => {
    const userAccessCode = pmUserStatus?.code;
    const nowActiveType = activeItem?.vehicleType || activeType;
    const scooterCompanyList = VEHICLE_TYPE_MAP[EVehicleType.SCOOTER].list;
    const isRequireLicenseCompany =
      activeCompany === undefined ||
      scooterCompanyList.find((company) => company === activeCompany) !== undefined;

    // app-scheme으로 type을 지정하고 들어왔을 때, type에 따라서도 적용 할 수 있도록 함
    // 자전거 아이콘으로 대여하기 버튼이 활성화 되어있는 경우를 찾아서 적용
    const isBike = nowActiveType === EVehicleType.BIKE || !isRequireLicenseCompany;

    return !isBike && userAccessCode !== EServiceAccessStatus.READY;
  }, [activeCompany, activeItem?.vehicleType, activeType, pmUserStatus?.code]);

  const { getVehicleInfo } = usePmVehicle();

  const checkRequireLicense = useCallback(
    async (company: ECompanyType, vehicleId: string) => {
      const vehicleInfo = await getVehicleInfo(company, vehicleId);

      return (
        accessCode !== EServiceAccessStatus.READY &&
        vehicleInfo.vehicleType === EVehicleType.SCOOTER
      );
    },
    [accessCode, getVehicleInfo]
  );

  return {
    hasInitStatus,
    isRequireLicense,
    isTryTrip,
    isRiding,
    isUnpaid,
    isWaitReturn,
    isLock,
    hasLicense: !isRequireLicense,
    checkRequireLicense,
  };
};
