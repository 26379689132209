import useOnce from 'hooks/useOnce';
import { useEffect, useState } from 'react';
import ua from 'utils/uaParser';

import { useAccessKey } from './useAccessKey';
import useAppEventStore from './useAppEventStore';
import useLocalMount from './useLocalMount';
import useUserInfo from './useUserInfo';

export const usePmPageLaunch = (executeEffect: boolean = false) => {
  const accessKey = useAccessKey();

  const {
    fetchAppLaunchStatus,
    pmUserKey: userKey,
    pmLaunchStatus,
    fetchPaymentCard,
  } = useUserInfo();
  const initialized = !!userKey;
  const loadedLaunchStatus = pmLaunchStatus?.loaded;

  const { resumeKey } = useAppEventStore();

  const [startInitAction, setStartInitAction] = useState(false);
  const isMounted = useLocalMount();

  useOnce(accessKey && !initialized && executeEffect, () => {
    if (accessKey) {
      fetchAppLaunchStatus(accessKey, ua.userOs);
    }
  });

  useOnce(startInitAction && executeEffect, () => {
    fetchPaymentCard();
  });

  useEffect(() => {
    if (resumeKey && executeEffect) {
      fetchPaymentCard();
    }
  }, [resumeKey, executeEffect]);

  useEffect(() => {
    isMounted && initialized && setStartInitAction(true);
  }, [initialized, isMounted, executeEffect]);

  return {
    initialized,
    startInitAction,
    accessKey,
    userKey,
    loadedLaunchStatus,
  };
};

export default usePmPageLaunch;
