import { useEffect } from 'react';
import backKeyHandlerStore from 'store/hwBackKeyHandlerStore';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const useHandleBackKey = (enableHandlingBackKey: boolean, callback?: VoidFunction) => {
  useEffect(() => {
    if (enableHandlingBackKey) {
      backKeyHandlerStore.addFunction(callback);
    }
    const functions = backKeyHandlerStore.getState();

    tmapWrapper.handleBackKeyEventFromWeb(functions.length > 0, () => {
      functions.map((it) => it());
    });
    return () => {
      backKeyHandlerStore.removeFunction(callback);
    };
  }, [enableHandlingBackKey, callback]);
};

export default useHandleBackKey;
