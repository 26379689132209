import { API_HOST } from 'constant/Path';
import { useEffect } from 'react';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import { usePmUsingStatus } from './usePmUsingStatus';

const useSendLocation = () => {
  const { isRiding } = usePmUsingStatus();

  useEffect(() => {
    if (isRiding) {
      tmapWrapper.startReportLocation(`${API_HOST}/api/user/updateLocation`);
    } else {
      tmapWrapper.stopReportLocation();
    }
  }, [isRiding]);
};

export default useSendLocation;
