import classNames, { Argument as ClassNameType } from 'classnames';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import s from 'styles/components/ImageLoader.module.scss';
import { DEFAULT_LOZAD_CLASSNAME, observeImage } from 'utils/imageObserver';

type TProps = {
  forwardRef?: ForwardedRef<HTMLImageElement>;
  className?: ClassNameType;
  toggleClass?: ClassNameType;
  src: string;
  alt: string;
  onError?: VoidFunction;
  onLoad?: VoidFunction;
  onClick?: VoidFunction;
};

const ImageLoader = ({
  className,
  toggleClass,
  src,
  alt,
  onError,
  onLoad,
  onClick,
  ...props
}: TProps) => {
  const [hasError, setHasError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    observeImage(DEFAULT_LOZAD_CLASSNAME);
  }, []);

  if (hasError) {
    return null;
  }

  return (
    <img
      ref={props.forwardRef}
      className={classNames(DEFAULT_LOZAD_CLASSNAME, className, {
        [s.hide]: !isVisible,
      })}
      data-src={src}
      data-toggle-class={toggleClass}
      alt={alt}
      onLoad={() => {
        setIsVisible(true);
        onLoad?.();
      }}
      onError={() => {
        onError?.();
        setHasError(true);
      }}
      onClick={onClick}
    />
  );
};

export default forwardRef<HTMLImageElement, TProps>((props, ref) => (
  <ImageLoader
    {...props}
    forwardRef={ref}
  />
));
