export const PageId = {
  Main: '/main',
  MainPoiPop: '/main/poi_pop',
  MainFilter: '/main/filter',
  MainPoint: '/main_point',
  PmCard: '/poi_device/',
  License: '/start/registration',
  LicensePopup: '/start/registration_popup',
  LicenseFailPopup: '/start/registration_popup_fail',
  EventPopup: '/popup',
  RidingGuide: '/ridingguide',
  QRScan: '/scan',
  Code: '/code',
  CodePop: '/code_pop',
  Terms: '/start/terms',
  Riding: '/main/riding',
  RidingPopup: '/main/riding_popup',
  CameraShot: '/camerashot',
  PaymentSuccess: '/endride',
  PaymentFail: '/requestpayment',
  Menu: '/main/menu',
  UserGuide: '/menu/userguide',
  PaymentGuide: '/paymentguide',
  PreAuth: '/preauth/popup',
};

export type TMainActionId =
  | 'tap.map'
  | 'tap.map_device'
  | 'tap.map_restricedarea'
  | 'panning.map'
  | 'pinchin.map'
  | 'pinchout.map'
  | 'tap.location'
  | 'tap.guide'
  | 'tap.startride'
  | 'tap.menu'
  | 'tap.back'
  | 'tap.tab_all'
  | 'tap.textbanner'
  | 'tap.card_change'
  | 'tap.clustering'
  | 'tap.point'
  | 'tap.point_infobox';

export type TMainPointId = 'tap.point_infobox_mypoint';

export type TMainPoiPopActionId = 'tap.backscreen' | 'tap.sound';

export type TMainFilterActionId = 'tap.device' | 'tap.menu_brand' | 'tap.backscreen';

export type TPmCardActionId = 'tap.startride' | 'tap.sound';

type TUserGuideActionId =
  | 'tap.back'
  | 'tap.tab_beforeride'
  | 'tap.tab_riding'
  | 'tap.tab_endride'
  | 'tap.tab_ask'
  | 'tap_tap.widget'
  | 'tap.gcooter_icon'
  | 'tap.xing_icon'
  | 'tap.tmap_icon'
  | 'tap.tmapinfo_icon';

export type TLicenseActionId =
  | 'tap.registration'
  | 'tap.registration_number'
  | 'tap.registration_choose'
  | 'tap.close'
  | 'tap.name'
  | 'popup_tap.authfail_confirm'
  | 'popup_tap.authsucess_confirm'
  | 'tap.backscreen';

export type TLicensePopupActionId = 'popup_tap.authsucess_confirm';

export type TLicenseFailPopupActionId = 'popup_tap.authfail_confirm';

export type TEventPopupActionId = 'impression' | 'tap.close' | 'tap.donotshowagain' | 'tap.detail';

export type TRidingGuideActionId = 'tap.check' | 'tap.ok' | 'tap.backscreen';

export type TCodeActionId = 'tap.scan' | 'tap.tab_cp' | 'tap.back' | 'tap.xbutton' | 'tap.okbutton';

export type TCodePopActionId = 'tap.cancel' | 'tap.next' | 'tap.backscreen';

export type TTermsActionId =
  | 'tap.xbutton'
  | 'tap.checkall'
  | 'tap.check'
  | 'tap.next'
  | 'impression.terms';

export type TRidingActionId =
  | 'tap.back'
  | 'tap.menu'
  | 'tap.location'
  | 'tap.guide'
  | 'tap.endride'
  | 'tap.backscreen'
  | 'tap.map_restricedarea'
  | 'panning.map'
  | 'pinchin.map'
  | 'pinchout.map'
  | 'tap.pause';

export type TRidingPopupActionId = 'popup_tap.endservice_confirm' | 'popup_tap.endservice_cancel';

type TPaymentSuccessFailActionId =
  | 'tap.confirm'
  | 'tap.changepayment'
  | 'tap.close'
  | 'tap.request'
  | 'end.riding';

type TMenuActionId =
  | 'tap.menu_history'
  | 'tap.menu_guide'
  | 'tap.menu_payment'
  | 'tap.menu_report'
  | 'tap.menu_event'
  | 'tap.menu_notice'
  | 'tap.menu_customerservice'
  | 'tap.backscreen';

export type TDurationActionId =
  | 'duration.load_map'
  | 'duration.init_position'
  | 'duration.render_list'
  | 'duration.load_map_background';

export type TCardGuideActionId = 'tap.outside' | 'tap.confirm';

export type TPreAuthActionId =
  | 'tap.close_button'
  | 'tap.cardchange_button'
  | 'tap.retry_button'
  | 'tap.backscreen';

export type TActionId =
  | TMainActionId
  | TMainPointId
  | TPmCardActionId
  | TUserGuideActionId
  | TLicenseActionId
  | TEventPopupActionId
  | TRidingGuideActionId
  | TCodeActionId
  | TCodePopActionId
  | TTermsActionId
  | TRidingActionId
  | TRidingPopupActionId
  | TPaymentSuccessFailActionId
  | TMenuActionId
  | TDurationActionId
  | TCardGuideActionId
  | TMainPoiPopActionId
  | TMainFilterActionId
  | TLicensePopupActionId
  | TLicenseFailPopupActionId
  | TPreAuthActionId;
