import { useEffect, useState } from 'react';
import { devLog } from 'utils/dev';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

type WindowSizeResult = {
  width: number;
  height: number;
  isLandscape: Nullable<boolean>;
};

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSizeResult>({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    isLandscape: ua.isInApp
      ? null
      : document.documentElement.clientWidth > document.documentElement.clientHeight,
  });

  const handleResize = () => {
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;
    let isLandscape: Nullable<boolean> = null;

    devLog('[resize]', width, height);

    if (ua.isInApp) {
      tmapWrapper.getDisplayInfo();
    } else {
      isLandscape = false;

      if (width > height) {
        isLandscape = true;
      }
    }

    setWindowSize({
      width,
      height,
      isLandscape,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
