import { ReactNode } from 'react';
import { ReactComponent as IconNegative } from 'resource/images/icon-negative.svg';
import { ReactComponent as IconPositive } from 'resource/images/icon-positive.svg';
import s from 'styles/components/pmReturn/PmReturnTitle.module.scss';
import { EReturnPaymentStatus } from 'types/App';

const ICON_MAP: Record<EReturnPaymentStatus, ReactNode> = {
  [EReturnPaymentStatus.PURCHASE_SUCCESS]: <IconPositive />,
  [EReturnPaymentStatus.PURCHASE_FAIL]: <IconNegative />,
  [EReturnPaymentStatus.PENDING]: <IconNegative />,
};

const TITLE_MAP: Record<EReturnPaymentStatus, ReactNode> = {
  [EReturnPaymentStatus.PURCHASE_SUCCESS]: (
    <p>
      <b>반납 완료되었습니다.</b>
    </p>
  ),
  [EReturnPaymentStatus.PURCHASE_FAIL]: (
    <p>
      <b>결제가 실패하였습니다.</b>
    </p>
  ),
  [EReturnPaymentStatus.PENDING]: (
    <p>
      <b>결제비밀번호</b>를 입력해 주세요.
    </p>
  ),
};

type TProps = {
  type: EReturnPaymentStatus;
};

export const PmReturnTitle = ({ type }: TProps) => {
  return (
    <div className={s.wrap}>
      <i>{ICON_MAP[type]}</i>
      {TITLE_MAP[type]}
    </div>
  );
};

export default PmReturnTitle;
