import { useMemo } from 'react';

type TProps = {
  src: string;
  alt?: string;
  srcSetList?: string[];
  className?: string;
  width?: number | string;
  height?: number | string;
};

const Image = ({ src, srcSetList, alt, className, width, height }: TProps) => {
  const srcSet = useMemo(
    () => (srcSetList || []).map((image, i) => `${image} ${i + 2}x`).join(', '),
    [srcSetList]
  );

  return (
    <img
      src={src}
      srcSet={srcSet}
      alt={alt || ''}
      className={className}
      width={width}
      height={height}
    />
  );
};

export default Image;
