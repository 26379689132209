import classNames from 'classnames';
import BackButton from 'component/BackButton';
import BottomButton from 'component/BottomButton';
import CloseButton from 'component/CloseButton';
import Header, { HEADER_HEIGHT } from 'component/Header';
import PmRejectRentPopup from 'component/PmRejectRentPopup';
import PmRentStatusLayer from 'component/PmRentStatusLayer';
import PmCompanyButton from 'component/pmCode/PmCompanyButton';
import TmdsTextField from 'component/tmds/TmdsSingleTextField';
import BottomSheet from 'component/tmds/bottomSheet/BottomSheet';
import { PopupLayerId } from 'constant/App';
import { COMPANY_LIST } from 'constant/Scooter';
import { useLayerContextConsumer } from 'context/LayerContext';
import useMovePage from 'hooks/useMovePage';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmStore from 'hooks/usePmStore';
import usePmTripStart from 'hooks/usePmTripStart';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import usePopupLayer from 'hooks/usePopupLayer';
import useUserInfo from 'hooks/useUserInfo';
import useWindowSize from 'hooks/useWindowSize';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CheckIconMedium } from 'resource/images/Check_Icon_Medium.svg';
import { ReactComponent as IconArrowDown } from 'resource/images/icon-arrow-down.svg';
import s from 'styles/pages/PmCodePage.module.scss';
import { ECompanyType, ELottieType } from 'types/App';
import { EKeyCode } from 'types/Device';
import ua from 'utils/uaParser';

export const PmCodePage = () => {
  const { pmLaunchStatus } = useUserInfo();

  const companies = useMemo(
    () => pmLaunchStatus?.data?.companies || [],
    [pmLaunchStatus?.data?.companies]
  );

  const { activeCompany } = usePmStore();
  const { getOverlayElement } = useLayerContextConsumer();
  const { enterCode, scanVehicle } = usePmTripStart();
  const { moveMainPageWithUA } = useMovePage();
  const { isTryTrip } = usePmUsingStatus();
  const nowWindowSize = useWindowSize();
  const codeLogger = usePmLogger(LogPageId.Code, true);
  const layer = usePopupLayer(PopupLayerId.COMPANY_SELECT_LIST);

  const [company, setCompany] = useState<ECompanyType>(COMPANY_LIST[0].code);
  const [companyList, setCompanyList] = useState(COMPANY_LIST);
  const [vehicleId, setVehicleId] = useState<string>('');
  const isEmpty = useMemo(() => !vehicleId, [vehicleId]);

  const refInputInstance = useRef<Nullable<HTMLInputElement>>(null);
  const refDisableScanPage = useRef(false);
  const height = useMemo(() => nowWindowSize.height, []);
  const [viewportHeight, setViewportHeight] = useState(height);
  const wrapMarginTop = useMemo(
    () => (ua.isAndroid && viewportHeight < height ? -HEADER_HEIGHT : 0),
    [height, viewportHeight]
  );
  const buttonBottom = useMemo(() => {
    /**
     * iOS 기준으로 코드번호 입력창을 입력할 경우 입력완료 버튼이 가려지는 부분 대응
     * window client viewport height - visualViewPortHeight = 키보드영역값 + 노치 바텀 사이즈
     * 키보드영역값 - iOS 노치 바텀 사이즈 = 키보드 영역값
     */
    const sab = getComputedStyle(document.documentElement).getPropertyValue('--sab');
    if (!ua.isIos) {
      return 0;
    }
    if (viewportHeight < height) {
      return `calc(${window.innerHeight - viewportHeight}px - ${sab})`;
    } else {
      return 0;
    }
  }, [viewportHeight, height]);

  const handleClickClose = useCallback(() => {
    refDisableScanPage.current = true;
    codeLogger.sendClickLog('tap.xbutton');
    moveMainPageWithUA();
  }, [codeLogger, moveMainPageWithUA]);

  const handleClickBackButton = useCallback(async () => {
    await scanVehicle();
    refDisableScanPage.current = true;
    moveMainPageWithUA();
  }, [scanVehicle, moveMainPageWithUA]);

  const handleSubmit = useCallback(async () => {
    codeLogger.sendClickLog('tap.okbutton');

    if (!vehicleId) {
      return;
    }
    refDisableScanPage.current = true;
    const result = await enterCode({ vehicleId, company });
    if (result) {
      moveMainPageWithUA();
    }
  }, [codeLogger, company, enterCode, moveMainPageWithUA, vehicleId]);

  const handleInputKeyup = useCallback(
    (e) => {
      if (e.keyCode === EKeyCode.ENTER) {
        e.target.blur();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const handleInputBlur = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickRejectConfirm = useCallback(() => {
    refInputInstance.current?.focus();
    setVehicleId('');
  }, []);

  useEffect(() => {
    return () => {
      if (!refDisableScanPage.current) {
        scanVehicle();
      }
    };
  }, [scanVehicle]);

  useEffect(() => {
    return () => {
      codeLogger.sendClickLog('tap.back');
    };
  }, []);

  useEffect(() => {
    if (activeCompany) {
      setCompany(activeCompany);
    }
  }, [activeCompany]);

  useEffect(() => {
    if (companies.length > 0) {
      // eslint-disable-next-line
      setCompanyList((prev) => prev.filter((g) => companies.some((c) => c.code === g.code)));
    }
  }, [companies]);

  useEffect(() => {
    const handleVisualViewportResize = () => {
      setTimeout(() => {
        /**
         * 키보드가 올라오기를 기다리고 visualViewport의 높이를 가져와서 세팅할 수 있도록 함.
         */
        const visualViewPortHeight = window.visualViewport?.height || 0;
        setViewportHeight(visualViewPortHeight);
      }, 150);
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleVisualViewportResize);
    }

    return () => {
      window.visualViewport?.removeEventListener('resize', handleVisualViewportResize);
    };
  }, []);

  return (
    <>
      <div
        className={s.wrap}
        style={{ marginTop: wrapMarginTop }}
      >
        <div
          className={s.holder}
          style={{ height: height - HEADER_HEIGHT }}
        >
          <PmRentStatusLayer
            loading={isTryTrip}
            type={ELottieType.UNLOCK}
          />
          <PmRejectRentPopup onInvalidCodeConfirm={handleClickRejectConfirm} />
          <Header
            title="코드번호 직접입력"
            leftButton={<BackButton onClick={handleClickBackButton} />}
            rightButton={<CloseButton onClick={handleClickClose} />}
          />
          <p className={s.info_text}>
            대여할 기기를 선택 후<br />
            코드 번호를 직접 입력 해주세요.
          </p>
          <div className={s.input_wrap}>
            <div className={s.button_wrap}>
              <PmCompanyButton
                company={company}
                onClick={() => layer.show()}
              />
              <i
                className={classNames(s.arrow_icon, {
                  [s.is_selected]: layer.visible,
                })}
              >
                <IconArrowDown />
              </i>
            </div>
            <TmdsTextField
              autoFocus
              value={vehicleId}
              type="text"
              placeholder="코드 번호"
              onKeyUp={handleInputKeyup}
              onBlur={handleInputBlur}
              onChange={(e) => {
                setVehicleId(e.target.value.toUpperCase());
              }}
              onClickClear={() => {
                setVehicleId('');
              }}
              onFocus={() => {
                codeLogger.sendClickLog('tap.scan');
              }}
              ref={refInputInstance}
              data-cy="대문자입력기"
            />
          </div>

          <div
            className={s.bottom_button_wrap}
            style={{ bottom: buttonBottom }}
          >
            <BottomButton
              className={classNames({
                [s.is_disabled_button]: isEmpty,
              })}
              gradient={false}
              onClick={handleSubmit}
              data-cy="입력완료"
            >
              입력완료
            </BottomButton>
          </div>
        </div>
      </div>

      {layer.visible &&
        createPortal(
          <div
            className={s.select_layer_wrap}
            onClick={() => layer.hide()}
          >
            <BottomSheet>
              <div className={s.container}>
                <ul>
                  {companyList.map((c) => {
                    const code = c.code;
                    const isSelected = code === company;

                    return (
                      <li
                        key={code}
                        className={classNames(s.item, {
                          [s.is_selected]: isSelected,
                        })}
                        onClick={() => {
                          codeLogger.sendClickLog('tap.tab_cp', {
                            index: code,
                          });
                          setCompany(code);
                        }}
                        data-cy={code}
                      >
                        <PmCompanyButton
                          company={code}
                          selected={isSelected}
                        />
                        {isSelected && <CheckIconMedium />}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </BottomSheet>
          </div>,
          getOverlayElement()
        )}
    </>
  );
};

export default PmCodePage;
