import { EPosition, useLayerContextConsumer } from 'context/LayerContext';
import { useCallback, useEffect } from 'react';

type TOptions = {
  level?: number;
  position?: EPosition;
  useBackgroundClose?: boolean;
  logId?: string;
};

const usePopupLayer = (id: string, options: TOptions = {}) => {
  const { pushPopup, removePopup, getOverlayElement, currentKey } = useLayerContextConsumer();

  const showPopup = useCallback(() => {
    const level = options?.level || 1;

    pushPopup({ ...options, key: id, level });
  }, [id, pushPopup, options]);

  const hidePopup = useCallback(() => {
    removePopup(id);
  }, [id, removePopup]);

  useEffect(() => {
    return () => {
      removePopup(id);
    };
  }, [id]);

  return {
    root: getOverlayElement(),
    visible: currentKey === id,
    show: showPopup,
    hide: hidePopup,
  };
};

export default usePopupLayer;
