import classNames from 'classnames';
import {
  BASE_COMPANY_LIST,
  COMPANY_ICON_MAP,
  COMPANY_NAME_MAP,
  VEHICLE_ICON,
} from 'constant/Scooter';
import useActiveCompanyList from 'hooks/useActiveCompanyList';
import usePmStore from 'hooks/usePmStore';
import { CSSProperties, useMemo } from 'react';
import { ReactComponent as IcoArrowDownSolid } from 'resource/images/ico_arrow_down_solid.svg';
import { ReactComponent as IconBetweenLine } from 'resource/images/ico_between_line.svg';
import exportedStyle from 'styles/ExportedValue.module.scss';
import s from 'styles/components/pmMain/PmHeaderDropdown.module.scss';
import { ECompanyType, EVehicleType } from 'types/App';

const COMPANY_BORDER_ICON_MAP = BASE_COMPANY_LIST.reduce(
  (obj, v) => ({ ...obj, [v.code]: v.imgIcon }),
  {}
);

const VEHICLE_COLOR_MAP: Record<EVehicleType, { all?: string; single?: string }> = {
  [EVehicleType.ALL]: {},
  [EVehicleType.BIKE]: {
    all: exportedStyle.iconAccent,
    single: exportedStyle.iconSecondary,
  },
  [EVehicleType.SCOOTER]: {
    all: exportedStyle.iconAccent,
    single: exportedStyle.evBlue500,
  },
};

type TProps = {
  onClickCompany?: (type: EVehicleType, company?: ECompanyType) => void;
};

const PmHeaderDropDown = ({ onClickCompany }: TProps) => {
  const { activeCompany, activeType } = usePmStore();

  const companyList = useActiveCompanyList();
  const vehicleIcon = useMemo(() => VEHICLE_ICON[activeType], [activeType]);

  if (activeCompany) {
    return (
      <div className={classNames(s.wrap, s.is_active)}>
        <ul className={s.list}>
          <div className={classNames(s.item, s.active_item)}>
            {vehicleIcon && (
              <div className={s.icon_vehicle}>
                <i
                  style={
                    {
                      '--color': VEHICLE_COLOR_MAP[activeType].single,
                    } as CSSProperties
                  }
                >
                  {vehicleIcon}
                </i>
                <IconBetweenLine />
              </div>
            )}
            <i className={s.icon_company}>{COMPANY_ICON_MAP[activeCompany]}</i>{' '}
            {COMPANY_NAME_MAP[activeCompany]}
          </div>
        </ul>
        <i className={s.icon_down}>
          <IcoArrowDownSolid />
        </i>
      </div>
    );
  }

  return (
    <>
      <div className={s.wrap}>
        {vehicleIcon && (
          <div className={s.icon_vehicle}>
            <i
              style={
                {
                  '--color': VEHICLE_COLOR_MAP[activeType].all,
                } as CSSProperties
              }
            >
              {vehicleIcon}
            </i>
            <IconBetweenLine />
          </div>
        )}
        <ul className={s.list}>
          {companyList.map((c, i) => (
            <li
              key={c.code}
              className={classNames(s.item, s.border)}
              style={{ zIndex: companyList.length + i }}
              onClick={() => onClickCompany?.(activeType, c.code)}
            >
              {COMPANY_BORDER_ICON_MAP[c.code]}
            </li>
          ))}
        </ul>
        <i className={s.icon_down}>
          <IcoArrowDownSolid />
        </i>
      </div>
    </>
  );
};

export default PmHeaderDropDown;
