import Header from 'component/Header';
import PmLocationInfo from 'component/terms/PmLocationInfo';
import PmTermsGcooter from 'component/terms/PmTermsGcooter';
import PmTermsUserInfo from 'component/terms/PmTermsUserInfo';
import PmTermsXing from 'component/terms/PmTermsXing';
import useAdditionalLocationSearch from 'hooks/useAdditionalLocationSearch';
import { useMemo } from 'react';
import s from 'styles/pages/PmTermsPage.module.scss';

const TERMS_MAP = {
  'K1TERMS-B2C-1': <PmTermsGcooter />,
  'K1TERMS-B2C-2': <PmTermsUserInfo />,
  'K2TERMS-B2C-1': <PmTermsXing />,
  'K2TERMS-B2C-2': <PmTermsUserInfo />,
  'K3TERMS-B2C-2': <PmTermsUserInfo />,
  'K4TERMS-B2C-2': <PmTermsUserInfo />,
  'K5TERMS-B2C-1': <PmLocationInfo />,
};

export const PmTermsPage = () => {
  const { currentQuery } = useAdditionalLocationSearch();
  const code = useMemo(() => currentQuery?.code, [currentQuery]);

  return (
    <div className={s.wrap}>
      <Header title="서비스 이용약관 동의" />
      {code && TERMS_MAP[code as string]}
    </div>
  );
};

export default PmTermsPage;
