import { AutoPlay } from '@egjs/flicking-plugins';
import Flicking, { ChangedEvent } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { StorageKey } from 'constant/Storage';
import { EPosition } from 'context/LayerContext';
import { differenceInDays } from 'date-fns';
import useHandleBackKey from 'hooks/useHandleBackKey';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePopupLayer from 'hooks/usePopupLayer';
import useStorageStore from 'hooks/useStorageStore';
import { Children, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import s from 'styles/components/tmds/TmdsEventPopup.module.scss';

type TProps = {
  defaultIndex?: number;
  onChange?: (index: number) => void;
  onVisible?: (isVisible: boolean) => void;
} & PropsWithChildren;

const TmdsEventPopup = ({ children, defaultIndex, onChange, onVisible }: TProps) => {
  const refIsFlicking = useRef(false);
  const { sendClickLog } = usePmLogger(LogPageId.EventPopup);
  const [currentIndex, setCurrentIndex] = useState(defaultIndex || 0);
  const totalLength = Children.count(children);
  const { visible, root, hide, show } = usePopupLayer(StorageKey.PM_EVENT_POPUP_MAP, {
    position: EPosition.BOTTOM,
    useBackgroundClose: true,
    logId: LogPageId.EventPopup,
  });

  const [timeStamp, setTimeStamp] = useStorageStore<number>(StorageKey.MAIN_EVENT_POPUP);

  const handleChange = useCallback(
    (e: ChangedEvent<Flicking>) => {
      onChange?.(e.index);
      setCurrentIndex(e.index);
    },
    [onChange]
  );

  useEffect(() => {
    const diffDays = differenceInDays(Date.now(), timeStamp);

    if (totalLength > 0 && (Number.isNaN(diffDays) || diffDays >= 1)) {
      show();
      onVisible?.(true);
    }
  }, [totalLength]);

  useHandleBackKey(visible, hide);

  if (!visible) {
    return null;
  }

  return createPortal(
    <div
      className={s.wrapper}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {totalLength > 1 && (
        <div className={s.count}>
          {currentIndex + 1} / {totalLength}
        </div>
      )}

      <div className={s.flicking_wrapper}>
        <Flicking
          plugins={[new AutoPlay({ duration: 3000 })]}
          circular
          onChanged={handleChange}
          onHoldStart={() => (refIsFlicking.current = true)}
          moveType="strict"
          duration={500}
        >
          {Children.map(children, (child, index) => (
            <div
              className={s.panel}
              key={index}
            >
              <div className={s.item}>{child}</div>
            </div>
          ))}
        </Flicking>
      </div>

      <div className={s.bottom_nav}>
        <button
          className={s.today_hide}
          onClick={() => {
            sendClickLog('tap.donotshowagain');
            setTimeStamp(Date.now());
            hide();
          }}
          data-cy="오늘은 그만보기"
        >
          오늘은 그만보기
        </button>
        <button
          className={s.hide}
          onClick={() => {
            sendClickLog('tap.close');
            hide();
          }}
          data-cy="닫기"
        >
          닫기
        </button>
      </div>
    </div>,
    root
  );
};

export default TmdsEventPopup;
