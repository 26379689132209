import classNames from 'classnames';
import { useCallback } from 'react';
import { ReactComponent as IconCopy } from 'resource/images/ico_duplicate.svg';
import s from 'styles/components/dev/ObjectViewer.module.scss';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

type TProps = {
  object: Object;
  className?: string;
  style?: React.CSSProperties;
};

const ObjectViewer = ({ object, className, style }: TProps) => {
  const keys = Object.keys(object);

  const handleCopyClick = useCallback(() => {
    const value = JSON.stringify(object, undefined, 2);
    if (ua.isInApp) {
      tmapWrapper.copyClipboard('', value);
      tmapWrapper.makeToast(`클립보드에 복사되었습니다`);
    } else {
      navigator.clipboard.writeText(value);
    }
  }, [object]);

  return (
    <ul
      className={classNames(s.wrapper, className)}
      style={style}
    >
      {keys.map((key) => {
        if (object[key] === null) {
          return (
            <li>
              <span className={s.key_text}>{key}</span>: null
            </li>
          );
        }

        if (typeof object[key] === 'object') {
          return (
            <li>
              <div className={s.title}>
                <span className={s.key_text}>{key}</span>
                : <IconCopy onClick={handleCopyClick} />
              </div>
              <ObjectViewer object={object[key]} />
            </li>
          );
        } else {
          return (
            <li>
              <span className={s.key_text}>{key}</span>: {object[key]?.toString() || 'undefined'}
            </li>
          );
        }
      })}
    </ul>
  );
};

export default ObjectViewer;
