import CommonLayout from 'component/CommonLayout';
import { isProdEnv } from 'constant/Env';
import { Paths } from 'constant/RoutePath';
import PmCodePage from 'pages/PmCodePage';
import PmEventPage from 'pages/PmEventPage';
import PmGuidePage from 'pages/PmGuidePage';
import PmMainPage from 'pages/PmMainPage';
import PmReturnPage from 'pages/PmReturnPage';
import PmTermsAgreePage from 'pages/PmTermsAgreePage';
import PmTermsPage from 'pages/PmTermsPage';
import PmTmapInsurancePage from 'pages/PmTmapInsurancePage';
import ProtoAppSchemePage from 'pages/ProtoAppSchemePage';
import ProtoLottiePage from 'pages/ProtoLottiePage';
import ProtoPage from 'pages/ProtoPage';
import ProtoPaymentPage from 'pages/ProtoPaymentPage';
import ProtoPmRentPage from 'pages/ProtoPmRentPage';
import ProtoPopupDesignPage from 'pages/ProtoPopupDesignPage';
import ProtoPopupPage from 'pages/ProtoPopupPage';
import ProtoUserInfoPage from 'pages/ProtoUserInfoPage';
import ProtoVSMMapPage from 'pages/ProtoVSMMapPage';
import ScooterLicensePage from 'pages/ScooterLicensePage';
import styles from 'styles/pages/Routes.module.scss';
import { TRouter } from 'types/Router';

const protoRoutes: TRouter = {
  title: 'Proto',
  path: [
    Paths.Proto,
    Paths.ProtoVSMMap,
    Paths.ProtoPopup,
    Paths.ProtoPopupDesign,
    Paths.ProtoPmRent,
    Paths.ProtoAppSchemeTest,
    Paths.ProtoPayment,
    Paths.ProtoUserInfo,
    Paths.ProtoLottie,
  ],
  component: (props) => <CommonLayout {...{ ...props, className: styles.prototype_wrap }} />,
  routes: [
    {
      path: Paths.Proto,
      component: ProtoPage,
    },
    {
      path: Paths.ProtoVSMMap,
      component: ProtoVSMMapPage,
    },
    {
      path: Paths.ProtoPopup,
      component: ProtoPopupPage,
    },
    {
      path: Paths.ProtoPopupDesign,
      component: ProtoPopupDesignPage,
    },
    {
      path: Paths.ProtoPmRent,
      component: ProtoPmRentPage,
    },
    {
      path: Paths.ProtoAppSchemeTest,
      component: ProtoAppSchemePage,
    },
    {
      path: Paths.ProtoPayment,
      component: ProtoPaymentPage,
    },
    {
      path: Paths.ProtoUserInfo,
      component: ProtoUserInfoPage,
    },
    {
      path: Paths.ProtoLottie,
      component: ProtoLottiePage,
    },
  ],
};

const routes: TRouter[] = [
  {
    path: [
      Paths.Root,
      Paths.Pm,
      Paths.PmMain,
      Paths.PmLicense,
      Paths.PmDrivingGuide,
      Paths.PmGuide,
      Paths.PmTermsAgree,
      Paths.PmCodePage,
      Paths.PmReturnPage,
      Paths.PmTerms,
      Paths.PmEvent,
      Paths.PmTmapInsurance,
    ],
    component: (props) => (
      <CommonLayout {...{ ...props, className: styles.personal_mobility_wrap }} />
    ),
    routes: [
      {
        path: Paths.PmMain,
        component: PmMainPage,
      },
      {
        path: Paths.PmLicense,
        component: ScooterLicensePage,
      },
      {
        path: Paths.PmTermsAgree,
        component: PmTermsAgreePage,
      },
      {
        path: Paths.PmGuide,
        component: PmGuidePage,
      },
      {
        path: Paths.PmCodePage,
        component: PmCodePage,
      },
      {
        path: Paths.PmReturnPage,
        component: PmReturnPage,
      },
      {
        path: Paths.PmTerms,
        component: PmTermsPage,
      },
      {
        path: Paths.PmTmapInsurance,
        component: PmTmapInsurancePage,
      },
    ],
  },
  {
    title: 'event',
    exact: false,
    path: [Paths.PmEvent],
    component: (props) => {
      return <CommonLayout {...{ ...props, className: styles.personal_mobility_wrap }} />;
    },
    routes: [
      {
        exact: false,
        path: Paths.PmEvent,
        component: PmEventPage,
      },
    ],
  },
  isProdEnv ? ({} as TRouter) : protoRoutes,
];

export default routes;
