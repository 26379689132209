import qs from 'query-string';
import { EAreas, ECompanyType } from 'types/App';
import { TQrScanResult } from 'types/Device';
import {
  TAreaInfoParam,
  TAreasResponse,
  TCompanyAreasParam,
  TPmApiParams,
  TPmApiResponse,
  TPointInfoResponse,
  TRingParam,
  TVehicles,
} from 'types/api/Vehicle';
import { getApi, postApi } from 'utils/fetcher';

class VehicleRepository {
  getVehicleList = (params: TPmApiParams) => {
    return getApi<TPmApiResponse>('/api/vehicles', { params });
  };

  getVehicleInfo = (company: ECompanyType, vehicleId: string, params?: Object) => {
    return getApi<TVehicles>(`/api/company/${company}/vehicle/${vehicleId}`, { params });
  };

  getCompanyAreaInfo = ({ company, lat, lon }: TAreaInfoParam) => {
    return getApi<TPointInfoResponse>(`/api/company/${company}/pointInfo`, {
      params: {
        lat,
        lng: lon,
      },
    });
  };

  getVehicleQr = (urlFromQR) => {
    return getApi<TQrScanResult>(
      qs.stringifyUrl({
        url: '/api/vehicle/qr',
        query: { urlFromQR },
      })
    );
  };

  getCompanyAreas = ({ company, ...params }: TCompanyAreasParam) => {
    return getApi<TAreasResponse>(`/api/company/${company}/areas`, {
      params,
    });
  };

  postRingVehicle = ({ company, vehicleId, lat, lon }: TRingParam) => {
    return postApi(`/api/company/${company}/vehicle/${vehicleId}/ring`, {
      lat,
      lng: lon,
    });
  };

  checkVehicleReject = async (company: ECompanyType, vehicleId: string, params = {}) => {
    const vehicleInfo = await getApi<TVehicles>(`/api/company/${company}/vehicle/${vehicleId}`, {
      params,
    });

    const pointInfo = vehicleInfo.area;

    const isRejectedArea = [
      EAreas.NO_PARKING,
      EAreas.NO_SERVICE,
      EAreas.CHARGE_ADDITIONAL,
    ].includes(pointInfo?.type as EAreas);

    return {
      ...vehicleInfo,
      rejectInfo: isRejectedArea
        ? {
            area: pointInfo?.type,
            fee: pointInfo?.additionalFee,
          }
        : null,
    };
  };
}

const vehicleRepository = new VehicleRepository();

export default vehicleRepository;
