import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import s from 'styles/components/Skeleton.module.scss';

export enum ESkeletonType {
  VEHICLE_CARD = 'VEHICLE_CARD',
}

const SKELETON_MAP: Record<ESkeletonType, ReactNode> = {
  [ESkeletonType.VEHICLE_CARD]: (
    <div className={s.skeleton__vehicle_card}>
      <span className={s.grid_first} />
      <span className={s.grid_second} />
      <span className={s.grid_third} />
      <div className={s.grid_img} />
    </div>
  ),
};

type TProps = PropsWithChildren<{
  type: ESkeletonType;
  loaded: boolean;
}>;

const Skeleton = ({ children, loaded, type }: TProps) => {
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    if (!loaded) {
      setShowSkeleton(true);
    }
  }, [loaded]);

  useEffect(() => {
    return () => {
      setShowSkeleton(true);
    };
  }, []);

  return (
    <div className={s.wrap}>
      {showSkeleton && (
        <div
          className={classNames(s.skeleton, {
            [s.is_hide]: loaded,
          })}
          onTransitionEnd={() => setShowSkeleton(false)}
        >
          {SKELETON_MAP[type]}
        </div>
      )}
      {children}
    </div>
  );
};

export default Skeleton;
