import classNames from 'classnames';
import { useLayerContextConsumer } from 'context/LayerContext';
import { useJSXMultiline } from 'hooks/useJSXMultiline';
import { HTMLAttributes, PropsWithChildren, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
import s from 'styles/components/ModalPopup.module.scss';

import TmdsButton from './tmds/TmdsButton';

export type TButtonProps = PropsWithChildren<HTMLAttributes<HTMLButtonElement>> & {
  type?: 'confirm' | 'cancel';
};

export type TProps = {
  title?: string;
  titleComponent?: ReactNode;
  detail?: string;
  detailComponent?: ReactNode;
  buttons?: Array<TButtonProps>;

  onConfirm?: () => void;
  confirmLabel?: string;
  onCancel?: () => void;
  cancelLabel?: string;
};

const ModalPopup = ({
  title = '',
  detail = '',
  titleComponent,
  detailComponent,
  buttons,
  ...defaultButtonProps
}: TProps) => {
  const { getOverlayElement } = useLayerContextConsumer();

  const titleJSX = useJSXMultiline(title);
  const detailJSX = useJSXMultiline(detail);

  const defaultButtons: Array<TButtonProps> | null = useMemo(() => {
    const { onConfirm, confirmLabel, onCancel, cancelLabel } = defaultButtonProps;
    const simpleSet: Array<TButtonProps> = [];

    if (onCancel) {
      simpleSet.push({ type: 'cancel', children: cancelLabel || '취소', onClick: onCancel });
    }
    if (onConfirm) {
      simpleSet.push({ type: 'confirm', children: confirmLabel || '확인', onClick: onConfirm });
    }

    return simpleSet.length > 0 ? simpleSet : null;
  }, [defaultButtonProps]);

  return createPortal(
    <div className={s.wrapper}>
      <div className={s.content}>
        <div
          className={s.title}
          data-cy="popup-title"
        >
          {titleComponent || titleJSX}
        </div>
        {(detail || detailComponent) && (
          <div className={s.detail}>{detailComponent || detailJSX}</div>
        )}
      </div>
      <div className={s.actions}>
        {(buttons || defaultButtons || []).map(({ type = '', ...buttonProps }, idx) => (
          <TmdsButton
            size="large"
            {...buttonProps}
            className={classNames(s.button, buttonProps.className)}
            key={idx}
            data-cy={type}
            color={type === 'confirm' ? 'primary' : 'secondary'}
          >
            {buttonProps.children}
          </TmdsButton>
        ))}
      </div>
    </div>,
    getOverlayElement()
  );
};

export default ModalPopup;
