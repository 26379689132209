import { ETripErrorCode, TApiStatus } from 'types/Api';
import {
  EAreas,
  ECompanyName,
  ECompanyType,
  EServiceAccessStatus,
  EServiceUserOS,
  ETripStatus,
  TCardInfo,
  TPaymentInfo,
} from 'types/App';
import { TLonLat } from 'types/Map';

export enum EUserStep {
  STAND_BY = 'STAND_BY',
  WAIT_RIDING = 'WAIT_RIDING',
  RIDING = 'RIDING',
  WAIT_RETURN = 'WAIT_RETURN',
  UNPAID = 'UNPAID',
  REJECT = 'REJECT',
}

export enum EBannerType {
  NOTICE = 'NOTICE',
  BENEFIT = 'BENEFIT',
}

export enum EPopupViewOption {
  NEVER = 'NEVER',
  NO_7DAY = 'NO_7DAY',
  NO_TODAY = 'NO_TODAY',
}

export type TUserTripStatus = {
  tripId: number;
  tripOrderId: number;
  status: ETripStatus;
  company?: ECompanyType;
  duration?: number;
  battery?: number;
  vehicleId?: string;
  distance?: number;
  vehicleType?: string;
  lastAlertPushMessage?: {
    msg: string;
    msgType: string;
  };
};

export type TStartTripArgs = {
  company?: ECompanyType;
  userOs: EServiceUserOS;
  vehicleId?: string;
  urlFromQR?: string;
} & TLonLat;

type TUserServiceAccessStatus = {
  code: EServiceAccessStatus;
  desc?: string;
};

export type TRejectInfo = {
  reason: ERejectReason;
  area?: EAreas;
  fee?: number;
};

export enum ERejectReason {
  LOW_BATTERY = 'LOW_BATTERY',
  DISABLE_RENT_AREA = 'DISABLE_RENT_AREA',
  DISABLE_RETURN_AREA = 'DISABLE_RETURN_AREA',
  INVALID_CODE = 'INVALID_CODE',
  LOST_VEHICLE = 'LOST_VEHICLE',
  ERROR_VEHICLE = 'ERROR_VEHICLE',
}

export type TPmEventBanner = {
  landingUrl: Nullable<string>;
  startDateTime: string;
  endDateTime: string;
  use: boolean;
  promotionId: Nullable<string>;
};

export type TPmBanner = Nullable<
  TPmEventBanner & {
    bannerId: number;
    title: string;
    bannerType: EBannerType;
  }
>;

export type TPmPopup = Nullable<
  TPmEventBanner & {
    imgUrl: string;
    popupId: number;
    viewOption: EPopupViewOption;
  }
>;

export type TPmLaunchData = {
  userKey?: string;
  tripStatus?: Nullable<TUserTripStatus>;
  userStatus?: TUserServiceAccessStatus;
  needCompanyTerm?: Record<ECompanyType, boolean>;
  unpaidOrder?: Nullable<TPaymentInfo>;
  companies: { code: ECompanyType; name: ECompanyName }[];
  bannerList: TPmBanner[];
  popupList: TPmPopup[];
};

export type TPmLaunchStatus = TApiStatus<TPmLaunchData>;

export type TPmStatus = {
  step: EUserStep;
  tripInfo?: Partial<TUserTripStatus>;
  rentInfo?: Partial<TStartTripArgs>;
  rejectInfo?: TRejectInfo;
};

export type TUserInfoState = {
  accessKey?: string;
  pmUserKey?: string;

  pmStatus: TPmStatus;

  pmUserStatus?: TUserServiceAccessStatus;
  pmLaunchStatus?: TPmLaunchStatus;
  pmTermsAgree?: Partial<Record<ECompanyType, boolean>>;

  paymentCard?: TApiStatus<TCardInfo>;
};

export const RENT_REJECT_CODE = {
  [ETripErrorCode.TRIP_LOW_BATTERY]: ERejectReason.LOW_BATTERY,
  [ETripErrorCode.TRIP_INVALID_SERIAL]: ERejectReason.INVALID_CODE,
  [ETripErrorCode.TRIP_LOST_VEHICLE]: ERejectReason.LOST_VEHICLE,
  [ETripErrorCode.TRIP_INSPECTION]: ERejectReason.ERROR_VEHICLE,
};

export type TCardInfoResponse = TApiStatus<TCardInfo>;
