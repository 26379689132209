import useOnce from 'hooks/useOnce';
import { useCallback, useState } from 'react';

import useInterval from './useInterval';

export const useUpdateNumber = (initialNumber, { step } = { step: 1 }) => {
  const [number, setNumber] = useState(initialNumber);

  const { start, stop } = useInterval(() => {
    setNumber((n) => n + step);
  });

  useOnce(typeof initialNumber === 'number', () => {
    setNumber(initialNumber);
    start();
  });

  const resetNumber = useCallback(
    (newStartNumber = 0) => {
      stop();
      setNumber(newStartNumber);
      start();
    },
    [start, stop]
  );

  return { number, resetNumber };
};
