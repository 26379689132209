import BottomButton from 'component/BottomButton';
import PmGuideAnimation from 'component/PmGuideAnimation';
import BottomSheet from 'component/tmds/bottomSheet/BottomSheet';
import { useLayerContextConsumer } from 'context/LayerContext';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { Suspense, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as IcoBlackZone } from 'resource/images/ico_blackzone_guide.svg';
import { ReactComponent as IcoRedZone } from 'resource/images/ico_redzone_guide.svg';
import s from 'styles/components/pmMain/PmRidingGuide.module.scss';

type TProps = {
  onClickConfirm: VoidFunction;
  onClickOverlay: VoidFunction;
};

const LoadableGuide = () => (
  <Suspense fallback={<div style={{ height: 'inherit', width: 'inherit' }} />}>
    <PmGuideAnimation />
  </Suspense>
);

export const PmRidingGuide = ({ onClickConfirm, onClickOverlay }: TProps) => {
  const { getOverlayElement } = useLayerContextConsumer();
  const ridingGuideLogger = usePmLogger(LogPageId.RidingGuide, true);

  const handleClickConfirm = useCallback(
    (e) => {
      e.stopPropagation();

      ridingGuideLogger.sendClickLog('tap.ok');
      onClickConfirm();
    },
    [onClickConfirm, ridingGuideLogger]
  );

  useEffect(() => {
    return () => {
      ridingGuideLogger.sendClickLog('tap.backscreen');
    };
  }, [ridingGuideLogger]);

  return createPortal(
    <div
      className={s.wrap}
      onClick={() => {
        ridingGuideLogger.sendClickLog('tap.backscreen');
        onClickOverlay();
      }}
    >
      <BottomSheet>
        <div
          className={s.guide}
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className={s.title}>주행, 반납가능 구역을 확인하세요</h3>
          <div className={s.image}>
            <div className={s.image_content}>
              <LoadableGuide />
            </div>
          </div>

          <div className={s.description}>
            <IcoBlackZone />
            <div className={s.text}>
              <span className={s.bold}>주행 불가 구역</span> 진입 시 기기가
              <br />
              자동으로 종료됩니다.
            </div>
          </div>

          <div className={s.description}>
            <IcoRedZone />
            <div className={s.text}>
              <span className={s.bold}>반납 불가 구역</span>에 반납 시
              <br />
              추가 비용이 발생합니다.
            </div>
          </div>

          <BottomButton
            onClick={handleClickConfirm}
            gradient={false}
          >
            확인했어요
          </BottomButton>
        </div>
      </BottomSheet>
    </div>,
    getOverlayElement()
  );
};
