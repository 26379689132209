import { TApiResponse } from 'types/Api';
import { TCardInfoResponse, TPmLaunchStatus } from 'types/api/User';
import fetcher from 'utils/fetcher';

class UserRepository {
  getInit = (props?: { userOs?: string; accessKey?: string }) => {
    return fetcher.get<TPmLaunchStatus>('/api/init', {
      headers: {
        UserOs: props?.userOs,
        AccessKey: props?.accessKey,
      },
    });
  };

  getPaymentCard = () => {
    return fetcher.get<TCardInfoResponse>('/api/user/defaultPayMeanInfo');
  };

  getEventCheck = (event: string) => {
    return fetcher.get<TApiResponse<boolean>>('/api/baemin/check', {
      params: {
        event,
      },
    });
  };
}

const userRepository = new UserRepository();

export default userRepository;
