import { Fragment, ReactNode, useMemo } from 'react';

export const useJSXMultiline = (str: string | ReactNode, options = { separator: '\n' }) => {
  const textNode = useMemo(() => {
    if (typeof str === 'string') {
      const [first, ...multiline] = str.split(options?.separator);

      return (
        <>
          {first}
          {multiline.map((line, idx) => (
            <Fragment key={`${line}_${idx}`}>
              <br />
              {line}
            </Fragment>
          ))}
        </>
      );
    }

    return str;
  }, [str, options?.separator]);

  return textNode;
};
