import { BASE_COMPANY_LIST } from 'constant/Scooter';
import { VEHICLE_TYPE_MAP } from 'constant/Vehicle';
import { useMemo } from 'react';
import { TBaseCompanyItem } from 'types/App';

import usePmStore from './usePmStore';
import useUserInfo from './useUserInfo';

const useActiveCompanyList = () => {
  const { pmLaunchStatus } = useUserInfo();

  const { loaded, companies } = useMemo(() => {
    return {
      loaded: pmLaunchStatus?.loaded && !pmLaunchStatus?.error,
      companies: pmLaunchStatus?.data?.companies || [],
    };
  }, [pmLaunchStatus]);

  const { activeCompany, activeType } = usePmStore();

  const companyList = useMemo(() => {
    const list = VEHICLE_TYPE_MAP[activeType].list.reduce((arr, v) => {
      const item = BASE_COMPANY_LIST.find((b) => b.code === v);

      if (!item) {
        return arr;
      }

      return [...arr, item];
    }, [] as TBaseCompanyItem[]);

    if (!loaded) {
      return list;
    }

    const item = list.find((v) => v?.code === activeCompany);
    if (activeCompany && item) {
      return [item];
    }

    if (companies.length > 0) {
      return list.filter((v) => companies.some((c) => c.code === v?.code)) || [];
    }

    return list;
  }, [companies, activeType, activeCompany, loaded]);

  return companyList;
};

export default useActiveCompanyList;
