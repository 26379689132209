import classNames from 'classnames';
import SideMenuButton from 'component/SideMenuButton';
import PmCompanySelectLayer from 'component/pmMain/PmCompanySelectLayer';
import PmHeaderDropDown from 'component/pmMain/PmHeaderDropdown';
import { PopupLayerId } from 'constant/App';
import useMainHeaderPopover from 'hooks/useMainHeaderPopover';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmApi from 'hooks/usePmStore';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import usePopupLayer from 'hooks/usePopupLayer';
import { useCallback, useEffect } from 'react';
import { ReactComponent as IcoArrowLeft } from 'resource/images/ico_arrow_left.svg';
import { ReactComponent as IconHome } from 'resource/images/icon-home.svg';
import pmStore from 'store/pmStore';
import s from 'styles/components/pmMain/PmMainHeader.module.scss';
import { ECompanyType, EVehicleType } from 'types/App';
import { sendAFSelectBrand } from 'utils/appsFlyer';

type TProps = {
  onClickBack?: VoidFunction;
  onClickSide?: VoidFunction;
  onOpenDropDown?: VoidFunction;
};

const PmMainHeader = ({ onClickBack, onClickSide, onOpenDropDown }: TProps) => {
  const { getVehicleList } = usePmApi();
  const mainLogger = usePmLogger(LogPageId.Main);
  const mainFilterLogger = usePmLogger(LogPageId.MainFilter);
  const { isRiding } = usePmUsingStatus();
  const companyLayer = usePopupLayer(PopupLayerId.COMPANY_BOTTOM_LAYER);
  const { isVisible: isPopoverVisible, tooltip, hide: hideHeaderPopover } = useMainHeaderPopover();

  const handleOpenDropdown = useCallback(() => {
    hideHeaderPopover();
    if (companyLayer.visible) {
      companyLayer.hide();
    } else {
      onOpenDropDown?.();
      companyLayer.show();
      // 드롭다운 오픈 이벤트
      mainLogger.sendClickLog('tap.tab_all');
    }
  }, [companyLayer, hideHeaderPopover, mainLogger, onOpenDropDown]);

  const handleClickCompany = useCallback(
    (type: EVehicleType, company?: ECompanyType) => {
      if (companyLayer.visible) {
        pmStore.resetList();
        pmStore.setActiveItem(null);
        pmStore.setActiveType(type);
        pmStore.setActiveCompany(company);

        mainFilterLogger.sendClickLog('tap.menu_brand', {
          unit: type.toLowerCase() || 'all',
          index: company?.toLowerCase() || 'all',
        });
        sendAFSelectBrand(type);
      }
    },
    [companyLayer.visible, mainFilterLogger]
  );

  useEffect(() => {
    if (!isRiding) {
      getVehicleList();
    }
  }, [isRiding, getVehicleList]);

  return (
    <>
      <div
        className={classNames(s.round_button, s.left)}
        onClick={onClickBack}
      >
        <button className={s.back_button}>
          {isRiding ? (
            <IconHome aria-label="홈" />
          ) : (
            <IcoArrowLeft
              className={s.icon_back}
              aria-label="뒤로가기"
            />
          )}
        </button>
      </div>
      <div
        className={classNames(s.round_button, s.right)}
        onClick={onClickSide}
        aria-label="메뉴"
      >
        <SideMenuButton />
      </div>

      {!isRiding && (
        <div className={s.button_wrap}>
          <button
            className={s.list_button}
            onClick={handleOpenDropdown}
            data-cy="company-select"
            aria-label="제조사 선택"
          >
            <PmHeaderDropDown onClickCompany={handleClickCompany} />
            {companyLayer.visible && <PmCompanySelectLayer onClickCompany={handleClickCompany} />}

            {isPopoverVisible && tooltip}
          </button>
        </div>
      )}
    </>
  );
};

export default PmMainHeader;
