export type TApiResponse<T> = {
  code: string | number;
  message: Nullable<string>;
  data: T;
};

export type TApiStatus<T> = {
  data: T;
  loaded: boolean;
  loading: boolean;
  error?: any;
};

export type TApiStatusType = 'pending' | 'rejected' | 'fulfilled';

export enum EApiResponseCode {
  SUCCESS = 'SUCCESS',
  CLIENT_ERROR = 'CLIENT_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
}

export enum EUserErrorCode {
  USER_NOT_EXIST = 'USER_NOT_EXIST',
  USER_NOT_ENOUGH = 'USER_NOT_ENOUGH',
  USER_ALREADY = 'USER_ALREADY',
  USER_INVALID_ACCESS_KEY = 'USER_INVALID_ACCESS_KEY',
  USER_NAME_NOT_MATCH = 'USER_NAME_NOT_MATCH',
}

export enum ETripErrorCode {
  TRIP_LOST_VEHICLE = 'TRIP_LOST_VEHICLE',
  TRIP_INSPECTION = 'TRIP_INSPECTION',
  TRIP_ALREADY_START = 'TRIP_ALREADY_START',
  TRIP_ALREADY_END = 'TRIP_ALREADY_END',
  TRIP_ONLY_ONE_DEVICE = 'TRIP_ONLY_ONE_DEVICE',
  TRIP_INVALID_SERIAL = 'TRIP_INVALID_SERIAL',
  TRIP_TOO_FAR_AWAY = 'TRIP_TOO_FAR_AWAY',
  TRIP_OVER_BOUNDARY = 'TRIP_OVER_BOUNDARY',
  TRIP_LOW_BATTERY = 'TRIP_LOW_BATTERY',
  TRIP_UNLOCKING_FAILED = 'TRIP_UNLOCKING_FAILED',
  TRIP_LOCKING_FAILED = 'TRIP_LOCKING_FAILED',
  TRIP_NO_PARKING_AREA = 'TRIP_NO_PARKING_AREA',
}

export enum ELicenseErrorCode {
  USER_NAME_NOT_MATCH = 'USER_NAME_NOT_MATCH',
  CLIENT_ERROR = 'CLIENT_ERROR',
  LICENSE_NOT_MATCH = 'LICENSE_NOT_MATCH',
  LICENSE_SERVER_ERROR = 'LICENSE_SERVER_ERROR',
}
