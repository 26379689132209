import BottomButton from 'component/BottomButton';
import BottomSheet from 'component/tmds/bottomSheet/BottomSheet';
import { useLayerContextConsumer } from 'context/LayerContext';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CardGuideImage } from 'resource/images/img-card-guide.svg';
import s from 'styles/components/pmMain/PmCardGuide.module.scss';

type TProps = {
  onClickConfirm: VoidFunction;
  onClickOverlay: VoidFunction;
};

export const PmCardGuide = ({ onClickConfirm, onClickOverlay }: TProps) => {
  const cardGuideLogger = usePmLogger(LogPageId.PaymentGuide);
  const { getOverlayElement } = useLayerContextConsumer();

  useEffect(() => {
    cardGuideLogger.sendPageView();

    return () => {
      cardGuideLogger.sendClickLog('tap.outside');
    };
  }, []);

  return createPortal(
    <div
      className={s.wrap}
      onClick={() => {
        cardGuideLogger.sendClickLog('tap.outside');
        onClickOverlay();
      }}
    >
      <BottomSheet>
        <div className={s.guide}>
          <div className={s.image}>
            <CardGuideImage />
          </div>

          <h3>결제수단 등록이 먼저 필요해요.</h3>
          <p>
            편리한 서비스 이용을 위해
            <br />
            결제수단을 먼저 등록해 주세요.
          </p>
          <BottomButton
            onClick={(e) => {
              e.stopPropagation();
              cardGuideLogger.sendClickLog('tap.confirm');
              onClickConfirm();
            }}
            gradient={false}
          >
            알겠어요
          </BottomButton>
        </div>
      </BottomSheet>
    </div>,
    getOverlayElement()
  );
};
