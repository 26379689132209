import { PageId, TActionId } from 'constant/PmLog';
import useOnce from 'hooks/useOnce';
import { useCallback } from 'react';
import mixpanelManager from 'utils/mixpanel';
import tlaLogger from 'utils/tlaLogger';

export const LogPageId = PageId;

export const usePmLogger = (pageId, triggerPageView = false) => {
  const bindPageId = useCallback(() => {
    tlaLogger.updatePageId(pageId);
    mixpanelManager.updateMeta({ pageId });
  }, [pageId]);

  const sendApp = useCallback(
    (actionId: TActionId, custom = {}) => {
      bindPageId();
      tlaLogger.sendApp(actionId, custom);

      // 믹스패널에서는 event만 있기 때문에 구분할 필요가 없음 sendApp도 sendEvent로 mixpanel 전송
      // https://tmobi.atlassian.net/wiki/spaces/TMAPBIZ/pages/139526285#TLA(logbox)--%3E--Mixpanel
      // https://tmobi.atlassian.net/wiki/spaces/TMAPPOINEW/pages/265848673
      mixpanelManager.sendEvent(actionId, custom);
    },
    [bindPageId]
  );

  // actionId가 "" 이고 properties만 받아서 전송
  const sendExpose = useCallback(
    (properties?: Record<string, any>) => {
      bindPageId();
      tlaLogger.sendExpose(properties);
      mixpanelManager.sendEvent('', properties);
    },
    [bindPageId]
  );

  // actionId가 없고, properties도 없이 전송
  const sendPageView = useCallback(() => {
    bindPageId();
    tlaLogger.sendExpose();
    mixpanelManager.sendEvent();
  }, [bindPageId]);

  const sendClickLog = useCallback(
    (actionId: TActionId, properties?: Record<string, any>) => {
      bindPageId();
      tlaLogger.sendEvent(actionId, properties);
      mixpanelManager.sendEvent(actionId, properties);
    },
    [bindPageId]
  );

  useOnce(triggerPageView, () => {
    sendPageView();
  });

  return {
    sendApp,
    sendExpose,
    sendPageView,
    sendClickLog,
  };
};
