import BackButton from 'component/BackButton';
import BottomButton from 'component/BottomButton';
import Header from 'component/Header';
import { NotFoundPopup } from 'component/event/NotFoundPopup';
import { API_PATH } from 'constant/Api';
import { Paths } from 'constant/RoutePath';
import useOnce from 'hooks/useOnce';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconShare } from 'resource/images/share.svg';
import s from 'styles/pages/PmEventPage.module.scss';
import { APP_SCHEME } from 'types/App';
import { parseApiPath } from 'utils/apis';
import { getApi } from 'utils/fetcher';
import { parseAppScheme } from 'utils/tmapInApp';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

type TEventItem = {
  buttonColor: string;
  buttonName: string;
  imgUrl: string;
  landingUrl: string;
};

const SHARE_URL =
  'http://www.tmap.co.kr/tmap2/mobile/tmap.jsp?scheme=tmap&host=kickboard&extra=mkt_channel_share';

const PmEventPage = () => {
  const location = useLocation();
  const [data, setData] = useState<TEventItem>();
  const [loaded, setLoaded] = useState(false);
  const [hasError, setError] = useState(false);
  const [hasImageError, setImageError] = useState(false);

  const handleClickButton = useCallback(() => {
    // 0. 이벤트 페이지 내 접근경로 `tmap://kickboard?extra=${encodeURIComponent('landing_/event/${code}')}`
    // 1. 메인 접근
    // 2. extra 확인 후 이벤트 창 열기
    // 3. 킥보드 페이지는 항상 메인에서 접근하기 때문에 창만 닫아주기
    if (data?.landingUrl === 'tmap://kickboard' || !data) {
      tmapWrapper.closeWebview();
      return;
    }

    const appSchemeData = parseAppScheme(data.landingUrl);

    if (appSchemeData) {
      if (appSchemeData.serviceName === APP_SCHEME && appSchemeData.landing) {
        tmapWrapper.openServiceByUrl(appSchemeData.landing);
      } else {
        tmapWrapper.openServicePage({
          serviceName: appSchemeData.serviceName as any,
          params: appSchemeData.params,
          url: data.landingUrl,
        });
      }
    }
  }, [data]);

  const handleClickShare = useCallback(() => {
    tmapWrapper.copyClipboard('', SHARE_URL);
    tmapWrapper.makeToast('이벤트 URL이 복사되었습니다.');
  }, []);

  useOnce(location.pathname, () => {
    const [, code = ''] = location.pathname.split(Paths.PmEvent);
    const eventPageId = code.replace(/^\//, '');

    if (!eventPageId) {
      setError(true);
      return;
    }

    getApi(parseApiPath(API_PATH.GET_EVENT_DETAIL, { eventPageId }))
      .then((v: TEventItem) => setData(v))
      .catch(() => setError(true))
      .finally(() => setLoaded(true));
  });

  if (hasError) {
    return <NotFoundPopup />;
  }

  if (loaded && !data) {
    return <NotFoundPopup />;
  }

  return (
    <div className={s.wrap}>
      <Header
        title="TMAP BIKE"
        rightButton={<IconShare onClick={handleClickShare} />}
        leftButton={<BackButton onClick={tmapWrapper.closeWebview} />}
        gradient={true}
      />

      {data?.imgUrl && !hasImageError && (
        <img
          className={s.image}
          src={data.imgUrl}
          width="100%"
          alt="이벤트 이미지"
          onError={() => setImageError(true)}
        />
      )}

      <div className={s.action}>
        <BottomButton
          gradient={false}
          onClick={handleClickButton}
          style={{
            backgroundColor: data?.buttonColor,
          }}
        >
          {data?.buttonName || '티맵 킥보드 바로가기'}
        </BottomButton>
      </div>
    </div>
  );
};

export default PmEventPage;
