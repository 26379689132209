import qs from 'query-string';
import ua from 'utils/uaParser';

import tmapWrapper from './wrappers/TMapWrapper';

export const getFullPath = (path: string, query = {}) => {
  const q = qs.stringify(query);

  return `${window.location.origin + path}${q ? `?${q}` : ''}`;
};

export const moveToPage = (path: string, query = {} as any) => {
  const url = getFullPath(path, query);

  if (ua.isInApp) {
    tmapWrapper.openServiceByUrl(url, '', 'console.log', true);
  } else {
    window.location.href = url;
  }
};
