import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';
import s from 'styles/components/BottomButton.module.scss';

import TmdsButton from './tmds/TmdsButton';

type TProps = PropsWithChildren<
  HTMLAttributes<HTMLButtonElement> & { disabled?: boolean; gradient?: boolean }
>;

export const BottomButton = ({
  className,
  children,
  disabled,
  gradient = true,
  ...buttonProps
}: TProps) => {
  return (
    <div
      className={classNames(s.button_wrap, {
        [s.background]: !!gradient,
      })}
    >
      {gradient && <div className={s.gradient} />}
      <TmdsButton
        {...buttonProps}
        className={classNames(className, s.bottom_button)}
        disabled={disabled}
        size="large"
        color="primary"
      >
        {children}
      </TmdsButton>
    </div>
  );
};

export default BottomButton;
