import { Paths } from 'constant/RoutePath';

import codeMockCases from './pages/PmCode';
import mainMockCases from './pages/PmMain';
import returnPageMockCases from './pages/PmReturnPage';
import scooterLicenseMockCases from './pages/ScooterLicense';

export const pageCaseMap = {
  [Paths.PmMain]: mainMockCases,
  [Paths.PmCodePage]: codeMockCases,
  [Paths.PmReturnPage]: returnPageMockCases,
  [Paths.PmLicense]: scooterLicenseMockCases,
} as const;
