import { PaymentPaths } from 'constant/RoutePath';
import { useCallback, useEffect, useRef } from 'react';
import { EPaymentStatusCode } from 'types/InApp';
import { parseApiPath } from 'utils/apis';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import useAppEventStore from './useAppEventStore';

export const usePmPayment = () => {
  const { resumeKey } = useAppEventStore();
  const refPaymentChecking = useRef(false);
  const refPayActivityCallbackFire = useRef(false);

  useEffect(() => {
    const returnByAOSBack = !refPayActivityCallbackFire.current;

    if (refPaymentChecking.current && !returnByAOSBack) {
      refPaymentChecking.current = false;
    }
  }, [resumeKey]);

  const checkCardUpdate = useCallback(async () => {
    const isUpdateSuccess = await tmapWrapper
      .startPaymentActivity(PaymentPaths.CardUpdate)
      .then((result) => result[0] === EPaymentStatusCode.SUCCESS);

    return isUpdateSuccess;
  }, []);

  const checkPinNumber = useCallback(async () => {
    const pinAvailable = await tmapWrapper
      .startPaymentActivity(PaymentPaths.Password)
      .then((result) => result[0] === EPaymentStatusCode.SUCCESS);

    return pinAvailable;
  }, []);

  const retryPayment = useCallback(async (orderId?: number) => {
    const urlValue = parseApiPath(PaymentPaths.Retry, { orderId });

    const isSuccess = await tmapWrapper
      .startPaymentActivity(ua.isIos ? encodeURIComponent(urlValue) : urlValue)
      .then((result) => result[0] === EPaymentStatusCode.SUCCESS);

    return isSuccess;
  }, []);

  return { checkCardUpdate, checkPinNumber, retryPayment };
};
