import { WEB_ACCESS_KEY } from 'constant/Env';
import { useCallback, useEffect } from 'react';
import userInfoStore from 'store/userInfoStore';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import useAdditionalLocationSearch from './useAdditionalLocationSearch';
import useUserInfo from './useUserInfo';

export const useAccessKey = () => {
  const { accessKey } = useUserInfo();

  const { currentQuery } = useAdditionalLocationSearch();

  const setAccessKey = useCallback(async () => {
    if (ua.isInApp) {
      const ak = await tmapWrapper.getAccessKey();
      userInfoStore.setAccessKey(ak);
    } else {
      userInfoStore.setAccessKey((currentQuery.ak as string) || WEB_ACCESS_KEY);
    }
  }, [currentQuery.ak]);

  useEffect(() => {
    if (accessKey) {
      return;
    }
    setAccessKey();
  }, [accessKey, setAccessKey]);

  return accessKey;
};
