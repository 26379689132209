import CloseButton from 'component/CloseButton';
import Header from 'component/Header';
import ModalPopup from 'component/ModalPopup';
import PmRentStatusLayer from 'component/PmRentStatusLayer';
import PmReturnFail from 'component/pmReturn/PmReturnFail';
import PmReturnSuccess from 'component/pmReturn/PmReturnSuccess';
import { PENDING_STATE } from 'constant/Api';
import { PopupLayerId } from 'constant/App';
import { MODAL_TEXT } from 'constant/Scooter';
import { StorageKey } from 'constant/Storage';
import useAppEventStore from 'hooks/useAppEventStore';
import useHandleBackKey from 'hooks/useHandleBackKey';
import useMovePage from 'hooks/useMovePage';
import useOnce from 'hooks/useOnce';
import usePaymentPoint from 'hooks/usePaymentPoint';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmPayResult from 'hooks/usePmPayResult';
import { usePmPayment } from 'hooks/usePmPayment';
import usePopupLayer from 'hooks/usePopupLayer';
import useSendLocation from 'hooks/useSendLocation';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import tripOrderRepository from 'repository/tripOrderRepository';
import { localStorageStore } from 'store/storageStore';
import s from 'styles/pages/PmReturnPage.module.scss';
import { ELottieType } from 'types/App';
import { sendAFCompleteRide, sendAFPayFailure } from 'utils/appsFlyer';
import { clearEmptyString } from 'utils/string';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const DEFAULT_PAYMENT_INFO = { ...PENDING_STATE, data: undefined, error: undefined };
const POINT_ON_OFF = {
  ON: 'on',
  OFF: 'off',
};

const SALE_UNIT = {
  SALE: 'sale',
  NORMAL: 'normal',
};

export const PmReturnPage = () => {
  const { resumeKey } = useAppEventStore();
  const { pmStatus, pmUserKey } = useUserInfo();
  const { initTripId, initTripOrderId } = useMemo(
    () => ({
      initTripId: pmStatus.tripInfo?.tripId,
      initTripOrderId: pmStatus.tripInfo?.tripOrderId,
    }),
    [pmStatus]
  );

  const tripId = useMemo(() => initTripId, []);
  const tripOrderId = useMemo(() => initTripOrderId, []);
  const [isCardChanged, setCardChanged] = useState(false);

  const remainUnpaidPopup = usePopupLayer(PopupLayerId.REMAIN_UNPAID);
  const { checkPinNumber, retryPayment } = usePmPayment();
  const { isActive } = usePaymentPoint();
  const { payResult, setPayResult, success, paymentInfo, pollingPaymentResult } = usePmPayResult();
  const { replaceMainPage } = useMovePage();
  const successLogger = usePmLogger(LogPageId.PaymentSuccess);
  const failLogger = usePmLogger(LogPageId.PaymentFail);

  useSendLocation();

  const handleClickRetry = useCallback(async () => {
    if (tripOrderId) {
      setCardChanged(false);
      setPayResult(DEFAULT_PAYMENT_INFO);

      tripOrderRepository.postTripOrderPurchase(tripOrderId).finally(() => {
        // [TBD]
        // repurchase api 호출 후에 바로 polling 호출시에는 여전히 PURCHASE_FAIL 로 나옴 => 100ms 이후에 호출하도록 임시 적용
        setTimeout(() => {
          pollingPaymentResult(tripId, tripOrderId);
        }, 100);
      });
      failLogger.sendClickLog('tap.request');
    }
  }, [tripOrderId, setPayResult, failLogger, pollingPaymentResult, tripId]);

  const handleClickChangePayment = useCallback(async () => {
    failLogger.sendClickLog('tap.changepayment');
    const isSuccess = await retryPayment(paymentInfo?.orderId);
    if (isSuccess) {
      handleClickRetry();
    }
  }, [failLogger, handleClickRetry, paymentInfo?.orderId, retryPayment]);

  const handleClickPin = useCallback(() => {
    checkPinNumber().then((isSuccess) => {
      isSuccess && handleClickRetry();
    });
  }, [checkPinNumber, handleClickRetry]);

  const hasSale = useMemo(() => {
    if (paymentInfo?.discountInfo) {
      return (
        paymentInfo.discountInfo.filter(({ name }) => clearEmptyString(name) !== '포인트사용')
          .length >= 1
      );
    }
    return false;
  }, [paymentInfo]);

  const handleClickClose = useCallback(() => {
    if (success) {
      successLogger.sendClickLog('tap.close', {
        index: isActive ? POINT_ON_OFF.ON : POINT_ON_OFF.OFF,
        unit: hasSale ? SALE_UNIT.SALE : SALE_UNIT.NORMAL,
      });
      replaceMainPage();
    } else {
      failLogger.sendClickLog('tap.close');
      remainUnpaidPopup.show();
    }
  }, [success, successLogger, isActive, hasSale, replaceMainPage, failLogger, remainUnpaidPopup]);

  const handleClickConfirm = useCallback(() => {
    if (success) {
      successLogger.sendClickLog('tap.confirm', {
        index: isActive ? POINT_ON_OFF.ON : POINT_ON_OFF.OFF,
        unit: hasSale ? SALE_UNIT.SALE : SALE_UNIT.NORMAL,
      });
      replaceMainPage();
    }
  }, [hasSale, isActive, replaceMainPage, success, successLogger]);

  const handleBackKeyPressed = useCallback(() => {
    if (success) {
      handleClickConfirm();
    } else {
      handleClickClose();
    }
  }, [handleClickClose, handleClickConfirm, success]);

  useHandleBackKey(ua.isAndroid && ua.isInApp, handleBackKeyPressed);

  useOnce(tripOrderId, async () => {
    pollingPaymentResult(tripId, tripOrderId);
  });

  useEffect(() => {
    return () => {
      localStorageStore.setValue(StorageKey.VISIT_PAY_RESULT, '');
    };
  }, [resumeKey]);

  useEffect(() => {
    if (success) {
      successLogger.sendPageView();
    } else {
      failLogger.sendPageView();
    }
  }, [success]);

  useOnce(payResult.loaded, () => {
    if (pmUserKey && payResult.data) {
      const eventValue = {
        paymentMoney: payResult.data.amount,
        deviceBrandName: payResult.data.companyName,
        riderId: pmUserKey,
        // userStartLocation?: {
        //   lat: number | string;
        //   lon: number | string;
        // };
        // userEndLocation: {
        //   lat: number | string;
        //   lon: number | string;
        // };
        // moveDistance:payResult.data.status;
        // startTime: number | string;
        // endTime: number | string;
        // rideTime: number | string;
      };

      if (success) {
        sendAFCompleteRide(eventValue, { tripId });
        successLogger.sendClickLog('end.riding');
      } else {
        sendAFPayFailure(eventValue, { tripId });
        failLogger.sendClickLog('end.riding');
      }
    }
  });

  return (
    <div className={s.scroll_wrap}>
      <div className={s.wrap}>
        <PmRentStatusLayer
          loading={!payResult.loaded}
          type={ELottieType.PAYMENT}
        />
        <Header
          leftButton={null}
          rightButton={<CloseButton onClick={handleClickClose} />}
        />

        {paymentInfo && (
          <>
            {success ? (
              <PmReturnSuccess
                paymentInfo={paymentInfo}
                onConfirm={handleClickConfirm}
              />
            ) : (
              <PmReturnFail
                hideErrorMessage={isCardChanged}
                paymentInfo={paymentInfo}
                onChangePayment={handleClickChangePayment}
                onRetryPurchase={handleClickRetry}
                onEnterPin={handleClickPin}
              />
            )}
          </>
        )}

        {remainUnpaidPopup.visible && (
          <ModalPopup
            title={MODAL_TEXT.UNPAID.title}
            buttons={[
              {
                type: 'cancel',
                children: '취소',
                onClick: () => {
                  remainUnpaidPopup.hide();
                },
              },
              {
                type: 'confirm',
                children: '확인',
                onClick: () => {
                  remainUnpaidPopup.hide();
                  tmapWrapper.closeWebview();
                },
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default PmReturnPage;
