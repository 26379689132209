import { isDevEnv } from 'constant/Env';
import { Paths } from 'constant/RoutePath';
import { StorageKey } from 'constant/Storage';
import { useCallback, useMemo, useRef } from 'react';
import tripRepository from 'repository/tripRepository';
import vehicleRepository from 'repository/vehicleRepository';
import { localStorageStore } from 'store/storageStore';
import userInfoStore from 'store/userInfoStore';
import { ECameraStatus } from 'types/InApp';
import { ERejectReason, EUserStep } from 'types/api/User';
import { saveAFUserInfo, sendAFEndRide, sendAFShotParking } from 'utils/appsFlyer';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import useCurrentPosition from './useCurrentPosition';
import useDatadogLog, { ELogStep } from './useDatadogLog';
import useMovePage from './useMovePage';
import useUserInfo from './useUserInfo';

type TReturnCameraParam = {
  status: ECameraStatus;
  base64Image?: string;
  tripId?: number;
  lng: number;
  lat: number;
};

export const usePmTrip = () => {
  const { getLastPosition } = useCurrentPosition();

  const { sendCameraLog } = useDatadogLog();

  const { movePage } = useMovePage();
  const isRequestEnd = useRef(false);

  const { pmStatus } = useUserInfo();
  const tripStatus = useMemo(() => pmStatus.tripInfo, [pmStatus.tripInfo]);

  const handleReturnCamera = useCallback(
    ({ status, base64Image, tripId, lng, lat }: TReturnCameraParam) => {
      const showErrorToast = () => {
        tmapWrapper.makeToast('이미지 업로드 실패하였습니다.');
      };

      if (status === ECameraStatus.COMPLETE) {
        if (base64Image && tripId !== undefined) {
          sendCameraLog(ELogStep.REQUEST_API, '업로드 API 요청');

          tripRepository.postUploadTripEndImage(tripId, { base64Image, lng, lat }).catch((e) => {
            showErrorToast();
            sendCameraLog(ELogStep.UPLOAD_API_ERROR, '업로드 API 실패', e);
          });
        } else {
          showErrorToast();
        }
      }
    },
    [sendCameraLog]
  );

  const startReturnCamera = useCallback(
    (params: { tripId?: number; lng: number; lat: number }) => {
      return tmapWrapper
        .startCameraActivity([
          {
            key: 'title',
            value: '주차사진 촬영',
          },
          {
            key: 'captureMessage',
            value:
              '기기를 안전한 장소에 주차하셨나요?\n기기의 전체 모습과 주변이 잘 보이도록 촬영 해 주세요.',
          },
        ])
        .then((results) => {
          const [status, base64Image] = results;

          sendCameraLog(ELogStep.CAMERA_CALLBACK, '촬영 callback', {
            status,
            hasImage: !!base64Image,
          });

          setTimeout(
            () =>
              handleReturnCamera({
                status,
                base64Image,
                ...params,
              }),
            ua.isIos ? 500 : 0
          );
        });
    },
    [handleReturnCamera, sendCameraLog]
  );

  const endTrip = useCallback(
    async ({ company, vehicleId = '' }, { checkArea } = { checkArea: true }) => {
      const lonLat = await getLastPosition();
      if (!lonLat) {
        tmapWrapper.makeToast('반납에 실패했습니다.\n 재시도를 해주세요');
        return;
      }

      if (checkArea && company && vehicleId && !isDevEnv) {
        const vehicleInfo = await vehicleRepository.checkVehicleReject(company, vehicleId, {
          lng: lonLat.lon,
          lat: lonLat.lat,
        });

        if (vehicleInfo.rejectInfo) {
          userInfoStore.setReturnRejectInfo({
            ...vehicleInfo.rejectInfo,
            reason: ERejectReason.DISABLE_RETURN_AREA,
          });
          return;
        }
      }

      const tripId = tripStatus?.tripId;
      if (tripId === undefined) {
        return;
      }

      userInfoStore.setPmStatus({
        step: EUserStep.WAIT_RETURN,
        tripInfo: { tripId },
      });

      try {
        saveAFUserInfo(tripId, { trip: tripStatus, end: lonLat });

        if (isRequestEnd.current) {
          return;
        }

        isRequestEnd.current = true;

        const tripEndResult = await tripRepository.postTripEnd(tripId, lonLat);

        userInfoStore.setPmStatus({
          step: EUserStep.WAIT_RETURN,
          tripInfo: { tripId, tripOrderId: tripEndResult.tripOrderId },
        });

        localStorageStore.setValue(StorageKey.VISIT_PAY_RESULT, `${tripEndResult.tripOrderId}`);
        sendAFEndRide();

        setTimeout(() => {
          sendAFShotParking();

          if (lonLat) {
            sendCameraLog(ELogStep.TRY_OPEN_CAMERA, '반납 카메라 실행 시도');
            startReturnCamera({ tripId, lat: lonLat.lat, lng: lonLat.lon });
          } else {
            sendCameraLog(ELogStep.TRY_OPEN_CAMERA, '반납 카메라 실행 실패');
          }

          movePage(Paths.PmReturnPage);
          isRequestEnd.current = false;
        }, 100);
      } catch (err: any) {
        localStorageStore.setValue(StorageKey.VISIT_PAY_RESULT, '');
        isRequestEnd.current = false;
        userInfoStore.setPmStatus({
          step: EUserStep.RIDING,
          tripInfo: { tripId, company, vehicleId },
        });
        tmapWrapper.makeToast(err.response.data?.message);
      }
    },
    [tripStatus, getLastPosition, startReturnCamera, sendCameraLog, movePage]
  );

  const resumeTrip = useCallback((tripId: number) => {
    return tripRepository.postTripResume(tripId);
  }, []);

  const pauseTrip = useCallback((tripId: number) => {
    return tripRepository.postTripPause(tripId);
  }, []);

  const moveToReturnPage = useCallback(
    (tripOrderId: number) => {
      userInfoStore.setPmStatus({
        step: EUserStep.WAIT_RETURN,
        tripInfo: { tripOrderId },
      });
      movePage(Paths.PmReturnPage);
    },
    [movePage]
  );

  const getTripStatus = useCallback(async (tripId: number) => {
    const data = await tripRepository.getTripStatus(tripId);
    userInfoStore.setTripInfo({ ...data, tripId });
    return data;
  }, []);

  return {
    endTrip,
    tripId: tripStatus?.tripId,
    startReturnCamera,
    resumeTrip,
    pauseTrip,
    getTripStatus,
    moveToReturnPage,
  };
};

export default usePmTrip;
