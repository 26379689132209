export enum EProjectEnv {
  PROD = 'PROD',
  RTG = 'RTG',
  STAGE = 'STAGE',
  DEV = 'DEV',
}

type TNodeEnv = 'development' | 'production';
export const NODE_ENV = process.env.NODE_ENV as TNodeEnv;
export const APP_ENV = process.env.REACT_APP_ENV as EProjectEnv;

export const isProdEnv = APP_ENV === EProjectEnv.PROD;
export const isRtgEnv = APP_ENV === EProjectEnv.RTG;
export const isStageEnv = APP_ENV === EProjectEnv.STAGE;
export const isDevEnv = APP_ENV === EProjectEnv.DEV;
export const isLocalEnv = NODE_ENV === 'development';
export const isLocalOrDev = isLocalEnv || isDevEnv;

export const WEB_ACCESS_KEY = 'web-accesskey';

const env = {
  isProdEnv,
  isLocalEnv,
  isLocalOrDev,
};

export default env;
