import s from 'styles/components/terms/PmTermsDetail.module.scss';

const PmTermsUserInfo = () => {
  return (
    <div className={s.content}>
      <h1>개인정보 제3자 제공 동의</h1>
      <div>티맵모빌리티㈜가 서비스 제공을 위하여 아래와 같이 정보를 제공하는 것에 동의 합니다.</div>
      <section className={s.table}>
        <div>
          <h3 className={s.accent}>제공받는 자</h3>
          <p className={s.accent}>개인형 이동수단 서비스 제공자</p>
        </div>
        <div>
          <h3 className={s.accent}>제공 목적</h3>
          <p className={s.accent}>사고 발생 시 보험 처리 및 고객 문의 응대</p>
        </div>
        <div>
          <h3>제공 항목</h3>
          <p>휴대폰번호,이름,생년월일, 성별, 개인위치정보 (이용중 시간대별 GPS 좌표값)</p>
        </div>
        <div>
          <h3 className={s.accent}>보유/이용기간</h3>
          <p className={s.accent}>정보제공 동의일로부터 목적 달성시까지</p>
        </div>
      </section>
      <ul>
        <li>
          ※ 개인위치정보란, 회원 가입 명의 휴대전화가 특정한 시간에 존재하거나 존재하였던 장소에
          관한 정보입니다.
        </li>
        <li>
          ※ 고객님의 개인정보/개인위치정보는 고객님의 모빌리티 서비스 이용시 해당 서비스를 제공하기
          위한 목적으로만 제공됩니다.
        </li>
        <li>
          <b>※ 회원께서는 동의를 거부할 권리가 있으나, 거부 시 서비스 이용이 제한될 수 있습니다.</b>
        </li>
      </ul>
    </div>
  );
};

export default PmTermsUserInfo;
