import { WEB_ACCESS_KEY, isProdEnv } from 'constant/Env';
import useOnce from 'hooks/useOnce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import mixpanelManager from 'utils/mixpanel';
import tlaLogger from 'utils/tlaLogger';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import packageInfo from '../../package.json';

type TLoggerData = {
  userKey: string;
  deviceId?: string;
  carrierName?: string;
};

const serviceId = 'pm_kickboard';

export const useInitLogger = (accessKey) => {
  const [initLogger, setInitLogger] = useState(false);
  const [loggerData, setLoggerData] = useState<TLoggerData>({
    userKey: '',
    deviceId: undefined,
    carrierName: undefined,
  });
  const refRetryUserKey = useRef(false);
  const prepareLogData = useMemo(
    () =>
      !!(
        accessKey &&
        loggerData.carrierName !== undefined &&
        loggerData.deviceId !== undefined &&
        loggerData.userKey
      ),
    [accessKey, loggerData.carrierName, loggerData.deviceId, loggerData.userKey]
  );

  useOnce(prepareLogData, () => {
    if (!isProdEnv || accessKey !== WEB_ACCESS_KEY) {
      tlaLogger.init({
        serviceId,
        accessKey,
        sessionKey: `${accessKey}_${Date.now()}`,
        title: 'TMAP BIKE',
        packageVersion: packageInfo.version,
        isProdEnv: isProdEnv,
        ...loggerData,
      });

      mixpanelManager.init({
        serviceId,
        userKey: loggerData.userKey,
      });

      setInitLogger(true);
    }
  });

  const setInitLoggerData = useCallback(async () => {
    if (ua.isInApp) {
      tmapWrapper.getUserKey().then((userKey) => {
        if (!userKey && !refRetryUserKey.current) {
          refRetryUserKey.current = true;
          setTimeout(
            () =>
              tmapWrapper.getUserKey().then((uk) => {
                setLoggerData((d) => ({ ...d, userKey: uk || '' }));
              }),
            300
          );
          return;
        }

        setLoggerData((d) => ({ ...d, userKey: userKey || '' }));
      });

      tmapWrapper.getDeviceId().then((deviceId) => {
        setLoggerData((d) => ({ ...d, deviceId: deviceId || '' }));
      });

      tmapWrapper.getCarrierName().then((carrierName) => {
        setLoggerData((d) => ({ ...d, carrierName: carrierName || '' }));
      });
    } else {
      setLoggerData({
        userKey: 'web-userkey',
        deviceId: 'web-deviceId',
        carrierName: 'web-carrierName',
      });
    }
  }, []);

  useEffect(() => {
    setInitLoggerData();
  }, [setInitLoggerData]);

  return initLogger;
};
