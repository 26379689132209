import s from 'styles/components/PaymentCardBrandImage.module.scss';
import { TCardInfo } from 'types/App';

type TProps = Pick<TCardInfo, 'payMeansImageInfo'>;

export const PaymentCardBrandImage = ({ payMeansImageInfo }: TProps) => {
  return (
    <i
      className={s.bi}
      style={{ backgroundColor: payMeansImageInfo.bgColor }}
    >
      <img
        src={payMeansImageInfo.ciImageUrl}
        alt={payMeansImageInfo.displayName}
      />
    </i>
  );
};
