import classNames from 'classnames';
import CurrentPositionButton from 'component/CurrentPositionButton';
import PmGuideButton from 'component/PmGuideButton';
import PmBottomNotice from 'component/pmMain/PmBottomNotice';
import { PmCardGuide } from 'component/pmMain/PmCardGuide';
import PmCardItem from 'component/pmMain/PmCardItem';
import PmRentButton from 'component/pmMain/PmRentButton';
import PmTripStatus from 'component/pmMain/PmTripStatus';
import BottomSheet from 'component/tmds/bottomSheet/BottomSheet';
import { PopupLayerId } from 'constant/App';
import useHandleBackKey from 'hooks/useHandleBackKey';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { usePmPayment } from 'hooks/usePmPayment';
import usePmStore from 'hooks/usePmStore';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import usePopupLayer from 'hooks/usePopupLayer';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useMemo } from 'react';
import pmStore from 'store/pmStore';
import s from 'styles/components/pmMain/PmBottom.module.scss';

const PmBottom = () => {
  const { pmLaunchStatus, paymentCard, fetchPaymentCard } = useUserInfo();
  const paymentLoaded = useMemo(
    () => pmLaunchStatus?.loaded || paymentCard?.loaded,
    [pmLaunchStatus?.loaded, paymentCard?.loaded]
  );
  const { activeItem } = usePmStore();

  const { checkCardUpdate } = usePmPayment();
  const { isRiding } = usePmUsingStatus();
  usePmLogger(LogPageId.MainPoiPop, !isRiding && !!activeItem);

  const layer = usePopupLayer(PopupLayerId.PM_CARD_GUIDE_ID);

  const handleClickCardRegistration = useCallback(() => {
    layer.hide();
    checkCardUpdate().then(() => {
      fetchPaymentCard();
    });
  }, [checkCardUpdate, layer]);

  const handClickOverlay = useCallback(() => {
    layer.hide();
  }, [layer]);

  useHandleBackKey(activeItem !== null, () => {
    pmStore.setActiveItem(null);
  });

  return (
    <div className={s.bottom}>
      {layer.visible ? (
        <PmCardGuide
          onClickConfirm={handleClickCardRegistration}
          onClickOverlay={handClickOverlay}
        />
      ) : (
        <>
          <CurrentPositionButton className={s.position} />
          <PmGuideButton className={s.guide} />
        </>
      )}
      {paymentLoaded && (
        <BottomSheet className={s.bottom_sheet}>
          <PmBottomNotice />
          <div
            className={classNames(s.contents_wrap, {
              [s.is_riding]: isRiding,
            })}
          >
            {!isRiding ? (
              <>
                {activeItem && <PmCardItem item={activeItem} />}
                <PmRentButton onClickRegister={handleClickCardRegistration} />
              </>
            ) : (
              <PmTripStatus />
            )}
          </div>
        </BottomSheet>
      )}
    </div>
  );
};

export default PmBottom;
