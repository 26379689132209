import { API_HOST } from 'constant/Path';
import { HttpResponse, http } from 'msw';
import { TPageMockCase } from 'tmsw/types/types';

import { MockInitApi } from '../api/init';
import { MockTripOrderInfo } from '../api/tripOrder/info';
import { MockDefaultPayMeanInfoApi } from '../api/user/defaultPayMeanInfo';

const returnPageMockCases: TPageMockCase = {
  returnSuccess: {
    description: '반납 성공 케이스',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi());
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(new MockDefaultPayMeanInfoApi({ isSuccess: true }));
      }),
      http.get(`${API_HOST}/api/tripOrder/*/info`, () => {
        return HttpResponse.json(new MockTripOrderInfo({ isSuccess: true }));
      }),
    ],
  },

  returnFail: {
    description: '반납 실패 케이스',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi());
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(new MockDefaultPayMeanInfoApi({ isSuccess: true }));
      }),
      http.get(`${API_HOST}/api/tripOrder/*/info`, () => {
        return HttpResponse.json(new MockTripOrderInfo({ isSuccess: false }));
      }),
    ],
  },

  orderPayMeanInfo: {
    description: '페이사 결제 실패시 나타나는 화면',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi());
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(new MockDefaultPayMeanInfoApi({ isSuccess: true }));
      }),
      http.get(`${API_HOST}/api/tripOrder/*/info`, () => {
        return HttpResponse.json(
          new MockTripOrderInfo(
            { isSuccess: false },
            {
              tripId: 1422,
              tripOrderId: 1425,
              orderId: 79240,
              status: 'PURCHASE_FAIL',
              companyCode: 'XINGXING',
              companyName: '씽씽',
              vehicleId: 'ZU3KG5',
              useInfo: [
                {
                  name: '이용일시',
                  value: '2024-03-20 (수) 16:12',
                },
                {
                  name: '이동거리',
                  value: '0.0km',
                },
                {
                  name: '이용시간',
                  value: '총 0분 32초',
                },
              ],
              feeInfo: [
                {
                  name: '잠금해제',
                  value: 1000,
                },
                {
                  name: '라이딩',
                  value: 0,
                },
                {
                  name: '반납 추가 비용',
                  value: 0,
                },
              ],
              discountInfo: [],
              amount: 1000,
              purchasePayMeanInfo: {
                status: null,
                statusName: null,
                payMeansDisplayName: null,
                payMeansDisplayNumber: '**66',
                paymentGroup: 'PAY_PAYMENT',
                payMeansImageInfo: {
                  paymentType: 'NAVER_PAY',
                  displayName: '네이버페이',
                  bgColor: '#00000000',
                  ciImageUrl: 'https://cdn.tmappay.com/payment/STG/NAVER_PAY_CI_1695172798170.svg',
                  symbolImageUrl:
                    'https://cdn.tmappay.com/payment/STG/NAVER_PAY_SYMBOL_1695172798170.svg',
                  ciBgColor: '#00000000',
                  symbolBgColor: '#00DE5AFF',
                },
              },
              orderPayMeanInfo: {
                status: null,
                statusName: null,
                payMeansDisplayName: 'KB국민카드',
                payMeansDisplayNumber: '**66',
                paymentGroup: 'PAY_PAYMENT',
                payMeansImageInfo: {
                  paymentType: 'NAVER_PAY',
                  displayName: '네이버페이',
                  bgColor: '#00000000',
                  ciImageUrl: 'https://cdn.tmappay.com/payment/STG/NAVER_PAY_CI_1695172798170.svg',
                  symbolImageUrl:
                    'https://cdn.tmappay.com/payment/STG/NAVER_PAY_SYMBOL_1695172798170.svg',
                  ciBgColor: '#00000000',
                  symbolBgColor: '#00DE5AFF',
                },
              },
              failMessage: '4005/[3028]해지된 카드입니다',
              pinRequired: false,
            }
          )
        );
      }),
    ],
  },
};

export default returnPageMockCases;
