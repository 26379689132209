import { useCallback, useRef, useState } from 'react';
import termRepository from 'repository/termRepository';
import { ECompanyType, EServiceUserOS, ETermsStatus, TTerm } from 'types/App';
import ua from 'utils/uaParser';

type TTermStatus = TTerm & {
  agree: boolean;
};

const useTerms = () => {
  const [termList, setTermList] = useState<TTermStatus[]>();
  const userOs = ua.isAndroid ? EServiceUserOS.ANDROID : EServiceUserOS.IOS;
  const refTermVersion = useRef('');

  const getCompanyTerms = useCallback(async (company: ECompanyType) => {
    const result = await termRepository.getCompanyTerms(company);
    setTermList(result.termList.map((it) => ({ ...it, agree: false })));
    refTermVersion.current = result.termVersion;
  }, []);

  const postCompanyTermAgree = useCallback(
    (company: ECompanyType, agrees: TTermStatus[], termVersion: string) => {
      const termAgrees = agrees.map((term) => ({
        code: term.code,
        status: term.agree ? ETermsStatus.AGREE : ETermsStatus.DISAGREE,
      }));

      return termRepository.postCompanyTermAgree(company, {
        agrees: termAgrees,
        userOs,
        termVersion,
      });
    },
    [userOs]
  );

  return {
    termList,
    termVersion: refTermVersion.current,
    setTermList,
    getCompanyTerms,
    postCompanyTermAgree,
  };
};

export default useTerms;
