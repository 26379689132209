import { Paths } from 'constant/RoutePath';
import qs from 'query-string';
import { useCallback } from 'react';
import { EGuideAnchor, EGuideTab } from 'types/App';
import { moveToPage } from 'utils/path';

import { usePmUsingStatus } from './usePmUsingStatus';

const useOpenPage = () => {
  const { isRiding } = usePmUsingStatus();

  const openGuidePage = useCallback(
    (param: { anchor?: EGuideAnchor; type?: EGuideTab } = {}) => {
      const query = qs.stringify({
        type: isRiding ? EGuideTab.USING : undefined,
        ...param,
      });

      moveToPage(Paths.PmGuide, query);
    },
    [isRiding]
  );

  return { openGuidePage };
};

export default useOpenPage;
