import Store from './base';

class BackKeyHandlerStore extends Store<Function[]> {
  constructor() {
    super([]);
  }

  addFunction = (func?: Function) => {
    if (func && !this.state.includes(func)) {
      this.setState([...this.state, func]);
    }
  };

  removeFunction = (func?: Function) => {
    this.setState(this.state.filter((it) => it !== func));
  };
}

const backKeyHandlerStore = new BackKeyHandlerStore();

export default backKeyHandlerStore;
