import classNames from 'classnames';
import Image from 'component/Image';
import VSMMarker from 'component/VSMMarker';
import { BATTERY_ACTIVE_ICON_MAP, COMPANY_ICON_MAP, VEHICLE_ICON } from 'constant/Scooter';
import usePmStore from 'hooks/usePmStore';
import { memo } from 'react';
import ImgMarkerSelectedBackground from 'resource/images/img-marker-selected-background.png';
import ImgMarkerShadow from 'resource/images/img-marker-shadow.png';
import { ReactComponent as IconTagDiscount } from 'resource/images/tag_discount.svg';
import s from 'styles/components/pmMain/PmMarker.module.scss';
import { getBatteryState } from 'utils/vehicle';

const PmSelectVehicleMarker = memo(() => {
  const { activeItem, activeCompany } = usePmStore();

  if (!activeItem) {
    return null;
  }

  const { company, vehicleType, battery, event } = activeItem;

  const batteryState = getBatteryState(battery);
  const iconCompany = COMPANY_ICON_MAP[company];
  const iconVehicle = VEHICLE_ICON[vehicleType];

  const iconBattery = BATTERY_ACTIVE_ICON_MAP[batteryState];

  return (
    <VSMMarker
      {...activeItem}
      anchor="bottom"
      className={classNames(s.is_active)}
    >
      <button className={s.icon}>
        <div className={classNames(s.icon, s.is_active)}>
          {!activeCompany && <i className={s.company}>{iconCompany}</i>}
          {event ? (
            <IconTagDiscount className={s.discount} />
          ) : (
            <i className={s.battery}>{iconBattery}</i>
          )}

          <div className={s.vehicle}>
            <i>{iconVehicle}</i>
            <div className={s.marker}>
              <Image
                src={ImgMarkerSelectedBackground}
                width={50}
              />
              <Image
                src={ImgMarkerShadow}
                className={s.shadow}
              />
            </div>
          </div>
        </div>
      </button>
    </VSMMarker>
  );
});

export default PmSelectVehicleMarker;
