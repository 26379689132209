import classNames from 'classnames';
import ImageViewer from 'component/ImageViewer';
import ModalPopup from 'component/ModalPopup';
import Skeleton, { ESkeletonType } from 'component/Skeleton';
import { EApiDataCode } from 'constant/Api';
import { PopupLayerId } from 'constant/App';
import { BATTERY_ACTIVE_ICON_MAP, COMPANY_NAME_MAP, VEHICLE_ICON } from 'constant/Scooter';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmVehicle from 'hooks/usePmVehicle';
import usePopupLayer from 'hooks/usePopupLayer';
import { useCallback, useEffect, useState } from 'react';
import vehicleRepository from 'repository/vehicleRepository';
import { ReactComponent as IconAlert } from 'resource/images/card-scooter-alert.svg';
import { ReactComponent as IconDiscount } from 'resource/images/card-scooter-discount.svg';
import s from 'styles/components/pmMain/PmCardItem.module.scss';
import { TVehicles } from 'types/api/Vehicle';
import { parseMinuteToHourMinute, setComma } from 'utils/formatter';
import { getBatteryState } from 'utils/vehicle';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

type TProps = {
  item: TVehicles;
};

const isValidNumber = (...args) => args.every((item) => !isNaN(parseInt(item)));

const PmCardItem = ({ item }: TProps) => {
  const [data, setData] = useState<TVehicles>({} as TVehicles);
  const [isImageError, setImageError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const pmCardLogger = usePmLogger(LogPageId.PmCard);
  const mainPoiPopLogger = usePmLogger(LogPageId.MainPoiPop);

  const modal = usePopupLayer(`${PopupLayerId.RING_DENY}_${item.vehicleId}`);
  const imageViewer = usePopupLayer(`${PopupLayerId.PM_IMAGE_VIEWER}_${item.vehicleId}`);

  const { getVehicleInfo } = usePmVehicle();

  const handleClickRing = useCallback(() => {
    pmCardLogger.sendClickLog('tap.startride');
    mainPoiPopLogger.sendClickLog('tap.sound');

    vehicleRepository
      .postRingVehicle(data)
      .then(() => {
        tmapWrapper.makeToast('벨 소리 울리는 중');
      })
      .catch((e) => {
        if (e.response.data.code === EApiDataCode.USER_NOT_EXIST) {
          modal.show();
          return;
        }

        tmapWrapper.makeToast('기기와 너무 멀리 떨어져 있습니다.');
      });
  }, [modal, data]);

  useEffect(() => {
    setLoaded(false);
    setImageError(false);

    getVehicleInfo(item.company, item.vehicleId)
      .then((v) => {
        setLoaded(true);
        setData({
          ...item,
          ...v,
          isAllowBeep: item.isAllowBeep || v.allowBeep,
          parkingImage: item.parkingImage || v.parkingImage,
        });
      })
      .catch(() => {
        setLoaded(true);
        setData(item);
      });
  }, [item.vehicleId, item.company, item.parkingImage, getVehicleInfo]);

  return (
    <>
      <Skeleton
        type={ESkeletonType.VEHICLE_CARD}
        loaded={loaded}
      >
        <div className={s.card_wrap}>
          <div className={s.desc_wrap}>
            <div className={s.title_wrap}>
              <h3 className={s.title}>{`${COMPANY_NAME_MAP[data.company]}#${data.vehicleId}`}</h3>
              {data.isAllowBeep && <IconAlert onClick={handleClickRing} />}
            </div>

            <div className={s.battery_wrap}>
              <div className={s.battery}>
                <span className={s.icon}>
                  {isValidNumber(data.battery)
                    ? BATTERY_ACTIVE_ICON_MAP[getBatteryState(data.battery)]
                    : ''}
                </span>
                <span className={s.text}>
                  {isValidNumber(data.battery) ? `${data.battery}%` : ''}
                </span>
              </div>

              {isValidNumber(data.availableTime) ? (
                <span className={s.time}>
                  {parseMinuteToHourMinute(data.availableTime as number)} 이용가능
                </span>
              ) : (
                ''
              )}
            </div>

            {data.event ? (
              <div className={s.price}>
                <i>
                  <IconDiscount />
                </i>
                <em>잠금해제 무료</em>&nbsp;+ 분당 {setComma(data.chargeFee) || 0}원
              </div>
            ) : (
              <div className={s.price}>
                {isValidNumber(data.unlockFee, data.chargeTime, data.chargeFee)
                  ? `잠금해제 ${setComma(data.unlockFee || 0)}원 + ${
                      data.chargeTime === 1 ? '' : data.chargeTime
                    } 분당 ${setComma(data.chargeFee) || 0}원`
                  : ''}
              </div>
            )}
          </div>

          {data.parkingImage && !isImageError ? (
            <div
              className={s.image_wrap}
              onClick={imageViewer.show}
            >
              <img
                src={data.parkingImage}
                alt="기기 이미지"
                className={s.item}
                onError={() => setImageError(true)}
              />
            </div>
          ) : (
            <div className={s.image_wrap}>
              <div className={classNames(s.item, s.is_show)}>{VEHICLE_ICON[data.vehicleType]}</div>
            </div>
          )}

          {imageViewer.visible && data.parkingImage && (
            <ImageViewer
              title="마지막 반납 사진"
              src={data.parkingImage}
              onClose={imageViewer.hide}
            />
          )}
        </div>
      </Skeleton>

      {modal.visible ? (
        <ModalPopup
          titleComponent={
            <>
              서비스 약관 동의 후 사용 가능한 기능입니다.
              <br />
              ‘대여하기’ 버튼을 눌러 정보 등록 및 약관 동의를 진행해 주세요.
            </>
          }
          onConfirm={() => modal.hide()}
        />
      ) : null}
    </>
  );
};

export default PmCardItem;
