import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import useHandleBackKey from 'hooks/useHandleBackKey';
import { usePmLogger } from 'hooks/usePmLogger';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import s from 'styles/components/LayerContext.module.scss';
import ua from 'utils/uaParser';

export enum EPosition {
  BOTTOM = 'bottom',
}

type TPopup = {
  key: string;
  level: number;
  position?: EPosition;
  useBackgroundClose?: boolean;
  logId?: string;
};

type TProps = {
  getOverlayElement: () => Element;
  pushPopup: (params: TPopup) => void;
  removePopup: (key: string) => void;
  visibleDimmed: boolean;
  currentKey: string;
};

const LayerContext = React.createContext<TProps>({
  getOverlayElement: () => document.body,
  pushPopup: (params: TPopup) => {},
  removePopup: (key: string) => {},
  visibleDimmed: false,
  currentKey: '',
});

export const useLayerContextConsumer = () => {
  return useContext(LayerContext);
};

export const LayerContextProvider = ({ children }) => {
  const refOverlay = useRef<HTMLDivElement>(null);
  const refTransitionEnd = useRef(false);
  const [componentKeys, setComponentKeys] = useState<TPopup[]>([]);

  const getOverlayElement = useCallback((): Element => {
    return refOverlay.current || document.body;
  }, []);

  const pushPopup = useCallback((params: TPopup) => {
    setComponentKeys((prev) => {
      if (prev.find((it) => it.key === params.key)) {
        return prev;
      }
      const newComponentKeys = [...prev, params].sort((a, b) => a.level - b.level);
      return newComponentKeys;
    });
  }, []);

  const currentPopup = useMemo(() => componentKeys[componentKeys.length - 1], [componentKeys]);
  const currentKey = currentPopup?.key;
  const currentBackgroundClose = currentPopup?.useBackgroundClose;
  const currentLogId = currentPopup?.logId;

  const { sendClickLog } = usePmLogger(currentLogId);

  const removePopup = useCallback(
    (key: string, force = false) => {
      if (force) {
        refTransitionEnd.current = false;
        setComponentKeys((prev) => prev.filter((it) => it.key !== key));
        return;
      }

      if (currentPopup?.key === key && currentPopup.position) {
        refOverlay.current?.classList.add(s[currentPopup.position]);
        refTransitionEnd.current = true;
        return;
      }

      setComponentKeys((prev) => prev.filter((it) => it.key !== key));
    },
    [currentPopup]
  );

  useHandleBackKey(ua.isInApp && ua.isAndroid && currentKey !== undefined, () => {
    removePopup(currentKey);
  });

  useEffect(() => {
    setTimeout(() => {
      if (refOverlay.current && currentPopup?.position) {
        refOverlay.current.classList.remove(s[currentPopup.position]);
      }
    }, 150);
  }, [currentPopup]);

  useEffect(() => {
    if (currentKey) {
      disableBodyScroll(document.body);
      refOverlay.current && disableBodyScroll(refOverlay.current, { allowTouchMove: () => true });
    } else {
      enableBodyScroll(document.body);
      refOverlay.current && enableBodyScroll(refOverlay.current);
    }
  }, [currentKey]);

  return (
    <LayerContext.Provider
      value={{
        getOverlayElement,
        pushPopup,
        removePopup,
        visibleDimmed: !!currentKey,
        currentKey,
      }}
    >
      {children}
      {!!currentKey && (
        <div
          style={{ opacity: 0.6, visibility: 'visible' }}
          className={s.dimmed}
        />
      )}
      <div
        className={classNames(s.overlay, s[currentPopup?.position || ''], {
          [s.hide]: !currentKey,
        })}
        onClick={() => {
          if (currentBackgroundClose) {
            removePopup(currentKey);
            if (currentLogId) {
              sendClickLog('tap.backscreen');
            }
          }
        }}
        ref={refOverlay}
        onTransitionEnd={() => {
          if (refTransitionEnd.current) {
            removePopup(currentKey, true);
          }
        }}
      />
    </LayerContext.Provider>
  );
};
