import { useCallback } from 'react';
import vehicleRepository from 'repository/vehicleRepository';
import { ECompanyType } from 'types/App';

const usePmVehicle = () => {
  const getVehicleInfo = useCallback(
    (company: ECompanyType, vehicleId: string, params?: Object) => {
      return vehicleRepository.getVehicleInfo(company, vehicleId, params);
    },
    []
  );

  return { getVehicleInfo };
};

export default usePmVehicle;
