export enum StorageKey {
  VISIT_PAY_RESULT = 'VISIT_PAY_TRIP_ID_RESULT',
  PM_EVENT_POPUP_MAP = 'SCOOTER_EVENT_POPUP_MAP',
  MAIN_POPOVER = 'MAIN_POPOVER',
  BIKE_RED_DOT = 'BIKE_RED_DOT',
  ACTIVE_POINT = 'ACTIVE_POINT',
  POINT_POPOVER = 'POINT_POPOVER',
  MAIN_EVENT_POPUP = 'MAIN_EVENT_POPUP',
  TMAP_DRIVER_EVENT = 'TMAP_DRIVER',
}
