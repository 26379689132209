import axios, { AxiosRequestConfig } from 'axios';
import { API_PATH } from 'constant/Api';
import { WEB_ACCESS_KEY } from 'constant/Env';
import { API_HOST } from 'constant/Path';
import { useCallback, useMemo, useState } from 'react';
import { EServiceUserOS } from 'types/App';
import ua from 'utils/uaParser';

import { useAccessKey } from './useAccessKey';

type TProps = {
  baseURL?: string;
};

const headers = {
  crossDomain: true,
  'Content-Type': 'application/json',
  UserOs: ua.isAndroid ? EServiceUserOS.ANDROID : EServiceUserOS.IOS,
};

const getWarning = () => Promise.reject('Call InitFetcher First');

const usePmApiFetcher = (props: TProps = {}) => {
  const accessKey = useAccessKey();
  const baseURL = useMemo(() => props.baseURL || API_HOST, [props.baseURL]);
  const [userKey, setUserKey] = useState();

  const fetcher = useMemo(() => {
    if (!userKey) {
      return;
    }
    const f = axios.create({
      baseURL,
      headers: {
        ...headers,
        userKey,
      },
    });

    f.interceptors.response.use((res) => res.data);

    return f;
  }, [baseURL, userKey]);

  const get = useCallback(
    (url, config?: AxiosRequestConfig) => {
      return fetcher?.get(url, config) || getWarning();
    },
    [fetcher]
  );

  const post = useCallback(
    (url, data?, config?: AxiosRequestConfig) => {
      return fetcher?.post(url, data, config) || getWarning();
    },
    [fetcher]
  );

  const initFetcher = useCallback(() => {
    if (!accessKey || accessKey === WEB_ACCESS_KEY) {
      return Promise.reject('');
    }

    return axios
      .get(`${baseURL}${API_PATH.GET_INIT}`, {
        headers: {
          ...headers,
          AccessKey: accessKey,
        },
      })
      .then((res) => {
        setUserKey(res.data.data.userKey);

        return res.data;
      });
  }, [baseURL, accessKey]);

  return {
    initFetcher,
    get,
    post,
  };
};

export default usePmApiFetcher;
