import { ECompanyType, TCompanyTermResponse } from 'types/App';
import { TTermAgreeParams } from 'types/api/Term';
import { getApi, postApi } from 'utils/fetcher';

class TermRepository {
  getCompanyTerms = (company: ECompanyType) => {
    return getApi<TCompanyTermResponse>(`/api/term/company/${company}/terms`);
  };

  postCompanyTermAgree = (company: ECompanyType, params: TTermAgreeParams) => {
    return postApi(`/api/term/company/${company}/termsAgree`, params);
  };
}

const termRepository = new TermRepository();

export default termRepository;
