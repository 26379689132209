import classNames from 'classnames';
import VSMMarker from 'component/VSMMarker';
import { getNextZoomLevel } from 'constant/Scooter';
import useMap from 'hooks/useMap';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmStore from 'hooks/usePmStore';
import { memo, useCallback, useMemo } from 'react';
import s from 'styles/components/pmMain/PmMarker.module.scss';
import { TVehicles } from 'types/api/Vehicle';

type TProps = {
  item: TVehicles;

  clickable?: boolean;
  onClick?: () => void;
  onInit?: () => void;
};

// https://tmobi.atlassian.net/browse/TMAPPM-417
const COUNT_INDEX_LIST = [1000, 500, 200, 100, 50, 20, 10];
const getCountText = (count: number): string => {
  const c = COUNT_INDEX_LIST.find((v) => count >= v);

  if (c === COUNT_INDEX_LIST[0]) {
    return '999+';
  }

  return c ? `${c}+` : `${count}`;
};

const PmClusterMarker = memo(({ clickable = true, onClick, item, onInit }: TProps) => {
  const { activeItem } = usePmStore();
  const { moveToCenter, getFixedZoomLevel } = useMap();
  const mainLogger = usePmLogger(LogPageId.Main);

  const isActiveItem = useMemo(() => activeItem?.vehicleId === item.vehicleId, [activeItem, item]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (clickable) {
        onClick?.();

        const nowZoomLevel = getFixedZoomLevel();
        const zoom = getNextZoomLevel(nowZoomLevel);

        mainLogger.sendClickLog('tap.clustering');
        moveToCenter({ ...item, zoom }, { speed: 1 });
      }
    },
    [clickable, onClick, getFixedZoomLevel, item, moveToCenter, mainLogger]
  );

  return (
    <VSMMarker
      {...item}
      anchor="bottom"
      className={classNames({
        [s.is_active]: isActiveItem,
      })}
      onClick={handleClick}
      onInit={onInit}
    >
      <button className={s.icon}>
        <div className={s.count_wrap}>
          <div className={s.circle}>
            <div className={s.number}>{getCountText(item.count)}</div>
          </div>
        </div>
      </button>
    </VSMMarker>
  );
});

export default PmClusterMarker;
