import classNames from 'classnames';
import PmCompanyItem from 'component/pmMain/PmCompanyItem';
import BottomSheet from 'component/tmds/bottomSheet/BottomSheet';
import { IS_NEW_GUIDE_EXPIRED } from 'constant/App';
import { VEHICLE_ICON, VEHICLE_TEXT } from 'constant/Scooter';
import { StorageKey } from 'constant/Storage';
import { VEHICLE_TYPE_MAP } from 'constant/Vehicle';
import { useLayerContextConsumer } from 'context/LayerContext';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmStore from 'hooks/usePmStore';
import useStorageStore from 'hooks/useStorageStore';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import s from 'styles/components/pmMain/PmCompanySelectLayer.module.scss';
import { ECompanyType, EVehicleType } from 'types/App';

const SELECT_LIST = [EVehicleType.ALL, EVehicleType.BIKE, EVehicleType.SCOOTER];

type TProps = {
  onClickCompany?: (type: EVehicleType, company?: ECompanyType) => void;
};

const vehicleLogKeyMap = {
  [EVehicleType.ALL]: 0,
  [EVehicleType.BIKE]: 1,
  [EVehicleType.SCOOTER]: 2,
};

const PmCompanySelectLayer = ({ onClickCompany }: TProps) => {
  const { pmLaunchStatus } = useUserInfo();
  const companies = useMemo(() => pmLaunchStatus?.data?.companies || [], [pmLaunchStatus]);

  const { activeCompany, activeType } = usePmStore();

  const { getOverlayElement } = useLayerContextConsumer();
  const refWrapper = useRef<HTMLDivElement>(null);
  const [selectedType, setSelectedType] = useState(EVehicleType.ALL);
  const [isNewVisible, setNewVisible] = useStorageStore(StorageKey.BIKE_RED_DOT, true);
  const mainFilterLogger = usePmLogger(LogPageId.MainFilter, true);

  const list = useMemo(() => {
    const vehicleList = VEHICLE_TYPE_MAP[selectedType].list;

    if (companies.length > 0) {
      return vehicleList.filter((v) => companies.some((c) => c.code === v));
    }

    return vehicleList;
  }, [selectedType, companies]);

  const handleClickType = useCallback(
    (e, type: EVehicleType) => {
      e.preventDefault();
      e.stopPropagation();

      setSelectedType(type);

      if (type === EVehicleType.BIKE) {
        setNewVisible(false);
      }

      mainFilterLogger.sendClickLog('tap.device', { index: vehicleLogKeyMap[type] });
    },
    [mainFilterLogger, setNewVisible]
  );

  useEffect(() => {
    setSelectedType(activeType);
  }, [activeType]);

  return createPortal(
    <div
      ref={refWrapper}
      className={s.wrap}
      onClick={(e) => {
        if (e.target === refWrapper.current) {
          mainFilterLogger.sendClickLog('tap.backscreen');
        }
      }}
    >
      <BottomSheet>
        <div className={s.contents_wrap}>
          <ul className={s.select_list}>
            {SELECT_LIST.map((v) => (
              <li
                key={v}
                className={classNames(s.item, {
                  [s.is_selected]: selectedType === v,
                })}
                onClick={(e) => handleClickType(e, v)}
                data-cy={v}
              >
                {!IS_NEW_GUIDE_EXPIRED && isNewVisible && v === EVehicleType.BIKE && (
                  <span className={s.new} />
                )}
                {VEHICLE_ICON[v] && <i className={s.icon}>{VEHICLE_ICON[v]}</i>}
                {VEHICLE_TEXT[v]}
              </li>
            ))}
          </ul>

          <ul className={s.company_list}>
            {VEHICLE_TYPE_MAP[selectedType].group && (
              <PmCompanyItem
                selectType={selectedType}
                company={undefined}
                groupList={list}
                isSelected={!activeCompany && selectedType === activeType}
                onClick={() => onClickCompany?.(selectedType, undefined)}
                text={[
                  selectedType === EVehicleType.ALL ? '' : VEHICLE_TEXT[selectedType],
                  '전체',
                ].join(' ')}
              />
            )}
            {list.map((v) => (
              <PmCompanyItem
                key={v}
                company={v}
                isSelected={activeCompany === v && selectedType === activeType}
                onClick={() => onClickCompany?.(selectedType, v)}
              />
            ))}
          </ul>
        </div>
      </BottomSheet>
    </div>,
    getOverlayElement()
  );
};

export default PmCompanySelectLayer;
