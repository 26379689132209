import { useSyncExternalStore } from 'react';
import mapStore from 'store/mapStore';

const useMapStore = () => {
  const map = useSyncExternalStore(mapStore.subscribe, mapStore.getState, mapStore.getState);

  return map;
};

export default useMapStore;
