import { LayerConfig } from '@vsm/vsm';
import { EMapType } from 'types/Map';

export const MAP_CONFIG_URL = {
  [EMapType.VECTOR]:
    'https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_JS.conf',
  [EMapType.RASTER]:
    'https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_RASTER_PUBLIC.conf',
};

const LAYER_ID = 1000;
const STACK_ID = 100;

export const VIEW_LEVEL_ALL = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
];

export const LAYER_TEMPLATE: LayerConfig = {
  id: LAYER_ID,
  name: '테스트주기',
  map: {
    format: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Point', // 필수
        coordinates: [], // 필수
      },
      properties: {
        maxLevel: '21',
        minLevel: '0',
        stackId: STACK_ID,
      },
    },
  },
  stacks: [
    {
      id: STACK_ID,
      code: 'CUSTOM_STACK',
      name: '커스텀 스택',
      geometryType: 'POINT', // 필수
      properties: {},
      viewLevels: [],
    },
  ],
};

export const DEFAULT_WGS84_LON_NUM = 126.985038139087;
export const DEFAULT_WGS84_LAT_NUM = 37.566490618017;

export const DEFAULT_MAX_BOUNDS = {
  west: 123.67610460093894, // minLon
  south: 31.788687936722795, // minLat
  east: 133.43682002411995, // maxLon
  north: 38.8154905286365, // maxLat
};

export const DEFAULT_MIN_ZOOM = 7;
export const DEFAULT_REQUEST_ZOOM = 11;
export const DEFAULT_MAX_ZOOM = 19;

export const DEFAULT_ZOOM = 15;

export const DEVICE_CLUSTER_LEVEL = 16;
export const VEHICLE_CLUSTER_LEVEL = 15;
export const NUMBER_CLUSTER_LEVEL = 14;
export const POLYGON_VISIBLE_LEVEL = 14;
