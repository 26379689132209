import { BATTERY_ICON_MAP, VEHICLE_ICON } from 'constant/Scooter';
import { renderToString } from 'react-dom/server';
// 지도에 회사 이미지를 baseData로 적용하는 경우 간헐적으로 로드를 못하는 버그가 있어서 svg 추가
import { ReactComponent as BiChipGcooter } from 'resource/images/bi_chip_gcoo.svg';
import { ReactComponent as BiChipTMap } from 'resource/images/bi_chip_tmap.svg';
import { ReactComponent as BiChipXing } from 'resource/images/bi_chip_xing.svg';
import { ReactComponent as MarkerBase } from 'resource/images/bubble.svg';
import { ReactComponent as IconTagDiscount } from 'resource/images/tag_discount.svg';
import { EBatteryState, ECompanyType, EVehicleType } from 'types/App';

export const getBatteryState = (battery) => {
  if (battery >= 70) {
    return EBatteryState.FULL;
  }

  if (battery >= 30) {
    return EBatteryState.NORMAL;
  }

  return EBatteryState.LOW;
};

const COMPANY_ICON_MAP = {
  [ECompanyType.TMAP]: <BiChipTMap />,
  [ECompanyType.XINGXING]: <BiChipXing />,
  [ECompanyType.GCOOTER]: <BiChipGcooter />,
};

export const getVehicleMarker = (params: {
  company: ECompanyType;
  battery: EBatteryState;
  event: boolean;
  vehicle: EVehicleType;
}) => {
  const baseString = renderToString(<MarkerBase />);

  const vehicleIcon = VEHICLE_ICON[params.vehicle] || null;
  const Vehicle = vehicleIcon ? renderToString(vehicleIcon) : '';
  const Company = renderToString(COMPANY_ICON_MAP[params.company]);
  const Battery = renderToString(BATTERY_ICON_MAP[params.battery]);
  const Discount = renderToString(<IconTagDiscount />);

  const rightTopString = params.event
    ? `<g transform="translate(20,0)">${Discount}</g>`
    : `<g transform="translate(24,2)">${Battery}</g>`;

  return baseString
    .replace('<g>{vehicle}</g>', `<g transform="translate(6,6)">${Vehicle}</g>`)
    .replace('<g>{company}</g>', `<g>${Company}</g>`)
    .replace('<g>{rightTop}</g>', rightTopString);
};
