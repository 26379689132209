import { EApiResponseCode } from 'constant/Api';
import { API_HOST } from 'constant/Path';
import { HttpResponse, http } from 'msw';
import { TPageMockCase } from 'tmsw/types/types';
import { ETripErrorCode, EVehicleType } from 'types/App';

import { MockVehicleItem } from '../api/company/vehicle';
import { MockInitApi, mockAllNeedTerms, mockNeedLicenseUserStatus } from '../api/init';

const codeMockCases: TPageMockCase = {
  noRegisterLicenseUser: {
    description: '면허가 등록이 되지 않은 유저',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
    ],
  },

  bikeWithLess10PercentBattery: {
    description: '배터리가 10보다 낮은 바이크 테스트',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem({ battery: 9 }));
      }),
    ],
  },

  bikeWithMoreThan10PercentBattery: {
    description: '배터리가 10보다 높은 바이크 테스트',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem({ battery: 20 }));
      }),
    ],
  },

  scooterWithMoreThan10PercentBattery: {
    description: '배터리가 10보다 높은 킥보드 테스트',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(
          new MockVehicleItem({ battery: 20, vehicleType: EVehicleType.SCOOTER })
        );
      }),
    ],
  },

  afterInputTermsTest: {
    description: '입력 완료 후 이용약관 관련 테스트',
    mocks: [
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem());
      }),
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(
          new MockInitApi({
            userStatus: mockNeedLicenseUserStatus,
            needCompanyTerm: mockAllNeedTerms,
          })
        );
      }),
    ],
  },
  prePurchaseFailInvalid: {
    description: '가승인 요청 실패 (잘못된 카드)',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem({ battery: 20 }));
      }),
      http.post(`${API_HOST}/api/trip/start`, () => {
        return HttpResponse.json({ code: 'INVALID_REFUND_AMOUNT' }, { status: 500 });
      }),
      http.post(`${API_HOST}/api/trip/orderId`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: {
            orderId: 1235,
          },
        });
      }),
    ],
  },
  prePurchaseFailEtc: {
    description: '가승인 요청 실패 (기타 에러)',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem({ battery: 20 }));
      }),
      http.post(`${API_HOST}/api/trip/start`, () => {
        return HttpResponse.json({ code: 'NETWORK_ERROR' }, { status: 500 });
      }),
      http.post(`${API_HOST}/api/trip/orderId`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: {
            orderId: 1235,
          },
        });
      }),
    ],
  },
  prePurchaseFailWithMessage: {
    description: '가승인 요청 실패 (unknown error 메세지)',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem({ battery: 20 }));
      }),
      http.post(`${API_HOST}/api/trip/start`, () => {
        return HttpResponse.json(
          { code: 'UNKNOWN_ERROR', message: '알 수 없는 오류' },
          { status: 500 }
        );
      }),
      http.post(`${API_HOST}/api/trip/orderId`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: {
            orderId: 1235,
          },
        });
      }),
    ],
  },

  rentError: {
    description: '기기 점검 중',
    mocks: [
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi({ userStatus: mockNeedLicenseUserStatus }));
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem({ battery: 20 }));
      }),
      http.post(`${API_HOST}/api/trip/start`, () => {
        return HttpResponse.json({ code: ETripErrorCode.TRIP_INSPECTION }, { status: 500 });
      }),
      http.post(`${API_HOST}/api/trip/orderId`, () => {
        return HttpResponse.json({
          code: EApiResponseCode.SUCCESS,
          data: {
            orderId: 1235,
          },
        });
      }),
    ],
  },
};

export default codeMockCases;
