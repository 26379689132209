import { WEB_ACCESS_KEY } from 'constant/Env';
import { useCallback, useSyncExternalStore } from 'react';
import userRepository from 'repository/userRepository';
import userInfoStore from 'store/userInfoStore';
import { EServiceUserOS } from 'types/App';
import { EUserStep, TPmStatus } from 'types/api/User';
import { setUserKey } from 'utils/datadog';
import { injectUserKey } from 'utils/fetcher';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const useUserInfo = () => {
  const state = useSyncExternalStore(userInfoStore.subscribe, userInfoStore.getState);

  const fetchAppLaunchStatus = useCallback(async (accessKey: string, userOs: EServiceUserOS) => {
    try {
      if (accessKey === WEB_ACCESS_KEY) {
        throw new Error('invalid user');
      }

      const userKey = await tmapWrapper.getUserKey();

      userInfoStore.setApiPmLaunchStatus(undefined, 'pending');

      const response = await userRepository.getInit({
        accessKey,
        userOs,
      });
      const responseData = response?.data.data;
      const pmUserKey = responseData.userKey;

      if (responseData && pmUserKey) {
        injectUserKey(pmUserKey);
        setUserKey(userKey);

        const needCompanyTerm = responseData.needCompanyTerm || {};

        let pmStatus: TPmStatus = {
          step: responseData.tripStatus ? EUserStep.RIDING : EUserStep.STAND_BY,
          tripInfo: responseData.tripStatus || undefined,
          rentInfo: undefined,
        };

        if (responseData.unpaidOrder) {
          const { tripId, tripOrderId } = responseData.unpaidOrder;
          pmStatus = {
            step: EUserStep.UNPAID,
            tripInfo: { tripId, tripOrderId },
          };
        }

        userInfoStore.updateState({
          pmUserKey: userKey,
          pmUserStatus: responseData.userStatus,
          pmTermsAgree: Object.keys(needCompanyTerm || {}).reduce((prev, cur) => {
            return {
              ...prev,
              [cur]: !needCompanyTerm[cur],
            };
          }, {}),
          pmStatus,
        });
        userInfoStore.setApiPmLaunchStatus(
          {
            ...responseData,
            userKey: userKey,
          },
          'fulfilled'
        );
      }
    } catch (e: any) {
      userInfoStore.setApiPmLaunchStatus(e, 'rejected');
    }
  }, []);

  const fetchPaymentCard = useCallback(async () => {
    try {
      userInfoStore.setApiPaymentCard(undefined, 'pending');
      const response = await userRepository.getPaymentCard();
      userInfoStore.setApiPaymentCard(response.data?.data, 'fulfilled');
    } catch (e: any) {
      userInfoStore.setApiPaymentCard(e, 'rejected');
    }
  }, []);

  return { ...state, fetchAppLaunchStatus, fetchPaymentCard };
};

export default useUserInfo;
