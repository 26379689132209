import { EServiceUserOS } from 'types/App';
import UAParser from 'ua-parser-js';
import { devLog } from 'utils/dev';

const parser = new UAParser();
const userAgent = navigator.userAgent;

parser.setUA(userAgent);

const appName = 'Tmap';
const userAgentLow = userAgent.toLowerCase();
const parserResult = parser.getResult();
const tmapIndex = userAgentLow.indexOf(appName.toLowerCase());
const tmapVersions =
  tmapIndex >= 0
    ? parserResult.ua.slice(tmapIndex).replace(/\/$/g, '').replace(/ /g, '').split('/')
    : [];

const isAndroid = parserResult.os.name === 'Android';

const tmap = {
  isInApp: tmapIndex >= 0,
  isSkIOS: userAgentLow.indexOf('sk/ios') >= 0,
  isKuIOS: userAgentLow.indexOf('ku/ios') >= 0,
  isIos: parserResult.os.name === 'iOS',
  isAndroid: isAndroid,
  tmapVersion: tmapVersions.length > 0 ? tmapVersions.slice(1).join('/') : '',
  tmapAppVersion: tmapVersions.length > 0 ? tmapVersions[tmapVersions.length - 1] : '',
  osVersion: parserResult.os.version,
  userOs: isAndroid ? EServiceUserOS.ANDROID : EServiceUserOS.IOS,
};

const ua = {
  ...parserResult,
  uaLow: userAgentLow,
  ...tmap,
};

devLog('[UA]', ua);

export default ua;

/**
 * 두개의 버전정보를 받아서
 * first가 크면 1을 반납
 * second가 크면 -1을 반납
 * 같으면 0을 반납
 */
const compareVersion = (first: string, second: string) => {
  const firstVersionList = first.split('.').map((it) => Number(it));
  const secondVersionList = second.split('.').map((it) => Number(it));
  const minLength = Math.min(firstVersionList.length, secondVersionList.length);

  for (let i = 0; i < minLength - 1; i++) {
    const firstNow = firstVersionList[i];
    const secondNow = secondVersionList[i];
    if (firstNow > secondNow) {
      return 1;
    }
    if (firstNow < secondNow) {
      return -1;
    }
  }

  if (firstVersionList.length === secondVersionList.length) {
    return 0;
  }

  return firstVersionList.length > secondVersionList.length ? 1 : -1;
};

export const getLessThanAppVersion = (version: string) => {
  if (!tmap.tmapAppVersion) {
    return false;
  }

  return compareVersion(tmap.tmapAppVersion, version) <= 0;
};
