import s from 'styles/components/pmMain/PmPriceMarker.module.scss';
import { setComma } from 'utils/formatter';

type TProps = {
  additionalPrice: number;
};

const PmPriceMarker = ({ additionalPrice }: TProps) => {
  return (
    <div className={s.marker_wrap}>
      <div className={s.round}>
        <span className={s.text}>💰추가금 {setComma(additionalPrice)}원</span>
      </div>

      <div className={s.bar_wrap}>
        <div className={s.bar} />
        <div className={s.shadow} />
      </div>
    </div>
  );
};

export default PmPriceMarker;
