import { StorageKey } from 'constant/Storage';
import { useCallback } from 'react';

import useStorageStore from './useStorageStore';
import useUserInfo from './useUserInfo';

const usePaymentPoint = () => {
  const { paymentCard } = useUserInfo();

  const [usingPoint, setUsingPoint] = useStorageStore(StorageKey.ACTIVE_POINT, false);

  const activePoint = useCallback(() => {
    setUsingPoint(true);
  }, [setUsingPoint]);

  const disabledPoint = useCallback(() => {
    setUsingPoint(false);
  }, [setUsingPoint]);

  return {
    pointInfo: paymentCard?.data?.pointInfo,
    isActive: usingPoint === true,
    activePoint,
    disabledPoint,
  };
};

export default usePaymentPoint;
