export const NETWORK_TIMEOUT = 30000;

export const API_VERSION_HEADER = {
  V2: 'application/vnd.tmappm.v2+json',
};

export enum EApiResponseCode {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL', // 미정
}

export enum EApiDataCode {
  OK = '100',
  API_ERROR = '400',
  API_ERROR2 = '800',
  SERVER_ERROR = '500',
  AUTHENTICATION = '401',
  AK_EXPIRATION = '397',
  GENERAL_FAIL = 'GENERAL_FAIL',
  USER_NOT_EXIST = 'USER_NOT_EXIST',
  FAIL = 'FAIL', // 미정
}

export const API_PATH = {
  GET_INIT: '/api/init',
  GET_USER_CHECK_FOR_TRIP: '/api/user/checkForTrip',
  GET_USER_KEY: '/api/user/key',
  GET_DEFAULT_CARD_INFO: '/api/user/defaultPayMeanInfo',

  POST_USER_LICENSE_VERIFY: '/api/user/licenseVerify',

  POST_TRIP_END: '/api/trip/{tripId}/end',
  POST_TRIP_PAUSE: '/api/trip/{tripId}/pause',
  POST_TRIP_RESUME: '/api/trip/{tripId}/resume',
  GET_TRIP_STATUS: '/api/trip/{tripId}/status',
  POST_TRIP_START: '/api/trip/start',
  POST_TRIP_ORDER_ID: '/api/trip/orderId',
  POST_UPLOAD_TRIP_IMAGE: '/api/trip/{tripId}/end/base64Image',

  GET_TRIP_ORDER_INFO: '/api/tripOrder/{tripOrderId}/info',
  POST_TRIP_ORDER_PURCHASE: '/api/tripOrder/{tripOrderId}/purchase',

  GET_VEHICLE_LIST: '/api/vehicles',
  POST_VEHICLE_RING: '/api/company/{company}/vehicle/{vehicleId}/ring',
  GET_VEHICLE_INFO: '/api/company/{company}/vehicle/{vehicleId}',
  GET_VEHICLE_QR: '/api/vehicle/qr',

  GET_COMPANY_TERMS: '/api/term/company/{company}/terms',
  POST_COMPANY_TERM_AGREE: '/api/term/company/{company}/termsAgree',

  GET_COMPANY_AREAS: '/api/company/{company}/areas',
  GET_COMPANY_AREA_INFO: '/api/company/{company}/pointInfo',

  GET_EVENT_DETAIL: '/api/eventPage/{eventPageId}',
};

export const PENDING_STATE = {
  loading: true,
  loaded: false,
};

export const FULFILLED_STATE = {
  loading: false,
  loaded: true,
  error: undefined,
};

export const REJECTED_STATE = {
  loading: false,
  loaded: true,
};
