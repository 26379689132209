import { ECompanyType, EPaymentStatus, EPaymentType, EVehicleType, TCardInfo } from 'types/App';

const successPaymentInfoMockData = {
  status: EPaymentStatus.OK,
  statusName: '정상',
  payMeansDisplayName: '하나카드',
  payMeansDisplayNumber: '**77',
  paymentGroup: 'CARD_PAYMENT',
  payMeansImageInfo: {
    paymentType: EPaymentType.CARD,
    displayName: '하나카드',
    bgColor: '#019591',
    ciImageUrl:
      'https://tmappay-resource.s3.ap-northeast-2.amazonaws.com/payment/STG/CARD_CI_1647841637910.svg',
    symbolBgColor:
      'https://tmappay-resource.s3.ap-northeast-2.amazonaws.com/payment/STG/CARD_SYMBOL_1647841637910.svg',
  },
  pointInfo: {
    availablePoint: 1500,
    pointPercent: {
      [ECompanyType.TMAP]: {
        [EVehicleType.BIKE]: 10,
      },
      [ECompanyType.XINGXING]: {
        [EVehicleType.SCOOTER]: 5,
      },
      [ECompanyType.GCOOTER]: {
        [EVehicleType.SCOOTER]: 5,
      },
    },
  },
};

export class MockDefaultPayMeanInfoApi {
  loaded = true;
  loading = false;
  data = {};
  constructor({ isSuccess = true }: { isSuccess: boolean }, replaceData: Partial<TCardInfo> = {}) {
    this.data = isSuccess
      ? successPaymentInfoMockData
      : {
          ...successPaymentInfoMockData,
          ...replaceData,
        };
  }
}
