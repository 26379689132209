import Vsm from '@vsm/vsm';
import classNames from 'classnames';
import useLocalMount from 'hooks/useLocalMount';
import useMapStore from 'hooks/useMapStore';
import { PropsWithChildren, memo, useEffect, useRef } from 'react';
import { TLonLat } from 'types/Map';

type TProps = PropsWithChildren<
  TLonLat & {
    className?: string;
    anchor?: 'bottom' | 'center';
    onClick?: (e) => void;
    onInit?: () => void;
  }
>;

const VSMMarker = memo(({ lat, lon, anchor, className, onClick, children, onInit }: TProps) => {
  const { map } = useMapStore();
  const refMarker = useRef<HTMLDivElement>(null);
  const marker = useRef<Vsm.Marker>();
  const isMounted = useLocalMount();

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (refMarker.current && map && !marker.current) {
      marker.current = new Vsm.Marker({
        element: refMarker.current,
        map,
        lngLat: {
          lat,
          lng: lon,
        },
      });

      onInit?.();
    }
  }, [map, isMounted]);

  useEffect(() => {
    return () => {
      marker.current?.destroy();
    };
  }, []);

  useEffect(() => {
    if (lat || lon) {
      marker.current?.setLngLat({ lat, lng: lon });
    }
  }, [lat, lon]);

  useEffect(() => {
    anchor && marker.current?.setAnchor(anchor);
  }, [anchor]);

  useEffect(() => {
    let nowEventTarget;
    let nowEventHandler;

    if (onClick && refMarker.current) {
      nowEventTarget = refMarker.current;
      nowEventHandler = onClick;

      nowEventTarget.addEventListener('click', nowEventHandler);
    }

    return () => {
      if (nowEventHandler && nowEventTarget) {
        nowEventTarget.removeEventListener('click', nowEventHandler);
      }
    };
  }, [onClick]);

  return (
    <div style={{ display: 'none' }}>
      <div
        ref={refMarker}
        className={classNames('vsm-marker', className)}
      >
        {children}
      </div>
    </div>
  );
});

export default VSMMarker;
