import classNames from 'classnames';
import { TMAP_HOMEPAGE_HOST } from 'constant/Path';
import { useCallback } from 'react';
import s from 'styles/components/terms/PmTermsDetail.module.scss';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const PmLocationInfo = () => {
  const handleClickInformationProcessing = useCallback(() => {
    tmapWrapper.openBrowser(`${TMAP_HOMEPAGE_HOST}/terms/ifr_pers_info_agree.html`);
  }, []);

  const handleClickLocationService = useCallback(() => {
    tmapWrapper.openBrowser(`${TMAP_HOMEPAGE_HOST}/terms/term_location.html`);
  }, []);

  return (
    <div className={s.content}>
      <h1>개인위치정보 이용동의</h1>
      <div>
        티맵모빌리티(주)가 개인위치정보를 이용하여 위치기반 서비스를 제공하는 것에 동의합니다.
      </div>
      <section className={classNames(s.vertical_table, s.description)}>
        <div>
          <h3 className={s.table_title}>서비스 내용 및 이용 목적</h3>
          <p>
            TMAP 바이크 서비스 제공
            <section className={s.list}>
              <section>- TMAP 바이크 이용 가능 지역 안내</section>
              <section>- 회원의 TMAP 바이크 이용 및 반납 내역 확인</section>
              <section>- 고객 VOC 응대(사고, 고장, 보험 등)</section>
            </section>
          </p>
        </div>
        <div>
          <h3 className={s.table_title}>이용 항목</h3>
          <p>회원의 개인위치정보</p>
        </div>
        <div>
          <h3 className={classNames(s.table_title, s.accent)}>보유 및 이용기간</h3>
          <p className={s.accent}>위치기반서비스 이용약관 기재된 바에 따름</p>
        </div>
      </section>
      <div>
        <p>서비스 이용에 필요한 최소한의 개인정보로 동의 거부시 서비스 이용이 제한됩니다.</p>
        <p>
          더 자세한 내용에 대해서는
          <span
            className={s.link}
            onClick={handleClickInformationProcessing}
          >
            개인정보처리방침
          </span>
          ,
          <span
            className={s.link}
            onClick={handleClickLocationService}
          >
            위치기반서비스이용약관
          </span>
          을 참고하시기 바랍니다.
        </p>
      </div>
    </div>
  );
};

export default PmLocationInfo;
