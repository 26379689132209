import usePopupLayer from 'hooks/usePopupLayer';
import { createPortal } from 'react-dom';

const PortalDimmed = ({ title, id }) => {
  const { root, hide } = usePopupLayer(id);

  return createPortal(
    <div style={{ top: 0, color: '#fff', left: 0, position: 'fixed' }}>
      <h1>{title}</h1>
      <button
        style={{ color: 'inherit' }}
        onClick={() => {
          hide();
        }}
      >
        닫기
      </button>
    </div>,
    root as any
  );
};

const ProtoPopupPage = () => {
  const dimmed1 = usePopupLayer('1');
  const dimmed2 = usePopupLayer('2');

  return (
    <div>
      <h1>팝업 테스트 페이지</h1>
      <button
        onClick={() => {
          dimmed1.show();
        }}
      >
        화면1 열기
      </button>
      <br />
      <button
        onClick={() => {
          dimmed2.show();
        }}
      >
        화면2 열기
      </button>
      {dimmed1.visible && (
        <PortalDimmed
          title="화면1"
          id="1"
        />
      )}
      {dimmed2.visible && (
        <PortalDimmed
          title="화면2"
          id="2"
        />
      )}
    </div>
  );
};

export default ProtoPopupPage;
