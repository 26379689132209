import s from 'styles/components/terms/PmTermsDetail.module.scss';

export const PmTermsXing = () => {
  return (
    <div className={s.content}>
      <h1>제 1 조 (목적)</h1>
      <div>
        본 약관은 주식회사 피유엠피(이하 “회사”)가 티맵모빌리티(이하 “제휴사업자”)에 제공하는
        XINGXING(씽씽) 서비스의 이용과 관련하여 회사와 회원의 권리, 의무, 책임사항 및 기타 필요한
        사항을 규정함을 목적으로 합니다.
      </div>
      <h1>제 2 조 (정의)</h1>
      <div>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</div>
      <ul>
        <ol>
          1. “XINGXING(씽씽)”(이하 “서비스”)이라 함은 애플리케이션(이하 “앱”이라 합니다)을 통해
          “이동 수단 공유 서비스”를 제공하는 서비스로 이와 관련한 각종 부가서비스 및 제휴서비스
          일체를 포함합니다.
        </ol>
        <ol>
          2. “회원”이라 함은 본 약관에 따라 제휴사업자를 통해 회사와 이용계약을 체결하고 회사가
          제공하는 서비스를 이용하는 고객을 말합니다.
        </ol>
        <ol>
          3. “이동 수단 공유 서비스” 이라 함은 회원이 회사가 소유한 이동 수단(전동킥보드,
          전기자전거)을앱을 통하여 이용 요금을 결제하고 이용하는 것을 말합니다.
        </ol>
        <ol>
          4. “전동킥보드”라 함은 전동 관련 장치를 달아서 전기의 힘으로 달리는 스케이트보드에 긴
          손잡이가 달린 모양의 이동수단을 말합니다.
        </ol>
        <ol>
          5. “전기자전거”라 함은 자전거로서 사람의 힘을 보충하기 위하여 전동기를 장착하고 페달과
          동력으로 이동하는 수단을 말합니다.
        </ol>
        <ol>
          6. “이용 요금”이라 함은 씽씽 서비스 앱을 통해 잠금 해제(unlock) 한 시점부터 다시
          잠금(lock)한 시점까지의 이용 시간에 대한 요금을 말합니다.
        </ol>
        <ol>
          7. “반납 요금”이라 함은 대여한 이동수단의 반납 위치에 따라 추가되는 요금을 말합니다.
        </ol>
        <ol>
          8. “자동결제”라 함은 최초 이용 시 신용카드 등의 결제수단정보를 입력 후 이후 경비가 결제될
          때마다 별도의 인증과정이 없이 자동으로 결제되는 것을 말합니다.
        </ol>
        <ol>
          9. “간편결제” 이라 함은 “회원”이 카드 등 결제수단 정보를 매번 입력할 필요없이 관련 정보에
          대한 한번의 등록 만으로 결제를 가능하게끔 하는 서비스를 말합니다.
        </ol>
        <ol>
          10. “운행정보 수집장치”라 함은 전동킥보드의 무인 대여 및 반납 기능, 위치, 총 누적주행거리,
          이용시간, 도난 및 파손 예방 등 이동 수단의 운행 정보를 “회사”의 서버로 전송할 수 있는 통신
          단말기를 말합니다.
        </ol>
        <ol>
          11. “제휴 업체”라 함은 외부 채널에서 씽씽의 서비스를 대여하고 반납할 수 있도록 서비스 제휴
          계약을 맺은 업체를 말합니다.
        </ol>
      </ul>
      <div>
        본 약관에서 사용하는 용어의 정의는 제2조에서 정하는 것을 제외하고는 관련법령, 상관례 및
        이용정책에서 정하는 바에 의합니다.
      </div>
      <h1>제 3 조 (약관의 효력 및 변경)</h1>
      <ul>
        <ol> 1. 본 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.</ol>
        <ol>
          2. 본 약관의 내용을 회원이 쉽게 알 수 있도록 회사가 본 약관을 제휴사업자의 서비스 화면에
          게시하거나 기타의 방법으로 회원에게 공지함으로써, 본 약관은 효력이 발생합니다.
        </ol>
        <ol>
          3. 회사는 「 약관의 규제에 관한 법률 」, 「 정보통신망 이용촉진 및 정보보호 등에 관한 법률
          」 (이하“정보통신 망법”)등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수
          있습니다. 약관이 개정된 경우 회사는 지체 없이 개정 약관을 현행 약관과 함께 이메일 또는
          제2항과 같은 방법으로 개정 약관의 적용일로부터 7일 전에 공지 또는 통지합니다. 다만, 회원의
          권리 또는 의무에 관한 중요한 규정이 변경되거나 회원에게 불리한 경우에는 그 적용일자 30일
          전부터 적용일 이후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 회원에게 전자적
          형태(전자우편, SMS, 앱 알림 등)로 약관 개정 사실을 발송하여 고지합니다.
        </ol>
        <ol>
          4. 회사가 전항에 따라 개정약관을 명확하게 공지 또는 통지하면서 회원에게 개정약관
          적용일자까지 거부의사를 표시하지 아니할 경우 약관의 변경에 동의한 것으로 간주한다는 뜻을
          명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우
          회원이 개정약관에 동의한 것으로 봅니다.
        </ol>
        <ol>
          5. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며,
          이 경우 회사 또는 회원은 이용계약을 해지할 수 있습니다.
        </ol>
        <h1>제 4 조 (회원가입 및 이용계약의 성립)</h1>
        <ol>
          1. 이 서비스의 회원이 되고자 하는 자는 본 약관 및 제휴사업자 별도의 「 개인정보
          수집ㆍ이용에 관한 동의 」, 「개인정보 제3자 제공에 관한 동의」, 「위치정보동의」에 대하여
          동의 절차를 완료함으로써 회원가입신청을 하고 회사가 이러한 신청에 대하여 승인함으로써
          이용계약이 체결됩니다.
        </ol>
        <ol>
          2. 회사는 회원이 되고자 하는 자의 가입신청에 대하여 서비스 이용 승인을 원칙으로 합니다.
          다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승인하지 않을 수 있습니다.
          <p>2.1. 가입 신청자가 신청일 기준으로 만 16세 미만인 경우 </p>
          <p>2.2. 회원 가입 신청 시 입력한 정보가 사실관계와 다르거나 정확하지 않은 경우 </p>
          <p>2.3. 서비스 이용에 필수적인 정보 또는 자격이 충분하지 않은 경우 </p>
          <p>
            2.4. 가입 신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단, 회사의
            회원 재가입 승인을 얻은 경우에는 예외로 함.
          </p>
          <p>
            2.5. 회원 가입 신청자의 귀책사유로 인하여 회사의 승인이 불가능하거나 기타 규정한 제반
            사항을 위반하며 신 청하는 경우
          </p>
        </ol>
        <ol>
          3. 회사는 가입 신청 시 입력한 정보가 허위로 판명된 경우, 승인을 철회 또는 이용을 제한할
          수있습니다.
        </ol>
        <ol>
          4. 제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및
          본인인증을 요청할 수 있 습니다.
        </ol>
        <ol>
          5. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는
          승인을유보할 수 있습 니다.
        </ol>
        <ol>
          6. 회사는 회원가입 신청자에 대해 회사 정책에 따라 등급별로 구분하여 이용 요금, 서비스 메뉴
          등을 세분하여 이용에 차등을 둘 수 있습니다.
        </ol>
      </ul>
      <h1>제 5조 (이동 수단 정보 수집장치)</h1>
      <div>
        회사는 효율적인 서비스 운영을 위하여 모든 이동 수단에 위치정보 수집장치를 설치하였으며
        그목적은 다음과 같습니다.
      </div>
      <ul>
        <ol>
          1. 운행정보 수집장치를 통해 수집된 데이터는 사업자 자산관리 및 보호, 이동 수단 대여 및
          반납여부 확인, 주행거리 확인, 이용시간 확인에 사용할 수 있습니다.
        </ol>
        <ol>
          2. 회사는 회원의 이동 수단 미반환 등 계약 위반시의 이동 수단 회수, 이동 수단 공유서비스의
          이행 또는 분쟁 해결 등을 위한 경우, 수사기관 등의 적법한 조사요구 또는 명령 등에 대한 협조
          및 이행을 위한 경우, 그 외에 회원이 별도로 위치정보 수집, 이용, 제공에 동의한 경우 위 목적
          범위 내에서 이동수단의 위치정보를 수집, 이용, 제공할 수 있습니다.
        </ol>
        <ol>
          3. 위치정보의 보호 및 이용 등에 관한 법률 제 15조에 따라 급박한 위험으로부터 긴급구조
          요청이 있는 경우에는 회원의 동의 없이도 개인의 위치정보가 긴급구조기관에게 제공될 수
          있습니다.
        </ol>
      </ul>
      <h1>제 6 조 (약관 외 준칙)</h1>
      <ul>
        <ol>
          1. 회사는 필요한 경우 이용정책을 정하여 운영할 수 있으며 해당 내용은 씽씽 앱, 홈페이지 및
          제휴사업자의 서비스를 통해 공지 합니다.
        </ol>
        <ol>
          2. 본 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 이용정책에서 정한 바에 따릅니다.
        </ol>
        <ol>
          3. 회원은 항상 이용정책의 내용에 변경이 있는지 여부를 확인하여야 하며, 이를 확인하지 않아
          발생한 손해에 대 해서는 회사는 책임을 부담하지 않습니다. 단, 이용정책 변경이 회원의 권리
          또는 의무에 중대한 영향을 미치는 변경이어서 개별통지가 필요한 내용이거나 회원이 변경내용을
          확인하지 못한 것에 대한 회사의 귀책사유가 있는 경우는 예외로 합니다.
        </ol>
        <h1> 제 7 조 (서비스 이용제한 등)</h1>
        <ol>
          <p>
            1. 회사는 다음 각 호에 해당하는 경우 회원에 대하여 서비스 제공을 제한하거나 중지할 수
            있습니다.
          </p>
          <p>1.1. 회사와 회원 간에 서비스 제공과 관련된 약정이 종료되는 경우 </p>
          <p>
            1.2. 회원이 본 약관의 의무를 위반하거나 본 약관 제17조 제14항에 명시된 이용제한행위 또는
            이에 준하여 서비스의 정상적인 운영을 심각하게 방해한 경우
          </p>
          <p>1.3. 회원이 타인의 명의를 도용하여 회원가입을 한 경우 </p>
          <p>
            1.4. 유료결제를 함에 있어서 타인 명의 결제 도용, 전화번호 도용 등 불법적인 결제를 한
            경우
          </p>
          <p>
            1.5. 불법프로그램의 제공 및 운영 방해, 정보통신망법을 위반한 불법 통신 및 해킹,
            악성프로그램의 배포, 접속 권한 초과 행위 등과 같이 회원이 관련법령을 위반한 경우
          </p>
          <p>1.5.1. 회원이 계속해서 1년 이상 로그인하지 않는 경우</p>
        </ol>
        <ol>
          2. 본 조 제1항에 따라 서비스 이용이 제한되는 경우 회원이 본 서비스 이용과 관련하여 획득한
          혜택과 보유한 권리 등도 제한되는 서비스 범위 안에서 동일하게 제한되며, 서비스 이용이
          중지되는 경우엔 서비스 이용과 관련하여 획득한 혜택 및 권리 등도 중지됩니다. 단,
          제휴사업자를 통해 유료 서비스를 구매한 경우, 제휴 업체의 환불 정책을 따릅니다.
        </ol>
        <ol>
          3. 본 조 제1항에 따라 서비스 이용이 제한 또는 중지된 회원에 대해서 회사는 회원에게
          통지로써 회원자격을 상실 또는 1년 동안 정지시킬 수 있습니다.
        </ol>
        <ol>
          4. 회사는 1년 365일 서비스를 제공하기 위해 모든 노력을 기울이지만 불가항력 또는 기타
          사정으로 인해 서비스를 제공하지 못할 수도 있으므로 서비스가 항상 제공될 것이라고
          보장하지는 않습니다. 특히 다음 각 호의 경우 당해 서비스의 전부 또는 일부의 제공을
          중단하거나 제한하고 기기의 반환을 요구할 수 있습니다.
          <p>
            4.1. 천재지변, 전쟁, 폭동, 화재, 파업 등 쟁의행위, 정부기관의 통제 기타 회사의 합리적인
            노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는 경우
          </p>
          <p>4.2. 기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우 </p>
          <p>
            4.3. 서비스용 설비의 장애 및 정기 점검, 보수점검, 교체 또는 고장, 통신의 두절 등의
            사유가 발생한 경우
          </p>
          <p>
            4.4. 서비스가 제3자와의 제휴를 통하여 제공되는 경우에 당해 제휴사업자의 사정에 따라
            변경되거나 중지되는 경우
          </p>
          <p>4.5. 기타 서비스의 원활한 운영을 현저히 저해하는 사유가 발생한 경우</p>
        </ol>
        <ol>
          5. 본 조에 따라 서비스 내용이 변경 또는 중단되는 경우, 회사는 그로 인해 회원이 입은 손해에
          대하여 고의 또는 과실이 없는 한 배상하지 않습니다.
        </ol>
        <ol>
          6. 본 조에 따라 서비스 이용을 제한하거나 중지하는 경우에는 회사는 사전에 이를 회원에게
          개별 통지합니다. 다만, 부득이한 경우 사후에 통지합니다.
        </ol>
      </ul>
      <h1>제 8 조 (서비스 제공)</h1>
      <ul>
        <ol>
          1. 회사가 제공하는 서비스의 내용은 다음과 같습니다.
          <p>
            1.1. 위치기반 서비스 : 회원의 위치정보를 활용하여 이동 수단의 위치 정보를 제공하는
            서비스
          </p>
          <p>
            1.2. 이동 수단 공유 서비스 : 회사 소유의 씽씽 서비스 앱을 통하여 이동 수단을 이용할 수
            있는서비스
          </p>
          <p>1.2.1. 전동킥보드 공유 서비스 </p>
          <p>1.2.2. 전기자전거 공유 서비스</p>
        </ol>
        <ol>
          2. 회사는 제 1항의 각 호의 서비스 이외에도 추가적인 서비스를 개발하여 회원에게 제공할
          수있습니다.
        </ol>
        <ol>
          3. 서비스 내용은 사고 발생 시 또는 과태료 및 범칙금 발생 시 해당 관할기관의 의견진술용
          등으로 사용할 수 있습니다.
        </ol>
        <ol>
          4. 회사가 제공하는 서비스와 앱의 사용은 무료입니다. 단, 회사가 제공한 서비스를 통해 이동
          수단을 이용할 경우 제10조에 따라 각 이동 수단에 해당하는 이용요금을 지불할 의무를
          가집니다.
        </ol>
      </ul>
      <h1>제 9 조 (이동 수단 이용요금 등)</h1>
      <ul>
        <ol>
          1. 회원이 회사가 제공한 서비스를 통해 이동 수단을 이용하는 경우, 회사는 제휴사업자를 통해
          회원에게 해당 이용 요금을 청구합니다
        </ol>
        <ol>
          2. 요금은 곧 “이용 요금”을 말하며 회원 가입 완료 후에만 이동 수단 이용이 가능합니다.
        </ol>
        <ol>
          3. 회사는 이용 요금을 제휴사업자의 앱을 통해 회원에게 통지합니다. 이동 수단은 운행이 끝날
          때 앱을 통하여 반납 및 잠금(lock)을 완료하여야 합니다. 이동 수단이 반납되지 않으면 운행이
          계속되고 이용 요금이 계속 과금됩니다.
        </ol>
        <ol>
          4. 경우에 따라 이용 요금 산정 기준은 변경될 수 있으며, 회사는 이를 약관 변경공지와 같은
          방식으로 공지합니다.
        </ol>
        <ol> 5. 제휴사업자의 회원이 탈퇴 요청을 할 시 제휴사업자의 환불 정책을 따릅니다.</ol>
        <ol>
          6. 서비스와 관련하여 발생한 미납요금, 손해배상 등 회원의 회사 및 제휴사업자에 대한 채무가
          모두 정산될 때까지, 해당 회원의 서비스 사용이 중지되며, 그 채무가 일정기간 계속하여
          변제되지 않을 경우에는 해당 회원의 회원자격이 박탈될 수 있습니다. 서비스 사용 중지 또는
          회원자격 박탈에 대한 판단 기준은 회사의 약관 및 이용정책에 근거합니다.
        </ol>
        <ol>
          7. 회원이 본 약관 및 위 각 조항을 준수하지 않았을 경우, 회사 및 제휴사업자는 본 약관 및
          이용정책에 따라 손해배상 금액 등 각종 요금을 부과하는 것 외에 계약을 해지할 수 있는 권리를
          가집니다.
        </ol>
        <ol>
          8. 회사 및 제휴사업자는 본 서비스와 관련하여 발생한 미납요금, 손해배상 등 회원의 회사에
          대한 채무가 정산되지 않을 경우 회원을 상대로 보전처분, 본안소송제기, 강제집행절차의 착수
          등 채무 변제를 위한 법적 조치에 들어갈 수 있 습니다.
        </ol>
      </ul>
      <h1>제 10 조 (환불)</h1>
      <ul>
        <ol>
          1. 시스템 오류 등 회원의 귀책사유 없이 초과결제 또는 중복결제 등의 문제가 발생하였을 경우,
          회사 및 제휴사업자는 해당 이용 요금을 회원에게 환불합니다. 단, 회원에게 귀책사유가 있는
          경우 요금의 일부만 환불할 수 있습니다.
        </ol>
        <ol>
          2. 제1항의 환불을 하는 경우 회사는 회원이 지불한 결제수단과 동일한 방법으로 환불합니다.
          다만, 동일한 방법으로 환불이 불가능할 경우에는 현금 등 다른 수단으로 환불할 수 있습니다.
        </ol>
      </ul>
      <h1>제 11 조 (서비스 이용)</h1>
      <ul>
        <ol>
          1. 회원이 서비스를 통하여 이동 수단을 이용함에 있어서는 본 약관에 규정된 내용은 물론,
          도로법 및 도로교통법, 자전거법, 이와 관련된 각 시행령·시행규칙, 각 지방자치단체마다
          개별적으로 규정된 전동킥보드 이용에 관한 조례 등 일체의 관련 법령 및 자치법규에 규정된
          내용을 모두 준수하여야 합니다.
        </ol>
        <ol>
          2. 서비스 이용 시 고의 내지 과실로 인하여 본조 제1항에 규정된 본 약관 내용 및 관련 법령,
          자치법규를 위반하는 회원은 본 약관상 규정된 불이익 내지 관련 법령 및 자치법규상 규정된
          불이익을 받을 수 있으며 서비스 이용 중 회원 자신의 위법 행위로 인하여 부과 받은 벌금 및
          과태 등은 회원 본인이 직접 부담하여야 합니다.
        </ol>
        <ol>
          3. 회원은 이동 수단 운행 전 헬멧 등의 안전장비를 착용함으로써 스스로를 보호하기 위한
          모든합리적인 주의를 기울여야 하며 안전 장비를 착용하지 않음으로써 발생하는 모든 문제에
          대해서 회사 및 제휴사업자는 책임지지 않습니다.
        </ol>
        <ol>
          4. 이동 수단과 단말기 등 모든 장비가 회사의 재산입니다. 어떠한 방법으로도 전동킥보드,
          전동킥보드의 부품, 단말기, 전기자전거, 전기자전거의 부품 및 단말기 또는 기타 회사의 장비를
          분해, 수리, 개조, 손상시켜서는 안됩니다.
        </ol>
        <ol>
          5. 광고 또는 이와 유사한 상업적 목적을 위해 이동 수단을 이용하여서는 안됩니다. 단, 회사와
          별도의 계약이나 서면 승인이 이는 경우 상업적으로 이용할 수 있습니다.
        </ol>
        <ol> 6. 회사는 이동 수단을 이용하기 위한 장소를 제공하지 않습니다.</ol>
        <ol>
          7. 이동 수단 운행은 지정된 지역 내에서만 가능합니다. 지정된 지역을 벗어났을시 이용 정지,
          추가 요금 등의 불이익이 발생할 수 있습니다.
        </ol>
        <ol>
          8. 회원은 다음 각 호의 행위를 하여서는 안됩니다.
          <p>8.1. 이동 수단 운행 중 휴대폰 사용, 이어폰 착용 등의 안전을 방해하는 행위</p>
          <p>8.2. 약물에 취하거나 음주인 상태로 이용하는 행위</p>
          <p>8.3. 이동 수단에 2명 이상이 탑승하는 행위 </p>
          <p>
            8.4. 이동 수단의 대여(잠금해제) 또는 반납(잠금)을 할 때 회사가 제공하는 앱을 통해서만
            하여야하며 다른 잠금장치를 사용하거나 다른 물체에 고정 시키는 행위
          </p>
          <p>8.5. 이동 수단을 개인 소유물이나 폐쇄된 장소 또는 기타 비공개 장소에 주차하는 행위 </p>
          <p>
            8.6. 이동 수단을 레이싱, 상업 목적 등 개인 이동의 목적 이외의 목적으로 이용하는 행위
          </p>
          <p>
            8.7. 비포장 도로, 운행이 금지된 장소, 불법인 장소, 타인에게 불쾌함이나 피해를 주는
            장소에서이용하는 행위
          </p>
          <p>8.8. 이동 수단을 한 손으로 운전하는 행위 </p>
          <p>8.9. 점프, 묘기, 산악 등반 등의 무리한 운행을 하는 행위 </p>
          <p>8.10. 물 위를 운행하는 행위(기기 고장, 감전 등의 안전을 위함) </p>
          <p>8.11. 헬멧 등 보호장구를 착용하지 않고 이동 수단을 운행하는 행위</p>
        </ol>

        <ol>
          9. 전동킥보드, 전기자전거의 최대 하중은 100kg이기 때문에 그 이상을 적재하여서는 안됩니다.
        </ol>
        <ol>
          10. 이동 수단은 배터리를 주기적으로 충전해야 하며 회원은 안전하고 신중하게 이용하여야
          하며다음의 사항들을 숙지 및 준수 하여야 합니다.
          <p>
            10.1. 전동킥보드의 배터리양이 감소함에 따라 속도 및 기타 작동 능력이 저하 될 수
            있습니다.
          </p>
          <p>
            10.2. 전동킥보드 운행 중 배터리가 일정량 이하로 감소하였을때 안전을 위하여 운행을
            정지해야 합니다.
          </p>
          <p>10.3. 회원이 이동 수단을 대여하거나 운행할 때 배터리양은 보장되지 않습니다. </p>
          <p>
            10.4. 이동 수단 사용 중 배터리양 감소 비율은 보장되지 않으며 기계, 도로 상태, 기상 조건
            및 기타 요인에 따라 달라질 수 있습니다.
          </p>
          <p>10.5. 회원은 이동 수단의 배터리양을 확인하고 탑승해야 합니다.</p>
          <p>
            10.6. 이동 수단의 배터리양과 관련하여 운행 할 수있는 거리 또는 시간은 보장되지 않습니다.
          </p>
          <p>
            10.7. 회원이 원하는 목적지에 도달하기 전에 이동 수단의 배터리양이 모두 소진되어 작동이
            멈출수 있습니다.
          </p>
        </ol>
        <ol>
          11. 회사 및 제휴사업자는 다음의 경우에 도난 또는 분실로 간주할 수 있으며 도난 또는 분실시
          회원은 최대한 빠른 시간 내에 회사 및 제휴사업자에 신고하여야 합니다. 이동 수단이 분실 또는
          도난되었을 때, 해당 이동 수단을 마지막으로 이용한 회원은 본인의 책임이 아닌 이유를
          합리적이고 타당하게 말해야 하며 그럼에도 불구하고 분실, 도난의 책임이 마지막으로 이용한
          회원에게 있다고 판단되는 경우 회원은 모든 책임을 부담하여야 합니다. 이 때 회사 및
          제휴사업자는 적절하다고 판단되는 모든 조치를 취할 권한을 가집니다.
          <p>11.1. 24시간 이내에 이동 수단이 반납되지 않은 경우</p>
          <p>
            11.2. 이동 수단에 부착된 단말기의 GPS 기능이 작동하지 않는 경우. 단, GPS 기능이
            오작동인경우는 제외한다.
          </p>
          <p>11.3. 이동 수단이 개인 소유물, 폐쇄된 장소, 제3자와 관련된 장소에 주차된 경우</p>
        </ol>
        <ol>
          12. 회원은 비, 안개, 눈, 우박, 얼음, 더위 또는 뇌우를 포함하여 이에 국한되지 않는 조건으로
          서비스를 이용하는 것이 위험한 지 여부를 결정할 책임이 있습니다. 날씨, 시야, 주변 환경 및
          교통 상황에 맞게 이동 수단 동작 및 제동 거리를 조정해야 합니다.
        </ol>
        <ol>
          13. 회원은 서비스 이용 중 고장, 기기의 파손 또는 사고가 발생하였을 경우 회사에 신고하여야
          합니다.
        </ol>
        <ol>
          14. 본 조에 명시된 조항에 대한 불이행 시 발생하는 모든 문제에 대해서 회사는 책임지지
          않습니다.
        </ol>
      </ul>
      <h1>제 12 조 (회사의 의무)</h1>
      <ul>
        <ol>
          1. 회사는 관련 법령과 본 약관이 금지한 행위 또는 미풍양속에 반하는 행위를 하지 않으며,
          계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
        </ol>
        <ol>
          2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해
          보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
        </ol>
        <ol>
          3. 회사가 제공하는 서비스로 인하여 회원에게 상해, 손해가 발생한 경우 그러한 상해, 손해가
          회사의 고의나 과실에 기해 발생한 경우에 한하여 회사에서 책임을 부담합니다.
        </ol>
        <ol>
          4. 회사는 정보통신망법, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련있는
          법령을 준수합니다.
        </ol>
      </ul>
      <h1>제 13 조 (회원의 의무)</h1>
      <ul>
        <ol>
          1. 회원은 이용 시간(이동 수단을 잠금 해제(unlock) 한 시점부터 다시 잠금(lock) 한 시점까지)
          중발생하는 전동킥보드, 전기자전거에 대한 손해, 신체에 대한 상해를 포함하여 모든 책임은
          회원이부담합니다. 단, 전동킥보드, 전기자전거에 대한 손해, 신체에 대한 상해 등이 회사의
          고의 또는 과실에 의해 발생한 경우 제16조 제3항에 따릅니다.
        </ol>
        <ol> 2. 회원은 운전면허증을 취득 후 전동킥보드를 운행해야 합니다.</ol>
        <ol>
          3. 회원은 도로교통법, 자전거법, 도시공원 및 노지 등에 관한 법률 및 각 지자체 조례 또는
          행정명령 등 관련 법령으로 전동킥보드, 전기자전거 운행이 금지된 곳에서 운행을 하여서는
          안됩니다.
        </ol>
        <ol>
          4. 회원은 전동킥보드, 전기자전거를 이용하는 경우 헬멧과 무릎 보호대 등의 안전 장비를
          착용함으로써 스스로를 보호하기 위한 모든 합리적인 주의를 기울여야 합니다.
        </ol>
        <ol>
          5. 회원은 전동킥보드, 전기자전거 운행을 시작하기 전에 타이어, 브레이크, 핸들, 조명,
          프레임, 체인, 페달, 안장 등을 확인하여 이용에 적합하고 안전한지 확인해야 합니다.
        </ol>
        <ol>
          6. 전동킥보드나 전기자전거에 기술적 결함이 있거나 이용 시작 또는 이용 도중 이동 수단이
          훼손되는 경우, 이용을 즉시 종료 후 안전한 곳에 주차하여야 합니다. 이러한 모든 결함 및
          훼손은 회사에 신고하여야 합니다.
        </ol>
        <ol>
          7. 서비스 이용 중 회원 자신의 위법 행위로 인하여 부과 받은 벌금 및 과태료 등은 회원 본인이
          직접 부담하여야 합니다.
        </ol>
        <ol>
          8. 회원은 서비스 이용에 필요한 아이디, 비밀번호, 결제정보 등을 안전하게 기밀로 유지해야
          합니다. 회원이 자신의 아이디, 비밀번호, 결제정보 등의 관리를 소홀히 하여 발생하는 모든
          책임은 회원 본인에게 있습니다. 단, 회사의 고의 또는 과실로 인하여 회원정보가 유출된 경우는
          예외로 합니다.
        </ol>
        <ol>
          9. 회원 가입 및 서비스 이용과 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한
          책임과 불이익은 전적으로 회원이 부담하여야 합니다. 다만, 회사가 회원이 입력한 정보의 오류
          및 그로 인한 불이익을 사전에 알았음이 명백한 경우 또는 선량한 관리자의 주의 의무를 다하지
          않았음이 명백한 경우는 예외로 합니다.
        </ol>
        <ol>
          10. 회원은 서비스를 이용하기 전에 서비스 조건을 확인해야 합니다. 조건을 확인하지 않고
          서비스를 이용함으로써 발생하는 모든 손실과 손해에 대한 책임은 회원 본인에게 있습니다. 단,
          회사의 고의 또는 과실로 인하여 회원이 서비스 조건을 도저히 확인할 수 없는 경우는 예외로
          합니다.
        </ol>
        <ol>
          11. 회원은 서비스 이용 시 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단을
          임의로 사용해서는 안됩니다. 타인의 결제수단을 임의로 사용함으로써 발생하는 회사,
          결제수단의 적법한 소유자, 전자결제대행사 등의 실과 손해에 대한 모든 책임은 회원에게
          있습니다.
        </ol>
        <ol>
          12. 회원은 회사가 서비스를 안전하게 제공할 수 있도록 회사에 협조하여야 하며, 회사가 회원의
          본 약관 및 이용 정책 위반행위를 발견하여 회원에게 해당 위반행위에 대하여 소명을 요청할
          경우 회원은 회사의 요청에 적극 응하여야 합니다.
        </ol>
        <ol>
          13. 회원은 분쟁이 발생한 경우 분쟁의 해결을 위하여 성실히 임하여야 하며, 분쟁해결 시
          회원이고의로 분쟁해결을 위한 조치를 하지 않거나 이에 준하는 분쟁해결노력 해태로 인하여
          회사 또는상대 회원 또는 제3자에게 손해가 발생한 경우 회원은 이에 대한 책임을 부담하여야
          합니다.
        </ol>
        <ol>
          14. 회원은 다음 각 호의 행위를 하여서는 안되며 회사는 다음 각호의 행위 적발에 따라
          회원에게 다음과 같이 조치 할 수 있습니다.
          <p>서비스 이용제한 행위 조치</p>
          <p>회사가 지정한 주 정차 금지 구역에 주차하는 행위</p>
          <p>
            ■ 다른 회원이 찾기 힘든 장소(건물 내부, 사유지, 지하, 지하주차장, 실내 또는 인적이 드문
            고립된 장소 등)
          </p>
          <p>■ 기타 제3자 또는 도시에 피해를 줄 수 있는 장소</p>
          <p>■ 기기 관리의 용이함을 저해하는 장소(고속도로, 강변도로 등)</p>
          <p>■ 거주자 우선 주차 구역</p>
          <p>■ 장애인 주차 구역</p>
          <p>■ 차량 인근 (전, 후면)으로 운전자의 안전과 차량에 피해를 줄 수 있는 장소</p>
          <p>■ 신호등 또는 교통표지판 주변</p>
          <p>■ 비상구 또는 비상계단 주변</p>
          <p>또는 다른 회원들이 서비스를 이용할 수 없도록 피해를 유발하는 행위</p>
          <p>~2회:경고 (SMS 또는 유선 등) 3회: 1주일 제한</p>
          <p>서비스 이용제한 행위 조치</p>
          <p>대통령 직속 4차 산업혁명 위원회에서 지정한 주 정차 금지 구역에 주차하는 행위</p>
          <p>■ 보행자 통행을 방해할 수 있는 보도 중앙</p>
          <p>■ 횡단보도, 산책로 등 보행자 진출입을 방해할 수 있는 구역</p>
          <p>■ 점자블록, 장애인 등 교통약자를 위한 엘리베이터 입구 및 진출입로 주변</p>
          <p>■ 버스 정류장, 택시 승강장 탑승자의 승하차, 지하철역 진출입을 방해하는 장소</p>
          <p>■ 건물, 상가, 빌딩 등의 차량 및 보행자 진출입을 방해할 수 있는 위치</p>
          <p>■ 차도와 보도와 자전거 도로 진출입로</p>
          <p>■ 차량 진출입을 위해 차도와 인도 사이 턱을 낮춘 진출입로</p>
          <p>■ 자전거 도로와 자전거 도로 진출입로</p>
          <p>■ 소방기본법에 따른 소방시설 5미터 이내 구역</p>
          <p>■ 육교 위, 지하보차도 안 보행 구조물 기능을 저해하는 공간</p>
          <p>■ 계단, 난간 등 낙하, 추락 등에 따른 사고 발생 가능 지역</p>
          <p>■ 터널 안 다리 위, 공사장 주변</p>
          <p>■ 도로관리청이 지정한 통행 제한 구간</p>
          <p>
            *주 정차 위반 차량 견인 등에 관한 조례 개정에 따라 그로 인해 부과되는 과태료 및 견인,
            보관, 인수 등의 비용은 회원 본인이 직접 부담하여야 합니다.
          </p>
          <p>~2회:경고</p>
          <p>■ (SMS 또는 유선 등) 3회: 1주일 제</p>
          <p>■ 서비스 이용 중 전동킥보드를 방치하는 행위</p>
          <p>■ 전동킥보드 1대에 2명이상 탑승하여 위험을 초래하며 이용하는 행위</p>
          <p>■ 소란, 민폐 등으로 지역사회에 불편을 일으키며 서비스나 앱을 사용하는 행위</p>
          <p>
            ■ 상업적인 용도로 이용하는 행위. 단, 회사와 계약 또는 서면 승인이 있는 경우는 제외한다.
          </p>
          <p>■ 개인적인 이동 수단 외의 목적으로 이용하는 행위</p>
          <p>■ 법률 및 규정에 따라 주행이 허용되는 곳을 벗어나 운행하는 행위(공원 등의 공공시설)</p>
          <p>
            ■ 서비스 이용 중 전동킥보드나 전기자전거를 별도의 잠금 장치를 사용하여 물건, 물체 등에
            잠그는 등 전동킥보드나 전기자전거를 무단으로 점유하는 행위
          </p>
          <p>■ 서비스 이용제한 행위 조치</p>
          <p>■ 상습 부정 사용자, 기타 불법적이거나 부당한 행위</p>
          <p>1회:영구제한</p>
          <p>
            ■ 타인에게 상해를 입히거나 괴롭히거나 불안 또는 그러할 가능성이 있는 방식으로
            전동킥보드, 전기자전거를 이용하는 행위
          </p>
          <p>■ 서비스 가입 또는 회원정보 변경 시 허위내용을 등록하는 행위</p>
          <p>■ 서비스 가입 또는 사용을 위해 타인의 정보를 도용하는 행위</p>
          <p>■ 서비스 이용 시 본인이 아닌 타인이 전동킥보드, 전기자전거를 이용하는 행위</p>
          <p>■ 회원의 계정을 타인이 사용하도록 권한을 부여하거나 양도 또는 이전하는 행위</p>
          <p>■ 운전면허증을 취득하지 않고 전동킥보드를 운행하는 행위</p>
          <p>■ 다른 회원의 개인정보 및 계정정보를 수집하는 행위</p>
          <p>■ 고의로 전동킥보드, 전기자전거를 훼손, 절도하는 행위</p>
          <p>■ 약물에 취하거나 음주인 상태로 이용하는 행위</p>
          <p>■ 교통법규 등을 어기는 위법 행위</p>
          <p>■ 올바른 네트워크의 작동을 손상시킬 수 있는 행위</p>
          <p>■ 회사가 게시한 정보를 권한 없이 변경하는 행위</p>
          <p>■ 회사와 기타 제 3자의 저작권 등 지적재산권을 침해하는 행위</p>
          <p>■ 회사 및 기타 제 3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
          <p>
            ■ 반납 시 반납위치를 촬영하는 사진에 허위사진, 합성 사진, 기타 타인에게 불쾌감을 줄 수
            있는 사진을 게시하는 행위
          </p>
          <p>■ 사용할 권한을 부여받지 아니한 액세스 지점에서 사용하는 행위</p>
        </ol>
        <ol>
          15. 회사는 회원이 제14항의 금지행위를 한 것으로 판단될 경우 서비스 이용을 제한할 수
          있으며, 이때 발생하는 회원 귀책사유의 책임은 회원이 부담하고 회사의 귀책사유가 있는 경우
          회사가 부담합니다.
        </ol>
        <ol>
          16. 제14항의 금지행위 여부에 대한 판단에 대해 회원이 이의를 제기한 경우 소명의 기회를
          제공하며 금지행위 여부에 대한 확정은 회원의 동의를 얻어 회사가 최종 결정합니다.
        </ol>
      </ul>

      <h1>제 14 조 (개인정보 수집, 이용 및 보호의무)</h1>
      <ul>
        <ol>
          1. 회사는 서비스의 원활한 제공을 목적으로 개인정보를 수집 및 이용합니다. 이를 위해서
          회사는 회원에게 별도의 동의를 구합니다.
        </ol>
        <ol>
          2. 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
          노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보취급방침이
          적용됩니다.
        </ol>
      </ul>
      <h1>제 15 조 (서비스 관리 책임)</h1>
      <div>
        <p>회사의 서비스 관리책임자의 성명, 소속부서, 직위 및 연락처는 다음과 같습니다.</p>
        <p>⠿ 주식회사 피유엠피</p>
        <p>⠂ 성명 : 김상훈</p>
        <p>⠂ 소속 : 서비스본부</p>
        <p>⠂ 직위 : CTO</p>
        <p>⠂ 연락처 : 1670-3737</p>
      </div>
      <h1>제 16 조 (개인정보의 동의 철회)</h1>
      <div>
        회원은 서비스 해지(이용 중지의 통보)의 방법으로 회사의 개인정보의 수집, 이용, 제공에 대한
        동의를 언제든지 철회하실 수 있습니다.
      </div>
      <h1>제 17 조 (개인정보의 보유기간 및 이용기간)</h1>
      <div>
        회원의 개인정보는 회사가 서비스를 제공하는 기간 동안에 한하여 보유 및 이용되며, 회사는
        개인정보의 수집 및 이용 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 다만,
        국세기본법, 법인세법, 부가가치세법 기타 관계법 령의 규정에 의하여 보존할 필요성이 있는
        경우에는 관계법령에 따라 보존합니다.
      </div>

      <h1>제 18 조 (손해 배상)</h1>
      <ul>
        <ol>
          1. 회원 또는 회사가 본 약관의 규정을 위반함으로 인하여 상대방에게 손해가 발생하게 되는
          경우, 본 약관을 위 반한 당사자는 상대방에게 그 손해를 배상하여야 합니다. 그 손해는 통상의
          손해에 한하며, 특별한 사정으로 인한 손해는 그것을 알았거나 알 수 있었을 때에 배상책임을
          부담합니다.
        </ol>
        <ol>
          2. 회원은 서비스를 이용함에 있어서 회원의 귀책사유로 발생한 다른 회원, 제3자에게 신체 또는
          재산상 피해 등 모든 상해나 손해, 손실이 발생한 경우 회원이 모든 것을 배상하여야 합니다.
          단, 회사의 귀책사유가 인정되는 경우, 이로 인해 발생한 손해는 회사가 배상합니다.
        </ol>
        <ol>
          3. 회원이 서비스를 이용하던 중 이동수단의 도난 또는 파손이 발생한 경우 회원은 회사의
          손해를 배상할 의무가 있습니다.
        </ol>
      </ul>
      <h1>제 19 조 (회원탈퇴 및 자격상실)</h1>
      <ul>
        <ol>
          1. 제휴사업자의 회원 은 언제든지 이메일 등 기타 회사 및 제휴사업자가 정한 방법으로 회원
          탈퇴를 요청할 수 있으며, 회사 및 제휴사업자는 회원의 요청에 따라 조속히 회원탈퇴에 필요한
          제반 절차를 수행합니다. 단, 회원에게 미수가 있을 경우에는 미수에 대한 결제를 완료한 이후에
          탈퇴 신청이 가능합니다.
        </ol>
        <ol>
          2. 회원이 다음 각 호에 해당하는 경우, 회사 및 제휴사업자는 회원자격을 상실시킬 수
          있습니다.
        </ol>
        <ol> 3. 가입 신청 시에 허위 내용을 등록한 경우</ol>
        <ol>
          4. 본 약관 제17조 제14항에 명시된 이용제한행위 및 관계법령에 위반되는 행위를 하는 등
          회원자격을 유지시키는 것이 부적절하다고 판단되는 경우
        </ol>
        <ol>
          5. 회원은 제2항에 따른 서비스 이용정지 기타 서비스 이용과 관련된 이용제한에 대해 회사가
          정한 절차에 따라 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고 판단되는
          경우 즉시 서비스 이용을 재개합니 다.
        </ol>
        <ol>
          6. 회원이 사망한 경우 회원 사망일에 회원자격이 상실되며, 제3자가 사망자의 회원자격을
          도용하여 행한 모든 행위는 유효한 것으로 인정되지 않습니다.
        </ol>
        <ol>
          7. 회원 탈퇴 시 3개월간 회원 재가입이 제한됩니다. 단, 회사에서 허가한 경우에는 재가입이
          가능합니다.
        </ol>
      </ul>
      <h1>제 20 조 (저작권의 귀속 및 이용제한)</h1>
      <ul>
        <ol> 1. 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</ol>
        <ol>
          2. 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포,
          방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자가 이용하도록 하여서는 안됩니다.
        </ol>
      </ul>

      <h1>제 21 조 (게시물의 저작권)</h1>
      <ul>
        <ol>1. 회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</ol>
        <ol>
          2. 게시물은 검색결과 내지 회사의 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당
          노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우,
          회사는 저작권법 규정을 준수하며, 회원은 언제든지 고객센터 또는 각 서비스 내 관리기능을
          통해 해당 게시물 등에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
        </ol>
        <ol>
          3. 회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스,
          전자우편, SMS(단문메시지) 등을 통해 사전에 회원의 동의를 얻습니다.
        </ol>
      </ul>
      <h1>제 22 조 (책임 제한)</h1>
      <ul>
        <ol>
          1. 회사는 천재지변 또는 이에 준하는 불가항력 및 회사의 귀책사유가 아닌 사유로 인하여
          서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
        </ol>
        <ol>
          2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 및 손해에 대하여는 책임을 지지
          않습니다. 단, 회사의 귀 책사유가 인정되는 경우, 이로 인해 발생한 손해는 회사가 배상합니다.
        </ol>
        <ol> 3. 회사는 회원의 수익을 보장하지 않습니다.</ol>
        <ol>
          4. 회사는 회원이 서비스와 관련하여 제공한 정보 및 그 정보의 진실성 또는 적법성 등 일체에
          대하여 보증하지 않으며 그와 관련하여 어떠한 책임도 부담하지 않습니다. 단, 서비스 내 타인의
          인격을 침해하거나 지적 재산권 등을 침해하는 허위 불법 자료나 정보로 인하여 발생한 책임에
          대해 회사가 고의 또는 과실로 정보통신망 이용촉진 및 정보보호에 관한 법률에 따른 임시조치를
          못한 경우, 이로 인해 발생한 손해는 회사가 배상합니다
        </ol>
        <ol>
          5. 회원은 도로교통법, 교통법규, 교통 표지판 및 신호 등 회원에게 적용되는 법규를 준수할
          의무가 있고 회사는 개별 회원의 법규 준수 여부를 보증하거나 책임을 대리하지 않습니다.
        </ol>
        <ol> 6. 회원이 법규를 위반함으로써 발생하는 모든 법적 책임은 회원 본인에게 있습니다.</ol>
        <ol>
          7. 회원이 다른 회원 또는 제3자에 대한 손해 배상 책임이 있는 경우, 회사는 회사에서 제공하는
          보호프로그램 정책 및 관계법령에 따라 책임을 부담합니다.
        </ol>
        <ol>
          8. 회사는 회원간 또는 회원과 제휴사 간의 중개자로서 회원이 본 서비스를 이용하는 과정에서
          발생한 회원 및 제3자간의 분쟁을 포함하여 교통사고로 인한 각종 피해, 도난, 폭행, 성범죄
          등에 대하여 회사의 과실이 없는 한 책임을 지지 않습니다.
        </ol>
      </ul>
      <h1>제 23 조 (서비스 종료)</h1>
      <ul>
        <ol>
          1. 회사가 서비스를 종료하고자 할 경우 회사는 서비스를 종료하고자 하는 날로부터 3개월
          이전에 본 약관 제3조 제2항에서 정한 방법으로 회원에게 알려드립니다.
        </ol>
        <ol>
          2. 본 조 제1항에 따라 서비스가 종료되는 경우 회사는 관계법령에 따라 보유하고 있는
          개인정보를 파기합니다.
        </ol>
        <ol> 3. 서비스가 종료되는 경우 회원이 쿠폰 등은 모두 자동 소멸됩니다.</ol>
      </ul>
      <h1>제 24 조 (준거법 및 관할법원)</h1>
      <div>
        본 약관에서 정하지 않은 사항과 본 약관의 해석에 대해서는 대한민국법령 및 상관례에 따르며,
        서비스 및 본 약관과 관련한 제반 분쟁 및 소송은 민사소송법상 관할법원을 전속관할로 합니다.
      </div>
      <div>부칙</div>
      <div>본 약관은 2021년 8월 27일부터 시행합니다.</div>
    </div>
  );
};

export default PmTermsXing;
