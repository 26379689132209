import { Paths } from 'constant/RoutePath';
import useOnce from 'hooks/useOnce';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

export const useAppLocation = () => {
  const history = useHistory();
  const isIos15 = useMemo(() => {
    const [major] = (ua.osVersion || '')?.split('.');
    return ua.isIos && Number(major) >= 15;
  }, []);

  // https://tde.sktelecom.com/stash/projects/LEGOPUB/repos/personal-mobility-web/pull-requests/159/overview
  useOnce(isIos15, () => {
    if (window.history.length === 1) {
      window.history.pushState(null, '', Paths.PmMain);
    }
  });

  const goBack = useCallback(() => {
    let isHistoryBack = false;

    window.onpopstate = () => {
      isHistoryBack = true;
    };

    history.goBack();

    setTimeout(() => {
      if (!isHistoryBack) {
        tmapWrapper.closeWebview();
      }
    }, 300);
  }, [history]);

  return { goBack };
};
