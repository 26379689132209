import classNames from 'classnames';
import DescriptionList from 'component/DescriptionList';
import { WEB_HOST } from 'constant/Path';
import { TActionId } from 'constant/PmLog';
import { Paths } from 'constant/RoutePath';
import { COMPANY_ICON_MAP, COMPANY_NAME_MAP } from 'constant/Scooter';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { useCallback, useMemo } from 'react';
import { ReactComponent as BtnCsTalk } from 'resource/images/btn_cs_talk.svg';
import { ReactComponent as IconPhone } from 'resource/images/icon-phone.svg';
import s from 'styles/components/guide/Guide.module.scss';
import guideStyle from 'styles/components/guide/GuideReport.module.scss';
import { ECompanyType, EGuideAnchor } from 'types/App';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const GUIDE_LIST = [
  '사고 발생 시 상황을 자세히 알 수 있도록 사진 기록을 남겨주세요.',
  '각 운영사의 정책 및 면책사항에 따라 보험 적용이 제한될 수 있습니다.',
  '티맵 바이크는 킥보드 서비스 중개 플랫폼으로서 사고 보상 보험에 대한 책임은 각 운영사에 있습니다.',
];

const COMPANY_CLICK_LOG_MAP: Record<ECompanyType, TActionId> = {
  [ECompanyType.GCOOTER]: 'tap.gcooter_icon',
  [ECompanyType.XINGXING]: 'tap.xing_icon',
  [ECompanyType.TMAP]: 'tap.tmap_icon',
};

const GuideReport = () => {
  const userGuideLogger = usePmLogger(LogPageId.UserGuide);

  const CALL_LIST = useMemo(
    () => [
      {
        company: ECompanyType.GCOOTER,
        phone: '1833-5748',
      },
      {
        company: ECompanyType.XINGXING,
        phone: '1670-3737',
      },
      {
        company: ECompanyType.TMAP,
        text: '티맵 고객 센터 1:1 문의',
        Button: (
          <button
            className={guideStyle.button}
            onClick={() => {
              userGuideLogger.sendClickLog(COMPANY_CLICK_LOG_MAP[ECompanyType.TMAP]);
              tmapWrapper.openCustomerCenter();
            }}
          >
            <BtnCsTalk />
          </button>
        ),
      },
    ],
    []
  );

  const handleClickPhoneCall = useCallback((item) => {
    tmapWrapper.phoneCall(item.phone);
  }, []);

  const handleClickInsuranceButton = useCallback(() => {
    userGuideLogger.sendClickLog('tap.tmapinfo_icon');
    tmapWrapper.openServiceByUrl(`${WEB_HOST}${Paths.PmTmapInsurance}`, 'TMAP 바이크 보험');
  }, [userGuideLogger]);

  return (
    <div className={s.guide_wrap}>
      <div className={s.guide_item}>
        <h3
          className={classNames(s.title, guideStyle.header_title)}
          data-anchor={EGuideAnchor.REPORT}
        >
          사고 및 고장신고 안내
        </h3>

        <div className={classNames(s.warn_wrap, guideStyle.report_warn_wrap)}>
          <span>
            사고나 기기고장 등 신고는{' '}
            <em>
              이용하신 기기의 <br /> 운영사 고객센터
            </em>
            로 접수 가능합니다.
          </span>
        </div>

        <ul className={guideStyle.call_wrap}>
          {CALL_LIST.map((c) => (
            <li
              key={c.company}
              className={guideStyle.item}
            >
              <div className={guideStyle.company_wrap}>
                <div className={guideStyle.title_wrap}>
                  <i className={guideStyle.icon}>{COMPANY_ICON_MAP[c.company]}</i>
                  <span className={guideStyle.title}>{COMPANY_NAME_MAP[c.company]}</span>
                  {c.company === ECompanyType.TMAP && (
                    <button
                      className={guideStyle.insurance_button}
                      onClick={handleClickInsuranceButton}
                    >
                      보험안내
                    </button>
                  )}
                </div>
                <span className={guideStyle.text}>{c.phone || c.text}</span>
              </div>

              {c.Button || (
                <button
                  className={guideStyle.button}
                  onClick={() => {
                    userGuideLogger.sendClickLog(COMPANY_CLICK_LOG_MAP[c.company]);
                    handleClickPhoneCall(c);
                  }}
                >
                  <IconPhone />
                </button>
              )}
            </li>
          ))}
        </ul>

        <div className={s.bar} />

        <div className={classNames(s.guide_item, guideStyle.guide_wrap)}>
          <DescriptionList
            list={GUIDE_LIST}
            itemClassName={guideStyle.guide_item}
          />
        </div>
      </div>
    </div>
  );
};

export default GuideReport;
