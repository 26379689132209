import classNames from 'classnames';
import Popover from 'component/Popover';
import { ReactNode } from 'react';
import { ReactComponent as IconTnow } from 'resource/images/icon_tnow.svg';
import { ReactComponent as IconEventTarget } from 'resource/images/prmotion/event_target_text.svg';
import { ReactComponent as IconEventText } from 'resource/images/prmotion/event_text.svg';
import s from 'styles/components/promotion/promotion.module.scss';
import { isInTime } from 'utils/date';

import Store from './base';
import { localStorageStore, sessionStorageStore } from './storageStore';

type Promotion = {
  key: string;
  startDate: string;
  endDate: string;
  tooltip: ReactNode;
  tag: ReactNode;
  checkFn?: () => Promise<boolean>;
};

type State = {
  promotions: Promotion[];
  currentPromotion?: Promotion;
};

const promotions: Promotion[] = [
  {
    key: 'PLACE',
    startDate: '2024-08-01 00:00',
    endDate: '2024-12-31 23:59',
    tooltip: (
      <Popover
        className={classNames(s.popover, s.place_promotion_wrapper)}
        tailClassName={s.place_tail}
        text={
          <div className={s.place_promotion}>
            <div className={s.go_where}>
              <IconTnow className={s.tnow_icon} />
              <span>어디갈까?</span>
            </div>
            <IconEventTarget />
          </div>
        }
      />
    ),
    tag: (
      <div className={s.place_promotion_tag}>
        <IconTnow className={s.tnow_icon} />
        <span className={s.tag_go_where}>어디갈까?</span>
        <IconEventText />
      </div>
    ),
    checkFn: async () => true,
  },
];

class PromotionStore extends Store<State> {
  constructor() {
    super({ promotions });

    promotions.forEach((it) => {
      sessionStorageStore.remove(it.key);
    });
  }

  joinPromotion = (key: string, userKey: string) => {
    const promotion = this.state.promotions.find((it) => it.key === key);
    if (!promotion) return;

    if (isInTime(promotion.startDate, promotion.endDate)) {
      localStorageStore.setValue(key, userKey);
    }
  };

  setCurrentPromotion = (key: string, userKey: string) => {
    const promotion = this.state.promotions.find((it) => it.key === key);
    if (!promotion) return;

    if (isInTime(promotion.startDate, promotion.endDate)) {
      sessionStorageStore.setValue(key, userKey);
      localStorageStore.setValue(key, userKey);

      this.updateState({
        currentPromotion: promotion,
      });
    }
  };

  getPromotionByPriority = async (userKey: string) => {
    for (let i = 0; i < this.state.promotions.length; i++) {
      const it = this.state.promotions[i];
      if (!isInTime(it.startDate, it.endDate)) continue;

      const value = localStorage.getItem(it.key);
      if (!value?.includes(userKey)) {
        continue;
      }

      try {
        const result = await it.checkFn?.();
        if (result) {
          return it;
        }
      } catch {
        continue;
      }
    }
  };

  clearAllPromotion = () => {
    this.state.promotions.forEach((it) => {
      sessionStorageStore.remove(it.key);
      localStorageStore.remove(it.key);
    });
  };

  completePromotion = (key: string) => {
    sessionStorageStore.remove(key);
    localStorageStore.remove(key);
    this.updateState({
      currentPromotion: undefined,
    });
  };
}

const promotionStore = new PromotionStore();

export default promotionStore;
