import { PopupLayerId } from 'constant/App';
import { MODAL_TEXT } from 'constant/Scooter';
import useAdditionalLocationSearch from 'hooks/useAdditionalLocationSearch';
import useMovePage from 'hooks/useMovePage';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmTripStart from 'hooks/usePmTripStart';
import usePopupLayer from 'hooks/usePopupLayer';
import usePrevious from 'hooks/usePrevious';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useEffect, useMemo } from 'react';
import userInfoStore from 'store/userInfoStore';
import { EAreas } from 'types/App';
import { ERejectReason, EUserStep } from 'types/api/User';

import ModalPopup, {
  TButtonProps as TModalButtonProps,
  TProps as TModalPopupProps,
} from './ModalPopup';

type TProps = {
  onInvalidCodeConfirm?: VoidFunction;
};

export const PmRejectRentPopup = ({ onInvalidCodeConfirm }: TProps) => {
  const popup = usePopupLayer(PopupLayerId.DISABLE_RENT_AREA, { level: 2 });
  const { currentQuery } = useAdditionalLocationSearch();
  const { pmStatus } = useUserInfo();
  const { moveMainPageWithUA } = useMovePage();

  const {
    disableRent,
    area,
    lowBattery,
    lostVehicle,
    errorVehicle,
    invalidCode,
    rentInfo: { company, vehicleId },
  } = useMemo(() => {
    const rejectInfo = pmStatus.rejectInfo;
    const byBattery = rejectInfo?.reason === ERejectReason.LOW_BATTERY;
    const rejectArea = rejectInfo?.reason === ERejectReason.DISABLE_RENT_AREA;
    const byCode = rejectInfo?.reason === ERejectReason.INVALID_CODE;
    const byLostVehicle = rejectInfo?.reason === ERejectReason.LOST_VEHICLE;
    const byVehicleError = rejectInfo?.reason === ERejectReason.ERROR_VEHICLE;

    return {
      area: rejectInfo?.area,
      invalidCode: byCode,
      lowBattery: byBattery,
      lostVehicle: byLostVehicle,
      errorVehicle: byVehicleError,
      disableRent: byBattery || rejectArea || byCode || byLostVehicle || byVehicleError,
      rentInfo: pmStatus.rentInfo || {},
      step: pmStatus.step,
    };
  }, [pmStatus]);

  const { enterCode } = usePmTripStart();

  const stopRent = useCallback(() => {
    userInfoStore.setPmStatus({ step: EUserStep.STAND_BY, rentInfo: { company, vehicleId } });
    popup.hide();
  }, [company, vehicleId, popup]);

  const continueRent = useCallback(async () => {
    if (company && vehicleId) {
      userInfoStore.setPmStatus({
        step: EUserStep.WAIT_RIDING,
        rentInfo: { company, vehicleId },
      });

      const result = await enterCode({ company, vehicleId }, false);
      if (result) {
        moveMainPageWithUA();
      }
    }
    popup.hide();
  }, [company, vehicleId, popup, enterCode, moveMainPageWithUA]);

  const popupProps: TModalPopupProps | null = useMemo(() => {
    const continueRentButtons: TModalButtonProps[] = [
      {
        type: 'cancel',
        children: '취소',
        onClick: () => {
          codePopLogger.sendClickLog('tap.cancel');
          stopRent();
        },
      },
      {
        type: 'confirm',
        children: '대여 계속하기',
        onClick: () => {
          codePopLogger.sendClickLog('tap.next');
          continueRent();
        },
      },
    ];
    const stopRentButtons: TModalButtonProps[] = [
      { type: 'confirm', children: '확인', onClick: () => stopRent() },
    ];

    if (lostVehicle) {
      return {
        title: MODAL_TEXT.LOST_VEHICLE.title,
        buttons: stopRentButtons,
      };
    }

    if (errorVehicle) {
      return {
        title: MODAL_TEXT.ERROR_VEHICLE.title,
        buttons: stopRentButtons,
      };
    }

    // 반납제한지역 : 반납불가, 추가과금 및 서비스 외 지역
    if (area && [EAreas.CHARGE_ADDITIONAL, EAreas.NO_PARKING, EAreas.NO_SERVICE].includes(area)) {
      return {
        title: MODAL_TEXT.RETURN_LIMIT.title,
        detailComponent: MODAL_TEXT.RETURN_LIMIT.detailComponent,
        buttons: continueRentButtons,
      };
    }

    if (lowBattery) {
      return {
        title: MODAL_TEXT.LOW_BATTERY.title,
        buttons: stopRentButtons,
      };
    }

    if (invalidCode) {
      return {
        title: MODAL_TEXT.INVALID_CODE.title,
        buttons: [
          {
            type: 'confirm',
            children: '확인',
            onClick: () => {
              stopRent();
              onInvalidCodeConfirm?.();
            },
          },
        ],
      };
    }

    return null;
  }, [
    area,
    lowBattery,
    invalidCode,
    continueRent,
    stopRent,
    lostVehicle,
    errorVehicle,
    onInvalidCodeConfirm,
  ]);

  const codePopLogger = usePmLogger(LogPageId.CodePop, !!(popup.visible && popupProps));

  const prevRentState = usePrevious(disableRent);

  useEffect(() => {
    if (!popup.visible && disableRent && disableRent !== prevRentState) {
      popup.show();
    }

    // history back 에 의해서 닫힌 경우
    if (!popup.visible && disableRent && disableRent === prevRentState && !currentQuery.id) {
      userInfoStore.setPmStatus({
        step: EUserStep.STAND_BY,
        rentInfo: { company, vehicleId },
      });
      invalidCode && onInvalidCodeConfirm?.();
    }
  }, [
    popup.visible,
    prevRentState,
    disableRent,
    invalidCode,
    onInvalidCodeConfirm,
    company,
    vehicleId,
  ]);

  return popup.visible && popupProps ? <ModalPopup {...popupProps} /> : null;
};

export default PmRejectRentPopup;
