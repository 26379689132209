import { useCallback, useEffect, useState } from 'react';
import { ESidebarStatus } from 'types/App';

import { useComponentQuery } from './useComponentQuery';

export const useSideBarToggle = () => {
  const [sidebarStatus, setSidebarStatus] = useState<ESidebarStatus>(ESidebarStatus.INIT);
  const sideBarDimmed = useComponentQuery({ type: 'side' });

  const show = useCallback(() => {
    sideBarDimmed.show();
    setSidebarStatus(ESidebarStatus.SHOW);
  }, [sideBarDimmed]);

  const hide = useCallback(() => {
    sideBarDimmed.hide();
    setSidebarStatus(ESidebarStatus.HIDE);
  }, [sideBarDimmed]);

  useEffect(() => {
    if (sideBarDimmed.visible && sidebarStatus === ESidebarStatus.INIT) {
      setSidebarStatus(ESidebarStatus.SHOW);
    }
  }, [sidebarStatus]);

  useEffect(() => {
    if (!sideBarDimmed.visible && sidebarStatus === ESidebarStatus.SHOW) {
      setSidebarStatus(ESidebarStatus.HIDE);
    }
  }, [sideBarDimmed]);

  return {
    isDimmedVisible: sideBarDimmed.visible,
    isSidebarVisible: sidebarStatus === ESidebarStatus.SHOW,
    show,
    hide,
  };
};

export default useSideBarToggle;
