import { useSyncExternalStore } from 'react';
import layoutStore from 'store/layoutStore';

const useLayoutStore = () => {
  const { appSize, windowSize } = useSyncExternalStore(layoutStore.subscribe, layoutStore.getState);

  return { appSize, windowSize };
};

export default useLayoutStore;
