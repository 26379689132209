import Image from 'component/Image';
import GuideItem from 'component/guide/GuideItem';
import ImageIconX from 'resource/images/ico_x.png';
import { ReactComponent as IconUsingGuide1 } from 'resource/images/icon-guide-2-1-1.svg';
import { ReactComponent as IconUsingGuide3 } from 'resource/images/icon-guide-2-1-2.svg';
import { ReactComponent as IconUsingGuide2 } from 'resource/images/icon-guide-2-1-3.svg';
import { ReactComponent as IconWarning1 } from 'resource/images/icon-guide-2-2-1.svg';
import { ReactComponent as IconWarning2 } from 'resource/images/icon-guide-2-2-2.svg';
import { ReactComponent as IconWarning3 } from 'resource/images/icon-guide-2-2-3.svg';
import { ReactComponent as IconWarning4 } from 'resource/images/icon-guide-2-2-4.svg';
import s from 'styles/components/guide/Guide.module.scss';
import { EGuideAnchor } from 'types/App';

const GuideDriving = () => (
  <div className={s.guide_wrap}>
    <div className={s.guide_item}>
      <h3
        className={s.title}
        data-anchor={EGuideAnchor.DRIVING_HOW_TO}
      >
        대여&주행 방법
      </h3>
      <GuideItem
        list={[
          {
            SvgComponent: <IconUsingGuide1 />,
            title: 'QR코드 스캔',
            desc: (
              <>
                대여하기 버튼을 누르고
                <br />
                기기의 QR코드를 스캔하세요.
              </>
            ),
          },
          {
            SvgComponent: <IconUsingGuide2 />,
            title: '바이크 이용하기',
            desc: (
              <>
                앉기 편한 높이로 안장을 조절한 후 페달을 밟아보세요. 전기 모터가 작동하여 편안하게
                이용 할 수 있어요.
              </>
            ),
          },
          {
            SvgComponent: <IconUsingGuide3 />,
            title: '킥보드 이용하기',
            desc: (
              <>
                땅을 차면서 킥보드가 움직이면
                <br />
                가속 레버를 천천히 누르세요.
              </>
            ),
          },
        ]}
      />
    </div>

    <div className={s.guide_item}>
      <h3
        className={s.title}
        data-anchor={EGuideAnchor.DRIVING_WARNING}
      >
        주행 시 유의사항
      </h3>

      <GuideItem
        list={[
          {
            SvgComponent: <IconWarning1 />,
            title: '서비스 외 지역 확인',
            desc: (
              <>
                지도에 어둡게 표시된 지역은 <br />
                주행 불가 또는 반납 추가 요금이 <br />
                부과 될 수 있어요.
              </>
            ),
          },
          {
            SvgComponent: <IconWarning2 />,
            title: '헬멧 착용은 필수',
            desc: (
              <>
                안전을 위해서 헬멧은
                <br />꼭 착용 해주세요.
              </>
            ),
          },
          {
            SvgComponent: (
              <div className={s.icon_wrap}>
                <Image
                  src={ImageIconX}
                  className={s.icon}
                />
                <IconWarning3 />
              </div>
            ),
            title: '음주 후 탑승 NO!',
            desc: (
              <>
                음주운전은 어느 상황에서도 절대 금지됩니다. 음주운전으로 인한 사고는 보험 적용이
                불가하니 주의해주세요!
              </>
            ),
          },
          {
            SvgComponent: (
              <div className={s.icon_wrap}>
                <Image
                  src={ImageIconX}
                  className={s.icon}
                />
                <IconWarning4 />
              </div>
            ),
            title: '2인 이상 동반 탑승 NO!',
            desc: (
              <>
                안전을 위해
                <br />
                회원 본인만 탑승하세요.
              </>
            ),
          },
        ]}
      />
    </div>
  </div>
);

export default GuideDriving;
