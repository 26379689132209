import Store from './base';

const defaultState = {
  resumeKey: 0,
  isPause: false,
};

class AppEventStore extends Store<{ resumeKey: number; isPause: boolean }> {
  constructor() {
    super(defaultState);
  }

  setResumeKey = (resumeKey: number) => {
    this.setState({ resumeKey, isPause: false });
  };

  pause = () => {
    this.updateState({ isPause: true });
  };

  reset = () => {
    this.setState(defaultState);
  };
}

const appEventStore = new AppEventStore();

export default appEventStore;
