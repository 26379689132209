import axios, { CancelTokenSource } from 'axios';
import { DEFAULT_REQUEST_ZOOM } from 'constant/Map';
import { useCallback, useRef, useSyncExternalStore } from 'react';
import vehicleRepository from 'repository/vehicleRepository';
import pmStore from 'store/pmStore';
import { EAreas, ECompanyType, EMapErrorCode } from 'types/App';
import { TPmApiParams } from 'types/api/Vehicle';
import { devLog } from 'utils/dev';

import useMap from './useMap';

// 이 level 부터 cluster 시작 됨
const CLUSTER_LEVEL = 15;

const usePmStore = () => {
  const { activeCompany, activeType, vehicleList, activeItem } = useSyncExternalStore(
    pmStore.subscribe,
    pmStore.getState
  );

  const { getMinMaxBounds, getFixedZoomLevel } = useMap();
  const refAreaCancelToken = useRef<Nullable<CancelTokenSource>>(null);
  const refPrevAreaParam = useRef<object | null>(null);

  const getParams = useCallback(
    (params = {}) => {
      const bounds = getMinMaxBounds();

      if (!bounds) {
        return;
      }

      const level = getFixedZoomLevel();
      const mapParam = { ...bounds, level };
      const nowParam = {
        companies: activeCompany,
        clusterLevel: CLUSTER_LEVEL,
        vehicleType: activeType || undefined,
        ...mapParam,
        ...params,
      };

      return nowParam;
    },
    [activeCompany, activeType, getFixedZoomLevel, getMinMaxBounds]
  );

  const requestVehicleList = useCallback(
    async (params: TPmApiParams) => {
      try {
        pmStore.setApiVehicleList(undefined, 'pending');
        if (!params.level || params.level < DEFAULT_REQUEST_ZOOM) {
          return;
        }
        const result = await vehicleRepository.getVehicleList(params);
        if (params.companies === activeCompany) {
          pmStore.setApiVehicleList({ items: result.vehicles }, 'fulfilled');
        }
      } catch (e: any) {
        pmStore.setApiVehicleList(e, 'rejected');
      }
    },
    [activeCompany]
  );

  const getVehicleList = useCallback(
    (params = {}) => {
      const nowParam = getParams(params);

      if (!nowParam) {
        return;
      }

      requestVehicleList(nowParam);

      return nowParam;
    },
    [getParams, requestVehicleList]
  );

  const getCompanyAreas = useCallback(
    (company: ECompanyType, force = false) => {
      const bounds = getMinMaxBounds();

      if (!bounds) {
        return Promise.reject();
      }

      const level = getFixedZoomLevel();
      const mapParam = { ...bounds, level };
      let hasDifferentParam = true;

      if (refPrevAreaParam.current === null) {
        refPrevAreaParam.current = mapParam;
      } else {
        hasDifferentParam = Object.keys(refPrevAreaParam.current).some(
          (key) => refPrevAreaParam.current?.[key] !== mapParam[key]
        );
      }

      if (!hasDifferentParam && !force) {
        return Promise.reject(new Error(EMapErrorCode.SAME_AREA));
      }

      refPrevAreaParam.current = mapParam;
      refAreaCancelToken.current?.cancel();
      refAreaCancelToken.current = axios.CancelToken.source();

      return vehicleRepository
        .getCompanyAreas({
          company,
          ...mapParam,
          areaTypes: [EAreas.NO_PARKING],
          cancelToken: refAreaCancelToken.current.token,
        })
        .catch((e) => {
          devLog(e);
          refAreaCancelToken.current = null;
          refPrevAreaParam.current = null;

          return e;
        });
    },
    [getMinMaxBounds, getFixedZoomLevel]
  );

  return {
    activeCompany,
    activeType,
    vehicleList,
    activeItem,
    getVehicleList,
    getCompanyAreas,
    getParams,
  };
};

export default usePmStore;
