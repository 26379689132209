import classNames from 'classnames';
import { CSSProperties, Children, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import s from 'styles/components/pmMain/RollingBanner.module.scss';

type TProps = {
  className?: string;
  delayTimeMs: number;
} & PropsWithChildren;

const RollingBanner = ({ children, className, delayTimeMs }: TProps) => {
  const refTimeout = useRef<NodeJS.Timeout>();
  const refContent = useRef<HTMLDivElement>(null);
  const refCurrentIndex = useRef(0);
  const maxCount = Children.count(children) + 1;

  const rolling = useCallback(() => {
    const prev = refCurrentIndex.current;
    const nextIndex = (prev + 1) % maxCount;

    if (!refContent.current) {
      return;
    }

    refContent.current.style.setProperty('transition', 'transform ease-in-out 1s');
    refCurrentIndex.current = nextIndex;
    refContent.current.style.setProperty('--index', refCurrentIndex.current.toString());
  }, [maxCount]);

  useEffect(() => {
    refTimeout.current = setTimeout(() => {
      rolling();
    }, delayTimeMs);

    return () => {
      clearTimeout(refTimeout.current);
    };
  }, [delayTimeMs, rolling]);

  return (
    <div className={classNames(s.wrapper, className)}>
      <div
        className={classNames(s.content)}
        style={
          {
            '--index': refCurrentIndex.current,
            transition: 'transform ease-in-out 1s',
          } as CSSProperties
        }
        ref={refContent}
        onTransitionEnd={() => {
          if (!refContent.current) return;
          if (refCurrentIndex.current === maxCount - 1) {
            refCurrentIndex.current = 0;
            refContent.current.style.setProperty('transition', null);
            refContent.current.style.setProperty('--index', refCurrentIndex.current.toString());
          }
          refTimeout.current = setTimeout(rolling, delayTimeMs);
        }}
      >
        {children}
        {Children.map(children, (child, index) => (index === 0 ? child : null))}
      </div>
    </div>
  );
};

export default RollingBanner;
