import { FULFILLED_STATE, PENDING_STATE, REJECTED_STATE } from 'constant/Api';
import { TApiStatus, TApiStatusType } from 'types/Api';

import { startDelay } from './general';

export const getDefaultApiStatus = <T>(initialState: T): TApiStatus<T> => ({
  data: initialState,
  loaded: false,
  loading: false,
  error: undefined,
});

export const getFulfilledApiStatus = <T>(data: T): TApiStatus<T> => ({
  data: data,
  ...FULFILLED_STATE,
});

export const getPendingApiStatus = <T>(data: T): TApiStatus<T> => ({
  data: data,
  ...PENDING_STATE,
});

export const getRejectedState = (error: any): TApiStatus<any> => ({
  data: undefined,
  error,
  ...REJECTED_STATE,
});

export const getApiStatusMap: Record<TApiStatusType, <T>(data: T) => TApiStatus<T>> = {
  rejected: getRejectedState,
  pending: getPendingApiStatus,
  fulfilled: getFulfilledApiStatus,
};

const removeNullableString = (value) => (['null', 'undefined'].includes(value || '') ? '' : value);

export const getParam = (key: string) =>
  removeNullableString(new URLSearchParams(window.location.search).get(key));

export const getAllParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: any = {};

  for (const [key, value] of searchParams.entries()) {
    const safeValue = removeNullableString(value);

    if (params[key] === undefined) {
      params[key] = safeValue;
    } else if (Array.isArray(params[key])) {
      params[key] = [...params[key], safeValue];
    } else {
      params[key] = [params[key], safeValue];
    }
  }

  return params;
};

/*
    createAsyncThunk 와 getDefaultApiStatus state 를 이용하는
    action.type 을 필터링한 후 각 액션에 맞게 state 업데이트

    // example
    const initialState = {
      testStateKey: getDefaultApiStatus({...}),
    }
    const actionPrefix = "PM_ACTION"
    action.type
      "PM_ACTION/testStateKey/fulfilled"
      or
      "PM_ACTION/testStateKey/pending"
      or
      "PM_ACTION/testStateKey/rejected"
    */
export const createDefaultApiAsyncReducer = (initialState, actionPrefix) => {
  const overrideState = (state, action, apiStatusState) => {
    const statusReg = new RegExp('(/(fulfilled|pending|rejected))$');
    const status = action.type.match(statusReg)?.[0];

    // "PM_ACTION/testStateKey/fulfilled" => (storageKeyRef) => testStateKey
    const storeKey = action.type.replace(new RegExp(`${actionPrefix}\/`), '').replace(status, '');
    if (storeKey) {
      state[storeKey] = {
        ...initialState[storeKey],
        ...apiStatusState,
      };
    }
  };

  return {
    pending: (state, action) => {
      overrideState(state, action, PENDING_STATE);
    },

    fulfilled: (state, action) => {
      overrideState(state, action, {
        ...action.payload,
        ...FULFILLED_STATE,
      });
    },

    rejected: (state, action) => {
      overrideState(state, action, {
        error: action.error,
        ...REJECTED_STATE,
      });
    },
  };
};

export const parseApiPath = (apiPath: string, param = {}) => {
  return Object.keys(param).reduce((str, key) => str.replace(`{${key}}`, param[key]), apiPath);
};

export const polling = <T = any>(caller, options = { retryCount: 5, intervalTime: 500 }) => {
  let callCount = 0;

  const repeater = async () => {
    callCount++;

    return caller().catch(async (err) => {
      const isMaxCount = callCount >= options.retryCount;

      if (isMaxCount) {
        throw err;
      }

      await startDelay(options.intervalTime);
      try {
        const resp = await repeater();

        return resp;
      } catch (e) {
        throw err;
      }
    });
  };

  return repeater() as Promise<T>;
};
