import { useCallback, useEffect, useMemo, useSyncExternalStore } from 'react';
import { localStorageStore, sessionStorageStore } from 'store/storageStore';

const useStorageStore = <T>(
  key: string,
  defaultValue?: T,
  isSessionStorage?: boolean
): [T, (param: T) => void] => {
  const storageStore = useMemo(
    () => (isSessionStorage ? sessionStorageStore : localStorageStore),
    [isSessionStorage]
  );

  const storage = useSyncExternalStore(storageStore.subscribe, storageStore.getState);

  const setValue = useCallback(
    (value: T) => {
      storageStore.setValue(key, value);
    },
    [key, storageStore]
  );

  useEffect(() => {
    if (storage[key] !== null || defaultValue === undefined) {
      return;
    }

    storageStore.setValue(key, defaultValue);
  }, [key, storage, defaultValue, storageStore]);

  return [storage[key], setValue];
};

export default useStorageStore;
