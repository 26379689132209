export const setComma = (inputValue?: number | string | null): string => {
  if (inputValue === null || inputValue === undefined) {
    return '';
  }

  const reg: RegExp = /(^[+-]?\d+)(\d{3})/;
  let result: string = `${inputValue}`;

  while (reg.test(result)) {
    result = result.replace(reg, '$1,$2');
  }

  return result;
};

export const getDistance = (distance, kmFixedDigit = 0) => {
  if (isNaN(distance)) {
    return '';
  }

  const isKm = distance >= 1000;
  const unit = isKm ? 'km' : 'm';
  let d: number | string = 0;

  if (isKm) {
    const km = (distance / 1000).toFixed(kmFixedDigit);

    d = km.replace('.0', '');
  } else {
    d = Math.round(distance);
  }

  return `${setComma(d)}${unit}`;
};

export const parseRentDuration = (sec: number) => {
  try {
    const hhmmss = new Date(sec * 1000).toISOString().substr(11, 8);

    return hhmmss.replace(/^00:/, '');
  } catch {
    return '00:00';
  }
};

export const parseMinuteToHourMinute = (minute: number) => {
  if (isNaN(minute)) {
    return '';
  }

  const arr = [] as string[];

  const hour = Math.floor(minute / 60);
  const remainMinute = minute % 60;

  if (hour) {
    arr.push(`${hour}시간`);
  }

  if (remainMinute) {
    arr.push(`${hour ? ' ' : ''}${remainMinute}분`);
  }

  return arr.join('');
};
