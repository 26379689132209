import classNames from 'classnames';
import ExtensionInput from 'component/ExtensionInput';
import { BottomSheet } from 'component/tmds/bottomSheet/BottomSheet';
import { PopupLayerId } from 'constant/App';
import { LICENSE_TYPE_TEXT } from 'constant/Scooter';
import { useLayerContextConsumer } from 'context/LayerContext';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePopupLayer from 'hooks/usePopupLayer';
import { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as IconArrowDown } from 'resource/images/icon-arrow-down.svg';
import { ReactComponent as IconCheck } from 'resource/images/icon-check.svg';
import s from 'styles/components/scooterLicense/ScooterLicenseInput.module.scss';
import { ELicenseCodeType, TDriverLicense } from 'types/App';
import ua from 'utils/uaParser';

type TProps = {
  onChangeProperty: (key: keyof TDriverLicense, value: string) => void;
};

const LICENSE_REGION_CODE = [
  '서울',
  '부산',
  '경기',
  '강원',
  '충북',
  '충남',
  '전북',
  '전남',
  '경북',
  '경남',
  '제주',
  '대구',
  '인천',
  '대전',
  '울산',
];

const SCOOTER_TYPE_LIST = [
  ELicenseCodeType.Class1Large,
  ELicenseCodeType.Class1Middle,
  ELicenseCodeType.Class1Small,
  ELicenseCodeType.TowTruckLarge,
  ELicenseCodeType.TowTruck,
  ELicenseCodeType.TowTruckSmall,
  ELicenseCodeType.Class2Middle,
  ELicenseCodeType.Class2Small,
  ELicenseCodeType.Class2Motor,
].map((type) => ({ type, title: LICENSE_TYPE_TEXT[type] }));

export const ScooterLicenseInput = ({ onChangeProperty }: TProps) => {
  const refInputTargetMap = useRef({});
  const handleChangeInput = useCallback(
    (key, value) => {
      onChangeProperty?.(key, value);
    },
    [onChangeProperty]
  );
  const layer = usePopupLayer(PopupLayerId.LICENSE_TYPE_LAYER);
  const { getOverlayElement } = useLayerContextConsumer();
  const licenseLogger = usePmLogger(LogPageId.License);
  const [licenseCodeType, setLicenseCodeType] = useState<number>();
  const checkMoveNextInput = useCallback((value, length, nextTarget, intoView = false) => {
    const target = refInputTargetMap.current[nextTarget];

    if (value.length === length) {
      target?.focus();
      !ua.isIos && intoView && target?.scrollIntoView();
    }
  }, []);

  const checkMoveNextInputByRegionCode = useCallback((value) => {
    if (value.length === 2) {
      const isNumber = !isNaN(value);
      const isRegion = LICENSE_REGION_CODE.includes(value);

      (isNumber || isRegion) && refInputTargetMap.current['licenseNo2']?.focus();
    }
  }, []);

  const handleClickLicenseCodeType = useCallback(
    (e, type) => {
      e.stopPropagation();
      setLicenseCodeType(type);
      handleChangeInput('licenseTypeCode', type);
      licenseLogger.sendClickLog('tap.registration_choose', { index: type });
      layer.hide();
    },
    [handleChangeInput, layer, licenseLogger]
  );

  return (
    <div className={s.wrap}>
      <div className={s.input_area}>
        <label>이름</label>
        <ExtensionInput
          onChange={(e) => {
            licenseLogger.sendClickLog('tap.name');
            handleChangeInput('name', e.value);
          }}
          placeholder="이름을 입력해주세요"
          data-cy="이름"
        />
      </div>
      <div className={s.input_area}>
        <label>면허번호</label>
        <ul className={s.license_number}>
          <li>
            <ExtensionInput
              hideDeleteButton={true}
              placeholder="지역/숫자"
              maxLength={2}
              onAfterInit={(e) => {
                refInputTargetMap.current['licenseNo1'] = e.input;
              }}
              onChange={(e) => {
                licenseLogger.sendClickLog('tap.registration_number');
                handleChangeInput('licenseNo1', e.value);
                checkMoveNextInputByRegionCode(e.value);
              }}
              data-cy="번호1"
            />
            <span className={s.dash}>-</span>
          </li>
          <li>
            <ExtensionInput
              hideDeleteButton={true}
              placeholder="00"
              type="number"
              maxLength={2}
              onAfterInit={(e) => {
                refInputTargetMap.current['licenseNo2'] = e.input;
              }}
              onChange={(e) => {
                licenseLogger.sendClickLog('tap.registration_number');
                handleChangeInput('licenseNo2', e.value);
                checkMoveNextInput(e.value, 2, 'licenseNo3');
              }}
              data-cy="번호2"
            />
            <span className={s.dash}>-</span>
          </li>
          <li>
            <ExtensionInput
              hideDeleteButton={true}
              placeholder="000000"
              type="number"
              maxLength={6}
              onAfterInit={(e) => {
                refInputTargetMap.current['licenseNo3'] = e.input;
              }}
              onChange={(e) => {
                licenseLogger.sendClickLog('tap.registration_number');
                handleChangeInput('licenseNo3', e.value);
                checkMoveNextInput(e.value, 6, 'licenseNo4');
              }}
              data-cy="번호3"
            />
            <span className={s.dash}>-</span>
          </li>
          <li>
            <ExtensionInput
              hideDeleteButton={true}
              placeholder="00"
              type="number"
              maxLength={2}
              onAfterInit={(e) => {
                refInputTargetMap.current['licenseNo4'] = e.input;
              }}
              onChange={(e) => {
                licenseLogger.sendClickLog('tap.registration_number');
                handleChangeInput('licenseNo4', e.value);
                checkMoveNextInput(e.value, 2, 'licenseSerial', true);
              }}
              data-cy="번호4"
            />
          </li>
        </ul>
      </div>

      <div className={s.input_area}>
        <label>면허종</label>

        <button
          className={classNames(s.dropdown_button, {
            [s.has_data]: !!licenseCodeType,
          })}
          onClick={() => layer.show()}
          data-cy="면허종"
        >
          {licenseCodeType ? LICENSE_TYPE_TEXT[licenseCodeType] : '면허종을 선택해주세요'}
          <IconArrowDown className={s.icon} />
        </button>
        {layer.visible &&
          createPortal(
            <div
              className={s.layer_wrap}
              onClick={() => layer.hide()}
            >
              <BottomSheet>
                <ul className={s.list}>
                  {SCOOTER_TYPE_LIST.map((v) => (
                    <li
                      key={v.type}
                      className={classNames(s.item, {
                        [s.is_selected]: licenseCodeType === v.type,
                      })}
                      onClick={(e) => handleClickLicenseCodeType(e, v.type)}
                      data-cy={v.title}
                    >
                      {v.title}
                      {licenseCodeType === v.type && (
                        <IconCheck
                          width={20}
                          height={20}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </BottomSheet>
            </div>,
            getOverlayElement()
          )}
      </div>
    </div>
  );
};

export default ScooterLicenseInput;
