import useCurrentPosition from 'hooks/useCurrentPosition';
import useMap from 'hooks/useMap';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import { HTMLAttributes, useCallback, useState } from 'react';
import { ReactComponent as IconCurrent } from 'resource/images/ico_location_now.svg';
import pmStore from 'store/pmStore';

export const CurrentPositionButton = ({
  className = '',
  ...buttonProps
}: HTMLAttributes<HTMLButtonElement>) => {
  const mainLogger = usePmLogger(LogPageId.Main);
  const ridingLogger = usePmLogger(LogPageId.Riding);
  const { isRiding } = usePmUsingStatus();

  const [isRequestPosition, setRequestPosition] = useState(false);

  const { isMapMoved, isMapMovedByUser } = useMap();
  const { getPosition } = useCurrentPosition();

  const handleClick = useCallback(() => {
    setRequestPosition(true);

    getPosition({ move: true, animate: true });

    isMapMovedByUser().then(() => setRequestPosition(false));
    pmStore.setActiveItem(null);

    if (isRiding) {
      ridingLogger.sendClickLog('tap.location');
    } else {
      mainLogger.sendClickLog('tap.location');
    }
  }, [getPosition, isMapMovedByUser, isMapMoved, isRiding]);

  return (
    <button
      className={className}
      {...buttonProps}
      style={
        {
          ...buttonProps.style,
          '--icon-color': isRequestPosition ? 'var(--icon-accent)' : 'var(--icon-primary)',
        } as any
      }
      onClick={handleClick}
      title="현 위치"
    >
      <IconCurrent />
    </button>
  );
};

export default CurrentPositionButton;
