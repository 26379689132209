import { TApiStatusType } from 'types/Api';
import { ECompanyType, EServiceAccessStatus, TCardInfo } from 'types/App';
import {
  EUserStep,
  TPmLaunchData,
  TPmStatus,
  TRejectInfo,
  TStartTripArgs,
  TUserInfoState,
  TUserTripStatus,
} from 'types/api/User';
import { getDefaultApiStatus } from 'utils/apis';

import Store from './base';

const defaultPmLaunchStatus = {
  userKey: undefined,
  tripStatus: undefined,
  userStatus: undefined,
  needCompanyTerm: undefined,
  unpaidOrder: undefined,
  companies: [],
  bannerList: [],
  popupList: [],
};

const initialState: TUserInfoState = {
  accessKey: undefined,
  pmUserKey: undefined,

  pmStatus: {
    step: EUserStep.STAND_BY,
    tripInfo: undefined,
    rentInfo: undefined,
  },

  pmUserStatus: undefined,

  pmLaunchStatus: getDefaultApiStatus<TPmLaunchData>(defaultPmLaunchStatus),
  pmTermsAgree: {},

  paymentCard: undefined,
};

class UserInfoStore extends Store<TUserInfoState> {
  constructor() {
    super(initialState);
  }

  setAccessKey = (accessKey: string) => {
    this.updateState({ accessKey });
  };

  setLicenseRegister = () => {
    this.updateState({
      pmUserStatus: {
        code: EServiceAccessStatus.READY,
      },
    });
  };

  setTermAgree = (company: ECompanyType) => {
    const prevAgreeState = this.state.pmTermsAgree || {};

    this.updateState({
      pmTermsAgree: {
        ...prevAgreeState,
        [company]: true,
      },
    });
  };

  setRentInfo = (rentInfo: Partial<TStartTripArgs>) => {
    this.updateState({
      pmStatus: {
        ...this.state.pmStatus,
        rentInfo,
      },
    });
  };

  setTripInfo = (tripInfo: Partial<TUserTripStatus>) => {
    this.updateState({
      pmStatus: {
        ...this.state.pmStatus,
        tripInfo,
      },
    });
  };

  setReject = (rejectInfo: TRejectInfo) => {
    this.updateState({
      pmStatus: {
        ...this.state.pmStatus,
        step: EUserStep.REJECT,
        rejectInfo,
      },
    });
  };

  setReturnRejectInfo = (rejectInfo?: TRejectInfo) => {
    this.updateState({
      pmStatus: {
        ...this.state.pmStatus,
        rejectInfo,
      },
    });
  };

  setPmStatus = (pmStatus: TPmStatus) => {
    this.updateState({
      pmStatus,
    });
  };

  setApiPmLaunchStatus = (data?: TPmLaunchData, status: TApiStatusType = 'pending') => {
    this.setApiStatusState('pmLaunchStatus', data || defaultPmLaunchStatus, status);
  };

  setApiPaymentCard = (data?: TCardInfo, status: TApiStatusType = 'pending') => {
    this.setApiStatusState('paymentCard', data || undefined, status);
  };

  reset = () => {
    this.setState(initialState);
  };
}

const userInfoStore = new UserInfoStore();

export default userInfoStore;
