// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=375617480#InAppWebView%EC%97%B0%EB%8F%99%EA%B7%9C%EA%B2%A9-startQrCodeScanActivity
export enum EQRScanStatus {
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
  MANUAL = 'MANUAL',
}

export enum ECameraStatus {
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
}

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=396479049
export enum EPaymentStatusCode {
  SUCCESS = '0',
}
