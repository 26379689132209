import { devLog } from 'utils/dev';

import { splitArraySimilarSum } from './array';

export const poiIdChecker = (poiId: string | number) => {
  const strPoiId = `${poiId}`;
  const nineChecker = new RegExp(/^99/);
  const tenChecker = new RegExp(/^[6-9]/);

  if (strPoiId.length === 10 && tenChecker.test(strPoiId)) {
    devLog('[poiIdChecker] 10자리. 6~9로시작');
    return false;
  } else if (strPoiId.length === 9 && nineChecker.test(strPoiId)) {
    devLog('[poiIdChecker] 9자리. 99로시작');
    return false;
  }

  return true;
};

const REG_EXP_SPACE = /\s/g;
const DEFAULT_CHAR_SIZE = 13;
const DEFAULT_SPACE_SIZE = 6;
const DEFAULT_EXTRA_SIZE = 6 + 12 + 12; // gap + padding-left + padding-right

type TTagOptions = {
  charSize?: number;
  spaceSize?: number;
  extra?: number;
};

export const getTagWidth = (value: string, opt?: TTagOptions) => {
  const charSize = opt?.charSize || DEFAULT_CHAR_SIZE;
  const spaceSize = opt?.spaceSize || DEFAULT_SPACE_SIZE;
  const extraSize = opt?.extra || DEFAULT_EXTRA_SIZE;

  const spaceLen = value.match(REG_EXP_SPACE)?.length || 0;

  return (value.length - spaceLen) * charSize + spaceLen * spaceSize + extraSize;
};

export const splitStringTags = (arr: string[], opt?: TTagOptions): [string[], string[]] => {
  const lenArray: number[] = arr.map((n) => getTagWidth(n, opt));

  const splitResult = splitArraySimilarSum([...lenArray].sort((a: number, b: number) => b - a));

  const arr1: string[] = [];
  const arr2: string[] = [];

  lenArray.forEach((len, i) => {
    const position = splitResult[0].indexOf(len);
    if (position >= 0) {
      arr1.push(arr[i]);
      splitResult[0].splice(position, 1);
    } else {
      arr2.push(arr[i]);
    }
  });

  return [arr1, arr2];
};

export const getRandom = (range: number) => Math.floor(Math.random() * range);

export const startDelay = (ms: number) =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(true), ms);
  });
