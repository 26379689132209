export const PATH_PREFIX = '/app/scooter';
export const Paths = {
  Root: '/',
  Pm: '(/app)?/scooter',
  PmMain: '/app/scooter/main',
  PmLicense: '/app/scooter/license',
  PmDrivingGuide: '/app/scooter/driving-guide',
  PmGuide: '/app/scooter/guide',
  PmTermsAgree: '/app/scooter/terms-agree',
  PmCodePage: '/app/scooter/code',
  PmReturnPage: '/app/scooter/return',
  PmTerms: '/app/scooter/terms',
  PmEvent: '/app/scooter/event',
  PmTmapInsurance: '/app/scooter/tmap-insurance',

  Proto: '/proto',
  ProtoVSMMap: '/proto/vsm',
  ProtoPopup: '/proto/popup',
  ProtoPopupDesign: '/proto/popup-design',
  ProtoPmRent: '/proto/pm-rent',
  ProtoAppSchemeTest: '/proto/app-scheme',
  ProtoPayment: '/proto/payment',
  ProtoUserInfo: '/proto/user-info',
  ProtoLottie: '/proto/lottie',
} as const;

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=396479049
export const PaymentPaths = {
  Password: '/payment/pin?productionid=KICK_BOARD',
  CardUpdate: '/payment/card/update?productionid=KICK_BOARD',
  History: '/payment/history?productionid=KICK_BOARD',
  Retry: '/payment/card/update?productionid=KICK_BOARD&ordertype=retry&orderid={orderId}',
};
