export const ProtoQueryViewer = ({ item, ...divAttrs }) => {
  return (
    <div {...divAttrs}>
      {Object.keys(item || {}).map((k) => (
        <p key={k}>
          {k} : {JSON.stringify(item[k])}
        </p>
      ))}
    </div>
  );
};

export default ProtoQueryViewer;
