import { useCallback, useState } from 'react';
import tmapInApp from 'utils/tmapInApp';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const buttonStyle = { padding: 10, background: '#efefef', margin: 10 };

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=396479049
const testUrl = {
  password: {
    url: '/payment/pin?productionid=KICK_BOARD',
    callback: 'payTest1',
  },
  card: {
    url: '/payment/card/update?productionid=KICK_BOARD',
    callback: 'payTest2',
  },
  result: {
    url: '/payment/history?productionid=KICK_BOARD',
    callback: 'payTest3',
  },
};

const ProtoPaymentPage = () => {
  const [data, setData] = useState<any>();
  const handleClick = useCallback((type) => {
    const { url, callback } = testUrl[type];
    // @ts-ignore
    window[callback] = (code, status) => {
      setData({ code, status });
      tmapWrapper.makeToast(
        '[code]' + JSON.stringify(code) + '\n[status]' + JSON.stringify(status)
      );
    };

    setData(undefined);

    tmapInApp.sendWithScheme('startPaymentActivity', [
      {
        key: 'url',
        value: url,
      },
      {
        key: 'callbackJS',
        value: callback,
      },
    ]);
  }, []);

  return (
    <div style={{ paddingTop: 50 }}>
      <h2>startPaymentActivity 테스트</h2>
      <button
        style={buttonStyle}
        onClick={() => handleClick('password')}
      >
        결제비밀번호 입력 화면
      </button>
      <br />
      <button
        style={buttonStyle}
        onClick={() => handleClick('card')}
      >
        카드 선택하기 화면
      </button>
      <br />
      <button
        style={buttonStyle}
        onClick={() => handleClick('result')}
      >
        결제 내역 화면
      </button>
      <br />
      {data && (
        <>
          <div>code : {data?.code}</div>
          <div>status : {data?.status}</div>
        </>
      )}
    </div>
  );
};

export default ProtoPaymentPage;
