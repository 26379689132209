import { isLocalEnv } from 'constant/Env';
import { useEffect, useState } from 'react';

const useLocalMount = () => {
  const [isMounted, setMounted] = useState(!isLocalEnv);

  useEffect(() => {
    setMounted(true);
  }, []);

  return isMounted;
};

export default useLocalMount;
