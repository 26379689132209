import classNames from 'classnames';
import { useCallback } from 'react';
import s from 'styles/components/Tab.module.scss';
import { EGuideTab } from 'types/App';

type TProps = {
  list: { type: EGuideTab; title: string; className?: string }[];
  activeType: EGuideTab;
  onClick?: (e, type: EGuideTab) => void;
};

const Tab = ({ list, activeType, onClick }: TProps) => {
  const handleClick = useCallback(
    (e, type) => {
      onClick?.(e, type);
    },
    [onClick]
  );

  return (
    <ul className={s.list_wrap}>
      {list.map((v) => (
        <li
          key={v.title}
          className={classNames(s.item, v.className, {
            [s.active]: v.type === activeType,
          })}
          onClick={(e) => handleClick(e, v.type)}
        >
          <>
            {v.title}
            {v.type === activeType && <div className={s.active_bar} />}
          </>
        </li>
      ))}
    </ul>
  );
};

export default Tab;
