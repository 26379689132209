import { PopupLayerId } from 'constant/App';
import { isDevEnv } from 'constant/Env';
import { MODAL_TEXT } from 'constant/Scooter';
import usePmTrip from 'hooks/usePmTrip';
import usePopupLayer from 'hooks/usePopupLayer';
import useUserInfo from 'hooks/useUserInfo';
import { useEffect, useMemo, useRef } from 'react';
import userInfoStore from 'store/userInfoStore';
import { EAreas } from 'types/App';
import { ERejectReason } from 'types/api/User';
import { setComma } from 'utils/formatter';

import ModalPopup, { TProps as TModalPopupProps } from './ModalPopup';

const TryReturnPopupTitle = ({ n }: { n: string }) => (
  <>
    반납이 제한된 지역입니다.
    <br />
    해당 지역에 반납 시
    <br />
    <mark>
      <b>{n}원</b>
    </mark>
    의 추가요금이 발생합니다.
    <br />
    반납을 계속하시겠습니까?
  </>
);

export const PmRejectReturnPopup = () => {
  const popup = usePopupLayer(PopupLayerId.REJECT_RETURN_POPUP);
  const { endTrip } = usePmTrip();
  const refCallEndTrip = useRef(false);
  const { pmStatus } = useUserInfo();
  const {
    disableReturn,
    area,
    fee,
    rentInfo: { company },
  } = useMemo(() => {
    const rejectInfo = pmStatus.rejectInfo;
    const rejectArea = rejectInfo?.reason === ERejectReason.DISABLE_RETURN_AREA;

    return {
      rentInfo: pmStatus.rentInfo || {},
      area: rejectInfo?.area,
      fee: rejectInfo?.fee,
      disableReturn: rejectArea,
    };
  }, [pmStatus]);

  const popupProps: TModalPopupProps | null = useMemo(() => {
    if (area && [EAreas.CHARGE_ADDITIONAL, EAreas.NO_SERVICE].includes(area)) {
      return {
        titleComponent: <TryReturnPopupTitle n={setComma(fee || 0)} />,
        buttons: [
          {
            type: 'cancel',
            children: '취소',
            onClick: () => {
              userInfoStore.setReturnRejectInfo();
            },
          },
          {
            type: 'confirm',
            children: '반납하기',
            onClick: () => {
              refCallEndTrip.current = true;
              userInfoStore.setReturnRejectInfo();
            },
          },
        ],
      };
    }

    if (area === EAreas.NO_PARKING) {
      return {
        title: MODAL_TEXT.TRY_RETURN_DISABLE.title.replace('{n}', `${fee || 0}`),
        buttons: [
          {
            type: 'confirm',
            children: '확인',
            onClick: () => {
              userInfoStore.setReturnRejectInfo();
            },
          },
        ],
      };
    }

    return null;
  }, [area, fee]);

  useEffect(() => {
    if (disableReturn && popupProps) {
      !popup.visible && popup.show();
    } else {
      popup.visible && popup.hide();
    }
  }, [company, disableReturn, endTrip, popup, popupProps]);

  useEffect(() => {
    if (!popup.visible && refCallEndTrip.current) {
      refCallEndTrip.current && endTrip({ company }, { checkArea: false });
    }
  }, [company, endTrip, popup.visible]);

  return popup.visible && popupProps ? <ModalPopup {...popupProps} /> : null;
};

export default PmRejectReturnPopup;
