import { APP_ENV, EProjectEnv } from './Env';

const env = APP_ENV || EProjectEnv.DEV;

const API_HOST_MAP: Record<EProjectEnv, string> = {
  [EProjectEnv.PROD]: 'https://pm.tmobiapi.com',
  [EProjectEnv.RTG]: 'https://pm-rtg.tmobiapi.com',
  [EProjectEnv.STAGE]: 'https://pm-stg.tmobiapi.com',
  [EProjectEnv.DEV]: 'https://pm-dev.tmobiapi.com',
};

export const API_HOST = API_HOST_MAP[env];

export const WEB_HOST_MAP: Record<EProjectEnv, string> = {
  [EProjectEnv.PROD]: 'https://pm.tmobiweb.com',
  [EProjectEnv.RTG]: 'https://pm-rtg.tmobiweb.com',
  [EProjectEnv.STAGE]: 'https://pm-stg.tmobiweb.com',
  [EProjectEnv.DEV]: 'https://pm-dev.tmobiweb.com',
};

export const WEB_HOST = WEB_HOST_MAP[env];

const VSM_TILE_HOST_MAP: Record<EProjectEnv, string> = {
  [EProjectEnv.PROD]: 'https://vsm-tile.tmapmp.com',
  [EProjectEnv.RTG]: 'https://vsm-tile.tmapmp.com',
  [EProjectEnv.STAGE]: 'https://vsm-tile.dev.tmapmp.com',
  [EProjectEnv.DEV]: 'https://vsm-tile.dev.tmapmp.com',
};

export const VSM_TILE_VERSION_HOST = VSM_TILE_HOST_MAP[env];

export const VSM_TILE_VERSION_URL = `${VSM_TILE_VERSION_HOST}/api/scooter/latest-version`;
export const VSM_TILE_URL = `${VSM_TILE_VERSION_HOST}/mapTile/scooter/{z}/{x}/{y}.mvt`;

export const TMAP_HOMEPAGE_HOST_MAP: Record<EProjectEnv, string> = {
  [EProjectEnv.PROD]: 'https://tmapmobility.com',
  [EProjectEnv.RTG]: 'https://tmapmobility.com',
  [EProjectEnv.STAGE]: 'https://tmobi-stg.tmapmobility.com',
  [EProjectEnv.DEV]: 'https://tmobi-dev.tmapmobility.com',
};

export const TMAP_HOMEPAGE_HOST = TMAP_HOMEPAGE_HOST_MAP[env];
