import BackButton from 'component/BackButton';
import BottomButton from 'component/BottomButton';
import Header from 'component/Header';
import Image from 'component/Image';
import ModalPopup from 'component/ModalPopup';
import PmRentStatusLayer from 'component/PmRentStatusLayer';
import ScooterLicenseInput from 'component/scooterLicense/ScooterLicenseInput';
import { PopupLayerId } from 'constant/App';
import { Paths } from 'constant/RoutePath';
import { MODAL_TEXT } from 'constant/Scooter';
import { useDriverLicenseVerify } from 'hooks/useDriverLicenseVerify';
import useHandleBackKey from 'hooks/useHandleBackKey';
import useMovePage from 'hooks/useMovePage';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmTripStart from 'hooks/usePmTripStart';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import usePopupLayer from 'hooks/usePopupLayer';
import useUserInfo from 'hooks/useUserInfo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ExMarkIcon } from 'resource/images/icon-exmark.svg';
import LicenseMale from 'resource/images/license-male.png';
import LicenseMale2x from 'resource/images/license-male@2x.png';
import LicenseMale3x from 'resource/images/license-male@3x.png';
import userInfoStore from 'store/userInfoStore';
import s from 'styles/pages/ScooterLicensePage.module.scss';
import { ELicenseErrorCode } from 'types/Api';
import { ELottieType, TDriverLicense } from 'types/App';
import { EUserStep, TStartTripArgs } from 'types/api/User';
import { sendAFLicenseRegistration, sendAFLicenseRegistrationCompletion } from 'utils/appsFlyer';
import ua from 'utils/uaParser';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const DEFAULT_LICENSE_VALUE = {
  licenseNo1: '',
  licenseNo2: '',
  licenseNo3: '',
  licenseNo4: '',
  licenseSerial: '',
  name: '',
};

const LICENSE_ERROR_DIMMED_MESSAGE = {
  [ELicenseErrorCode.USER_NAME_NOT_MATCH]: 'TMAP 사용자 이름과 면허 명의가 일치하지 않습니다.',
  [ELicenseErrorCode.CLIENT_ERROR]: '면허인증에 실패하였습니다.\n입력 정보를 다시 확인해주세요.',
  [ELicenseErrorCode.LICENSE_NOT_MATCH]:
    '면허인증에 실패하였습니다.\n입력 정보를 다시 확인해주세요.',
};

const LICENSE_TOAST_MESSAGE = '일시적인 오류입니다. 잠시 후 다시 시도해 주세요.'; // ELicenseErrorCode.LICENSE_SERVER_ERROR

export const ScooterLicensePage = () => {
  const history = useHistory();
  const { verifyCheck } = useDriverLicenseVerify();
  const successDimmed = usePopupLayer(PopupLayerId.LICENSE_SUCCESS);
  const failDimmed = usePopupLayer(PopupLayerId.LICENSE_FAIL);
  const [failDimmedMessage, setFailDimmedMessage] = useState('');
  const { isTryTrip } = usePmUsingStatus();
  const [licenseValue, setLicenseValue] = useState<TDriverLicense>(DEFAULT_LICENSE_VALUE);
  const [rentInfo, setRentInfo] = useState<Partial<TStartTripArgs>>();

  const disableConfirm = useMemo(() => {
    const v = licenseValue;

    return !(
      v.licenseNo1 &&
      v.licenseNo2 &&
      v.licenseNo3 &&
      v.licenseNo4 &&
      v.name &&
      v.licenseTypeCode
    );
  }, [licenseValue]);

  const userInfo = useUserInfo();
  const { enterCode } = usePmTripStart();

  const { movePage } = useMovePage();

  const licenseLogger = usePmLogger(LogPageId.License, true);
  const licensePopupLogger = usePmLogger(LogPageId.LicensePopup, successDimmed.visible);
  const licenseFailPopupLogger = usePmLogger(LogPageId.LicenseFailPopup, failDimmed.visible);

  const handleClickRegister = useCallback(() => {
    licenseLogger.sendClickLog('tap.registration');

    verifyCheck({ ...licenseValue })
      .then(() => {
        successDimmed.show();
        licensePopupLogger.sendExpose();
      })
      .catch((err) => {
        const code = err.response.data.code;
        const dimmedMessage = LICENSE_ERROR_DIMMED_MESSAGE[code];
        licenseFailPopupLogger.sendExpose();

        if (dimmedMessage) {
          setFailDimmedMessage(dimmedMessage);
          failDimmed.show();
        } else {
          tmapWrapper.makeToast(LICENSE_TOAST_MESSAGE);
        }
      });
  }, [
    licenseLogger,
    verifyCheck,
    licenseValue,
    successDimmed,
    licensePopupLogger,
    licenseFailPopupLogger,
    failDimmed,
  ]);

  const handleChangeLicenseInput = useCallback((key, value) => {
    setLicenseValue((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleClickCancelRegistration = useCallback(() => {
    movePage(Paths.PmMain);
    userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
    licenseLogger.sendClickLog('tap.close');
  }, []);

  const handlePopupConfirm = useCallback(async () => {
    const vehicleId = rentInfo?.vehicleId;
    const company = rentInfo?.company;
    successDimmed.hide();
    licensePopupLogger.sendClickLog('popup_tap.authsucess_confirm');
    sendAFLicenseRegistrationCompletion();

    if (company && vehicleId) {
      await enterCode({ vehicleId, company });
    }
    movePage(Paths.PmMain);
  }, [enterCode, licensePopupLogger, movePage, successDimmed, rentInfo]);

  useEffect(() => {
    if (userInfo.pmStatus.rentInfo) {
      setRentInfo(userInfo.pmStatus.rentInfo);
    }
  }, [userInfo.pmStatus.rentInfo]);

  useEffect(() => {
    return () => {
      if (successDimmed.visible) {
        movePage(Paths.PmMain);

        userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
      }
    };
  }, [successDimmed.visible, history, movePage]);

  useEffect(() => {
    sendAFLicenseRegistration(LogPageId.Main);
  }, []);

  useHandleBackKey(ua.isAndroid && ua.isInApp, handleClickCancelRegistration);

  return (
    <div className={s.wrap}>
      <PmRentStatusLayer
        loading={isTryTrip}
        type={ELottieType.UNLOCK}
      />
      <Header
        title="운전 면허 등록"
        leftButton={<BackButton onClick={handleClickCancelRegistration} />}
      />
      <div className={s.guide}>
        <div className={s.image}>
          <Image
            src={LicenseMale}
            srcSetList={[LicenseMale2x, LicenseMale3x]}
          />
        </div>
        <p>
          <i>
            <ExMarkIcon />
          </i>
          <span>도로교통법상 전동 킥보드는</span>
        </p>
        <p>운전면허(원동기장치자전거면허 이상)</p>
        <p>취득자만 이용 가능합니다.</p>
      </div>
      <div className={s.input}>
        <ScooterLicenseInput onChangeProperty={handleChangeLicenseInput} />
      </div>
      <div className={s.bottom}>
        <BottomButton
          disabled={disableConfirm}
          onClick={handleClickRegister}
          data-cy="등록하기"
        >
          등록하기
        </BottomButton>
      </div>
      {successDimmed.visible && (
        <ModalPopup
          title={MODAL_TEXT.LICENSE_SUCCESS.title}
          onConfirm={handlePopupConfirm}
        />
      )}
      {failDimmed.visible && (
        <ModalPopup
          title={failDimmedMessage}
          onConfirm={() => {
            failDimmed.hide();
            licenseFailPopupLogger.sendClickLog('popup_tap.authfail_confirm');
          }}
        />
      )}
    </div>
  );
};

export default ScooterLicensePage;
