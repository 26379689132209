import Store from './base';

export type WindowSize = {
  width: number;
  height: number;
};

export type AppSize = {
  isLandscape: boolean;
};

export type LayoutState = {
  windowSize: WindowSize;
  appSize: AppSize;
};

const initialState = {
  windowSize: {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  },
  appSize: {
    isLandscape: false,
  },
};

class LayoutStore extends Store<LayoutState> {
  constructor() {
    super(initialState);
  }

  windowResize = ({ width, height }: { width: number; height: number }) => {
    this.updateState({
      windowSize: {
        width,
        height,
      },
    });
  };

  setLandscape = (isLandscape: boolean) => {
    this.updateState({
      appSize: {
        isLandscape,
      },
    });
  };

  reset = () => {
    this.setState(initialState);
  };
}

const layoutStore = new LayoutStore();

export default layoutStore;
