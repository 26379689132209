import { Paths } from 'constant/RoutePath';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TRouter } from 'types/Router';

const RouteWithSubRoutes = ({ path, routes, component: Component }: TRouter) => {
  return (
    <Route
      path={path}
      render={(props) => (
        <Component
          {...props}
          routes={routes}
        />
      )}
    />
  );
};

const renderNestedRouter = (routes: TRouter[]) => {
  return (
    <Switch>
      {routes.map((r, i) => {
        const route = { ...r, exact: r.exact ?? true };

        return (
          <RouteWithSubRoutes
            key={i}
            {...route}
          />
        );
      })}
      <Route path="*">
        <Redirect to={Paths.PmMain} />
      </Route>
    </Switch>
  );
};

export default renderNestedRouter;
