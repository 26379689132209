import { StorageKey } from 'constant/Storage';

import Store from './base';

const validateKey = (key: string) => {
  if (!key) {
    throw new Error('Key is Empty');
  }
};

class StorageStore extends Store<Record<string, any>> {
  private storage: Storage;

  constructor(storage: Storage) {
    super({});

    this.storage = storage;
    this.setDefault();
  }

  private setDefault = () => {
    const strings = Object.values(StorageKey);
    const defaultState = strings.reduce<any>((acc, key) => {
      acc[key] = this.getStorageItem(key);
      return acc;
    }, {});
    this.setState(defaultState);
  };

  private getStorageItem = (key: string) => {
    validateKey(key);
    const item = this.storage.getItem(key);

    try {
      return JSON.parse(item as string);
    } catch {
      return item;
    }
  };

  setValue = (key: string, value: any) => {
    validateKey(key);

    this.storage.setItem(key, JSON.stringify(value));
    this.setState({ ...this.state, [key]: value });
  };

  reset = () => {
    this.storage.clear();
    this.setDefault();
  };

  remove = (key: string) => {
    this.storage.removeItem(key);
    this.setState({
      ...this.state,
      [key]: undefined,
    });
  };
}

export const localStorageStore = new StorageStore(localStorage);

export const sessionStorageStore = new StorageStore(sessionStorage);
