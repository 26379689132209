import { useCallback, useEffect, useRef } from 'react';

type TCallback = VoidFunction;

// const interval = useInterval(callback, delay);
// interval.start();
// interval.stop();

const DEFAULT_DELAY = 1000;

const useInterval = (callback: TCallback, delay: number = DEFAULT_DELAY) => {
  const refInterval = useRef<NodeJS.Timer>();
  const refCallback = useRef<TCallback>();

  const stop = useCallback(() => {
    if (refInterval.current) {
      clearInterval(refInterval.current as any);
    }
    refInterval.current = undefined;
  }, []);

  const start = useCallback(() => {
    stop();
    refInterval.current = setInterval(() => {
      refCallback.current?.();
    }, delay);
  }, [stop, delay]);

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      stop();
    };
  }, []);

  return {
    start,
    stop,
  };
};

export default useInterval;
