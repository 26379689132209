export enum EAFEventKey {
  INITIATE_TUTORIAL = 'kick_initiate_tutorial',
  TUTORIAL_COMPLETION = 'kick_tutorial_completion',
  LICENSE_REGISTRATION = 'kick_license_registration',
  LICENSE_REGISTRATION_COMPLETION = 'kick_license_registration_completion',
  MAP_VIEW = 'kick_map_view',
  SELECT_BRAND = 'kick_select_brand',
  SELECT_DEVICE = 'kick_select_device',
  START_RIDE = 'kick_start_ride',
  CHECK_RIDING_GUIDE = 'kick_check_riding_guide',
  SCAN_DEVICE = 'kick_scan_device',
  TERMS_COMPLETION = 'kick_terms_completion',
  END_RIDE = 'kick_end_ride',
  SHOT_PARKING = 'kick_shot_parking',
  COMPLETE_RIDE = 'kick_complete_ride',
  PAY_FAILURE = 'kick_pay_failure',
  EVENT_BANNER_VIEW = 'kick_event_banner_view',
  EVENT_BANNER_CLICK = 'kick_event_banner_click',
  EVENT_POPUP_VIEW = 'kick_event_popup_view',
  EVENT_POPUP_CLICK = 'kick_event_popup_click',
  OPENED_FROM_PUSH_NOTIFICATION = 'kick_opened_from_push_notification',
  AF_OPENED_FROM_WIDGET = 'af_opened_from_widget', // 오픈스펙에서 위젯 제외
  OPENED_FROM_MKT = 'kick_opened_from_mkt',
}
