import classNames from 'classnames';
import { COMPANY_ICON_MAP, COMPANY_NAME_MAP } from 'constant/Scooter';
import s from 'styles/components/pmCode/PmCompanyButton.module.scss';
import { ECompanyType } from 'types/App';

type TProps = {
  onClick?: VoidFunction;
  company: ECompanyType;
  selected?: boolean;
};

const PmCompanyButton = ({ onClick, selected, company }: TProps) => (
  <button
    className={s.select_button}
    onClick={onClick}
    data-cy="회사선택"
  >
    <i className={classNames(s.icon, s.icon_company)}>{COMPANY_ICON_MAP[company]}</i>
    <span
      className={classNames(s.text, {
        [s.selected]: selected,
      })}
    >
      {COMPANY_NAME_MAP[company]}
    </span>
  </button>
);

export default PmCompanyButton;
