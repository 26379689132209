import { DEFAULT_ZOOM } from 'constant/Map';
import { useCallback } from 'react';
import appEventStore from 'store/appEventStore';
import TMapWrapper from 'utils/wrappers/TMapWrapper';

import useMap, { TMapOption } from './useMap';

const useCurrentPosition = () => {
  const { moveToCenter, isMapMovedByUser, setCurrentPosition, currentPosition } = useMap();

  const updatePosition = useCallback(
    (nowPosition, options: TMapOption = {}) => {
      const position = {
        ...nowPosition,
        zoom: DEFAULT_ZOOM,
      };
      const disableAnimation = typeof options.speed === 'undefined';

      setCurrentPosition(nowPosition);

      if (options.move) {
        moveToCenter(position, disableAnimation ? { animate: false } : { speed: options.speed });
      }
    },
    [moveToCenter, setCurrentPosition]
  );

  const getLastPosition = useCallback(
    async ({ allowCancelByUser = false } = {}) => {
      if (appEventStore.getState().isPause) return;
      let needUpdatePosition = true;
      if (allowCancelByUser) {
        isMapMovedByUser().then(() => (needUpdatePosition = false));
      }

      const nowPosition = await TMapWrapper.getLastPosition();
      if (needUpdatePosition) {
        updatePosition(nowPosition);
      }

      return nowPosition;
    },
    [updatePosition, isMapMovedByUser]
  );

  const getPosition = useCallback(
    async ({ move = false, animate = false } = {}) => {
      try {
        if (appEventStore.getState().isPause) return;
        const nowPosition = await TMapWrapper.getCurrentPosition();
        updatePosition(nowPosition, { speed: animate ? 3 : undefined, move });
        return nowPosition;
      } catch (e) {}
    },
    [updatePosition]
  );

  return {
    currentPosition,
    getLastPosition,
    getPosition,
  };
};

export default useCurrentPosition;
