import classNames from 'classnames';
import s from 'styles/components/tmds/TmdsSwitch.module.scss';

type TProps = {
  checked?: boolean;
  disabled?: boolean;
  size?: 'small' | 'default';
  onClick?: VoidFunction;
  onDisabledClick?: VoidFunction;
  title?: string;
};

const TmdsSwitch = ({
  checked,
  disabled,
  size = 'default',
  onClick,
  onDisabledClick,
  title,
}: TProps) => {
  return (
    <div
      aria-label={title}
      className={classNames(s.wrapper, s[size], {
        [s.checked]: checked,
        [s.disabled]: disabled,
      })}
      onClick={() => {
        disabled ? onDisabledClick?.() : onClick?.();
      }}
    >
      <div className={s.rect} />
      <div className={s.circle} />
    </div>
  );
};

export default TmdsSwitch;
