import { UnlockLoadableRunningAnimation } from 'component/PmRentStatusLayer';
import { useState } from 'react';

export const ProtoLottiePage = () => {
  const [mockDataLoading, setLoading] = useState(true);
  const [waitAni, setWaitAni] = useState(false);

  return (
    <>
      <UnlockLoadableRunningAnimation autoplay={true} />
      <p>{mockDataLoading ? 'API 진행중' : 'API 완료'}</p>
      <p>{waitAni && '애니메이션 완료 대기 중'}</p>
      {!waitAni && (
        <button
          onClick={() => {
            if (mockDataLoading) {
              setLoading(false);
              setWaitAni(true);
            }
          }}
        >
          {mockDataLoading && '[CLICK] API 완료상태로 변경'}
        </button>
      )}
    </>
  );
};

export default ProtoLottiePage;
