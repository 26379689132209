import { useEffect, useRef } from 'react';

const useOnce = (condition: any = false, fn: Function) => {
  const refIsExecute = useRef(false);

  useEffect(() => {
    if (condition && !refIsExecute.current) {
      refIsExecute.current = true;
      fn();
    }
  }, [condition, fn]);
};

export default useOnce;
