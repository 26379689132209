import classNames from 'classnames';
import { useAppLocation } from 'hooks/useAppLocation';
import { HTMLAttributes } from 'react';
import { ReactComponent as CloseTopIcon } from 'resource/images/icon-top-close-52.svg';
import s from 'styles/components/CloseButton.module.scss';

export const CloseButton = ({
  className = '',
  ...buttonProps
}: HTMLAttributes<HTMLButtonElement>) => {
  const { goBack } = useAppLocation();

  return (
    <button
      className={classNames(s.close_button, className)}
      onClick={goBack}
      {...buttonProps}
    >
      <CloseTopIcon />
    </button>
  );
};

export default CloseButton;
