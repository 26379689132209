import ObjectViewer from 'component/dev/ObjectViewer';
import { API_PATH } from 'constant/Api';
import { Paths } from 'constant/RoutePath';
import useAdditionalLocationSearch from 'hooks/useAdditionalLocationSearch';
import usePmApiFetcher from 'hooks/usePmApiFetcher';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import s from 'styles/pages/ProtoPmRentPage.module.scss';
import { ECompanyType } from 'types/App';

const MOCK_POS = {
  level: 1,
  maxLat: 37.497,
  maxLon: 126.9592,
  minLat: 37.494,
  minLon: 126.9532,
};

const ProtoPmRentPage = () => {
  const providers = Object.keys(ECompanyType);
  const { currentQuery, includeLocation } = useAdditionalLocationSearch(MOCK_POS);
  const curOptions = { ...MOCK_POS, ...currentQuery };
  const [resp, setResp] = useState<any>({});
  const [userKey, setUserKey] = useState();
  const [testMap, setTestMap] = useState<any>({});

  const tripStatus = useMemo(() => testMap?.[API_PATH.GET_INIT]?.data?.tripStatus, [testMap]);

  const history = useHistory();
  const { get, post, initFetcher } = usePmApiFetcher();

  const updateTestMap = (key, keyResp) => {
    setTestMap((prev) => ({
      ...prev,
      [key]: keyResp,
    }));
  };

  useEffect(() => {
    if (Object.keys(currentQuery).length < 1) {
      history.replace(includeLocation);
    }
  }, []);

  useEffect(() => {
    initFetcher().then((e) => {
      setUserKey(e?.data?.userKey);
      updateTestMap(API_PATH.GET_INIT, e);
    });
  }, [initFetcher]);

  useEffect(() => {
    if (tripStatus) {
      const url = API_PATH.GET_TRIP_STATUS.replace('{tripId}', tripStatus.tripId);

      get(url).then((e) => {
        updateTestMap(API_PATH.GET_TRIP_STATUS, e);
      });
    }
  }, [get, tripStatus]);

  return (
    <div
      className={s.main_page_wrap}
      style={{ lineBreak: 'anywhere' }}
    >
      <h1>대여 테스트 페이지</h1>

      <h2>인증정보</h2>

      <div>
        USER_KEY: {userKey} <br />
      </div>

      <h2>현재 운행정보</h2>
      <div>
        <ObjectViewer object={tripStatus || { tripId: '' }} />

        <p>
          <button
            onClick={() => {
              const url = API_PATH.POST_TRIP_END.replace('{tripId}', tripStatus.tripId);

              post(url, {
                lat: curOptions.maxLat,
                lng: curOptions.maxLon,
                tripId: tripStatus.tripId,
              }).then((e) => {
                // window.location.reload();
              });
            }}
          >
            {API_PATH.POST_TRIP_END}
          </button>
        </p>
        <p>
          <button
            onClick={() => {
              const url = API_PATH.POST_TRIP_PAUSE.replace('{tripId}', tripStatus.tripId);

              post(url, {}).then((e) => {
                window.location.reload();
              });
            }}
          >
            {API_PATH.POST_TRIP_PAUSE}
          </button>
        </p>
        <p>
          <button
            onClick={() => {
              const url = API_PATH.POST_TRIP_RESUME.replace('{tripId}', tripStatus.tripId);

              post(url, {}).then((e) => {
                window.location.reload();
              });
            }}
          >
            {API_PATH.POST_TRIP_RESUME}
          </button>
        </p>
      </div>

      <h2>초기 api 호출결과</h2>
      {Object.keys(testMap).map((key) => {
        return (
          <div key={key}>
            <p>{key}</p>
            <ObjectViewer object={testMap[key]} />
          </div>
        );
      })}

      <h2>companies</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          const pv = [
            ...e.currentTarget.querySelectorAll('input[name="provider"]'),
          ] as HTMLInputElement[];
          const checked = pv.filter((i) => i.checked)?.map((i) => i.value);
          const companies = checked.length === 1 ? { companies: checked[0] } : {};

          if (checked.length !== 1) {
            pv.forEach((item) => {
              item.checked = true;
            });
          }

          const url = queryString.stringifyUrl({
            url: API_PATH.GET_VEHICLE_LIST,
            query: {
              ...MOCK_POS,
              ...currentQuery,
              ...companies,
            },
          });

          get?.(url).then((response) => {
            setResp(response);
          });
        }}
      >
        {providers?.map((p) => (
          <p key={p}>
            <input
              type="checkbox"
              name="provider"
              id={p}
              value={p}
            />
            <label htmlFor={p}>{p}</label>
          </p>
        ))}

        <h2>그 외 옵션</h2>
        <a href={Paths.ProtoVSMMap}>지도로 이동해서 위치 다시 가져오기 [클릭]</a>

        <ObjectViewer object={curOptions} />
        <br />
        <input
          type="submit"
          value="검색"
        />
      </form>

      {resp?.data?.vehicles && <div>결과 : {resp?.data?.vehicles?.length}</div>}

      {resp?.data?.vehicles?.map((item, i) => {
        return (
          <li
            key={i}
            className={s.item}
          >
            <div>
              <ObjectViewer object={item} />
            </div>
            <div>
              <button
                onClick={() => {
                  post(API_PATH.POST_TRIP_START, {
                    company: item.company,
                    lat: item.lat,
                    lng: item.lon,
                    vehicleId: item.vehicleId,
                  }).then((e: any) => {
                    if (e.code === 200) {
                      window.location.reload();
                    }

                    window.alert(JSON.stringify(e));
                  });
                }}
              >
                {API_PATH.POST_TRIP_START}
              </button>
            </div>
            <div>
              <button
                onClick={(evt) => {
                  const url = API_PATH.GET_VEHICLE_INFO.replace('{company}', item.company).replace(
                    '{vehicleId}',
                    item.vehicleId
                  );

                  get?.(url).then((e: any) => {
                    const target = evt.nativeEvent.target as any;

                    if (e.code === 200 && target) {
                      target.innerHTML = (target.innerText || '') + '<br/>' + JSON.stringify(e);
                      return;
                    }

                    window.alert(JSON.stringify(e));
                  });
                }}
              >
                {API_PATH.GET_VEHICLE_INFO}
              </button>
            </div>
            <div>
              <button
                onClick={(evt) => {
                  const url = API_PATH.POST_VEHICLE_RING.replace('{company}', item.company).replace(
                    '{vehicleId}',
                    item.vehicleId
                  );

                  post(url, {
                    lat: item.lat,
                    lng: item.lon,
                  }).then((e: any) => {
                    const target = evt.nativeEvent.target as any;

                    if (e.code === 200 && target) {
                      target.innerHTML = (target.innerText || '') + '<br/>' + JSON.stringify(e);
                      return;
                    }

                    window.alert(JSON.stringify(e));
                  });
                }}
              >
                {API_PATH.POST_VEHICLE_RING}
              </button>
            </div>
          </li>
        );
      })}
      {resp?.message && <div>{resp.message}</div>}
    </div>
  );
};

export default ProtoPmRentPage;
