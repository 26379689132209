import { ParsedQuery } from 'query-string';
import { useMemo } from 'react';
import { EBannerType, EPopupViewOption, TPmBanner, TPmPopup } from 'types/api/User';
import { isInTime } from 'utils/date';
import { parseAppScheme } from 'utils/tmapInApp';

import useUserInfo from './useUserInfo';

const getState = (item?: TPmBanner | TPmPopup) => {
  if (item && item.use) {
    const inTime = isInTime(item.startDateTime, item.endDateTime);

    if (inTime) {
      return item;
    }
  }

  return null;
};

const parseBannerState = (banner: TPmBanner) => {
  const state = getState(banner) as TPmBanner;
  const appSchemeData = parseAppScheme(state?.landingUrl);

  const s = {
    bannerText: state ? state.title : '',
    bannerUrl: state ? state.landingUrl : '',
    bannerId: state ? state.bannerId : '',
    bannerType: state?.bannerType || EBannerType.NOTICE,
    serviceName: appSchemeData ? appSchemeData.serviceName : undefined,
    params: appSchemeData ? appSchemeData.params : undefined,
    inServiceLandingUrl: appSchemeData ? appSchemeData.landing : undefined,
  };

  return {
    ...s,
    visible: !!s.bannerText,
  };
};

const parsePopupState = (popup) => {
  const state = getState(popup) as TPmPopup;
  const appSchemeData = parseAppScheme(state?.landingUrl);

  const s = {
    imageUrl: state ? state.imgUrl : '',
    eventUrl: state ? state.landingUrl : '',
    popupId: state ? state.popupId : undefined,
    viewOption: state?.viewOption || EPopupViewOption.NEVER,
    serviceName: appSchemeData ? appSchemeData.serviceName : undefined,
    params: appSchemeData ? appSchemeData.params : undefined,
    inServiceLandingUrl: appSchemeData ? appSchemeData.landing : undefined,
  };

  return {
    ...s,
    visible: !!s.imageUrl,
  };
};

export type TNotice = {
  imageUrl?: string;
  eventUrl?: Nullable<string>;
  popupId?: number;
  viewOption?: EPopupViewOption;
  serviceName?: string;
  params?: ParsedQuery<string>;
  inServiceLandingUrl?: string;
  bannerId?: number | string;
  bannerText?: string;
  bannerUrl?: Nullable<string>;
  bannerType?: EBannerType;
  visible: boolean;
};

const usePmNotice = () => {
  const { pmLaunchStatus } = useUserInfo();

  const bannerListState = useMemo<TNotice[]>(() => {
    return pmLaunchStatus?.data?.bannerList?.map((b) => parseBannerState(b)) || [];
  }, [pmLaunchStatus?.data?.bannerList]);

  const popupListState = useMemo<TNotice[]>(() => {
    return pmLaunchStatus?.data?.popupList?.map((b) => parsePopupState(b)) || [];
  }, [pmLaunchStatus?.data?.popupList]);

  const visibleBannerList = useMemo(
    () => bannerListState.filter((b) => b.visible),
    [bannerListState]
  );

  return {
    bannerList: bannerListState,
    popupList: popupListState,
    visibleBannerList,
  };
};

export default usePmNotice;
