import ModalPopup from 'component/ModalPopup';
import usePopupLayer from 'hooks/usePopupLayer';

const ProtoPopupDesignPage = () => {
  const dimmed1 = usePopupLayer('1');
  const dimmed2 = usePopupLayer('2');
  const dimmed3 = usePopupLayer('3');

  return (
    <div>
      <button onClick={() => dimmed1.show()}>1 열기</button>
      <button onClick={() => dimmed2.show()}>2 열기</button>
      <button onClick={() => dimmed3.show()}>3 열기</button>
      {dimmed1.visible && (
        <ModalPopup
          title={'해당 페이지를 닫으시나요?'}
          detail={
            '기기 이용은 종료되지 않아요. 라이딩을 마치시면 앱으로 돌아와 꼭 반납하기 버튼을 눌러주세요.'
          }
          buttons={[
            {
              type: 'cancel',
              children: '취소',
              onClick: () => dimmed1.hide(),
            },
            {
              type: 'confirm',
              children: '확인',
              onClick: () => {
                alert('결제 액션');
                dimmed1.hide();
              },
            },
          ]}
        />
      )}
      {dimmed2.visible && (
        <ModalPopup
          titleComponent={
            <>
              기기를 찾을 수 없습니다.
              <br />
              입력하신 코드를 확인해 주세요.
            </>
          }
          buttons={[
            {
              type: 'confirm',
              children: '확인',
              onClick: () => dimmed2.hide(),
            },
          ]}
        />
      )}

      {dimmed3.visible && (
        <ModalPopup
          titleComponent={
            <>
              미결제 이용내역이 있습니다.
              <br />
              결제완료 후 서비스를
              <br />
              이용하실 수 있습니다.
            </>
          }
          detailComponent={
            <>
              <div>
                <span>이용일시</span>
                <span>2021.06</span>
              </div>
              <div>
                <span>미결제요금 </span>
                <span>2,2250</span>
              </div>
            </>
          }
          buttons={[
            {
              type: 'confirm',
              children: '확인',
              onClick: () => dimmed3.hide(),
            },
          ]}
        />
      )}
    </div>
  );
};

export default ProtoPopupDesignPage;
