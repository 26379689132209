import classNames from 'classnames';
import DescriptionList from 'component/DescriptionList';
import { ReactComponent as IconGuide5 } from 'resource/images/guide_extra charge.svg';
import { ReactComponent as IconGuide4 } from 'resource/images/guide_img_area.svg';
import { ReactComponent as IconGuide1 } from 'resource/images/guide_img_bike.svg';
import { ReactComponent as IconGuide3 } from 'resource/images/guide_img_discovery.svg';
import { ReactComponent as IconGuide2 } from 'resource/images/guide_img_license.svg';
import { ReactComponent as IconHand } from 'resource/images/ico_hand.svg';
import { ReactComponent as IconCaution } from 'resource/images/ico_sign_caution.svg';
import { ReactComponent as RightIcon } from 'resource/images/icon_arrow_right_gray_16.svg';
import s from 'styles/components/guide/Guide.module.scss';
import guideStyle from 'styles/components/guide/GuideBeforeUse.module.scss';
import { EGuideAnchor, EGuideTab } from 'types/App';

import GuideItem from './GuideItem';

const GUIDE_LIST = [
  '이용요금은 잠금해제 요금+주행요금을 합산하여 책정됩니다.',
  '각 운영사 정책에 따라 지역, 이용시간, 기기별로 이용요금이 상이합니다.',
  '서비스 이용전 이용하려는 기기의 핀을 클릭하여 상세 요금을 확인할 수 있습니다.',
];

type TProp = {
  onUpdateQuery: (type: string, anchor: string) => void;
};

const GuideBeforeUse = ({ onUpdateQuery }: TProp) => {
  return (
    <div className={s.guide_wrap}>
      <div className={classNames(s.guide_item, guideStyle.item)}>
        <h3
          className={s.title}
          data-anchor={EGuideAnchor.BEFORE_USER_GUIDE}
        >
          기본 이용 방법
        </h3>
        <GuideItem
          list={[
            {
              SvgComponent: <IconGuide1 />,
              title: '바이크 이용하기',
              desc: (
                <>
                  바이크는 면허 등록 없이
                  <br />
                  이용이 가능합니다.
                </>
              ),
            },
            {
              SvgComponent: <IconGuide2 />,
              title: '킥보드 운전면허 등록하기',
              desc: (
                <>
                  킥보드의 안전한 이용을 위해
                  <br />
                  면허등록은 필수입니다.
                </>
              ),
            },
            {
              SvgComponent: <IconGuide3 />,
              title: '주변 바이크/킥보드 탐색',
              desc: (
                <>
                  지도를 움직여 내 주변에 있는
                  <br />
                  바이크와 킥보드를 찾아보세요.
                </>
              ),
            },
            {
              SvgComponent: <IconGuide4 />,
              title: (
                <div className={guideStyle.item_title}>
                  <IconHand />
                  주행 가능한 구역 확인
                </div>
              ),
              desc: (
                <>
                  이용하실 기기의 핀을 눌러 서비스
                  <br />
                  구역과 이용요금을 확인하세요.
                </>
              ),
            },
            {
              SvgComponent: <IconGuide5 />,
              title: (
                <div className={guideStyle.item_title}>
                  <IconHand />
                  추가요금 구역 확인
                </div>
              ),
              desc: (
                <>
                  반납 불가, 주행 불가 구역에서는
                  <br />
                  추가 요금이 발생합니다.
                </>
              ),
            },
          ]}
        />
        <div
          className={guideStyle.caution_wrapper}
          onClick={() => {
            onUpdateQuery(EGuideTab.RETURN, EGuideAnchor.RETURN_WARNING);
          }}
        >
          <div className={guideStyle.caution_text_wrapper}>
            <IconCaution />
            <span className={guideStyle.caution_text}>견인료 발생 상황 보러가기</span>
          </div>
          <RightIcon />
        </div>
      </div>

      <div className={s.bar} />

      <div className={s.guide_item}>
        <h3
          className={classNames(s.title, guideStyle.guide_title)}
          data-anchor={EGuideAnchor.BEFORE_PRICE_GUIDE}
        >
          이용 요금 안내
        </h3>

        <DescriptionList
          itemClassName={guideStyle.desc_item}
          list={GUIDE_LIST}
        />
      </div>
    </div>
  );
};

export default GuideBeforeUse;
