import { FULFILLED_STATE, PENDING_STATE, REJECTED_STATE } from 'constant/Api';
import { useCallback, useMemo, useState } from 'react';
import tripOrderRepository from 'repository/tripOrderRepository';
import userInfoStore from 'store/userInfoStore';
import { TApiStatus } from 'types/Api';
import { EReturnPaymentStatus, TPaymentInfo } from 'types/App';
import { EUserStep } from 'types/api/User';
import { polling } from 'utils/apis';

const DEFAULT_PAYMENT_INFO = { ...PENDING_STATE, data: undefined, error: undefined };

const usePmPayResult = () => {
  const [payResult, setPayResult] =
    useState<Partial<TApiStatus<TPaymentInfo>>>(DEFAULT_PAYMENT_INFO);
  const { success, paymentInfo } = useMemo(() => {
    return {
      success: payResult?.data?.status === EReturnPaymentStatus.PURCHASE_SUCCESS,
      paymentInfo: payResult?.data,
    };
  }, [payResult]);

  const pollingPaymentResult = useCallback((tripId?: number, tripOrderId?: number) => {
    const fetcher = () => {
      return new Promise<TPaymentInfo>((resolve, reject) => {
        tripOrderRepository
          .getTripOrderInfo(tripOrderId)
          .then((data) => {
            const isFinish = [
              EReturnPaymentStatus.PENDING,
              EReturnPaymentStatus.PURCHASE_SUCCESS,
              EReturnPaymentStatus.PURCHASE_FAIL,
            ].includes(data.status);

            isFinish ? resolve(data) : reject();
          })
          .catch(() => {
            reject();
          });
      });
    };

    return polling<TPaymentInfo>(fetcher, { retryCount: Infinity, intervalTime: 1000 })
      .then((data) => {
        setPayResult({ ...FULFILLED_STATE, data });

        if (
          [EReturnPaymentStatus.PURCHASE_FAIL, EReturnPaymentStatus.PENDING].includes(data.status)
        ) {
          userInfoStore.setPmStatus({
            step: EUserStep.UNPAID,
            tripInfo: { tripId, tripOrderId },
          });
        } else {
          userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
        }
      })
      .catch((err) => {
        setPayResult({ ...REJECTED_STATE, error: err, data: undefined });
        userInfoStore.setPmStatus({ step: EUserStep.STAND_BY });
      });
  }, []);

  return { payResult, setPayResult, success, paymentInfo, pollingPaymentResult };
};

export default usePmPayResult;
