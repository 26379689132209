import axios from 'axios';
import { VSM_TILE_URL, VSM_TILE_VERSION_URL } from 'constant/Path';
import { COMPANY_NAME_MAP } from 'constant/Scooter';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { EAreas, ECompanyType, EGeometryType, EMapErrorCode, TArea, TGeometry } from 'types/App';
import { devLog } from 'utils/dev';

import useMap from './useMap';
import useMapPolygon from './useMapPolygon';
import usePmStore from './usePmStore';
import useUserInfo from './useUserInfo';

const PolygonColorMap = {
  [EAreas.NO_SERVICE]: '#666666',
  [EAreas.NO_PARKING]: '#f26347',
  [EAreas.CHARGE_ADDITIONAL]: '#ea7903',
};

const getPolygonStyle = (type: EAreas) => ({
  fill: PolygonColorMap[type],
  'fill-opacity': 0.5,
});

// const getLineStyle = (type: EAreas) => ({
//   stroke: PolygonColorMap[type],
//   'stroke-width': '2',
//   'stroke-opacity': '0.5',
// });

const STACK_ID_MAP: Record<ECompanyType, object> = {
  [ECompanyType.XINGXING]: {
    [EAreas.CHARGE_ADDITIONAL]: 100010002,
    [EAreas.NO_SERVICE]: 100010001,
    [EAreas.NO_PARKING]: 100010003,
  },
  [ECompanyType.GCOOTER]: {
    [EAreas.NO_SERVICE]: 100010010,
    [EAreas.NO_PARKING]: 100010011,
  },
  // 씽씽과 동일
  [ECompanyType.TMAP]: {
    [EAreas.CHARGE_ADDITIONAL]: 100010002,
    [EAreas.NO_SERVICE]: 100010001,
    [EAreas.NO_PARKING]: 100010003,
  },
};

const AREA_NAME_MAP = {
  [EAreas.CHARGE_ADDITIONAL]: '추가 과금지역',
  [EAreas.NO_SERVICE]: '서비스 외 지역',
  [EAreas.NO_PARKING]: '주차 금지구역',
};

export const STACK_ID_LIST = Object.values(STACK_ID_MAP)
  .map((v) => Object.values(v))
  .flat();

const VECTOR_TILE_STACKS = Object.keys(STACK_ID_MAP)
  .map((company) =>
    Object.keys(STACK_ID_MAP[company]).map((areaType) => ({
      company,
      id: STACK_ID_MAP[company][areaType],
      areaType: areaType as EAreas,
      name: `${COMPANY_NAME_MAP[company]} ${AREA_NAME_MAP[areaType]}`,
    }))
  )
  .flat();

// 모두 vector tile 이여서 api 를 호출 할 필요가 없으면 true
// XINXING, TMap : NO_PARKING(vt), NO_SERVICE(vt), CHARGE_ADDITIONAL(vt)
// GCOOTER : NO_PARKING (vt), NO_SERVICE(vt)
const ALL_VECTOR_TILE: Record<ECompanyType, boolean> = {
  [ECompanyType.XINGXING]: true,
  [ECompanyType.GCOOTER]: true,
  [ECompanyType.TMAP]: true,
};

const POLYGON_VIEW_LEVEL = [14, 15, 16, 17, 18, 19];

const VIEW_LEVEL_MAP: Record<ECompanyType, object> = {
  [ECompanyType.XINGXING]: {
    [EAreas.NO_PARKING]: POLYGON_VIEW_LEVEL,
  },
  [ECompanyType.GCOOTER]: {
    [EAreas.NO_PARKING]: POLYGON_VIEW_LEVEL,
  },
  [ECompanyType.TMAP]: {
    [EAreas.NO_PARKING]: POLYGON_VIEW_LEVEL,
  },
};

type THashMap = {
  [key: string]: boolean;
};

type TLayerIdMap = {
  [key: string]: EAreas;
};

type TParsedArea = TArea & {
  geometryList: TGeometry[];
};

const usePmPolygon = () => {
  const {
    addPolygonFeatureCollection,
    addMultiPolygonFeatureCollection,
    addVectorTile,
    isLayerRendered,
    isRenderCompleted,
    isVectorTileRendered,
    repaintByProperties,
  } = useMapPolygon();
  const { removeLayer } = useMap();

  const { pmStatus } = useUserInfo();

  const ridingCompany = useMemo(() => pmStatus.tripInfo?.company, [pmStatus]);

  const { activeCompany, activeItem, getCompanyAreas } = usePmStore();

  const hashMap = useRef<THashMap>({});
  const layerIdMap = useRef<TLayerIdMap>({});
  const tileLayerId = useRef<number>();

  const refNowCompany = useRef(activeCompany);

  useEffect(() => {
    refNowCompany.current = activeItem?.company || ridingCompany || activeCompany;
  }, [activeCompany, activeItem, ridingCompany]);

  const getVersion = useCallback(() => {
    return axios.get(VSM_TILE_VERSION_URL).then((res) => res.data.version);
  }, []);

  const renderPolygon = useCallback(
    (area: TParsedArea) => {
      const coordinates = (area.geometryList || []).reduce((arr, g) => {
        if (hashMap.current[g.hashCode]) {
          return arr;
        }

        hashMap.current[g.hashCode] = true;
        return [...arr, g.coordinates];
      }, [] as any);

      const type = area.type;
      const { layerId } = addPolygonFeatureCollection(coordinates.flat(), getPolygonStyle(type), {
        viewLevels: POLYGON_VIEW_LEVEL,
      });

      layerIdMap.current[layerId] = type;
    },
    [addPolygonFeatureCollection]
  );

  const renderMultiPolygon = useCallback(
    (area: TParsedArea) => {
      hashMap.current[area.geometry.hashCode] = true;

      const type = area.type;
      const { layerId } = addMultiPolygonFeatureCollection(
        area.geometry.coordinates,
        getPolygonStyle(type),
        {
          viewLevels: POLYGON_VIEW_LEVEL,
        }
      );

      layerIdMap.current[layerId] = type;
    },
    [addMultiPolygonFeatureCollection]
  );

  const removeAllLayer = useCallback(() => {
    Object.keys(layerIdMap.current).forEach((key) => {
      removeLayer(key);
    });

    hashMap.current = {};
    layerIdMap.current = {};
  }, [removeLayer]);

  const removeAreaLayer = useCallback(
    (area: EAreas) => {
      layerIdMap.current = Object.keys(layerIdMap.current).reduce((obj, key) => {
        if (layerIdMap.current[key] === area) {
          removeLayer(key);

          return obj;
        }

        return {
          ...obj,
          [key]: layerIdMap.current[key],
        };
      }, {});
    },
    [removeLayer]
  );

  const parseAreaResponse = useCallback((areas: TArea[]) => {
    const parsedData = (areas || []).reduce((obj, area) => {
      const isMultiPolygon = area.geometry.type === EGeometryType.MULTI_POLYGON;

      if (isMultiPolygon) {
        return {
          ...obj,
          [area.type]: area,
        };
      }

      const item = obj[area.type] as TParsedArea;

      if (item) {
        return {
          ...obj,
          [area.type]: {
            ...item,
            geometryList: [...item.geometryList, area.geometry],
          },
        };
      }

      return {
        ...obj,
        [area.type]: {
          ...area,
          geometryList: [area.geometry],
        },
      };
    }, {});

    return Object.values(parsedData) as TParsedArea[];
  }, []);

  const renderVehiclePolygon = useCallback(
    (nowCompany?: ECompanyType, force = false) => {
      if (!nowCompany) {
        return;
      }

      if (refNowCompany.current !== nowCompany) {
        return;
      }

      // 영역을 모두 vectorTile로 그릴 수 있으면 직접 폴리곤 그리지 않도록 함
      if (ALL_VECTOR_TILE[nowCompany]) {
        return;
      }

      getCompanyAreas(nowCompany, force)
        .then((data) => {
          if (refNowCompany.current !== nowCompany) {
            return;
          }

          const areaList = parseAreaResponse(data.areas);

          areaList.forEach((area: TParsedArea) => {
            const { type, geometry } = area;
            const { hashCode, coordinates } = geometry;

            if (coordinates.length === 0) {
              return;
            }

            if (hashMap.current[hashCode]) {
              return;
            }

            const needUpdateLayer = [EAreas.NO_SERVICE, EAreas.CHARGE_ADDITIONAL].includes(type);

            if (needUpdateLayer) {
              removeAreaLayer(type);
            }

            const isMultiPolygon = geometry.type === EGeometryType.MULTI_POLYGON;

            if (isMultiPolygon) {
              removeAreaLayer(type);
              renderMultiPolygon(area);
            } else {
              renderPolygon(area);
            }
          });
        })
        .catch((e) => {
          devLog(e);

          if (e?.message === EMapErrorCode.SAME_AREA) {
            return;
          }
          removeAllLayer();
        });
    },
    [removeAreaLayer, removeAllLayer, renderMultiPolygon, renderPolygon, parseAreaResponse]
  );

  const toggleVectorTile = useCallback(
    (company?: ECompanyType) => {
      if (!tileLayerId.current) {
        return;
      }

      if (refNowCompany.current !== company) {
        return;
      }

      repaintByProperties({
        key: 'serviceName',
        value: company,
        layerId: tileLayerId.current,
      });
    },
    [repaintByProperties]
  );

  const hideAllVectorTile = useCallback(() => toggleVectorTile(), [toggleVectorTile]);

  const initVehicleVectorTile = useCallback(() => {
    getVersion().then((version) => {
      const { layerId } = addVectorTile({
        version,
        name: 'scooter',
        uri: `${VSM_TILE_URL}?version=${version}`,
        stacks: VECTOR_TILE_STACKS.map((s) => ({
          id: s.id,
          name: s.name,
          properties: {
            serviceName: s.company,
          },
          viewLevels: VIEW_LEVEL_MAP[s.company][s.areaType],
          style: {
            geometry: {
              ...getPolygonStyle(s.areaType),
            },
          },
        })),
      });

      tileLayerId.current = layerId;
      toggleVectorTile();
    });
  }, [addVectorTile, getVersion, toggleVectorTile]);

  return {
    getLayerIdMap: () => layerIdMap.current,
    isRenderCompleted,
    isLayerRendered,
    isVectorTileRendered,
    removeAllLayer,
    renderVehiclePolygon,
    initVehicleVectorTile,
    toggleVectorTile,
    hideAllVectorTile,
  };
};

export default usePmPolygon;
