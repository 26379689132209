import { TProps as TPaymentAnimationProps } from 'component/PaymentAnimation';
import { TProps as TAnimationProps } from 'component/PmRunningAnimation';
import { LOADING_TEXT } from 'constant/Scooter';
import { useJSXMultiline } from 'hooks/useJSXMultiline';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import s from 'styles/components/pmMain/PmRentStatusLayer.module.scss';
import { ELottieType } from 'types/App';

type TProps = {
  loading?: boolean;
  type: ELottieType;
};

const Animation = React.lazy(() => import('component/PmRunningAnimation'));
const PaymentAnimation = React.lazy(() => import('component/PaymentAnimation'));

export const UnlockLoadableRunningAnimation = ({ autoplay }: TAnimationProps) => (
  <Suspense fallback={<div />}>
    <Animation autoplay={autoplay} />
  </Suspense>
);

const PaymentLoadableRunningAnimation = ({ autoplay }: TPaymentAnimationProps) => (
  <Suspense fallback={<div />}>
    <PaymentAnimation autoplay={autoplay} />
  </Suspense>
);

const Component = {
  [ELottieType.UNLOCK]: (props) => <UnlockLoadableRunningAnimation {...props} />,
  [ELottieType.PAYMENT]: (props) => <PaymentLoadableRunningAnimation {...props} />,
};

export const PmRentStatusLayer = ({ loading, type }: TProps) => {
  const [visible, setVisible] = useState(loading);

  const title = useMemo(() => LOADING_TEXT[type], [type]);
  const titleJSX = useJSXMultiline(title || '');

  const AnimationComponent = useCallback((props) => Component[type]?.(props), [type]);

  useEffect(() => {
    loading && setVisible(true);
    return () => {
      loading && setVisible(false);
    };
  }, [loading]);

  if (!visible) {
    return null;
  }

  return (
    <div className={s.wrap}>
      <div className={s.image}>
        <AnimationComponent autoplay={true} />
      </div>
      <div className={s.comment}>{titleJSX}</div>
    </div>
  );
};

export default PmRentStatusLayer;
