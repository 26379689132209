import classNames from 'classnames';
import useOnce from 'hooks/useOnce';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmNotice from 'hooks/usePmNotice';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as IconAlert } from 'resource/images/icon-alert.svg';
import { ReactComponent as IconRightArrow } from 'resource/images/icon_arrow_right_gray_16.svg';
import s from 'styles/components/pmMain/PmBottomNotice.module.scss';
import { APP_SCHEME } from 'types/App';
import { EBannerType } from 'types/api/User';
import { sendAFEventBannerClick, sendAFEventBannerView } from 'utils/appsFlyer';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import RollingBanner from './RollingBanner';

const BANNER_TEXT: Record<EBannerType, string> = {
  [EBannerType.NOTICE]: '공지',
  [EBannerType.BENEFIT]: '혜택',
};

const BANNER_CLASS_NAME: Record<EBannerType, string> = {
  [EBannerType.NOTICE]: s.notice,
  [EBannerType.BENEFIT]: s.benefit,
};

const PmBottomNotice = () => {
  const { visibleBannerList } = usePmNotice();
  const { isRiding } = usePmUsingStatus();
  const { sendClickLog } = usePmLogger(LogPageId.Main);

  const [nowBannerList, setNowBannerList] = useState<any[]>([]);

  const handleClickBanner = useCallback(
    (banner) => {
      if (banner.serviceName === APP_SCHEME && banner.inServiceLandingUrl) {
        tmapWrapper.openServiceByUrl(banner.inServiceLandingUrl);
      } else {
        tmapWrapper.openServicePage({
          serviceName: banner.serviceName,
          params: banner.params,
          url: banner.bannerUrl,
        });
      }

      sendAFEventBannerClick(banner.bannerId);
      sendClickLog('tap.textbanner', { seq: banner.bannerId });
    },
    [sendClickLog]
  );

  useEffect(() => {
    if (visibleBannerList.length > 0) {
      setNowBannerList(visibleBannerList);
    }
  }, [visibleBannerList]);

  useOnce(visibleBannerList.length > 0, () => {
    const bannerId = visibleBannerList[0].bannerId;

    if (bannerId) {
      sendAFEventBannerView(bannerId);
    }
  });

  if (isRiding) {
    return (
      <div className={s.notice_wrap}>
        <div className={s.text_wrap}>
          <i className={s.icon_alert}>
            <IconAlert />
          </i>
          <div className={s.text}>
            반납 시 <em>빨간색, 노란색, 어두운 영역</em>을 주의하세요.
          </div>
        </div>
      </div>
    );
  }

  if (nowBannerList.length > 0) {
    return (
      <RollingBanner
        className={s.rolling_wrap}
        delayTimeMs={2 * 1000}
      >
        {nowBannerList.map((b, i) => (
          <div
            key={i}
            className={s.notice_wrap}
            onClick={() => handleClickBanner(b)}
          >
            <div className={s.text_wrap}>
              {b.bannerType && (
                <div className={classNames(s.icon, BANNER_CLASS_NAME[b.bannerType])}>
                  {BANNER_TEXT[b.bannerType]}
                </div>
              )}
              <div className={s.text}>{b.bannerText}</div>
            </div>
            {(b.inServiceLandingUrl || b.serviceName || b.bannerUrl) && (
              <IconRightArrow
                width={16}
                height={16}
              />
            )}
          </div>
        ))}
      </RollingBanner>
    );
  }

  return null;
};

export default PmBottomNotice;
