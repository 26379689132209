import {
  API_PATH,
  EApiResponseCode,
  FULFILLED_STATE,
  PENDING_STATE,
  REJECTED_STATE,
} from 'constant/Api';
import { useCallback, useState } from 'react';
import userInfoStore from 'store/userInfoStore';
import { getDefaultApiStatus } from 'utils/apis';
import fetcher from 'utils/fetcher';

import { usePmUsingStatus } from './usePmUsingStatus';

const DEFAULT_STATE = getDefaultApiStatus(undefined);

export const useDriverLicenseVerify = () => {
  const { hasLicense } = usePmUsingStatus();
  const [apiState, setApiState] = useState(DEFAULT_STATE);

  const resetCheck = useCallback(() => {
    setApiState(DEFAULT_STATE);
  }, []);

  const verifyCheck = useCallback((data) => {
    setApiState({
      ...PENDING_STATE,
      error: undefined,
      data: undefined,
    });

    return fetcher
      .post(API_PATH.POST_USER_LICENSE_VERIFY, data)
      .then((res) => {
        if (`${res.data.code}` === EApiResponseCode.SUCCESS) {
          setApiState({
            ...FULFILLED_STATE,
            data: res.data,
            error: undefined,
          });
          userInfoStore.setLicenseRegister();
          return;
        }

        setApiState({
          ...REJECTED_STATE,
          data: undefined,
          error: res.data,
        });
      })
      .catch((err) => {
        setApiState({
          ...REJECTED_STATE,
          data: undefined,
          error: err,
        });

        throw err;
      });
  }, []);

  const updateSuccessState = () => {
    setApiState({
      ...FULFILLED_STATE,
      data: undefined,
      error: undefined,
    });

    return Promise.resolve();
  };

  return {
    resetCheck,
    verifyCheck: hasLicense ? updateSuccessState : verifyCheck,
    apiState,
    hasLicense,
  };
};
