import ImageLoader from 'component/ImageLoader';
import TmdsEventPopup from 'component/tmds/TmdsEventPopup';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import usePmNotice, { TNotice } from 'hooks/usePmNotice';
import { useCallback } from 'react';
import s from 'styles/components/PmPopupController.module.scss';
import { APP_SCHEME } from 'types/App';
import { sendAFEventBannerClick } from 'utils/appsFlyer';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

type TProps = {
  visible: boolean;
};

const PmPopupController = ({ visible }: TProps) => {
  const { popupList } = usePmNotice();
  const eventLogger = usePmLogger(LogPageId.EventPopup);

  const handleClickPopup = useCallback(
    (item: TNotice) => {
      eventLogger.sendClickLog('tap.detail', {
        seq: item.popupId,
      });

      if (item.serviceName === (APP_SCHEME as any) && item.inServiceLandingUrl) {
        tmapWrapper.openServiceByUrl(item.inServiceLandingUrl);
      } else if (item.serviceName) {
        tmapWrapper.openServicePage({
          serviceName: item.serviceName,
          params: item.params,
          url: item.eventUrl || '',
        });
      }

      sendAFEventBannerClick(item.popupId || '');
    },
    [eventLogger]
  );

  if (!visible) {
    return null;
  }

  return (
    <TmdsEventPopup
      onVisible={(isVisible) => {
        const firstPopup = popupList?.[0];
        if (isVisible && firstPopup) {
          eventLogger.sendClickLog('impression', {
            seq: popupList[0].popupId,
          });
        }
      }}
      onChange={(index) => {
        if (popupList) {
          eventLogger.sendClickLog('impression', {
            seq: popupList[index].popupId,
          });
        }
      }}
    >
      {popupList.map(
        (it) =>
          it.imageUrl && (
            <ImageLoader
              key={it.popupId}
              className={s.image}
              src={it.imageUrl}
              alt="이벤트 이미지"
              onClick={() => handleClickPopup(it as any)}
            />
          )
      )}
    </TmdsEventPopup>
  );
};

export default PmPopupController;
