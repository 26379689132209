import Vsm, { MapOptions } from '@vsm/vsm';
import {
  DEFAULT_MAX_BOUNDS,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  DEFAULT_ZOOM,
  MAP_CONFIG_URL,
} from 'constant/Map';
import { EMapType, TLonLat } from 'types/Map';
import currentQuery from 'utils/query';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

import Store from './base';

type TState = {
  map?: Vsm.Map;
  camera?: Vsm.MapCamera;
  transform?: Vsm.MapTransform;
  isRenderCompleted?: boolean;
  currentPosition?: TLonLat;
};

class MapStore extends Store<TState> {
  constructor() {
    super({});
  }

  destroy = () => {
    if (!this.state?.map) {
      return;
    }

    this.state.map.destroy();
    this.setState({});
  };

  setMap = async (container: HTMLElement) => {
    if (this.state?.map) {
      return this.state.map.getCanvasContainer().parentElement;
    }
    const enoughSpecOption = { and: 7, ios: 12 };
    const loadMapType = Vsm.Extensions.TmapUtils.isEnoughSpec(enoughSpecOption)
      ? EMapType.VECTOR
      : EMapType.RASTER;

    const positionPrefix = 'position_';

    let lonLat = {
      lon: 0,
      lat: 0,
    };

    // extra로 position_lon,lat 로 들어오는 경우 처리
    if (currentQuery.extra?.includes(positionPrefix)) {
      const extra = currentQuery.extra as string;
      const [lon, lat] = extra
        .replace(positionPrefix, '')
        .split(',')
        .map((it) => Number(it));
      lonLat = { lon, lat };
    } else {
      lonLat = (await tmapWrapper.getLastPosition()) || { lon: 0, lat: 0 };
    }

    const defaultOptions: MapOptions = {
      config: MAP_CONFIG_URL[loadMapType],
      zoom: DEFAULT_ZOOM,
      maxBounds: DEFAULT_MAX_BOUNDS,
      center: [lonLat.lon, lonLat.lat],
      minZoom: DEFAULT_MIN_ZOOM,
      maxZoom: DEFAULT_MAX_ZOOM,
      container: container,
    };

    const map = new Vsm.Map({
      ...defaultOptions,
      emitFirstPaint: true,
      interactive: {
        rotate: false,
        pitch: false,
      },
    });

    (window as any).____map = container;

    map.once(Vsm.Map.EventNames.RenderComplete, this.renderCompleted);

    this.setState({
      map,
      camera: map.getCamera(),
      transform: map.getTransform(),
    });

    return map;
  };

  setCurrentPosition = (position: TLonLat) => {
    this.updateState(
      {
        currentPosition: position,
      },
      true
    );
  };

  reset = () => {
    this.setState({}, true);
  };

  private renderCompleted = () => {
    this.setState({
      ...this.state,
      isRenderCompleted: true,
    });
  };
}

const mapStore = new MapStore();

export default mapStore;
