import ReturnImage1 from 'resource/images/guide/return-image-1.png';
import ReturnImage1_2X from 'resource/images/guide/return-image-1@2x.png';
import ReturnImage1_3X from 'resource/images/guide/return-image-1@3x.png';
import ReturnImage2 from 'resource/images/guide/return-image-2.png';
import ReturnImage2_2X from 'resource/images/guide/return-image-2@2x.png';
import ReturnImage2_3X from 'resource/images/guide/return-image-2@3x.png';
import ReturnImage3 from 'resource/images/guide/return-image-3.png';
import ReturnImage3_2X from 'resource/images/guide/return-image-3@2x.png';
import ReturnImage3_3X from 'resource/images/guide/return-image-3@3x.png';
import ReturnImage4 from 'resource/images/guide/return-image-4.png';
import ReturnImage4_2X from 'resource/images/guide/return-image-4@2x.png';
import ReturnImage4_3X from 'resource/images/guide/return-image-4@3x.png';
import ReturnImage5 from 'resource/images/guide/return-image-5.png';
import ReturnImage5_2X from 'resource/images/guide/return-image-5@2x.png';
import ReturnImage5_3X from 'resource/images/guide/return-image-5@3x.png';
import ReturnImage6 from 'resource/images/guide/return-image-6.png';
import ReturnImage6_2X from 'resource/images/guide/return-image-6@2x.png';
import ReturnImage6_3X from 'resource/images/guide/return-image-6@3x.png';
import ReturnImage7 from 'resource/images/guide/return-image-7.png';
import ReturnImage7_2X from 'resource/images/guide/return-image-7@2x.png';
import ReturnImage7_3X from 'resource/images/guide/return-image-7@3x.png';
import ReturnImage8 from 'resource/images/guide/return-image-8.png';
import ReturnImage8_2X from 'resource/images/guide/return-image-8@2x.png';
import ReturnImage8_3X from 'resource/images/guide/return-image-8@3x.png';

const GUIDE_IMAGE_LIST = [
  [ReturnImage1, ReturnImage1_2X, ReturnImage1_3X],
  [ReturnImage2, ReturnImage2_2X, ReturnImage2_3X],
  [ReturnImage3, ReturnImage3_2X, ReturnImage3_3X],
  [ReturnImage4, ReturnImage4_2X, ReturnImage4_3X],
  [ReturnImage5, ReturnImage5_2X, ReturnImage5_3X],
  [ReturnImage6, ReturnImage6_2X, ReturnImage6_3X],
  [ReturnImage7, ReturnImage7_2X, ReturnImage7_3X],
  [ReturnImage8, ReturnImage8_2X, ReturnImage8_3X],
];

const GUIDE_TEXT_LIST = [
  <>
    주차금지 구역
    <br />
    (지도 붉은 색 영역)
  </>,
  <>
    버스/택시 정류장
    <br />
    10m 이내
  </>,
  <>
    지하철역 입/출구
    <br />
    10m 이내
  </>,
  <>
    교통 약자
    <br />
    엘리베이터 앞
  </>,
  <>
    자전거 도로 및<br />
    차도 위
  </>,
  <>
    횡단보도 주변
    <br />
    10m 이내
  </>,
  '점자 보도블록 위',
  '어린이 놀이터',
];

export const GUIDE_LIST = GUIDE_TEXT_LIST.map((text, i) => ({
  text,
  src: GUIDE_IMAGE_LIST[i][0],
  srcSet: GUIDE_IMAGE_LIST[i].slice(1),
}));
