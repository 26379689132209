import { EApiResponseCode } from 'constant/Api';
import { API_HOST } from 'constant/Path';
import { HttpResponse, http } from 'msw';
import { TPageMockCase } from 'tmsw/types/types';

import { MockVehicleItem } from '../api/company/vehicle';
import { MockInitApi } from '../api/init';
import { MockDefaultPayMeanInfoApi } from '../api/user/defaultPayMeanInfo';

const scooterLicenseMockCases: TPageMockCase = {
  successLicense: {
    description: '면허등록 성공',
    mocks: [
      http.post(`${API_HOST}/api/user/licenseVerify`, () => {
        return HttpResponse.json({});
      }),
      http.get(`${API_HOST}/api/company/*/vehicle/*`, () => {
        return HttpResponse.json(new MockVehicleItem({ battery: 20 }));
      }),
      http.get(`${API_HOST}/api/init`, () => {
        return HttpResponse.json(new MockInitApi());
      }),
      http.get(`${API_HOST}/api/user/defaultPayMeanInfo`, () => {
        return HttpResponse.json(new MockDefaultPayMeanInfoApi({ isSuccess: true }));
      }),
    ],
  },
};

export default scooterLicenseMockCases;
