export const firstCharToUppercase = (str = '') => {
  const [first, ...rest] = str;

  return `${first.toUpperCase()}${rest.join('')}`;
};

export const getValueToString = (value: any): string =>
  typeof value === 'boolean' ? value.toString() : `${value}`;

export const clearEmptyString = (str: string) => str.split(' ').join('');
