import s from 'styles/components/terms/PmTermsDetail.module.scss';

export const PmTermsGcooter = () => {
  return (
    <div className={s.content}>
      <h1>제1조 (목적)</h1>
      <div>
        본 약관은 주식회사 지바이크(이하 "회사"라 합니다)가 제공하는 “지쿠(GCOO)”서비스의 이용과
        관련하여 회사와 회원의 권리, 의무 및 책임사항, 기타 필요 사항을 규정함을 목적으로 합니다.
      </div>
      <h1>제2조 (정의)</h1>
      <div>본 약관에서 사용하는 용어는 아래와 같습니다.</div>
      <ul>
        <ol>
          1. “지쿠 서비스”(이하 “서비스”라 합니다)라 함은 “지쿠 애플리케이션”(이하 “앱”이라
          합니다)또는 회사와 별도 제휴관계를 맺은 제3자(이하 “제휴사업자”)가 운영하는 서비스를 통해
          이동수단을 찾고 이동수단을 이용하기 위해 스마트락을 해제/잠금하는 서비스로서, 구체적인
          내용은 제8조에 규정된 바와 같습니다.
        </ol>
        <ol>
          2. “회원”이라 함은 회사의 서비스에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고
          회사가 제공하는 서비스를 이용하는 고객을 말합니다.
        </ol>
        <ol>
          3. “제휴사업자”라 함은 자사의 앱 혹은 웹페이지에 지쿠 서비스를 제공하는 사업자를 말합니다.
        </ol>
        <ol>4. “이용요금”이라 함은 제휴사업자의 정책에 따라 회원에게 부과되는 요금을 말합니다.</ol>
      </ul>
      <h1>제3조 (약관의 효력 및 변경)</h1>
      <ul>
        <ol>1. 본 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 가집니다.</ol>
        <ol>
          2. 회사 및 제휴사업자는 본 약관의 내용을 회원이 쉽게 알 수 있도록 회사의 서비스 화면에 본
          약관을 게시하거나 기타의 방법으로 회원에게 공지하고, 이러한 게시 및 공지를 통하여 본
          약관의 효력이 발생합니다.
        </ol>
        <ol>
          3. 회사는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」
          등 관련 법령에 위배되지 않는 범위에서 본 약관을 개정할 수 있습니다. 약관이 개정된 경우
          회사 및 제휴사업자는 개정된 약관의 적용일자 14일 전까지 개정된 약관을 종전 약관과 함께
          앱푸시, 이메일, 문자메세지, 카카오톡 등을 통해 통지합니다. 단, 회원의 권리 또는 의무에
          영향을 미치는 중요한 내용이 변경되지 않는 경우에는 제2항과 같은 방법으로 공지할 수
          있습니다.
        </ol>
        <ol>
          4. 회사가 전항에 따라 개정된 약관을 공지 또는 통지하면서 회원에게 개정된 약관 적용일자까지
          거부의사를 표시할 것을 공지 또는 통지 하였음에도 회원이 명시적으로 거부의 의사표시를 하지
          아니한 경우 회원이 개정된 약관에 동의한 것으로 봅니다.
        </ol>
        <ol>
          5. 회원이 개정된 약관의 적용에 동의하지 않는 경우 회사는 개정된 약관의 내용을 적용할 수
          없으며, 이 경우 회사 또는 회원은 이용계약을 해지할 수 있습니다.
        </ol>
      </ul>
      <h1>제4조(서비스 이용계약 체결)</h1>
      <ul>
        <ol>
          1. 서비스 이용계약을 체결하고 싶은 자는 회사가 요구하는 항목들을 사실대로 기재하고
          회원가입을 신청할 수 있습니다.
        </ol>
        <ol>
          2. 회사는 원칙적으로 회원가입신청에 대하여 승낙합니다. 다만, 다음 각 호의 사유가 있는
          경우, 승낙을 하지 않거나 사후에 서비스 이용계약을 해지할 수 있습니다. ① 가입 신청자가
          신청일 기준으로 만 13세 미만인 경우 ② 회원 가입 신청 시 입력한 정보가 사실과 다른 경우 ③
          가입 신청자가 약관, 기타 법령을 위반하여 회원자격을 상실한 적이 있거나, 이용정지 상태인
          경우
        </ol>
        <ol>
          3. 제1항에 따른 신청에 있어 회사는 전문기관에 회원의 실명확인 및 본인인증을 요청할 수
          있습니다.
        </ol>
      </ul>
      <h1>제5조(회원정보의 변경)</h1>
      <ul>
        <ol>
          1. 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 직접 수정하거나 이메일
          등 기타 방법으로 회사 또는 제휴사업자에 대하여 그 변경사항을 알려야 합니다.
        </ol>
      </ul>
      <h1>제6조(약관 외 준칙)</h1>
      <ul>
        <ol>
          1. 회사는 필요한 경우 이용정책을 정하여 운영할 수 있으며 해당 내용은 앱, 홈페이지,
          제휴사업자의 채널 등을 통해 공지합니다.
        </ol>
        <ol>2. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령을 따릅니다.</ol>
        <ol>3. 회원은 항상 이용정책의 내용에 변경이 있는지 여부를 확인하여야 합니다..</ol>
      </ul>
      <h1>제7조(서비스 이용제한 등)</h1>
      <ul>
        <ol>
          1. 회사는 다음 각 호에 해당하는 경우 회원에 대하여 서비스 제공을 제한하거나 중지할 수
          있습니다.
          <p>① 회사 혹은 제휴사업자와 회원 간에 서비스 제공과 관련된 약정이 종료되는 경우</p>
          <p>② 회원이 본 약관의 의무를 위반한 경우 </p>
          <p>③ 회원이 타인의 명의를 도용하여 회원가입을 한경우 </p>
          <p>④ 유료결제를 함에 있어서 타인 명의 결제 도용, 전화번호 도용 등을 한 경우 </p>
          <p>
            ⑤ 불법프로그램의 제공 및 운영 방해, 정보통신망법을 위반한 불법 통신 및 해킹,
            악성프로그램의 배포, 접속 권한 초과 행위 등과 같이 법령을 위반한 경우
          </p>
        </ol>
        <ol>
          2. 본 조 제1항에 따라 회원의 귀책사유로 인하여 서비스 이용이 제한 또는 중지된 경우, 회사는
          회원에게 통지로써 회원자격을 상실 또는 1년 동안 정지시킬 수 있습니다.
        </ol>
        <ol>
          3. 회사는 다음 각 호의 경우 당해 서비스의 전부 또는 일부의 제공을 중단하거나 제한할 수
          있습니다.
          <p>
            ① 천재지변, 전쟁, 폭동, 화재, 파업 등 쟁의행위, 정부기관의 통제 기타 회사의 합리적인
            노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는 경우
          </p>
          <p>② 기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우 </p>
          <p>
            ③ 서비스용 설비의 장애 및 정기 점검, 보수점검, 교체 또는 고장, 통신의 두절 등의 사유가
            발생한 경우
          </p>
          <p>
            ④ 서비스가 제3자와의 제휴를 통하여 제공되는 경우에 당해 제휴사업자의 사정에 따라
            변경되거나 중지되는 경우
          </p>
          <p>⑤ 기타 서비스의 원활한 운영을 현저히 저해하는 사유가 발생한 경우</p>
        </ol>
        <ol>
          4. 본 조에 따라 서비스 이용을 제한하거나 중지하는 경우에는 회사는 사전에 이를 회원에게
          통지합니다. 다만 부득이한 경우 사후에 통지할 수 있습니다.
        </ol>
      </ul>
      <h1>제8조(서비스 제공)</h1>
      <ul>
        <ol>
          1. 회사가 제공하는 서비스의 내용은 다음과 같습니다.
          <p>① 이동수단 이용 및 대여 서비스</p>
          <p>② 위치기반 서비스: 회원 및 이동수단의 위치정보를 활용한 이동수단 위치 제공 서비스</p>
        </ol>
        <ol>
          2. 회사는 제1항의 각 호의 서비스 이외에도 추가적인 서비스를 개발하여 회원에게 제공할 수
          있습니다.
        </ol>
        <ol>
          3. 회사가 제공하는 서비스의 사용은 무료입니다. 단, 회사가 제공한 서비스를 통해 이동수단을
          이용할 경우 이용요금이 부과됩니다.
        </ol>
        <ol>
          4. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한
          이유가 있는 경우 서비스 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 회원에게
          중단사실을 통지하여야 합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
          사후에 통지할 수 있습니다.
        </ol>
        <ol>
          5. 회사는 서비스 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 사전에
          통지합니다.
        </ol>
      </ul>
      <h1>제9조(서비스 이용)</h1>
      <ul>
        <ol>
          1. 회원은 회사 또는 제휴사업자가 제공하는 서비스를 통하여 이동수단에 대한 이용요금을
          지불하고 이동수단을 이용하여야 합니다.
        </ol>
        <ol>
          2. 회원이 서비스를 통하여 이동수단을 이용함에 있어서는 본 약관에 규정된 내용은 물론,
          도로교통법, 개인 이동수단 이용 활성화에 관한 법률 및 이와 관련된 각 시행령·시행규칙, 각
          지방자치단체마다 개별적으로 규정된 개인 이동수단 이용 활성화에 관한 조례 등 일체의 관련
          법령 및 자치법규에 규정된 내용을 모두 준수하여야 합니다.
        </ol>
        <ol>
          3. 서비스 이용시 회원의 고의 내지 과실로 인하여 본조 제2항에 규정된 본 약관 내용 및 관련
          법령, 자치법규를 위반하는 회원은 본 약관상 규정된 불이익 내지 관련 법령 및 자치법규상
          규정된 불이익을 받을 수 있습니다.
        </ol>
      </ul>
      <h1>제10조(이용요금)</h1>
      <ul>
        <ol>
          1. 회원이 회사가 제공하는 이동수단을 이용하는 경우, 회원은 이용요금을 지불해야 합니다.
        </ol>
        <ol>
          2. 회사는 서비스에 적용되는 이용요금을 앱 또는 제휴사업자의 채널을 통하여 공지합니다.
        </ol>
        <ol>
          3. 서비스 이용 도중 회원 자신의 위법행위로 인하여 부과받은 벌금 및 과태료 등은 회원 본인이
          직접 부담하여야 합니다.
        </ol>
      </ul>
      <h1>제16조(회사의 의무)</h1>
      <ul>
        <ol>
          1. 회사는 관련 법령 및 본 약관이 금지한 행위 또는 공서양속에 반하는 행위를 하지 않으며,
          계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
        </ol>
        <ol>
          2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해
          보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
        </ol>
        <ol>
          3. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할
          경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 앱을
          활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.
        </ol>
        <ol>
          4. 회사는 정보통신망법, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는
          모든 법령을 준수합니다.
        </ol>
      </ul>
      <h1>제17조(회원의 의무)</h1>
      <ul>
        <ol>1. 회원은 고의, 과실로 초래한 회사, 본인, 제3자에게 끼친 손해에 대해 책임을 집니다.</ol>
        <ol>2. 회원은 운전면허증을 취득 후 전동킥보드를 운행해야 합니다.</ol>
        <ol>3. 회원은 도로교통법을 준수합니다.</ol>
        <ol>
          4. 회원은 전동킥보드를 이용하는 경우 이용방법을 인지하여야 하며, 헬멧과 무릎 보호대 등의
          안전장비를 착용함으로써 스스로를 보호하기 위한 주의를 기울여야 합니다.
        </ol>
        <ol>
          5. 회원은 전동킥보드 운행을 시작 하기 전에 전동킥보드의 타이어, 브레이크, 핸들, 조명,
          프레임 등을 확인하여 이용에 적합하고 안전한지 확인해야 합니다.
        </ol>
        <ol>
          6. 전동킥보드에 기술적 결함이 발견되거나, 훼손되는 경우 즉시 이용을 종료하고 안전한 곳에
          주차하여야 합니다. 결함 및 훼손은 회사에 신고하도록 합니다.
        </ol>
        <ol>
          7. 회원은 서비스 이용에 필요한 휴대전화 번호, 결제정보 등을 안전하게 기밀로 유지해야
          합니다.
        </ol>
        <ol>
          8. 회원은 서비스를 이용하기 전에 앱 또는 제휴사업자의 채널을 통해 이용요금, 반납비용 등
          서비스 조건을 확인해야 합니다.
        </ol>
        <ol>
          9. 회원은 서비스 이용시 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단을
          임의로 사용해서는 안 됩니다.
        </ol>
        <ol>
          10. 회사 또는 제휴사업자가 회원의 본 약관 및 이용정책 위반행위를 발견하여 회원에게 해당
          위반행위에 대하여 소명을 요청할 경우 회원은 회사 또는 제휴사업자의 요청에 적극 응하여야
          합니다.
        </ol>
        <ol>
          11. 회원은 회사 또는 회원간의 사이에서 분쟁이 발생한 경우 해당 분쟁의 해결을 위하여 성실히
          임하여야 합니다.
        </ol>
        <ol>
          12. 회원은 다음 각 호의 행위를 하여서는 안 됩니다.
          <p>■ 운전면허증을 취득하지 않고 전동킥보드를 운행하는 행위 </p>
          <p>■ 헬멧을 착용하지 않고 전동킥보드를 이용하는 행위 </p>
          <p>■ 도로교통법에서 전동킥보드의 운행을 금지한 도로 또는 장소에서 이용하는 행위</p>
          <p>
            ■ 빌딩, 아파트, 주택 등의 내부나 지하주차장 기타 이와 유사한 장소에 전동킥보드를
            주차하는 행위
          </p>
          <p>■ 상업적인 용도로 이용하는 행위 </p>
          <p>■ 약물에 취하거나 음주인 상태로 이용하는 행위 </p>
          <p>■ 전동킥보드 1대에 2명 이상이 탑승하여 이용하는 행위 </p>
          <p>■ 타인에게 상해를 입히거나 불안을 줄 수 있는 방식으로 전동킥보드를 이용하는 행위 </p>
          <p>■ 서비스 가입 또는 회원정보 변경 시 허위내용을 등록하는 행위 </p>
          <p>■ 서비스 가입 또는 사용을 위해 타인의 정보를 도용하는 행위 </p>
          <p>■ 다른 회원의 개인정보 및 계정정보를 수집하는 행위 </p>
          <p>■ 회원의 계정을 타인이 사용하도록 권한을 부여하거나 양도 또는 이전하는 행위 </p>
          <p>■ 소란 또는 불편을 일으키기 위하여 서비스나 앱을 사용하는 행위 </p>
          <p>■ 올바른 네트워크의 작동을 손상시킬 수 있는 행위 </p>
          <p>■ 회사가 게시한 정보를 권한 없이 변경하는 행위 </p>
          <p>
            ■ 회사로부터 서면의 허가 없이 앱이나 기타 서비스의 내용 등 회사가 게시한 정보를
            복사하거나 배포하는 행위
          </p>
          <p>■ 회사 및 기타 제3자의 저작권 등 지적재산권을 침해하는 행위 </p>
          <p>■ 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 </p>
          <p>
            ■ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개
            또는 게시하는 행위
          </p>
          <p>■ 사용할 권한을 부여 받지 아니한 액세스 지점에서 사용하는 행위 </p>
          <p>■ 호환되지 않거나 권한을 부여 받지 않은 장치로 서비스나 앱을 사용하는 행위 </p>
          <p>
            ■ 본 약관 및 관련 법령, 이용정책을 위반하여 회사, 상대 회원 또는 제3자에게 손해를 끼치는
            행위
          </p>
          <p>■ 고의로 이동수단을 훼손하는 행위 </p>
          <p>■ 기타 불법적인 행위</p>
        </ol>
      </ul>
      <h1>제18조(회원에 대한 통지)</h1>
      <ul>
        <ol>1. 회원에 대한 통지는 앱푸시, 이메일, 문자메세지, 카카오톡 등으로 할 수 있습니다.</ol>
        <ol>2. 회원은 공지를 확인할 의무가 있습니다.</ol>
      </ul>
      <h1>제19조(개인정보 수집, 이용 및 보호의무)</h1>
      <ul>
        <ol>
          1. 회사는 서비스의 원활한 제공을 목적으로 개인정보를 수집 및 이용합니다. 이를 위해서
          회사는 회원에게 별도의 동의를 구하여야 합니다.
        </ol>
        <ol>
          2. 회사는 정보통신망법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
          성실히 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의
          개인정보취급방침이 적용됩니다.
        </ol>
      </ul>
      <h1>제20조(서비스 관리 책임)</h1>
      <div>회사의 서비스 관리책임자의 성명, 소속부서, 직위 및 연락처는 다음과 같습니다.</div>
      <p>▣ 주식회사 지바이크 </p>
      <p>1. 성 명 : 윤종수</p>
      <p>2. 직 위 : CEO </p>
      <p>3. 연락처: 1833-5748</p>
      <h1>제21조(개인정보의 동의 철회)</h1>
      <div>
        회원은 회원탈퇴(서비스 해지)의 방법으로 회사의 개인정보의 수집, 이용, 제공에 대한 동의를
        언제든지 철회할 수 있습니다.
      </div>
      <h1>제22조(개인정보의 보유기간 및 이용기간)</h1>
      <div>
        회원의 개인정보는 회사가 서비스를 제공하는 기간 동안에 한하여 보유·이용되며, 회사는
        개인정보의 수집 및 이용목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 다만,
        국세기본법, 법인세법, 부가가치세법 기타 관련 법령의 규정에 의하여 보존할 필요성이 있는
        경우에는 관련 법령에 따라 보존할 수 있습니다.
      </div>
      <h1>제23조(손해 배상)</h1>
      <ul>
        <ol>
          1. 회원 또는 회사가 고의 또는 과실로 본 약관의 규정 내지 관련 법령을 위반함으로써
          상대방에게 손해를 입히게 되는 경우, 본 약관 내지 관련 법령을 위반한 당사자는 상대방에게 그
          손해를 배상하여야 합니다. 그 손해는 통상의 손해에 한하며, 특별한 사정으로 인한 손해는
          그것을 알았거나 알 수 있었을 때에 배상책임을 부담합니다.
        </ol>
        <ol>
          2. 회원은 서비스를 이용함에 있어서 자신의 고의 또는 과실로 다른 회원 또는 제3자에게 신체
          또는 재산상 피해를 입힌 경우 그 손해를 배상하여야 합니다.
        </ol>
      </ul>
      <h1>제24조(회원탈퇴 및 자격상실)</h1>
      <ul>
        <ol>
          1. 회원이 다음 각 호의 1에 해당하는 경우, 회사는 회원의 가입신청을 거절할 수 있습니다.
          <p>① 가입 신청시에 허위 내용을 등록한 경우 </p>
          <p>② 회원이 본 약관 및 관련 법령에 위반되는 행위를 한 적이 있는 경우</p>
        </ol>
        <ol>
          2. 회원은 제1항에 따른 회사의 조치에 대해 회사에 이의신청을 할 수 있으며, 회사는 회원의
          이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 허용합니다.
        </ol>
        <ol>3. 회원이 사망한 경우 회원 사망일에 회원자격이 상실됩니다.</ol>
        <ol>4. 회원 탈퇴 요청 시 1개월간 회원의 재가입이 제한됩니다. </ol>
      </ul>
      <h1>제25조(저작권의 귀속 및 이용제한)</h1>
      <ul>
        <ol>1. 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</ol>
        <ol>
          2. 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이
          복제·송신·출판·배포·방송·기타 방법에 의하여 영리 목적으로 이용하거나 제3자가 이용하도록
          하여서는 안 됩니다.
        </ol>
      </ul>
      <h1>제28조(책임 제한)</h1>
      <div>
        회사는 천재지변 또는 이에 준하는 불가항력 및 회사의 귀책사유가 아닌 사유로 인하여 서비스를
        제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
      </div>
      <h1>제29조(서비스 종료)</h1>
      <ul>
        <ol>
          1. 회사가 서비스를 종료하고자 할 경우 회사는 서비스를 종료하고자 하는 날로부터 3개월
          이전에 SMS, 앱 공지, 제휴사업자 채널 등을 통해 회원에게 고지합니다.
        </ol>
        <ol>
          2. 본 조 제1항에 따라 서비스가 종료되는 경우 회사는 관련 법령에 따라 보유하고 있는
          개인정보를 파기합니다.
        </ol>
      </ul>
      <h1>제30조(준거법 및 관할법원)</h1>
      <div>
        본 약관에서 정하지 않은 사항과 본 약관의 해석 및 관할법원에 대해서는 대한민국법령 및
        상관례에 따릅니다.
      </div>
      <div>부 칙</div>
      <div>본 약관은 2021년 11월 1일부터 시행합니다.</div>
    </div>
  );
};

export default PmTermsGcooter;
