import { TmapApp } from '@tmap-web-lib/tmap-app-interface';
import appEventStore from 'store/appEventStore';
import { TLonLat } from 'types/Map';
import tmapInApp, { setCallback } from 'utils/tmapInApp';
import ua, { getLessThanAppVersion } from 'utils/uaParser';

const handleResume = () => {
  appEventStore.setResumeKey(Date.now());
};

const handlePause = () => {
  appEventStore.pause();
};

class TMapWrapper {
  getCurrentPosition = async (): Promise<TLonLat | null> => {
    if (ua.isInApp) {
      const result = await TmapApp.getCurrentPosition();
      if (result) {
        return {
          lat: result[0],
          lon: result[1],
        };
      }
      return null;
    } else if (navigator.geolocation) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve({ lat: position.coords.latitude, lon: position.coords.longitude });
        });
      });
    } else {
      const errorMsg = '모바일기기 또는 브라우져에서 현위치찾기 기능을 지원하지 않습니다.';
      throw new Error(errorMsg);
    }
  };

  getLastPosition = async (): Promise<TLonLat | null> => {
    if (ua.isInApp) {
      const func = ua.isIos ? TmapApp.getCurrentPosition : TmapApp.getLastPosition;
      const result = await func();
      if (result) {
        return {
          lat: result[0],
          lon: result[1],
        };
      }
      return null;
    } else if (navigator.geolocation) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve({ lat: position.coords.latitude, lon: position.coords.longitude });
        });
      });
    } else {
      const errorMsg = '모바일기기 또는 브라우져에서 현위치찾기 기능을 지원하지 않습니다.';
      throw new Error(errorMsg);
    }
  };

  getAccessKey = (): Promise<string> => {
    return TmapApp.getAccessKey();
  };

  getUserKey = (): Promise<string> => {
    return TmapApp.getEUK();
  };

  getDeviceId = (): Promise<string> => {
    return TmapApp.getDeviceId();
  };

  getCarrierName = (): Promise<string> => {
    return TmapApp.getCarrierName();
  };

  copyClipboard = (label: string, content: string) => {
    TmapApp.copyClipboard({ label, content });
  };

  makeToast = (msg: string) => {
    TmapApp.makeToast({
      msg,
    });
  };

  openServiceByUrl = (
    url: string,
    title?: string,
    callbackJs?: string,
    cacheControl?: boolean,
    portraitonly?: boolean,
    useStatusBarArea?: boolean
  ) => {
    TmapApp.openServiceByUrl({
      portraitonly,
      useStatusBarArea,
      cacheControl,
      url,
      title,
    });
  };

  /**
   * 페이먼트 액티비티를 호출하고 리턴이 오기전에 먼저 onResume으로 아무행동 없이 돌아오게 될 경우에 되면, 실패를 돌려주어야한다.
   */
  startPaymentActivity = (url: string): Promise<[status: string | boolean, msg?: string]> => {
    return new Promise(async (resolve) => {
      setCallback('onResume', () => {
        handleResume();
        resolve([false]);
        setCallback('onResume', handleResume);
      });
      const result = await TmapApp.startPaymentActivity({ url });
      resolve(result);
    });
  };

  openServiceByName = (serviceName: string, data: any = {}) => {
    TmapApp.openServiceByName({ serviceName, jsonData: data });
  };

  startQrCodeScanActivity = async (title: string, showBottom: boolean = true) => {
    return TmapApp.startQrCodeScanActivity({ title, showBottom });
  };

  startCameraActivity = (options: any[]) => {
    return tmapInApp.sendAsync('startCameraActivity', options);
  };

  phoneCall = (phoneNumber: string) => {
    TmapApp.phoneCall({ strTel: phoneNumber });
  };

  openCustomerCenter = () => {
    // https://tmobi.atlassian.net/browse/LEGORENEW-346
    // 참고 https://tmobi.atlassian.net/wiki/spaces/TMAPCLIENT/pages/39110326/InApp+WebView

    const jsonData = { pageid: 'faq', extra: JSON.stringify({ categoryId: 4408642661145 }) };

    this.openServiceByName('customer-center', jsonData);
  };

  openPayPoint = () => {
    // 참고 https://tmobi.atlassian.net/wiki/spaces/TMAPCLIENT/pages/39110326/InApp+WebView

    this.openServiceByName('paypoint', {});
  };

  updateAccessKey = (accessKey: string) => {
    TmapApp.updateAccessKey({ key: accessKey });
  };

  recordEvent = (name: string, data: any) => {
    TmapApp.recordEvent({ name, json: data });
  };

  getDisplayInfo = () => {
    return TmapApp.getDisplayInfo();
  };

  startReportLocation = (url: string) => {
    TmapApp.startReportLocation({ url });
  };

  stopReportLocation = () => {
    TmapApp.stopReportLocation();
  };

  handleBackKeyEventFromWeb = (condition: boolean, fn: () => void) => {
    // 기기가 안드로이드이고 앱버전이 9.1.2 이상인 경우에만 실행
    const osCheck = ua.isInApp && ua.isAndroid;
    const versionCheck = ua.tmapAppVersion && !getLessThanAppVersion('9.1.2');

    /**
     * 9.1.2 이상인 경우 backKey를 눌렀을 때, 클라이언트에서 onHardwareBackKeyPressed를 실행
     * 9.1.2 이하인 경우 backKey를 눌렀을 때, 클라이언트에서 onBackKeyPressedEvent를 실행
     */
    if (osCheck) {
      const eventKey = versionCheck ? 'onHardwareBackKeyPressed' : 'onBackKeyPressedEvent';
      setCallback(eventKey, condition ? fn : undefined);
      TmapApp.handleBackKeyEventFromWeb({ handleFromWeb: condition });
    }
  };

  openBrowser = (url: string) => {
    if (ua.isInApp) {
      TmapApp.openBrowser({ url });
    } else {
      window.location.href = url;
    }
  };

  closeWebview = () => {
    TmapApp.onBackKeyPressed();
  };

  openServicePage = ({
    serviceName,
    params,
    url = '',
  }: {
    serviceName?: string;
    params?: Record<string, any>;
    url?: string;
  }) => {
    if (serviceName) {
      this.openServiceByName(serviceName, params);
    } else {
      url && this.openServiceByUrl(url, '', 'console.log', true);
    }
  };
}

const tmapWrapper = new TMapWrapper();

setCallback('onResume', handleResume);
setCallback('onPause', handlePause);

export default tmapWrapper;
