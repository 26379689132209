import useMapStore from 'hooks/useMapStore';
import usePmMarkerLayer, { LAYER_ID_MAP } from 'hooks/usePmMarkerLayer';
import usePmStore from 'hooks/usePmStore';
import { useCallback, useEffect, useMemo } from 'react';
import { EBatteryState, ECompanyType, EMarkerType, EVehicleType } from 'types/App';
import { TVehicles } from 'types/api/Vehicle';
import { getBatteryState, getVehicleMarker } from 'utils/vehicle';

type TProps = {
  items: TVehicles[];
  isDrawMarker: boolean;
};

const PmMarkerList = ({ items, isDrawMarker }: TProps) => {
  const { map, isRenderCompleted } = useMapStore();
  const { registerSVGResource, addMultiMarker, clearGarbageLayer, deleteAllMarkerLayer } =
    usePmMarkerLayer();
  const { activeItem } = usePmStore();

  const markerGroup = useMemo<Record<string, TVehicles[]>>(() => {
    return items
      .filter((it) => it.viewType === EMarkerType.DEVICE)
      .filter(
        (it) => !(it.vehicleId === activeItem?.vehicleId && it.company === activeItem?.company)
      )
      .reduce((acc, item) => {
        const key = `${item.company}-${item.vehicleType}-${item.event}-${getBatteryState(
          Number(item.battery)
        )}`;
        if (acc[key]) {
          acc[key].push(item);
        } else {
          acc[key] = [item];
        }
        return acc;
      }, {});
  }, [activeItem, items]);

  const drawLayer = useCallback(
    (markerKeys: string[]) => {
      markerKeys.map(async (key, i) => {
        const devices = markerGroup[key];
        return addMultiMarker(
          {
            imageName: key,
            devices,
            packageCode: key,
          },
          i
        );
      });
    },
    [addMultiMarker, markerGroup]
  );

  const drawMarker = useCallback(async () => {
    const markerKeys = Object.keys(markerGroup);

    clearGarbageLayer(markerKeys.map((key) => LAYER_ID_MAP[key]));

    const registryPromiseList = markerKeys.map((key) => {
      const [company, vehicleType, event, battery] = key.split('-');
      return registerSVGResource(key, [
        {
          id: key,
          svgData: getVehicleMarker({
            vehicle: vehicleType as EVehicleType,
            company: company as ECompanyType,
            battery: Number(battery) as EBatteryState,
            event: event === 'true',
          }),
        },
      ]);
    });

    try {
      await Promise.all(registryPromiseList);
    } finally {
      drawLayer(markerKeys);
    }
  }, [markerGroup, clearGarbageLayer, drawLayer, registerSVGResource]);

  useEffect(() => {
    if (!map || !isRenderCompleted) {
      return;
    }
    if (isDrawMarker) {
      drawMarker();
    } else {
      deleteAllMarkerLayer();
    }
  }, [map, drawMarker, deleteAllMarkerLayer, isDrawMarker, isRenderCompleted]);

  return <></>;
};

export default PmMarkerList;
