import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import s from 'styles/components/PaymentCardTitle.module.scss';
import { TCardInfo } from 'types/App';

import { PaymentCardBrandImage } from './PaymentCardBrandImage';

type TProps = TCardInfo & HTMLAttributes<HTMLSpanElement>;

export const PaymentCardTitle = ({
  payMeansDisplayName: aliasDisplayName,
  payMeansDisplayNumber: cardNumber,
  payMeansImageInfo,
  className = '',
}: TProps) => {
  return (
    <span className={s.wrap}>
      <PaymentCardBrandImage payMeansImageInfo={payMeansImageInfo} />
      <span className={classNames(s.wrap, className)}>
        <span>{aliasDisplayName}</span>
        {cardNumber && <span>{`(${cardNumber})`}</span>}
      </span>
    </span>
  );
};
