import { Paths } from 'constant/RoutePath';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { devLog } from 'utils/dev';

const useMovePage = () => {
  const history = useHistory();

  const isMainPage = useCallback(() => {
    return window.location.href.includes(Paths.PmMain);
  }, []);

  const replaceMainPage = useCallback(() => {
    history.replace(Paths.PmMain);
  }, [history]);

  const movePage = useCallback(
    (nextPage) => {
      if (isMainPage()) {
        devLog(`[move] movePage ${window.location.href} history.push(${nextPage})`);
        history.push(nextPage);
      } else {
        devLog(`[move] movePage ${window.location.href} history.replace(${nextPage})`);
        history.replace(nextPage);
      }
    },
    [history, isMainPage]
  );

  const moveMainPage = useCallback(() => {
    if (isMainPage()) {
      devLog(`[move] moveMainPage ${window.location.href} history.replace(Paths.PmMain))`);
      history.replace(Paths.PmMain);
    } else {
      devLog(`[move] moveMainPage ${window.location.href} history.goBack()`);
      history.goBack();
    }
  }, [history, isMainPage]);

  const moveMainPageWithUA = useCallback(() => {
    movePage(Paths.PmMain);
  }, [movePage]);

  return { isMainPage, movePage, replaceMainPage, moveMainPageWithUA, moveMainPage };
};

export default useMovePage;
