import classNames from 'classnames';
import { Paths } from 'constant/RoutePath';
import useOpenPage from 'hooks/useOpenPage';
import { LogPageId, usePmLogger } from 'hooks/usePmLogger';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import { useMemo } from 'react';
import { ReactComponent as IconContact } from 'resource/images/ico_counselor.svg';
import { ReactComponent as IconCoupon } from 'resource/images/ico_coupon.svg';
import { ReactComponent as IconCredit } from 'resource/images/ico_credit.svg';
import { ReactComponent as IconNotice } from 'resource/images/ico_notice.svg';
import { ReactComponent as IconQuestion } from 'resource/images/ico_question.svg';
import { ReactComponent as IconReceipt } from 'resource/images/ico_receipt.svg';
import { ReactComponent as IconSiren } from 'resource/images/ico_siren.svg';
import semverLt from 'semver/functions/lt';
import s from 'styles/components/SideBar.module.scss';
import { EGuideTab } from 'types/App';
import { moveToPage } from 'utils/path';
import { validAppVersion } from 'utils/tmapInApp';
import tmapWrapper from 'utils/wrappers/TMapWrapper';

const SideItem = ({ title, icon, onClick = () => {} }) => (
  <li
    className={s.item}
    onClick={onClick}
  >
    <i className={s.icon}>{icon}</i>
    <span className={s.title}>{title}</span>
  </li>
);

type TProps = {
  isDimmedVisible: boolean;
  isSidebarVisible: boolean;
  hide: VoidFunction;
};

const isUnder9_14_0 = validAppVersion && semverLt(validAppVersion, '9.14.0');

const SideBar = ({ isSidebarVisible, isDimmedVisible, hide }: TProps) => {
  const { openGuidePage } = useOpenPage();
  const menuLogger = usePmLogger(LogPageId.Menu, isSidebarVisible);
  const { isRiding } = usePmUsingStatus();

  const KICK_BOARD_MENU_LIST = useMemo(() => {
    return [
      {
        icon: <IconReceipt />,
        title: '결제 내역',
        onClick: () => {
          tmapWrapper.startPaymentActivity('/payment/history?productionid=KICK_BOARD');
          menuLogger.sendClickLog('tap.menu_history');
        },
      },
      {
        icon: <IconQuestion />,
        title: '이용 가이드',
        onClick: () => {
          openGuidePage();
          menuLogger.sendClickLog('tap.menu_guide');
        },
      },
      {
        icon: <IconCredit />,
        title: '결제 수단 선택',
        onClick: () => {
          if (isRiding) {
            tmapWrapper.makeToast('기기를 이용 중에는 결제수단을 변경할 수 없습니다.');
            return;
          }
          tmapWrapper.startPaymentActivity('/payment/card/update?productionid=KICK_BOARD');
          menuLogger.sendClickLog('tap.menu_payment');
        },
      },
      {
        icon: <IconSiren />,
        title: '사고/고장 신고',
        onClick: () => {
          menuLogger.sendClickLog('tap.menu_report');
          moveToPage(Paths.PmGuide, {
            type: EGuideTab.REPORT,
          });
        },
      },
    ];
  }, [openGuidePage, isRiding, menuLogger]);

  const MENU_LIST = useMemo(
    () => [
      ...(isUnder9_14_0
        ? [
            {
              icon: <IconNotice />,
              title: '공지사항',
              onClick: () => {
                tmapWrapper.openServiceByName('notice');
                menuLogger.sendClickLog('tap.menu_notice');
              },
            },
            {
              icon: <IconCoupon />,
              title: '이벤트/쿠폰',
              onClick: () => {
                tmapWrapper.openServiceByName('promotion');
                menuLogger.sendClickLog('tap.menu_event');
              },
            },
          ]
        : [
            {
              icon: <IconNotice />,
              title: '공지사항/이벤트',
              onClick: () => {
                tmapWrapper.openServiceByName('communication');
                menuLogger.sendClickLog('tap.menu_notice');
              },
            },
            {
              icon: <IconCoupon />,
              title: '제휴쿠폰',
              onClick: () => {
                tmapWrapper.openServiceByName('promotion');
                menuLogger.sendClickLog('tap.menu_event');
              },
            },
          ]),

      {
        icon: <IconContact />,
        title: '고객센터',
        onClick: () => {
          tmapWrapper.openCustomerCenter();
          menuLogger.sendClickLog('tap.menu_customerservice');
        },
      },
    ],
    [menuLogger]
  );

  return (
    <div>
      <div
        className={classNames(s.dimmed, {
          [s.is_show]: isDimmedVisible,
          [s.animation]: isSidebarVisible,
        })}
        onClick={() => {
          menuLogger.sendClickLog('tap.backscreen');
          hide();
        }}
      />

      <div
        className={classNames(s.side_wrap, {
          [s.is_show]: isDimmedVisible,
        })}
      >
        <div className={s.menu_wrap}>
          <ul>
            {KICK_BOARD_MENU_LIST.map((v) => (
              <SideItem
                key={v.title}
                {...v}
              />
            ))}
          </ul>
          <div className={s.bar} />
          <ul>
            {MENU_LIST.map((v) => (
              <SideItem
                key={v.title}
                {...v}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
