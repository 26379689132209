import { EApiResponseCode } from 'types/Api';
import { ECompanyType, EMarkerType, EVehicleType } from 'types/App';
import { TVehicles } from 'types/api/Vehicle';

const defaultVehicleList: TVehicles[] = [
  {
    company: ECompanyType.TMAP,
    viewType: EMarkerType.DEVICE,
    vehicleType: EVehicleType.BIKE,
    vehicleId: 'YK3W9Q',
    parkingImage: null,
    isAllowBeep: false,
    battery: 39,
    lon: 126.98594194193069,
    lat: 37.568995912704631,
    count: 1,
    chargeFee: null,
    chargeTime: null,
    availableDistance: null,
    availableTime: null,
    event: false,
    isAllowPause: true,
    allowBeep: false,
    allowPause: true,
    unlockFee: 500,
  },
];

export class MockVehicleListApi {
  loading = false;
  loaded = true;
  data = {
    vehicles: defaultVehicleList,
  };
  code = EApiResponseCode.SUCCESS;
}
