import { datadogLogs } from '@datadog/browser-logs';
import { useCallback, useMemo } from 'react';
import { ECompanyType } from 'types/App';

import useUserInfo from './useUserInfo';

export enum ELogStep {
  TRY_OPEN_CAMERA = 'Try Open Camera',
  CAMERA_CALLBACK = 'Camera Callback',
  REQUEST_API = 'Request Api',
  UPLOAD_API_ERROR = 'API Error',
}

type TGuideLogParams = {
  company?: ECompanyType;
  vehicleId?: string;
  tripId?: number;
};

const useDatadogLog = () => {
  const { pmStatus } = useUserInfo();
  const tripStatus = useMemo(() => pmStatus.tripInfo, [pmStatus.tripInfo]);

  const sendCameraLog = useCallback(
    (step: ELogStep, message: string, data = {}) => {
      datadogLogs.logger.error(`[CameraLog] ${tripStatus} - ${step} ${message}`, {
        data: {
          tripStatus,
          ...data,
        },
      });
    },
    [tripStatus]
  );

  const sendGuideLayerLog = useCallback((message: string, data: TGuideLogParams) => {
    datadogLogs.logger.error(
      `[GuideLayerLog] ${[data.company, data.tripId, data.vehicleId].join('|')} ${message}`,
      {
        data,
      }
    );
  }, []);

  return {
    sendCameraLog,
    sendGuideLayerLog,
  };
};

export default useDatadogLog;
