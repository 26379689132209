import { EApiResponseCode } from 'types/Api';
import { EReturnPaymentStatus } from 'types/App';

const successData = {
  tripId: 110,
  tripOrderId: 112,
  orderId: 20720,
  status: EReturnPaymentStatus.PURCHASE_SUCCESS,
  companyCode: 'TMAP',
  companyName: 'TMAP',
  vehicleId: 'PLQ7UT',
  useInfo: [
    {
      name: '이용일시',
      value: '2023-10-06 (금) 15:19',
    },
    {
      name: '이동거리',
      value: '0.0km',
    },
    {
      name: '이용시간',
      value: '총 0분 48초',
    },
  ],
  feeInfo: [
    {
      name: '잠금해제',
      value: 400,
    },
    {
      name: '라이딩',
      value: 150,
    },
    {
      name: '반납 추가 비용',
      value: 0,
    },
  ],
  discountInfo: [
    {
      name: '포인트 사용',
      value: -55,
    },
  ],
  amount: 495,
  purchasePayMeanInfo: {
    status: null,
    statusName: null,
    payMeansDisplayName: '하나카드',
    payMeansDisplayNumber: '**56',
    paymentGroup: 'CARD_PAYMENT',
    payMeansImageInfo: {
      paymentType: 'CARD',
      displayName: '하나카드',
      bgColor: '{bg컬러코드}',
      ciImageUrl: '{CI이미지URL}',
      symbolImageUrl: '{심볼이미지URL}',
      ciBgColor: '{CI컬러코드}',
      symbolBgColor: '{심볼컬러코드}',
    },
  },
  orderPayMeanInfo: null,
  failMessage: null,
  pinRequired: null,
};

const failData = {
  tripId: 110,
  tripOrderId: 112,
  orderId: 20720,
  status: EReturnPaymentStatus.PURCHASE_FAIL,
  companyCode: 'TMAP',
  companyName: 'TMAP',
  vehicleId: 'PLQ7UT',
  useInfo: [
    {
      name: '이용일시',
      value: '2023-10-06 (금) 15:19',
    },
    {
      name: '이동거리',
      value: '0.0km',
    },
    {
      name: '이용시간',
      value: '총 0분 48초',
    },
  ],
  feeInfo: [
    {
      name: '잠금해제',
      value: 400,
    },
    {
      name: '라이딩',
      value: 150,
    },
    {
      name: '반납 추가 비용',
      value: 0,
    },
  ],
  discountInfo: [
    {
      name: '포인트 사용',
      value: -55,
    },
  ],
  amount: 495,
  purchasePayMeanInfo: {
    status: null,
    statusName: null,
    payMeansDisplayName: '하나카드',
    payMeansDisplayNumber: '**56',
    paymentGroup: 'CARD_PAYMENT',
    payMeansImageInfo: {
      paymentType: 'CARD',
      displayName: '하나카드',
      bgColor: '{bg컬러코드}',
      ciImageUrl: '{CI이미지URL}',
      symbolImageUrl: '{심볼이미지URL}',
      ciBgColor: '{CI컬러코드}',
      symbolBgColor: '{심볼컬러코드}',
    },
  },
  orderPayMeanInfo: null,
  failMessage: null,
  pinRequired: null,
};

export class MockTripOrderInfo {
  code = EApiResponseCode.SUCCESS;
  message = '성공';
  data;

  constructor({ isSuccess = true }: { isSuccess?: boolean }, object: any = {}) {
    if (isSuccess) {
      this.data = { ...successData, ...object };
    } else {
      this.data = { ...failData, ...object };
    }
  }
}
