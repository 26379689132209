import Vsm, { MapOptions } from '@vsm/vsm';
import '@vsm/vsm/dist/vsm.css';
import useMapStore from 'hooks/useMapStore';
import { memo, useCallback, useEffect, useRef } from 'react';
import mapStore from 'store/mapStore';
import s from 'styles/components/VSMMap.module.scss';

type TProps = {
  windowSize?: { width: number | string; height: number | string };
  initOptions?: Partial<MapOptions>;
  enoughSpecOption?: Vsm.Extensions.TmapUtils.isEnoughSpecOptions;

  onClick?: (e) => void;
  onMoveEnd?: (e) => void;
  onConfigLoad?: (e) => void;
  onDragStart?: (e) => void;
  onPinchStart?: (e) => void;
  onPinchEnd?: (e) => void;
};

export const VSMMap = ({
  windowSize = { width: '100%', height: '100%' },
  onClick,
  onMoveEnd,
  onConfigLoad,
  onDragStart,
  onPinchStart,
  onPinchEnd,
}: TProps) => {
  const { map } = useMapStore();
  const refWrapper = useRef<HTMLDivElement>(null);

  const handleClickMap = useCallback((e) => onClick?.(e), [onClick]);
  const handleMoveEndMap = useCallback((e) => onMoveEnd?.(e), [onMoveEnd]);
  const handleConfigLoad = useCallback((e) => onConfigLoad?.(e), [onConfigLoad]);
  const handleDragStart = useCallback((e) => onDragStart?.(e), [onDragStart]);
  const handlePitchStart = useCallback((e) => onPinchStart?.(e), [onPinchStart]);
  const handlePitchEnd = useCallback((e) => onPinchEnd?.(e), [onPinchEnd]);

  useEffect(() => {
    if (refWrapper.current) {
      const result = mapStore.setMap(refWrapper.current);

      if (!result) {
        return;
      }

      if (result instanceof HTMLElement) {
        refWrapper.current.parentElement?.replaceChild(result, refWrapper.current);
      }
    }

    return () => {
      // TMAPPM-278 다시 mount 했을 때 vector tile 이 노출되지 않는 이슈
      // map?.destroy();
    };
  }, []);

  useEffect(() => {
    map?.on(Vsm.Map.EventNames.Click, handleClickMap);
    map?.on(Vsm.Map.EventNames.MoveEnd, handleMoveEndMap);
    map?.on(Vsm.Map.EventNames.ConfigLoad, handleConfigLoad);
    map?.on(Vsm.Map.EventNames.DragStart, handleDragStart);
    map?.on(Vsm.Map.EventNames.PitchStart, handlePitchStart);
    map?.on(Vsm.Map.EventNames.PitchEnd, handlePitchEnd);

    return () => {
      map?.off(Vsm.Map.EventNames.Click, handleClickMap);
      map?.off(Vsm.Map.EventNames.MoveEnd, handleMoveEndMap);
      map?.off(Vsm.Map.EventNames.ConfigLoad, handleConfigLoad);
      map?.off(Vsm.Map.EventNames.DragStart, handleDragStart);
      map?.off(Vsm.Map.EventNames.PitchStart, handlePitchStart);
      map?.off(Vsm.Map.EventNames.PitchEnd, handlePitchEnd);
    };
  }, [
    handleClickMap,
    handleMoveEndMap,
    handleConfigLoad,
    handleDragStart,
    handlePitchStart,
    handlePitchEnd,
    map,
  ]);

  return (
    <div
      ref={refWrapper}
      style={windowSize}
      className={s.map_wrap}
    />
  );
};

export default memo(VSMMap);
