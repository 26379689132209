import BottomButton from 'component/BottomButton';
import { PaymentCardTitle } from 'component/PaymentCardTitle';
import PmReturnTitle from 'component/pmReturn/PmReturnTitle';
import TmdsButton from 'component/tmds/TmdsButton';
import { useMemo } from 'react';
import { ReactComponent as IconArrow } from 'resource/images/icon-arrow-right-gray-16.svg';
import s from 'styles/components/PmReturn.module.scss';
import { EReturnPaymentStatus, TCardInfo, TPaymentInfo } from 'types/App';
import { setComma } from 'utils/formatter';

type TProps = {
  paymentInfo: TPaymentInfo;
  onChangePayment?: VoidFunction;
  onRetryPurchase?: VoidFunction;
  onEnterPin?: VoidFunction;
  hideErrorMessage?: boolean;
};

const PmReturnFail = ({
  paymentInfo,
  onChangePayment,
  onRetryPurchase,
  onEnterPin,
  hideErrorMessage = false,
}: TProps) => {
  const isPinRequired = useMemo(() => paymentInfo.pinRequired, [paymentInfo.pinRequired]);

  // 카드정보를 두개를 머지해야함 https://tmobi.atlassian.net/browse/TMAPPAY-2456
  const cardInfo = useMemo<Nullable<TCardInfo>>(() => {
    if (paymentInfo.purchasePayMeanInfo || paymentInfo.orderPayMeanInfo) {
      const purchasePayMeanInfo = paymentInfo.purchasePayMeanInfo;
      const orderPayMeanInfo = paymentInfo.orderPayMeanInfo;
      return {
        status: purchasePayMeanInfo?.status || orderPayMeanInfo?.status,
        statusName: purchasePayMeanInfo?.statusName || orderPayMeanInfo?.statusName,
        payMeansDisplayName:
          purchasePayMeanInfo?.payMeansDisplayName || orderPayMeanInfo?.payMeansDisplayName,
        payMeansDisplayNumber:
          purchasePayMeanInfo?.payMeansDisplayNumber || orderPayMeanInfo?.payMeansDisplayNumber,
        paymentGroup: purchasePayMeanInfo?.paymentGroup || orderPayMeanInfo?.paymentGroup,
        payMeansImageInfo:
          purchasePayMeanInfo?.payMeansImageInfo || orderPayMeanInfo?.payMeansImageInfo,
        pointInfo: purchasePayMeanInfo?.pointInfo || orderPayMeanInfo?.pointInfo,
      };
    }

    return null;
  }, [paymentInfo]);

  return (
    <>
      <div className={s.title_wrap}>
        <PmReturnTitle type={paymentInfo.status} />
        {paymentInfo.failMessage && !hideErrorMessage && (
          <div className={s.fail_message_wrap}>
            <span>{paymentInfo.failMessage}</span>
          </div>
        )}
      </div>

      <div className={s.item_wrapper}>
        <div className={s.item}>
          <span className={s.item_name}>최종 결제 금액</span>
          <span className={s.amount}>{setComma(paymentInfo.amount)}원</span>
        </div>

        {cardInfo && (
          <div className={s.item}>
            <span className={s.item_name}>결제수단</span>
            <PaymentCardTitle {...cardInfo} />
          </div>
        )}
      </div>

      <ul className={s.retry_guide}>
        {paymentInfo.status === EReturnPaymentStatus.PENDING ? (
          <li>안전한 거래를 위해 결제비밀번호 입력이 필요합니다.</li>
        ) : (
          <>
            <li>체크카드 잔액 부족 시 계좌에 잔액 입금 후 재시도 해주세요.</li>
            <li>
              사용 불가한 카드이거나 다른 결제 수단으로 결제를 원할 경우 결제 수단 변경 후 재시도
              해주세요.
            </li>
            <li>
              결제가 계속 실패할 경우 ‘서비스 상단 더보기 메뉴 &gt; 고객센터’ 로 문의 주시기
              바랍니다.
            </li>
          </>
        )}
      </ul>
      <div className={s.bottom}>
        <div className={s.card_change}>
          <TmdsButton
            color="blue-naked"
            size="large"
            onClick={onChangePayment}
            data-cy="changePayment"
          >
            결제수단 변경하기
            <i>
              <IconArrow />
            </i>
          </TmdsButton>
        </div>
        <BottomButton onClick={isPinRequired ? onEnterPin : onRetryPurchase}>
          {isPinRequired ? '결제비밀번호 입력' : '결제 재요청'}
        </BottomButton>
      </div>
    </>
  );
};

export default PmReturnFail;
