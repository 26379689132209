import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'resource/images/btn-input-delete.svg';
import s from 'styles/components/tmds/TmdsSingleTextField.module.scss';

type TProps = {
  label?: string;
  forwardRef?: any;
  onClickClear?: () => void;
  autoFocus?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TmdsSingleTextField = ({ label, autoFocus, ...props }: TProps) => {
  const ref = useRef<Nullable<HTMLInputElement>>(null);
  const refIsPressing = useRef(false);
  const [isFocus, setIsFocus] = useState(autoFocus);
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('focus', () => {
        setIsFocus(true);
      });

      ref.current.addEventListener('focusout', () => {
        if (!refIsPressing.current) {
          setIsFocus(false);
        }
      });
    }
  }, []);

  return (
    <div className={classNames(s.wrapper)}>
      {label && <div className={s.label}>{label}</div>}
      <div
        className={classNames(s.input_wrapper)}
        onMouseDown={() => {
          refIsPressing.current = true;
        }}
        onTouchStart={() => {
          refIsPressing.current = true;
        }}
        onMouseUp={() => {
          refIsPressing.current = false;
        }}
        onTouchEnd={() => {
          refIsPressing.current = false;
        }}
      >
        <input
          {...props}
          onChange={(e) => {
            setValue(e.target.value);
            props.onChange?.(e);
          }}
          className={classNames(s.input, props.className)}
          ref={(inputRef) => {
            ref.current = inputRef;
            if (props.forwardRef) {
              props.forwardRef.current = inputRef;
            }
          }}
          autoFocus={autoFocus}
        />
        {isFocus && value && (
          <i className={s.icon}>
            <DeleteIcon
              onClick={() => {
                props.onClickClear?.();
                if (ref.current) {
                  ref.current.value = '';
                  ref.current.focus();
                  refIsPressing.current = false;
                }
              }}
            />
          </i>
        )}
      </div>
    </div>
  );
};

export default forwardRef<HTMLInputElement, TProps>((props, ref) => (
  <TmdsSingleTextField
    {...props}
    forwardRef={ref}
  />
));
