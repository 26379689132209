import ObjectViewer from 'component/dev/ObjectViewer';
import { useAccessKey } from 'hooks/useAccessKey';
import usePmInit from 'hooks/usePmPageLaunch';
import { usePmUsingStatus } from 'hooks/usePmUsingStatus';
import useUserInfo from 'hooks/useUserInfo';
import { getApi, postApi } from 'utils/fetcher';
import ua from 'utils/uaParser';

const ProtoUserInfoPage = () => {
  const accessKey = useAccessKey();
  const { pmLaunchStatus } = useUserInfo();
  const { hasLicense } = usePmUsingStatus();

  usePmInit();

  return (
    <div style={{ lineBreak: 'anywhere', overflow: 'scroll' }}>
      <br />
      <br />
      <br />
      <h1>약관동의상태</h1>
      {Object.keys(pmLaunchStatus?.data.needCompanyTerm || {}).map((company) => {
        const agree = !pmLaunchStatus?.data.needCompanyTerm?.[company];

        return (
          <div>
            <p>
              {company} - {`${agree}`}{' '}
              <button
                onClick={async () => {
                  const data = await getApi(`/api/term/company/${company}/terms`);
                  const agrees = data.termList.map((item) => ({
                    code: item.code,
                    status: agree ? 'DISAGREE' : 'AGREE',
                  }));

                  postApi(`/api/term/company/${company}/termsAgree`, {
                    agrees,
                    userOs: ua.userOs,
                  })
                    .then((e) => {
                      window.location.reload();
                    })
                    .catch(() => {});
                }}
              >
                ([click] {`${agree} => ${!agree}`} 로 변경)
              </button>
            </p>
          </div>
        );
      })}
      <br />
      <br />
      <br />
      <h1>운전면허상태</h1>
      <p>
        {hasLicense ? '등록됨' : '등록필요'}{' '}
        {hasLicense && (
          <button
            onClick={() => {
              postApi('/api/user/licenseDelete')
                .then(() => {
                  window.location.reload();
                })
                .catch(() => {});
            }}
          >
            ([click] 면허삭제)
          </button>
        )}
      </p>

      <br />
      <br />
      <br />

      <ObjectViewer object={{ accessKey }} />
      <ObjectViewer object={{ pmLaunchStatus }} />

      <br />
    </div>
  );
};

export default ProtoUserInfoPage;
