import Vsm, { GeometryStyleOptions, GeometryType, StackSpec, StackStyles } from '@vsm/vsm';
import { LAYER_TEMPLATE, VIEW_LEVEL_ALL } from 'constant/Map';
import { useCallback, useState } from 'react';
import { EGeometryType } from 'types/App';
import { EMapGeometry } from 'types/Map';
import { cloneDeep, merge } from 'utils/lodash';

import useMapStore from './useMapStore';

type TLayerOption = { viewLevels?: number[] };

type TAddVectorTileParam = {
  version: string;
  name: string;
  uri: string;
  stacks?: Array<
    StackSpec & {
      style: StackStyles;
    }
  >;
};

const StackGeometryType: Record<EMapGeometry, GeometryType> = {
  [EMapGeometry.POINT]: 'POINT',
  [EMapGeometry.LINE_STRING]: 'LINE',
  [EMapGeometry.POLYGON]: 'POLYGON',
  [EMapGeometry.MULTI_POLYGON]: 'POLYGON',
};

let baseStackId = 100;
let baseLayerId = 1000;
let baseStyleId = 9000;

const getLayerOption = ({ type, coordinates, option }) => {
  const layerId = baseLayerId++;
  const stackId = baseStackId++;
  const template = cloneDeep(LAYER_TEMPLATE);
  const geometryType = StackGeometryType[type];

  return {
    layerId,
    stackId,
    option: merge(template, {
      id: layerId,
      name: type,
      map: {
        format: 'geojson',
        preventOverlap: true,
        data: {
          geometry: {
            type,
            coordinates,
          },
          properties: {
            stackId,
          },
        },
      },
      stacks: [
        {
          ...LAYER_TEMPLATE.stacks[0],
          id: stackId,
          geometryType,
          viewLevels: option?.viewLevels || VIEW_LEVEL_ALL,
        },
      ],
    }),
  };
};

const useMapPolygon = () => {
  const { map, isRenderCompleted } = useMapStore();

  const [isLayerRendered, setLayerRendered] = useState(false);
  const [isVectorTileRendered, setVectorTileRendered] = useState(false);

  const addStyle = useCallback(
    ({ layerId, stackId }, style?: GeometryStyleOptions) => {
      if (style) {
        map?.addStyle(layerId, {
          [stackId]: [
            {
              id: 0,
              condition: '',
              priority: 1,
              geometry: style,
            },
          ],
        });
      }
    },
    [map]
  );

  const addPoint = useCallback(
    (coordinates, option?: TLayerOption) => {
      const type = EMapGeometry.POINT;
      const layerOption = getLayerOption({ type, coordinates, option });

      map?.addLayer(layerOption.option);
    },
    [map]
  );

  const addLineString = useCallback(
    (coordinates, strokeStyle?: GeometryStyleOptions, option?: TLayerOption) => {
      const type = EMapGeometry.LINE_STRING;
      const layerOption = getLayerOption({ type, coordinates, option });

      addStyle(layerOption, strokeStyle);
      requestAnimationFrame(() => {
        map?.addLayer(layerOption.option);
      });

      return layerOption;
    },
    [map, addStyle]
  );

  const addPolygon = useCallback(
    (coordinates, polygonStyle?: GeometryStyleOptions, option?: TLayerOption) => {
      const type = EMapGeometry.POLYGON;
      const layerOption = getLayerOption({ type, coordinates, option });

      addStyle(layerOption, polygonStyle);
      requestAnimationFrame(() => {
        map?.addLayer(layerOption.option);
      });

      return layerOption;
    },
    [map, addStyle]
  );

  const addFeatureCollection = useCallback(
    (coordinates, { type, stackId, option }) => {
      const features = coordinates.map((coord) => ({
        type: 'Feature',
        geometry: {
          type,
          coordinates: type === EMapGeometry.LINE_STRING ? coord : [coord],
        },
        properties: {
          stackId,
        },
      }));

      setLayerRendered(false);
      map?.once(Vsm.Map.EventNames.RenderComplete, () => {
        setLayerRendered(true);
      });

      requestAnimationFrame(() => {
        map?.addLayer(
          merge(option, {
            map: {
              data: {
                type: 'FeatureCollection',
                features,
                geometry: null,
                properties: null,
              },
            },
          })
        );
      });
    },
    [map]
  );

  const addLineFeatureCollection = useCallback(
    (coordinates, strokeStyle?: GeometryStyleOptions, option?: TLayerOption) => {
      const type = EMapGeometry.LINE_STRING;
      const layerOption = getLayerOption({ type, coordinates, option });

      addStyle(layerOption, strokeStyle);
      addFeatureCollection(coordinates, { type, ...layerOption });

      return layerOption;
    },
    [addStyle, addFeatureCollection]
  );

  const addPolygonFeatureCollection = useCallback(
    (coordinates, polygonStyle?: GeometryStyleOptions, option?: TLayerOption) => {
      const type = EMapGeometry.POLYGON;
      const layerOption = getLayerOption({ type, coordinates, option });

      addStyle(layerOption, polygonStyle);
      addFeatureCollection(coordinates, { type, ...layerOption });

      return layerOption;
    },
    [addStyle, addFeatureCollection]
  );

  const addMultiPolygonFeatureCollection = useCallback(
    (coordinates, polygonStyle?: GeometryStyleOptions, option?: TLayerOption) => {
      const type = EMapGeometry.MULTI_POLYGON;
      const layerOption = getLayerOption({ type, coordinates, option });

      addStyle(layerOption, polygonStyle);
      addFeatureCollection(coordinates, { type, ...layerOption });

      return layerOption;
    },
    [addStyle, addFeatureCollection]
  );

  const addVectorTile = useCallback(
    ({ version, name, uri, stacks = [] }: TAddVectorTileParam) => {
      const layerId = baseLayerId++;
      const type = EGeometryType.POLYGON;
      const stackGeometryType = StackGeometryType[type];

      setVectorTileRendered(false);
      map?.once(Vsm.Map.EventNames.RenderComplete, () => {
        setVectorTileRendered(true);
      });

      map?.addLayer({
        id: layerId,
        name,
        type: name,
        map: {
          format: 'mvt',
          version,
          preventOverlap: true,
          uri,
        },
        stacks: stacks.map((s) => ({
          id: s.id,
          name: s.name,
          properties: s.properties,
          geometryType: stackGeometryType,
          viewLevels: s.viewLevels || VIEW_LEVEL_ALL,
        })),
      });

      map?.addStyle(
        layerId,
        stacks.reduce(
          (obj, s) => ({
            ...obj,
            [s.id]: [
              {
                id: baseStyleId++,
                condition: '',
                priority: 1,
                ...s.style,
              },
            ],
          }),
          {}
        )
      );
      return { layerId };
    },
    [map]
  );

  const repaintByProperties = useCallback(
    ({ layerId, key, value }) => {
      // @ts-ignore
      const layer = map?.getLayer(layerId);

      if (!layer) {
        return;
      }
      const stacks: any[] = layer.getStacks();

      const stackIdMap = stacks.reduce((acc, stack) => {
        const id = stack.getId();
        acc[id] = stack;
        return acc;
      }, {});

      const selectedStackIds: number[] = [];

      stacks.forEach((stack) => {
        const stackValue = stack.getProperties()[key];
        if (stackValue === value) {
          selectedStackIds.push(stack.getId());
        }
        stack.setVisibility(false);
      });

      selectedStackIds.forEach((id) => {
        stackIdMap[id]?.setVisibility(true);
      });

      requestAnimationFrame(() => {
        map?.requestRepaint();
      });
    },
    [map]
  );

  return {
    isRenderCompleted,
    isLayerRendered,
    isVectorTileRendered,
    addPoint,
    addLineString,
    addPolygon,
    addLineFeatureCollection,
    addPolygonFeatureCollection,
    addMultiPolygonFeatureCollection,
    addVectorTile,
    repaintByProperties,
  };
};

export default useMapPolygon;
