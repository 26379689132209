import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import useAdditionalLocationSearch from './useAdditionalLocationSearch';

export const useComponentQuery = (uniquePopupQuery: Record<string, string>) => {
  const history = useHistory();
  const {
    currentQuery,
    includeQuery: popupIncludeQuery,
    includeLocation: popupIncludeLocation,
  } = useAdditionalLocationSearch(uniquePopupQuery);

  const isComponentVisible = useMemo(() => {
    const keys = Object.keys(popupIncludeQuery);
    const isUniqueMatch = keys.reduce((prev, cur) => {
      return prev && currentQuery[cur] === popupIncludeQuery[cur];
    }, true);

    return isUniqueMatch;
  }, [currentQuery, popupIncludeQuery]);

  const show = useCallback(() => {
    if (!window.location.href.includes(popupIncludeLocation)) {
      history.push(popupIncludeLocation);
    }
  }, [history, popupIncludeLocation]);

  const hide = useCallback(() => {
    history.goBack();
  }, [history]);

  return {
    show,
    hide,
    visible: isComponentVisible,
  };
};
