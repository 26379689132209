import classnames from 'classnames';
import { PropsWithChildren } from 'react';
import s from 'styles/components/BottomSheet.module.scss';

type TProps = PropsWithChildren<{ className?: string }>;

export const BottomSheet = ({ children, className = '' }: TProps) => {
  return <div className={classnames(s.wrap, className)}>{children}</div>;
};

export default BottomSheet;
