import { ECompanyType, EVehicleType } from 'types/App';

type TVehicleTypeItem = {
  group: boolean;
  list: ECompanyType[];
};

export const VEHICLE_TYPE_MAP: Record<EVehicleType, TVehicleTypeItem> = {
  [EVehicleType.ALL]: {
    group: true,
    list: [ECompanyType.TMAP, ECompanyType.XINGXING, ECompanyType.GCOOTER],
  },
  [EVehicleType.BIKE]: {
    group: true,
    list: [ECompanyType.TMAP],
  },
  [EVehicleType.SCOOTER]: {
    group: true,
    list: [ECompanyType.XINGXING, ECompanyType.GCOOTER],
  },
};

export const PRE_PURCHASE_ERROR_CODE = {
  INVALID_ORDER: 'INVALID_ORDER',
  INVALID_REFUND_AMOUNT: 'INVALID_REFUND_AMOUNT',
  INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
  CARD_COMPANY_ERROR: 'CARD_COMPANY_ERROR',
  UNUSABLE_CARD: 'UNUSABLE_CARD',
  UNUSABLE_CARD_EXPIRED: 'UNUSABLE_CARD_EXPIRED',
  UNUSABLE_CARD__EXPIRED: 'UNUSABLE_CARD__EXPIRED',
  OVER_LIMIT_CARD: 'OVER_LIMIT_CARD',
  DAILY_AMOUNT_LIMIT_EXCEEDED: 'DAILY_AMOUNT_LIMIT_EXCEEDED',
  UNSUPPORTED_BUSINESS: 'UNSUPPORTED_BUSINESS',
  PASSWORD_MISMATCH: 'PASSWORD_MISMATCH',
  IMPOSSIBLE_INSTALLMENT: 'IMPOSSIBLE_INSTALLMENT',
  ID_NUMBER_MISMATCH: 'ID_NUMBER_MISMATCH',
  CARD_AND_BANK_CHECK_TIME: 'CARD_AND_BANK_CHECK_TIME',
  BANK_SYSTEM_ERROR: 'BANK_SYSTEM_ERROR',
  BANK_CHECK_TIME: 'BANK_CHECK_TIME',
  BANK_ACCOUNT_NEED_AGREEMENT: 'BANK_ACCOUNT_NEED_AGREEMENT',
  BANK_ACCOUNT__NEED_AGREEMENT: 'BANK_ACCOUNT__NEED_AGREEMENT',
  INSUFFICIENT_CHARGE_BALANCE: 'INSUFFICIENT_CHARGE_BALANCE',
  DELETE_PAY_MEANS: 'DELETE_PAY_MEANS',
  INVALID_ACCOUNT: 'INVALID_ACCOUNT',
  UNREGISTERED_ACCOUNT: 'UNREGISTERED_ACCOUNT',
  EXPIRED_PAYMENT_ID: 'EXPIRED_PAYMENT_ID',
  BLOCKED_PAYMENT: 'BLOCKED_PAYMENT',
  POINT_SYSTEM_ERROR: 'POINT_SYSTEM_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  ETC_ERROR: 'ETC_ERROR',
  CARD_CHECK_TIME: 'CARD_CHECK_TIME',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

const invalidErrorCodes = [
  PRE_PURCHASE_ERROR_CODE.INVALID_ORDER,
  PRE_PURCHASE_ERROR_CODE.INVALID_REFUND_AMOUNT,
  PRE_PURCHASE_ERROR_CODE.INSUFFICIENT_BALANCE,
  PRE_PURCHASE_ERROR_CODE.UNUSABLE_CARD,
  PRE_PURCHASE_ERROR_CODE.UNUSABLE_CARD_EXPIRED,
  PRE_PURCHASE_ERROR_CODE.UNUSABLE_CARD__EXPIRED,
  PRE_PURCHASE_ERROR_CODE.OVER_LIMIT_CARD,
  PRE_PURCHASE_ERROR_CODE.DAILY_AMOUNT_LIMIT_EXCEEDED,
  PRE_PURCHASE_ERROR_CODE.UNSUPPORTED_BUSINESS,
  PRE_PURCHASE_ERROR_CODE.PASSWORD_MISMATCH,
  PRE_PURCHASE_ERROR_CODE.IMPOSSIBLE_INSTALLMENT,
  PRE_PURCHASE_ERROR_CODE.BANK_ACCOUNT_NEED_AGREEMENT,
  PRE_PURCHASE_ERROR_CODE.BANK_ACCOUNT__NEED_AGREEMENT,
  PRE_PURCHASE_ERROR_CODE.INSUFFICIENT_CHARGE_BALANCE,
  PRE_PURCHASE_ERROR_CODE.DELETE_PAY_MEANS,
  PRE_PURCHASE_ERROR_CODE.INVALID_ACCOUNT,
  PRE_PURCHASE_ERROR_CODE.UNREGISTERED_ACCOUNT,
  PRE_PURCHASE_ERROR_CODE.EXPIRED_PAYMENT_ID,
  PRE_PURCHASE_ERROR_CODE.BLOCKED_PAYMENT,
  PRE_PURCHASE_ERROR_CODE.POINT_SYSTEM_ERROR,
];

export const PRE_PURCHASE_ERROR_MESSAGE = {
  [PRE_PURCHASE_ERROR_CODE.INVALID_ORDER]: '유효하지 않은 주문',
  [PRE_PURCHASE_ERROR_CODE.INVALID_REFUND_AMOUNT]: '취소/ 환불 대상금액 초과',
  [PRE_PURCHASE_ERROR_CODE.INSUFFICIENT_BALANCE]: '잔액부족',
  [PRE_PURCHASE_ERROR_CODE.CARD_COMPANY_ERROR]: '카드사 오류',
  [PRE_PURCHASE_ERROR_CODE.UNUSABLE_CARD]: '사용할 수 없는 카드',
  [PRE_PURCHASE_ERROR_CODE.UNUSABLE_CARD_EXPIRED]: '만료된 카드',
  [PRE_PURCHASE_ERROR_CODE.UNUSABLE_CARD__EXPIRED]: '만료된 카드',
  [PRE_PURCHASE_ERROR_CODE.OVER_LIMIT_CARD]: '사용 한도 초과',
  [PRE_PURCHASE_ERROR_CODE.DAILY_AMOUNT_LIMIT_EXCEEDED]: '일일 금액 한도초과',
  [PRE_PURCHASE_ERROR_CODE.UNSUPPORTED_BUSINESS]: '거래제한업종',
  [PRE_PURCHASE_ERROR_CODE.PASSWORD_MISMATCH]: '비밀번호 불일치',
  [PRE_PURCHASE_ERROR_CODE.IMPOSSIBLE_INSTALLMENT]: '할부 불가능',
  [PRE_PURCHASE_ERROR_CODE.ID_NUMBER_MISMATCH]: '주민번호/ 사업자번호 불일치',
  [PRE_PURCHASE_ERROR_CODE.CARD_AND_BANK_CHECK_TIME]: '카드사/은행 점검',
  [PRE_PURCHASE_ERROR_CODE.BANK_SYSTEM_ERROR]: '은행 오류',
  [PRE_PURCHASE_ERROR_CODE.BANK_CHECK_TIME]: '은행 점검',
  [PRE_PURCHASE_ERROR_CODE.BANK_ACCOUNT_NEED_AGREEMENT]: '이체 동의 해지 계좌, 재등록 필요',
  [PRE_PURCHASE_ERROR_CODE.BANK_ACCOUNT__NEED_AGREEMENT]: '이체 동의 해지 계좌, 재등록 필요',
  [PRE_PURCHASE_ERROR_CODE.INSUFFICIENT_CHARGE_BALANCE]: '포인트/잔고 부족',
  [PRE_PURCHASE_ERROR_CODE.DELETE_PAY_MEANS]: '결제수단 삭제',
  [PRE_PURCHASE_ERROR_CODE.INVALID_ACCOUNT]: '사용할 수 없는 계좌',
  [PRE_PURCHASE_ERROR_CODE.UNREGISTERED_ACCOUNT]: '등록되지 않은 계좌',
  [PRE_PURCHASE_ERROR_CODE.EXPIRED_PAYMENT_ID]: '네이버페이 결제수단 재 등록 필요',
  [PRE_PURCHASE_ERROR_CODE.BLOCKED_PAYMENT]: '거래차단',
  [PRE_PURCHASE_ERROR_CODE.POINT_SYSTEM_ERROR]: '포인트 사용 실패',
  [PRE_PURCHASE_ERROR_CODE.NETWORK_ERROR]: '통신 오류',
  [PRE_PURCHASE_ERROR_CODE.SYSTEM_ERROR]: '시스템 오류',
  [PRE_PURCHASE_ERROR_CODE.ETC_ERROR]: '기타 오류',
  [PRE_PURCHASE_ERROR_CODE.CARD_CHECK_TIME]: '카드사 점검',
};

/**
 * 가승인 에러 코드에 따라서 메세지와 타입을 나누어서 반환해주는 함수.
 * 참고: https://tmobi.atlassian.net/browse/TMAPPM-625
 */
export const getPrePurchaseErrorType = (code: string) => {
  const errorType = {
    etcError: {
      type: 'etcError',
      msg: '일시적인 문제가 발생했어요\n다시 시도해 주세요',
    },
    invalidError: {
      type: 'invalidError',
      msg: '결제수단이 유효하지 않아요\n다른 결제수단으로 호출해 주세요',
    },
  } as const;

  return invalidErrorCodes.includes(code) ? errorType.invalidError : errorType.etcError;
};
