import { TPaymentInfo } from 'types/App';
import { getApi, postApi } from 'utils/fetcher';

class TripOrderRepository {
  getTripOrderInfo = (tripOrderId?: number) => {
    return getApi<TPaymentInfo>(`/api/tripOrder/${tripOrderId}/info`);
  };

  postTripOrderPurchase = (tripOrderId: number) => {
    return postApi(`/api/tripOrder/${tripOrderId}/purchase`);
  };
}

const tripOrderRepository = new TripOrderRepository();

export default tripOrderRepository;
