import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { omit } from 'utils/lodash';

export const useAdditionalLocationSearch = (additional = {}) => {
  const { search, pathname } = useLocation();

  const { currentQuery, includeQuery, excludeQuery } = useMemo(() => {
    const current = queryString.parse(decodeURIComponent(search));

    const include = {
      ...current,
      ...additional,
    };

    const exclude = omit({ ...current }, Object.keys(additional));

    return {
      currentQuery: current,
      includeQuery: include,
      excludeQuery: exclude,
    };
  }, [additional, search]);

  return {
    currentQuery,
    includeQuery,
    excludeQuery,

    currentLocation: queryString.stringifyUrl({ url: pathname, query: { ...currentQuery } }),
    includeLocation: queryString.stringifyUrl({
      url: pathname,
      query: { ...includeQuery },
    }),
    excludeLocation: queryString.stringifyUrl({ url: pathname, query: { ...excludeQuery } }),
  };
};

export default useAdditionalLocationSearch;
