import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as IconBubbleTail } from 'resource/images/bubble_tail.svg';
import { ReactComponent as IconExit } from 'resource/images/ico_exit.svg';
import s from 'styles/components/tmds/TmdsTooltipFunction.module.scss';

type TProps = PropsWithChildren & {
  placement: 'topRight';
  title: ReactNode;
  visible?: boolean;
  className?: string;
  onClose?: VoidFunction;
};

const TmdsTooltipFunction = ({
  children,
  placement,
  visible,
  title,
  className,
  onClose,
}: TProps) => {
  if (!visible) {
    return <>{children}</>;
  }
  return (
    <div className={s.wrapper}>
      <div className={classNames(s.tooltip_wrapper, s[placement], className)}>
        <div>{title}</div>
        {onClose && (
          <div
            className={s.close}
            onClick={onClose}
          >
            <IconExit />
          </div>
        )}
        <div className={classNames(s.arrow, s[placement])}>
          <IconBubbleTail />
        </div>
      </div>
      {children}
    </div>
  );
};

export default TmdsTooltipFunction;
