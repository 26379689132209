import BackButton from 'component/BackButton';
import Header from 'component/Header';
import { createPortal } from 'react-dom';
import s from 'styles/components/ImageViewer.module.scss';

type TProps = {
  title: string;
  src: string;
  onClose?: () => void;
};

const ImageViewer = ({ title, src, onClose }: TProps) => {
  return createPortal(
    <div className={s.viewer_wrap}>
      <Header
        title={<span className={s.header}>{title}</span>}
        className={s.header}
        leftButton={
          <BackButton
            className={s.back_button}
            onClick={onClose}
          />
        }
      />
      <div className={s.image_wrap}>
        <img
          src={src}
          alt="이미지"
        />
      </div>
    </div>,
    document.body
  );
};

export default ImageViewer;
