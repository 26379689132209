import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as IconExit } from 'resource/images/ico_exit.svg';
import s from 'styles/components/tmds/TmdsTooltipPolicy.module.scss';

type TProps = PropsWithChildren & {
  placement: 'bottom' | 'top';
  visible?: boolean;
  title: ReactNode;
  subText?: ReactNode;
  onClose?: VoidFunction;
};

const TmdsTooltipPolicy = ({ children, visible, title, subText, placement, onClose }: TProps) => {
  return (
    <>
      {visible && (
        <div className={classNames(s.tooltip_wrapper, s[placement])}>
          <div>
            <div
              className={classNames(s.title, {
                [s.is_no_sub_text]: !subText,
              })}
            >
              {title}
            </div>
            {subText && <div className={s.sub_text}>{subText}</div>}
          </div>
          <div
            className={s.close_button}
            onClick={onClose}
          >
            <IconExit />
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default TmdsTooltipPolicy;
