import { ReactElement, ReactNode } from 'react';
import { ReactComponent as IconBike } from 'resource/images/Icon_Bike.svg';
import { ReactComponent as IconKickboard } from 'resource/images/Icon_Kickboard.svg';
import { ReactComponent as IconBatteryLargeFull } from 'resource/images/battery_large_full.svg';
import { ReactComponent as IconBatteryLargeHalf } from 'resource/images/battery_large_half.svg';
import { ReactComponent as IconBatteryLargeLow } from 'resource/images/battery_large_low.svg';
import { ReactComponent as IconBatterySmallFull } from 'resource/images/battery_small_full.svg';
import { ReactComponent as IconBatterySmallHalf } from 'resource/images/battery_small_half.svg';
import { ReactComponent as IconBatterySmallLow } from 'resource/images/battery_small_low.svg';
import BiChipGcooter from 'resource/images/bi_chip_gcoo.png';
import BiChipTMap from 'resource/images/bi_chip_tmap.png';
import BiChipXing from 'resource/images/bi_chip_xing.png';
import {
  EBatteryState,
  ECompanyName,
  ECompanyType,
  ELicenseCodeType,
  ELottieType,
  EVehicleType,
  TBaseCompanyItem,
} from 'types/App';

import { NUMBER_CLUSTER_LEVEL, VEHICLE_CLUSTER_LEVEL } from './Map';

export const MODAL_TEXT = {
  LOCK_USER: {
    title: '비정상적인 이용으로\n서비스 이용이 제한되었습니다.',
    detail: '이용제한을 해제하시려면\n고객센터로 문의주시기 바랍니다.',
  },
  LOW_BATTERY: {
    title: '기기 배터리 잔량이 부족하여\n대여가 불가합니다.',
  },
  RETURN_LIMIT: {
    title: '출발 전 도착지 주변을 확인해주세요.',
    detailComponent: (
      <>
        <em>대여하신 지역은 반납이 불가하거나 추가요금이 부과되는 지역입니다.</em>
        <br />
        이용하실 기기의 pin을 눌러 도착지 주변을
        <br />
        확인 후 이용해 주세요.
      </>
    ),
  },
  TRY_RETURN_DISABLE: {
    title: '이곳은 반납 금지 구역입니다.\n반납가능한 곳까지 이동하셔서 반납해주세요.',
  },
  NO_DRIVING: {
    title: '{n}분간 움직임이 없습니다. {m}분 이상 정차 시 자동 반납처리 됩니다.',
  },
  PAUSE_RETURN: {
    title: '일시잠금 후 {n}분이 경과하였습니다. {m}분 경과시 자동 반납처리 됩니다.',
  },
  LOW_BATTERY_RETURN: {
    title: '기기 배터리가 {n}% 미만입니다. {m}% 미만 시 자동 반납처리 됩니다.',
  },
  INVALID_CODE: {
    title: '기기를 찾을 수 없습니다\n입력하신 코드를 확인해 주세요.',
  },
  UNPAID: {
    title: '미결제 내역이 있습니다.\n미결제 내역이 있을 시\n서비스 이용이 제한됩니다.',
  },
  LICENSE_SUCCESS: {
    title: '운전면허 등록이 완료되었습니다.\n안전 라이딩 하세요!',
  },
  LICENSE_FAIL: {
    title: '면허인증에 실패하였습니다.\n입력 정보를 다시 확인해주세요.',
  },
  LOST_VEHICLE: {
    title: '분실 등록된 기기입니다.\n다른 기기를 이용해 주세요.',
  },
  ERROR_VEHICLE: {
    title: '점검중인 기기 입니다.\n다른 기기를 이용해 주세요.',
  },
};

export const LOADING_TEXT: Record<ELottieType, ReactNode> = {
  [ELottieType.UNLOCK]: (
    <>
      <b>잠금 해제 중이에요.</b>안전에 유의하여 주행하세요.
    </>
  ),
  [ELottieType.PAYMENT]: (
    <>
      <b>결제 진행 중입니다.</b>
      잠시만 기다려 주세요.
    </>
  ),
};

export const PM_CARD_HEIGHT = 105;
export const PM_ACTIVE_MARKER_HEIGHT = 56;
export const NOTICE_BANNER_HEIGHT = 50;
export const BOTTOM_SHEET_HEIGHT = 224;
export const MAP_HEIGHT = window.outerHeight - BOTTOM_SHEET_HEIGHT;

export const BASE_COMPANY_LIST: TBaseCompanyItem[] = [
  {
    code: ECompanyType.TMAP,
    name: ECompanyName.TMAP,
    imgIcon: (
      <img
        src={BiChipTMap}
        alt={ECompanyName.TMAP}
        width="20"
        height="20"
      />
    ),
  },
  {
    code: ECompanyType.XINGXING,
    name: ECompanyName.XINGXING,
    imgIcon: (
      <img
        src={BiChipXing}
        alt={ECompanyName.XINGXING}
        width="20"
        height="20"
      />
    ),
  },
  {
    code: ECompanyType.GCOOTER,
    name: ECompanyName.GCOOTER,
    imgIcon: (
      <img
        src={BiChipGcooter}
        alt={ECompanyName.GCOOTER}
        width="20"
        height="20"
      />
    ),
  },
];

export const COMPANY_LIST = BASE_COMPANY_LIST.map((c) => ({ code: c.code, name: c.name }));

export const COMPANY_NAME_MAP = BASE_COMPANY_LIST.reduce(
  (obj, v) => ({
    ...obj,
    [v.code]: v.name,
  }),
  {}
);

export const COMPANY_ICON_MAP = BASE_COMPANY_LIST.reduce(
  (obj, v) => ({
    ...obj,
    [v.code]: v.imgIcon,
  }),
  {}
);

export const LICENSE_TYPE_TEXT: Record<ELicenseCodeType, string> = {
  [ELicenseCodeType.Class1Large]: '1종대형',
  [ELicenseCodeType.Class1Middle]: '1종보통',
  [ELicenseCodeType.Class1Small]: '1종소형',
  [ELicenseCodeType.TowTruckLarge]: '대형견인차 (트레일러)',
  [ELicenseCodeType.TowTruck]: '구난차 (레커)',
  [ELicenseCodeType.TowTruckSmall]: '소형견인차',
  [ELicenseCodeType.Class2Middle]: '2종보통',
  [ELicenseCodeType.Class2Small]: '2종소형',
  [ELicenseCodeType.Class2Motor]: '2종원자',
};

export const VEHICLE_TEXT: Record<EVehicleType, string> = {
  [EVehicleType.ALL]: '모든기종',
  [EVehicleType.BIKE]: '바이크',
  [EVehicleType.SCOOTER]: '킥보드',
};

export const VEHICLE_ICON: Record<EVehicleType, ReactElement | null> = {
  [EVehicleType.ALL]: null,
  [EVehicleType.BIKE]: <IconBike />,
  [EVehicleType.SCOOTER]: <IconKickboard />,
};

export const BATTERY_ICON_MAP: Record<EBatteryState, ReactElement> = {
  [EBatteryState.LOW]: <IconBatterySmallLow />,
  [EBatteryState.NORMAL]: <IconBatterySmallHalf />,
  [EBatteryState.FULL]: <IconBatterySmallFull />,
};

export const BATTERY_ACTIVE_ICON_MAP: Record<EBatteryState, ReactNode> = {
  [EBatteryState.LOW]: <IconBatteryLargeLow />,
  [EBatteryState.NORMAL]: <IconBatteryLargeHalf />,
  [EBatteryState.FULL]: <IconBatteryLargeFull />,
};

export const OFFSET_HEIGHT = -PM_CARD_HEIGHT;

export const getNextZoomLevel = (nowZoom: number) => {
  // https://tmobi.atlassian.net/browse/TMAPPM-450
  //  11 12 13 -> 14
  if (nowZoom < NUMBER_CLUSTER_LEVEL) {
    return NUMBER_CLUSTER_LEVEL;
  }

  // 14 -> 15
  if (nowZoom < VEHICLE_CLUSTER_LEVEL) {
    return VEHICLE_CLUSTER_LEVEL;
  }

  return nowZoom;
};
