import { isProdEnv } from 'constant/Env';
import mixpanel from 'mixpanel-browser';
import { devLog } from 'utils/dev';

export enum EMixPanelToken {
  STAGE = '7600c413c33bbd7e3742b10f1a522f0d',
  PROD = 'cafac3a4974386155257d177fc48437a',
}

type TMeta = {
  pageId?: string;
  serviceId?: string;
};

type TOptions = {
  userKey?: string;
  serviceId?: string;
};

class MixpanelManager {
  private meta: TMeta = {};
  private loaded: boolean = false;
  private logQueue: Array<{ eventName: string; properties?: Record<string, any> }> = [];

  init(options?: TOptions) {
    this.meta.serviceId = options?.serviceId;
    const debugMode = !isProdEnv;
    const token = isProdEnv ? EMixPanelToken.PROD : EMixPanelToken.STAGE;

    devLog('[MIXPANEL] init', token, debugMode);
    mixpanel.init(token, {
      debug: debugMode,
      loaded: () => {
        devLog('[MIXPANEL] init success', token, debugMode);
        this.loaded = true;

        if (options?.userKey) {
          mixpanel.identify(options.userKey);
        }

        this.logQueue.forEach((log) => {
          devLog('[MIXPANEL] send queue', log.eventName, log.properties);
          this.trackEvent(log.eventName, log.properties);
        });

        this.logQueue = [];
      },
    });
  }

  identify(userKey: string) {
    mixpanel.identify(userKey);
  }

  updateMeta(nextMeta: Partial<TMeta>) {
    this.meta = {
      ...this.meta,
      ...nextMeta,
    };
    devLog('[MIXPANEL] update Meta', this.meta);
  }

  sendEvent(actionId?: string, properties?: Record<string, any>) {
    // serviceId:pageId:actionId
    // https://tmobi.atlassian.net/wiki/spaces/TMAPBIZ/pages/139526285#TLA(logbox)--%3E--Mixpanel
    const eventName = [this.meta.serviceId, this.meta.pageId, actionId].filter((n) => n).join(':');

    const log = {
      eventName,
      properties,
    };

    if (this.loaded) {
      this.trackEvent(eventName, { ...properties });
    } else {
      devLog('[MIXPANEL] add queue', eventName, log.properties);

      this.logQueue.push(log);
    }
  }

  private trackEvent(eventName: string, properties?: Record<string, any>) {
    devLog('[MIXPANEL] event', eventName, properties);

    mixpanel.track(eventName, properties);
  }
}

const mixpanelManager = new MixpanelManager();

export default mixpanelManager;
