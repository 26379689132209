import classNames from 'classnames';
import Image from 'component/Image';
import ItemGuide from 'component/guide/GuideItem';
import { GUIDE_LIST } from 'constant/Guide';
import { ReactComponent as IconReturnPay1 } from 'resource/images/icon-guide-3-1.svg';
import { ReactComponent as IconReturnPay2 } from 'resource/images/icon-guide-3-2.svg';
import { ReactComponent as IconReturnPay3 } from 'resource/images/icon-guide-3-3.svg';
import s from 'styles/components/guide/Guide.module.scss';
import guideStyle from 'styles/components/guide/GuideReturn.module.scss';
import { EGuideAnchor } from 'types/App';

const GuideReturn = () => (
  <div className={s.guide_wrap}>
    <div className={s.guide_item}>
      <h3
        className={s.title}
        data-anchor={EGuideAnchor.RETURN_GUIDE}
      >
        반납 방법
      </h3>

      <ItemGuide
        list={[
          {
            SvgComponent: <IconReturnPay1 />,
            title: '안전하게 주차하기!',
            desc: (
              <>
                주변에 피해를 주지 않는 곳에
                <br />
                주차하세요.
              </>
            ),
          },
          {
            SvgComponent: <IconReturnPay2 />,
            title: '반납 사진 찍기',
            desc: (
              <>
                반납 요청 후 주차사진을 촬영해
                <br />
                반납을 완료합니다.
              </>
            ),
          },
          {
            SvgComponent: <IconReturnPay3 />,
            title: '결제 완료!',
            desc: (
              <>
                이용요금은 등록하신 티맵
                <br />
                결제수단으로 자동 결제됩니다.
              </>
            ),
          },
        ]}
      />
    </div>

    <div className={s.guide_item}>
      <h3
        className={s.title}
        data-anchor={EGuideAnchor.RETURN_WARNING}
      >
        반납 시 유의사항
      </h3>

      <div className={s.warn_wrap}>
        <span>
          올바르게 주차하지 않을 경우
          <br />
          추가요금 또는 지자체 정책에 의해
          <br />
          <em>견인/보관료(4~5만원)가 징수</em>될 수 있습니다.
        </span>
      </div>

      <div className={guideStyle.warn_list_wrap}>
        <h4 className={guideStyle.sub_title}>이런곳에 주차하시면 안돼요🚫</h4>

        <ul className={guideStyle.list}>
          {GUIDE_LIST.map(({ text, src, srcSet }) => (
            <li
              key={src}
              className={guideStyle.item}
            >
              <Image
                src={src}
                srcSetList={srcSet}
                className={guideStyle.image}
              />
              <div className={guideStyle.text_wrap}>
                <span>{text}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className={guideStyle.warn_text_wrap}>
        <h4 className={guideStyle.sub_title}>주차 사진은 꼭 찍어주세요 📸</h4>
        <div className={guideStyle.guide_text}>
          <ul>
            {[
              '견인/보관료는 마지막 사용자에게 부과됩니다.',
              '부당한 요금 징수 소명을 위해 주차사진을 증거로 사용합니다.',
            ].map((t, i) => (
              <li
                key={i}
                className={guideStyle.bullet_item}
              >
                <i>-</i>
                <span>{t}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={s.bar} />

      <div className={classNames(guideStyle.bullet_item, guideStyle.notice)}>
        <i>*</i>
        <span>
          견인/보관료 징수는 지자체 교통과 정책으로 견인사유 및 비용은 이용지역 교통과에 문의주시기
          바랍니다.
        </span>
      </div>
    </div>
  </div>
);

export default GuideReturn;
