import { datadogRum } from '@datadog/browser-rum';
import mobileLogger from '@tmap-web-lib/mobile-logger';
import { isLocalEnv, isProdEnv } from 'constant/Env';
import { PATH_PREFIX } from 'constant/RoutePath';
import qs from 'query-string';
import semverValid from 'semver/functions/valid';
import { APP_SCHEME } from 'types/App';
import ua from 'utils/uaParser';

import { devLog } from './dev';

const APP_JS_NAMESPACE = 'TmapApp';
const CALLBACK_JS_NAMESPACE = 'TmapWebView';

export const setCallback = (funcName: string, fn?: Function) => {
  window[CALLBACK_JS_NAMESPACE][funcName] = fn;

  return `${CALLBACK_JS_NAMESPACE}.${funcName}`;
};

export const getCallback = (funcName: string) => {
  return window[CALLBACK_JS_NAMESPACE]?.[funcName];
};

export const validAppVersion = semverValid(ua.tmapAppVersion);

export const parseAppScheme = (landingUrl?: Nullable<string>) => {
  const url = landingUrl || '';
  const serviceName = url.match(/tmap:\/\/(\w+)?/)?.[1];
  const pmLandingScheme = `tmap://${APP_SCHEME}?extra=landing_`;

  if (url.includes(pmLandingScheme)) {
    const targetPage = decodeURIComponent(url.replace(pmLandingScheme, ''));

    return {
      serviceName: APP_SCHEME,
      landing: targetPage ? `${window.location.origin}${PATH_PREFIX}${targetPage}` : undefined,
    };
  }

  if (serviceName) {
    const [, query] = url.split('?');
    const params = qs.parse(query);

    return {
      serviceName: serviceName,
      params,
    };
  }

  return null;
};

const DEFAULT_SCHEME = 'tmapweb://';
const TMAP_SK_SCHEME = 'tmapjs://';
const TMAP_KT_SCHEME = 'tmapkujs://';

class TMapInApp {
  private config = {
    isInApp: ua.isInApp,
    isLocalEnv,
    isProdEnv,
    isIos: ua.isIos,
    isAndroid: ua.isAndroid,
    appVersion: ua.tmapAppVersion,
    isSkIOS: ua.isSkIOS,
    isKuIOS: ua.isKuIOS,
    onBeforeSend: (funcName, data) => {
      datadogRum.addAction(`TMapSender - ${funcName}`, { data });
    },
    onBeforeReceive: (funcName, data) => {
      datadogRum.addAction(`TMapReceiver - ${funcName}`, { data });
    },
  };

  private schemeName: string;

  constructor() {
    this.schemeName = DEFAULT_SCHEME;

    if (ua.isSkIOS) {
      this.schemeName = TMAP_SK_SCHEME;
    } else if (ua.isKuIOS) {
      this.schemeName = TMAP_KT_SCHEME;
    }
  }
  private sendIosApp = (funcName: string, params: Array<{ key: string; value: any }>) => {
    const paramString = params
      .map((n) => `${n.key}=${n.key !== 'url' ? encodeURIComponent(n.value) : n.value}`)
      .join('&');

    const fullScheme = `${this.schemeName}${funcName}?${paramString}`;

    devLog(`[Scheme] ${fullScheme}`);

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', fullScheme);
    document.documentElement.appendChild(iframe);
    iframe.parentNode?.removeChild(iframe);
  };

  private sendAndroidApp = (
    funcName: string,
    params: Array<{ key: string; value: any }> = [],
    isReturnValue: boolean = false
  ) => {
    const callbackFunction = params.find((it) => it.key === 'callJS' || it.key === 'callbackJS');
    const callbackName = callbackFunction?.value?.split('.').pop();
    const callback = getCallback(callbackName);

    if (window[APP_JS_NAMESPACE]?.[funcName]) {
      try {
        let result = window[APP_JS_NAMESPACE][funcName](
          ...params
            .filter((it) => (isReturnValue ? it !== callbackFunction : true))
            .map((n) => n.value)
        );
        result = isReturnValue ? result : true;

        if (isReturnValue && callbackFunction) {
          callback?.(result);
        }
        return result;
      } catch (e) {
        mobileLogger.addLog({
          type: 'ERR',
          title: 'SendErr',
          message: funcName,
        });
      }
    }

    callback?.(false);
  };

  sendWithScheme = (
    funcName: string,
    params: Array<{ key: string; value: any }> = [],
    options?: { isReturnValue: boolean }
  ) => {
    this.config.onBeforeSend(
      funcName,
      params.reduce((acc, now) => {
        acc[now.key] = now.value;
        return acc;
      }, {})
    );

    if (ua.isInApp && ua.isIos) {
      this.sendIosApp(funcName, params);
      return;
    }

    if (ua.isInApp && ua.isAndroid) {
      this.sendAndroidApp(funcName, params, options?.isReturnValue);
    }
  };

  sendAsync = (caller, params): Promise<any[]> => {
    return new Promise((resolve) => {
      const randomNumber = parseInt(`${Math.random() * 1000}`, 10);
      const callbackNameOption = params.find((kv) => kv.key === 'callbackJS')?.value;
      const callbackName = callbackNameOption || `${caller}_callback_${randomNumber}`;

      (window as any)[callbackName] = (...args) => {
        resolve(args);
      };

      this.sendWithScheme(caller, [
        ...params,
        {
          key: 'callbackJS',
          value: callbackName,
        },
      ]);
    });
  };
}

const tmapInApp = new TMapInApp();

export default tmapInApp;
