import { ReactNode } from 'react';

export type TSchemeParams = Array<{
  key: string;
  value: string | boolean | number;
}>;

export type TPoiDataJson = {
  pkey: string;
  poiId: string;
  navSeq: string;
  poiName: string;
  navX: string;
  navY: string;
  centerX: string;
  centerY: string;
  address: string;
  tel: string;
  rpFlag: number;
};

export enum ERPFlag {
  G000 = 16,
}

export enum ECompanyType {
  GCOOTER = 'GCOOTER',
  XINGXING = 'XINGXING',
  TMAP = 'TMAP',
}

export enum ECompanyName {
  GCOOTER = '지쿠',
  XINGXING = '씽씽',
  TMAP = '티맵 바이크',
}

export enum EBatteryState {
  LOW,
  NORMAL,
  FULL,
}

export enum EMarkerType {
  DEVICE = 'DEVICE',
  CLUSTER = 'CLUSTER',
  ICON = 'ICON',
}

export enum EVehicleType {
  BIKE = 'BIKE',
  SCOOTER = 'SCOOTER',
  ALL = '', // BIKE, SCOOTER 모두
}

export enum ETripStatus {
  START = 'START',
  PAUSE = 'PAUSE',
  END = 'END',
}

export enum ETripStartFailCode {
  IN_USE = 'IN_USE',
}

export enum EServiceAccessStatus {
  REQUIRE_PM_LICENSE = 'REQUIRE_PM_LICENSE', // PM 운전면허 검증 단계
  READY = 'READY', // 서비스 사용 가능
  LOCK = 'LOCK', // 서비스 사용 제한
}

export enum EServiceUserOS {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export type TTerm = {
  code: string;
  description: string;
  essential: boolean;
};

export enum ETermsStatus {
  AGREE = 'AGREE',
  DISAGREE = 'DISAGREE',
}

export enum ESidebarStatus {
  INIT,
  SHOW,
  HIDE,
}

export type TCompanyTermResponse = {
  termList: TTerm[];
  termVersion: string;
};

export enum ELicenseCodeType {
  Class1Large = 11,
  Class1Middle = 12,
  Class1Small = 13,
  TowTruckLarge = 14,
  TowTruck = 15,
  TowTruckSmall = 16,
  Class2Middle = 32,
  Class2Small = 33,
  Class2Motor = 38,
}

export type TDriverLicense = {
  licenseNo1: string;
  licenseNo2: string;
  licenseNo3: string;
  licenseNo4: string;
  licenseSerial?: string;
  name: string;
  licenseTypeCode?: number;
};

export enum EReturnPaymentStatus {
  PENDING = 'PENDING',
  PURCHASE_SUCCESS = 'PURCHASE_SUCCESS',
  PURCHASE_FAIL = 'PURCHASE_FAIL',
}

export enum EPaymentType {
  CARD = 'CARD',
  NAVER_PAY = 'NAVER_PAY',
}

// https://tmobi.atlassian.net/wiki/spaces/TMAPPAY/pages/40698415/API
export enum EPaymentStatus {
  OK = 'OK',
  NO_CARD = 'NO_CARD',
  NO_PIN = 'NO_PIN',
  NO_PAY_MEANS = 'NO_PAY_MEANS',
  EXPIRED = 'EXPIRED',
  MAINTENANCE = 'MAINTENANCE',
  CORPCARD_AUTH_EXPIRED = 'CORPCARD_AUTH_EXPIRED',
  CARD_MAINTENANCE = 'CARD_MAINTENANCE',
  PAY_MAINTENANCE = 'PAY_MAINTENANCE',
}

type TCardImageInfo = {
  paymentType: EPaymentType;
  displayName: string;
  bgColor: string;
  ciImageUrl: string;
  symbolImageUrl: string;
  ciBgColor: Nullable<string>;
  symbolBgColor: Nullable<string>;
};

export type TCardInfo = {
  status: EPaymentStatus;
  statusName: string;
  payMeansDisplayName: string;
  payMeansDisplayNumber: string;
  paymentGroup: string;
  payMeansImageInfo: TCardImageInfo;
  pointInfo: TPaymentPointInfo;
};

export type TDevicePercent = Record<EVehicleType, number>;

export type TPaymentPointInfo = {
  availablePoint: number;
  pointPercent: Record<ECompanyType, TDevicePercent>;
};

export type TPaymentInfo = {
  companyName: string;
  vehicleId: string;
  useInfo: Array<{ name: string; value: string }>;
  feeInfo: Array<{ name: string; value: number }>;
  discountInfo: Array<{ name: string; value: number }>;
  status: EReturnPaymentStatus;
  amount: number;
  companyCode: ECompanyType;
  orderPayMeanInfo: TCardInfo;
  orderId: number;
  failMessage: Nullable<string>;
  purchasePayMeanInfo: TCardInfo;
  tripId: number;
  tripOrderId: number;
  pinRequired: boolean;
};

export enum EAreas {
  SERVICE = 'SERVICE',
  NO_SERVICE = 'NO_SERVICE',
  NO_PARKING = 'NO_PARKING',
  CHARGE_ADDITIONAL = 'CHARGE_ADDITIONAL',
}

export enum EGeometryType {
  POLYGON = 'Polygon',
  MULTI_POLYGON = 'MultiPolygon',
}

export type TCoords = [number, number];
export type TGeometry = {
  type: EGeometryType;
  hashCode: number;
  coordinates: TCoords[] | TCoords[][];
};
export type TArea = {
  additionalFee: null | number;
  type: EAreas;
  geometry: TGeometry;
};

export type TPointInfo = {
  additionalFee: number;
  type: EAreas;
};

export enum EGuideTab {
  BEFORE_USE = 'before',
  USING = 'using',
  RETURN = 'return',
  REPORT = 'report',
}

export type TTripStartResponse = {
  tripId: number;
  event?: boolean;
};

export enum ETripErrorCode {
  TRIP_ALREADY_START = 'TRIP_ALREADY_START',
  TRIP_ALREADY_END = 'TRIP_ALREADY_END',
  TRIP_ONLY_ONE_DEVICE = 'TRIP_ONLY_ONE_DEVICE',
  TRIP_INVALID_SERIAL = 'TRIP_INVALID_SERIAL',
  TRIP_TOO_FAR_AWAY = 'TRIP_TOO_FAR_AWAY',
  TRIP_OVER_BOUNDARY = 'TRIP_OVER_BOUNDARY',
  TRIP_LOW_BATTERY = 'TRIP_LOW_BATTERY',
  TRIP_UNLOCKING_FAILED = 'TRIP_UNLOCKING_FAILED',
  TRIP_LOCKING_FAILED = 'TRIP_LOCKING_FAILED',
  TRIP_NO_PARKING_AREA = 'TRIP_NO_PARKING_AREA',
  TRIP_LOST_VEHICLE = 'TRIP_LOST_VEHICLE',
  TRIP_INSPECTION = 'TRIP_INSPECTION',
}

export enum EMapErrorCode {
  SAME_AREA = 'same area',
}

export enum EGuideAnchor {
  BEFORE_USER_GUIDE = 'BEFORE_USER_GUIDE',
  BEFORE_PRICE_GUIDE = 'BEFORE_PRICE_GUIDE',
  DRIVING_HOW_TO = 'DRIVING_HOW_TO',
  DRIVING_WARNING = 'DRIVING_WARNING',
  RETURN_GUIDE = 'RETURN_GUIDE',
  RETURN_WARNING = 'RETURN_WARNING',
  REPORT = 'REPORT',
}

export enum EMainPopupType {
  NOTICE = 'NOTICE',
  EVENT = 'EVENT',
}

export const APP_SCHEME = 'kickboard';

export type TBaseCompanyItem = {
  code: ECompanyType;
  name: ECompanyName;
  imgIcon: ReactNode;
};

export enum ELottieType {
  UNLOCK = 'UNLOCK',
  PAYMENT = 'PAYMENT',
}
